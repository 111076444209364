import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import './CatalogCommissionRates.scss';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { getBrandBaseCommissionRate, getBrandCommissionRateReturning } from '../../Helpers/catalog_helpers';
import { getBrand } from '../../Helpers/user_helpers';
import commaNumber from 'comma-number';
import CatalogSitewideRateRow from './CatalogSitewideRateRow';
import CatalogCreatorRateRow from './CatalogCreatorRateRow';
import { updateBrand } from '../../Actions/BrandActions';
import { setCustomCommissionRate } from '../../Actions/AnalyticsActions';
import CatalogGroup from './CatalogGroup';
import { getTalentWithRates } from '../../Helpers/talent_helpers';
import { openArtistModal } from '../../Actions/UIActions';
import { getBrandCustomAffiliateRatesMap, getBrandCustomAffiliateRateDisplayVariants } from '../../Helpers/brand_helpers';
import CatalogVariantRateCard from './CatalogVariantRateCard';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmPrompt from '../General/ConfirmPrompt';
import { getAllIntegrationsHaveCustomerScopesEnabled } from '../../Helpers/shopify_helpers';

const CatalogCommissionRates = props => {
  const {
    user,
    ui,
    talent,
    analytics,
    numTotalVariants,
    updateBrand,
    catalogGroups,
    saveGroupCustomAffiliateRate,
    setCustomCommissionRate,
    openArtistModal,
    handleTabChange,
    isLoadingAnalytics,
    controlBarSearchTerm,
    navigateToCatalogGroupProducts,
    canSupportCustomRates
  } = props;

  // Sitewide rates
  const baseCommissionRate = getBrandBaseCommissionRate(user);
  const commissionRateReturning = getBrandCommissionRateReturning(user);
  const brand = getBrand(user);
  const [autoFocusReturningRate, setAutoFocusReturningRate] = useState(false);
  const isSitewideRatesSectionHidden = controlBarSearchTerm.length > 0;

  // Talent rates
  const isLoadingTalentRates = talent.isFetchingInitial || !talent?.talent;
  const talentWithRatesPlaceholders = isLoadingTalentRates ? Array.from({ length: 3 }, (_, i) => ({ id: i + 1 })) : null;
  const talentWithRates = getTalentWithRates(talent);
  const filteredTalentWithRates = controlBarSearchTerm.length
    ? talentWithRates.filter(t => t.name.toLowerCase().includes(controlBarSearchTerm.toLowerCase()))
    : talentWithRates;
  const isTalentRatesSectionHidden = controlBarSearchTerm.length > 0 && !filteredTalentWithRates.length;

  // Catalog group rates
  const { catalogGroupRatesMap } = getBrandCustomAffiliateRatesMap(analytics);
  const isLoadingCatalogGroups = isLoadingAnalytics;
  const catalogGroupPlaceholders = isLoadingCatalogGroups ? Array.from({ length: 3 }, (_, i) => ({ id: i + 1 })) : null;
  const catalogGroupsWithRates = catalogGroups.filter(cg => catalogGroupRatesMap[cg.id]);
  const filteredCatalogGroupsWithRates = controlBarSearchTerm.length
    ? catalogGroupsWithRates.filter(cg => cg.title.toLowerCase().includes(controlBarSearchTerm.toLowerCase()))
    : catalogGroupsWithRates;
  const isCatalogGroupSectionHidden = !canSupportCustomRates || (controlBarSearchTerm.length > 0 && !filteredCatalogGroupsWithRates.length);

  // Product rates
  const isLoadingVariantRates = isLoadingAnalytics;
  const variantDisplayRatePlaceholders = isLoadingVariantRates ? Array.from({ length: 4 }, (_, i) => ({ id: i + 1 })) : null;
  const variantDisplayRates = useMemo(() => {
    if (!analytics?.brandAnalytics?.customAffiliateRates?.length) return [];
    return getBrandCustomAffiliateRateDisplayVariants(analytics);
  }, [analytics?.brandAnalytics?.customAffiliateRates]);
  const filteredVariantDisplayRates = controlBarSearchTerm.length
    ? variantDisplayRates.filter(vr => vr.variantTitle.toLowerCase().includes(controlBarSearchTerm.toLowerCase()))
    : variantDisplayRates;
  const isVariantRatesSectionHidden = !canSupportCustomRates || (controlBarSearchTerm.length > 0 && !filteredVariantDisplayRates.length);

  const handleUpdateBrandRates = async (updates, options) => {
    const { isReturningRateUpdate = false, skipAlert = false } = options || {};
    const result = await updateBrand(brand, updates);

    if (result?.error) {
      window.ALERT.error(`There was an issue updating your sitewide ${isReturningRateUpdate ? 'returning ' : ''}commission rate. Please try again.`, {
        hideAfter: 5
      });
      return false;
    }

    !skipAlert && window.ALERT.success(`Sitewide ${isReturningRateUpdate ? 'returning ' : ''}commission rate updated successfully!`);
    return true;
  };

  const handleRemoveReturningBrandRate = async () => {
    const result = await updateBrand(brand, { commission_rate_returning: null });

    if (result?.error) {
      window.ALERT.error('There was an issue removing your returning commission rate. Please try again.', {
        hideAfter: 5
      });
      return false;
    }

    window.ALERT.success('Sitewide returning commission rate removed successfully!');
    return true;
  };

  const handleUpdateBrandCommissionRate = newCommissionRate => {
    if (newCommissionRate === baseCommissionRate) return true;
    if (!newCommissionRate || newCommissionRate < 5 || newCommissionRate > 100) {
      window.ALERT.error('Please input a valid commission rate between 5% and 100%');
      return false;
    }

    return handleUpdateBrandRates({ commission_rate: newCommissionRate });
  };

  const handleUpdateBrandCommissionRateReturning = (newCommissionRateReturning, options = {}) => {
    if (!newCommissionRateReturning && !_.isNil(commissionRateReturning)) {
      return confirmAlert({
        customUI: ({ onClose }) => (
          <ConfirmPrompt
            header='Are you sure?'
            subheader={`Are you sure you want to remove your commission rate for returning customers?`}
            onCancel={() => {
              onClose();
            }}
            submitBtnDisplay={'Yes'}
            onSubmit={() => handleRemoveReturningBrandRate()}
            hideFormFields={true}
          />
        )
      });
    }

    if (newCommissionRateReturning === commissionRateReturning) return true;

    const allShopifyIntegrationsHaveCustomerScopesEnabled = getAllIntegrationsHaveCustomerScopesEnabled(user);
    if (!allShopifyIntegrationsHaveCustomerScopesEnabled) {
      window.ALERT.error('You must enable the "read_customers" scope in your Shopify integration to set a returning customer rate.');
      return false;
    }

    if (newCommissionRateReturning < 5 || newCommissionRateReturning > 100) {
      window.ALERT.error('Please input a valid commission rate between 5% and 100%');
      return false;
    }

    return handleUpdateBrandRates({ commission_rate_returning: newCommissionRateReturning }, { ...options, isReturningRateUpdate: true });
  };

  const SITEWIDE_RATE_SECTION_ROWS = [
    {
      label: 'All Products',
      meta: `${commaNumber(numTotalVariants)} Products`,
      rateValue: `${baseCommissionRate}`,
      isInputActionHidden: true,
      handleSaveRate: handleUpdateBrandCommissionRate
    },
    {
      label: 'All Products • Returning Customers',
      meta: `${commaNumber(numTotalVariants)} Products`,
      rateValue: `${commissionRateReturning}`,
      handleSaveRate: handleUpdateBrandCommissionRateReturning,
      isHidden: _.isNil(commissionRateReturning),
      ratePlaceholder: `${baseCommissionRate}`,
      autoFocusRateInput: autoFocusReturningRate
    }
  ];

  return (
    <div className='catalog-commission-rates-container'>
      {!isSitewideRatesSectionHidden && (
        <div className='commission-rates-section'>
          <div className='section-header-container'>
            <div className='section-header'>Sitewide Rates</div>
            <div className='description'>
              {`${
                !_.isNil(commissionRateReturning) ? 'These rates apply' : 'This rate applies'
              } to all products in your catalog. Sitewide rates are overridden by group, product, and creator rates.`}
            </div>
            {_.isNil(commissionRateReturning) && (
              <div className='disclaimer'>
                Want a
                <span
                  onClick={() => {
                    setAutoFocusReturningRate(true);
                    handleUpdateBrandCommissionRateReturning(baseCommissionRate, { skipAlert: true });
                  }}
                >
                  different rate
                </span>
                for returning shoppers?
              </div>
            )}
          </div>
          <div className='section-content'>
            {SITEWIDE_RATE_SECTION_ROWS.filter(r => !r.isHidden).map(r => {
              return (
                <CatalogSitewideRateRow
                  key={r.label}
                  ui={ui}
                  photoUserProfile={brand}
                  label={r.label}
                  meta={r.meta}
                  rateValue={r.rateValue}
                  isInputActionHidden={r.isInputActionHidden}
                  handleSaveRate={r.handleSaveRate}
                  ratePlaceholder={r.ratePlaceholder}
                  autoFocusRateInput={r.autoFocusRateInput}
                />
              );
            })}
          </div>
        </div>
      )}
      {!isCatalogGroupSectionHidden && (
        <div className='commission-rates-section'>
          <div className='section-header-container'>
            <div className='section-header'>
              Group Rates
              {!!filteredCatalogGroupsWithRates.length && <div className='count'>{filteredCatalogGroupsWithRates.length}</div>}
            </div>
            <div className='description'>
              These rates apply to all products within the group. Configure groups and set new rates
              <span onClick={() => handleTabChange('groups')}>here</span>.
            </div>
          </div>
          <div className='section-content'>
            {(catalogGroupPlaceholders || filteredCatalogGroupsWithRates).map(cg => {
              const customRate = isLoadingCatalogGroups ? null : catalogGroupRatesMap[cg.id];

              return (
                <CatalogGroup
                  key={cg.id}
                  isSkeleton={isLoadingCatalogGroups}
                  catalogGroup={cg}
                  baseCommissionRate={baseCommissionRate}
                  existingRate={customRate}
                  saveCustomAffiliateRate={saveGroupCustomAffiliateRate}
                  user={user}
                  navigateToCatalogGroupProducts={navigateToCatalogGroupProducts}
                />
              );
            })}
            {!isLoadingCatalogGroups && filteredCatalogGroupsWithRates.length === 0 && !!controlBarSearchTerm.length && (
              <div className='no-results'>There are no group rates matching your search criteria.</div>
            )}
          </div>
        </div>
      )}
      {!isVariantRatesSectionHidden && (
        <div className='commission-rates-section'>
          <div className='section-header-container'>
            <div className='section-header'>
              Specific Product Rates
              {!!filteredVariantDisplayRates.length && <div className='count'>{filteredVariantDisplayRates.length}</div>}
            </div>
            <div className='description'>
              These rates apply to specific products or variants. To set new product rates, search on the products tab
              <span onClick={() => handleTabChange('products')}>here</span>.
            </div>
          </div>
          <div className='section-content'>
            <div className='catalog-variant-rate-cards'>
              {(variantDisplayRatePlaceholders || filteredVariantDisplayRates).map(variantRate => {
                return <CatalogVariantRateCard key={variantRate.id} variantRate={variantRate} isSkeleton={isLoadingVariantRates} />;
              })}
            </div>
            {!isLoadingVariantRates && filteredVariantDisplayRates.length === 0 && !!controlBarSearchTerm.length && (
              <div className='no-results'>There are no product rates matching your search criteria.</div>
            )}
          </div>
        </div>
      )}
      {!isTalentRatesSectionHidden && (
        <div className='commission-rates-section'>
          <div className='section-header-container'>
            <div className='section-header'>
              Creator Rates
              {!!filteredTalentWithRates.length && <div className='count'>{filteredTalentWithRates.length}</div>}
            </div>
            <div className='description'>These rates apply to all products in your catalog promoted by specific creators.</div>
          </div>
          <div className='section-content'>
            {(talentWithRatesPlaceholders || filteredTalentWithRates).map(talent => {
              return (
                <CatalogCreatorRateRow
                  key={talent.id}
                  isSkeleton={isLoadingTalentRates}
                  ui={ui}
                  talent={talent}
                  analytics={analytics}
                  user={user}
                  setCustomCommissionRate={setCustomCommissionRate}
                  openArtistModal={openArtistModal}
                />
              );
            })}
            {!isLoadingTalentRates && filteredTalentWithRates.length === 0 && !!controlBarSearchTerm.length && (
              <div className='no-results'>There are no creator rates matching your search criteria.</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

CatalogCommissionRates.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  isLoadingAnalytics: PropTypes.bool.isRequired,
  numTotalVariants: PropTypes.number.isRequired,
  catalogGroups: PropTypes.array.isRequired,
  saveGroupCustomAffiliateRate: PropTypes.func.isRequired,
  setCustomCommissionRate: PropTypes.func.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  controlBarSearchTerm: PropTypes.string.isRequired,
  navigateToCatalogGroupProducts: PropTypes.func.isRequired,
  canSupportCustomRates: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  const { user, ui, talent, analytics } = state;
  return { user, ui, talent, analytics };
};

export default connect(mapStateToProps, {
  updateBrand,
  setCustomCommissionRate,
  openArtistModal
})(CatalogCommissionRates);
