import './global'; // Must live at top of this file in order to offer access to every other file.
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Auth } from 'aws-amplify';

import AWSConfig from './AWSConfig';
import storeData from './configureStore';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import { blockBeforeSend } from './Helpers/sentry_helpers';

import 'react-confirm-alert/src/react-confirm-alert.css'; // NOTE: This is a global import for react-confirm-alert, no need to import it in every file.

Auth.configure(AWSConfig);

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    beforeSend: blockBeforeSend
  });
}

const { store } = storeData;
const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <Provider store={store}>
      <App />
    </Provider>,
    rootElement
  );
} else {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    rootElement
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
