import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import './CreateShopifyIntegrationPanel.scss';

import { testShopifyIntegration as testShopifyIntegrationAPI } from '../../../../APIClient/integrations';
import { saveShopifyIntegration } from '../../../../Actions/BrandActions';
import { getBrand, getAllShopifyIntegrations, isBrand } from '../../../../Helpers/user_helpers';

const CreateShopifyIntegrationPanel = props => {
  const { user } = props;
  const brand = getBrand(user);
  const shopifyIntegrations = getAllShopifyIntegrations(user);

  const [shopName, setShopName] = useState('');
  const [privateKey, setPrivateKey] = useState('');
  const [adminAccessToken, setAdminAccessToken] = useState('');
  const [isTestingShopify, setIsTestingShopify] = useState(false);
  const [shopifyTestSuccess, setShopifyTestSuccess] = useState(false);

  const saveShopifyIntegration = async () => {
    const data = { shopName, privateKey, adminAccessToken };

    if (!shopName || !privateKey || !adminAccessToken) return cogoToast.error('Please fill out all fields.');
    else if (!shopifyTestSuccess) return cogoToast.error('Please test your Shopify connection before saving.');

    props
      .saveShopifyIntegration(brand, data)
      .then(() => cogoToast.success('Successfully saved Shopify config.'))
      .catch(() => cogoToast.error('There was an error saving your Shopify config.'));
  };

  /**
   * Before allowing the user to save their Shopify integration, we need to test it first. The
   * most common errors are:
   * 1. Wrong keys
   * 2. Not all scopes are granted (see requestedScopes in shopify_helpers.js in node)
   *
   * Only after a successful test will the brand be allowed to save their integration.
   */
  const testShopifyIntegration = async () => {
    const Brand_id = brand.id;

    if (!shopName?.length || !privateKey?.length || !adminAccessToken?.length) return cogoToast.error('Please fill out all fields.');
    const data = { Brand_id, shopName, privateKey, adminAccessToken };

    setIsTestingShopify(true);
    testShopifyIntegrationAPI(data)
      .then(() => {
        cogoToast.success('Successful! Please press save.');
        setShopifyTestSuccess(true);
      })
      .catch(e => cogoToast.error(e || 'There was an error connecting to Shopify, please check your credentials and try again.'))
      .finally(() => setIsTestingShopify(false));
  };

  return (
    <div className='create-shopify-integration-panel-outer'>
      <div className='create-shopify-integration-panel-inner'>
        <div className='settings-section' style={{ marginTop: '12px' }}>
          <div className='settings-section-title'>Shop Name</div>
          <div className='settings-section-subtitle'>
            You can find this anytime in your app settings channel. Go to the "Apps and sales channel." The name should be there in the format:
            YOUR_SHOP_NAME.myshopify.com.
          </div>

          <input className='settings-input' placeholder='shop-my-app' type='text' onChange={e => setShopName(e.target.value)} value={shopName} />
        </div>

        <div className='settings-section'>
          <div className='settings-section-title'>API secret key</div>
          <div className='settings-section-subtitle'>
            The private API key for your Shopify app. (In API credentials section of the app settings panel.)
          </div>
          <input
            className='settings-input'
            placeholder='db18c89f48196f20675fb23a075d029d'
            type='text'
            onChange={e => setPrivateKey(e.target.value)}
            value={privateKey}
          />
        </div>

        <div className='settings-section'>
          <div className='settings-section-title'>Admin API access token</div>
          <div className='settings-section-subtitle'>
            The admin access key for your Shopify app. (If you are unable to see it, create a new app and copy the newly generated one.)
          </div>

          <div className='settings-section-split-input'>
            <input
              className='settings-input'
              placeholder='shpat_b7d27f85a3f4170139aa1ba4f307d04'
              type='text'
              onChange={e => setAdminAccessToken(e.target.value)}
              value={adminAccessToken}
            />

            {shopifyTestSuccess ? (
              <button className='settings-button dark' onClick={saveShopifyIntegration} disabled={isTestingShopify}>
                Save
              </button>
            ) : (
              <button className='settings-button' onClick={testShopifyIntegration} disabled={isTestingShopify}>
                Test
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

CreateShopifyIntegrationPanel.propTypes = {
  user: PropTypes.object.isRequired,
  saveShopifyIntegration: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  saveShopifyIntegration
})(CreateShopifyIntegrationPanel);
