import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './EditMentionTagsModal.scss';

import Modal from '../../General/Modal';

import { updateBrandTarget } from '../../../APIClient/targets';
import { updateBrand as updateAdminSelectedBrand } from '../../../APIClient/brands';
import { removeMentionsForBrandMentionTag } from '../../../APIClient/mentions';
import { updateBrand } from '../../../Actions/BrandActions';

import { blockOnRequiringSubscription } from '../../../Helpers/subscription_helpers';
import { parseSocialMentionTags } from '../../../Helpers/brand_helpers';
import { getBrand } from '../../../Helpers/user_helpers';

const NEW_TAG_REGEX = /^[a-zA-Z0-9_#@ ]+$/;

const EditMentionTagsModal = props => {
  const { ui, user, updateBrand, filterCounts, curFilters, brandAndTargetOptions, close } = props;

  // Select tags based on active stats
  const brand = getBrand(user);
  const selectedBrand = curFilters?.Brand_id && brandAndTargetOptions.brands?.find(b => b.id === curFilters.Brand_id);
  const selectedTarget = curFilters?.Target_id && brandAndTargetOptions.targets?.find(t => t.id === curFilters.Target_id);
  const initialTags = parseSocialMentionTags(selectedBrand?.socialTags || selectedTarget?.socialTags || brand?.socialTags || '');

  // Handling Tag Adjustments
  const [newTagVal, setNewTagVal] = useState('');
  const [tags, setTags] = useState(_.uniq(initialTags));
  const removeTag = tag => {
    if (blockOnRequiringSubscription(user, 'SOCIAL_MENTIONS_FEED', { ui })) return null;
    setTags(tags.filter(t => t !== tag));
  };
  const addTag = () => {
    if (!newTagVal) return cogoToast.warn('Please enter a tag to add!');
    if (!NEW_TAG_REGEX.test(newTagVal)) return cogoToast.warn('Tags may only contain letters, numbers, @, #, and _');
    const newTags = _.uniq([...tags, newTagVal.toLowerCase()]);
    if (newTags.length >= 20) {
      return cogoToast.warn('You can only have up to 20 tags. Please remove some before adding more.');
    }
    newTagVal && setTags(newTags);
    setNewTagVal('');
  };

  // Saving
  const [isSaving, setIsSaving] = useState(false);
  const requiresSave = tags.join(',') !== initialTags.join(',');
  const clickSave = async () => {
    if (!requiresSave) return close();
    let finalTags = [...tags];
    if (newTagVal.length) finalTags.push(newTagVal.toLowerCase()); // If something in the input, add it to the tags so they aren't confused when it isn't added
    const tagsBeingAdded = finalTags.filter(tag => !initialTags.includes(tag));
    const tagsBeingDeleted = initialTags.filter(tag => !finalTags.includes(tag));

    const completeUpdate = async closeOptions => {
      if (isSaving) return;
      setIsSaving(true);

      try {
        if (selectedBrand) await updateAdminSelectedBrand(selectedBrand, { socialTags: finalTags.join(',') });
        else if (selectedTarget) await updateBrandTarget(selectedTarget, { socialTags: finalTags.join(',') });
        else await updateBrand(brand, { socialTags: finalTags.join(',') });

        // Alert about newly added tags
        if (tagsBeingAdded.length) {
          confirmAlert({
            title: 'Just Letting You Know',
            message: `Mentions are updated every few hours so you will not see mentions with ${
              tagsBeingAdded.length > 1 ? 'the new tags' : tagsBeingAdded[0]
            } right away. We will start collecting mentions with the new tags every few hours and you can expect to see them all in your feed within 24 hours.`,
            buttons: [
              {
                label: 'Okay',
                onClick: () => {}
              }
            ]
          });
        }
        close(closeOptions);
      } catch (e) {
        console.error(e);
        cogoToast.error('Error saving tags. Please try again.');
        setIsSaving(false);
      }
    };

    // Ensure they are okay with changes deleting existing mentions
    const deletionCount = tagsBeingDeleted.reduce((acc, tag) => {
      const count = filterCounts?.tags?.find(data => data.tagFound?.toLowerCase() === tag?.toLowerCase())?.count || 0;
      return acc + count;
    }, 0);

    deletionCount
      ? confirmAlert({
          title: 'Just Confirming',
          message: `There are ${deletionCount} mentions using the tag${tagsBeingDeleted.length > 1 ? 's' : ''} ${tagsBeingDeleted.join(
            ', '
          )}, which will be deleted if you remove it. Are you sure you want to remove ${tagsBeingDeleted.length > 1 ? 'these tags' : 'this tag'}?`,
          buttons: [
            { label: 'Cancel', className: 'cancel' },
            {
              label: 'No, Keep',
              className: 'cancel',
              onClick: () => setTags([...finalTags, ...tagsBeingDeleted])
            },
            {
              label: 'Yes, Remove',
              onClick: async () => {
                for (const tag of tagsBeingDeleted) {
                  await removeMentionsForBrandMentionTag(brand, tag);
                }
                await completeUpdate({ needsRefresh: true });
              }
            }
          ]
        })
      : tagsBeingDeleted.length || tagsBeingAdded.length
      ? await completeUpdate({ needsRefresh: true })
      : completeUpdate();
  };

  return (
    <Modal
      visible
      close={close}
      title='Custom Tracking Tags'
      subtitle='Specify which tags you want to track content for. Please note that mentions are updated every few hours so it may take some time for new tags to appear on your feed.'
      className='edit-mention-tags-modal'
      contentClassName='edit-mention-tags-modal-content'
      innerClassName='edit-mention-tags-modal-inner'
      showClose
    >
      <div className='tag-containers'>
        {tags.map(tag => {
          const remove = () => removeTag(tag);
          const substring = tag.replace('#', '').replace('@', '');
          const requiresExactMatch = tag !== substring;
          const specialChar = requiresExactMatch ? (tag.includes('#') ? '#' : '@') : null;
          return (
            <div className='tag-container' key={tag}>
              <div className='display-container'>
                <div className='display'>{tag}</div>
                {requiresExactMatch && (
                  <div className='subdisplay'>
                    This tag requires an exact match on {tag} and will not find mentions that include {substring} alone. To find mentions that include{' '}
                    {substring} even without the tag, do not use {specialChar} when defining the tag.
                  </div>
                )}
              </div>
              <div className='actions'>
                <div className='action' onClick={remove}>
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              </div>
            </div>
          );
        })}
        <div className='tag-container new'>
          <form
            onSubmit={e => {
              e.preventDefault();
              addTag();
            }}
          >
            <input placeholder='NEW TAG' value={newTagVal} onChange={e => setNewTagVal(e.target.value)} type='text' />
          </form>
          <div className='actions'>
            <div className='action' onClick={addTag}>
              <FontAwesomeIcon icon={faPlus} />
            </div>
          </div>
        </div>
      </div>
      <div className='edit-btns'>
        <div className='save-btn' onClick={clickSave}>
          {requiresSave ? (isSaving ? 'SAVING' : 'SAVE') : 'DONE'}
        </div>
      </div>
    </Modal>
  );
};

EditMentionTagsModal.propTypes = {
  // From inside
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  updateBrand: PropTypes.func.isRequired,

  // From outside
  close: PropTypes.func.isRequired,
  curFilters: PropTypes.object,
  brandAndTargetOptions: PropTypes.object,
  filterCounts: PropTypes.object
};

const mapStateToProps = state => {
  const { user, ui } = state;
  return { user, ui };
};

export default connect(mapStateToProps, {
  updateBrand
})(EditMentionTagsModal);
