import AWSConfig from './AWSConfig';
import { Auth } from 'aws-amplify';
import cogoToast from 'cogo-toast';
import axios from 'axios';
import _ from 'lodash';
import { MAX_401_RETRY } from './Helpers/constants';
import { getValidationData } from './APIClient/users';

Auth.configure(AWSConfig);

export const cognitoSignUp = async credentials => {
  return new Promise((resolve, reject) => {
    Auth.signUp(credentials)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        const errorMsg = err?.data?.error || err?.data?.message || err?.message;
        resolve({
          ...err,
          errorMsg,
          error: 1
        });
      });
  });
};

export const cognitoLogin = async credentials => {
  let validationData = {};
  try {
    // see `cognito-ip-allowlist-check` lambda in us-east-2 for pre-authentication checks
    validationData = await getValidationData();
  } catch (err) {
    console.log(err);
  }
  return new Promise((resolve, reject) => {
    Auth.signIn({ ...credentials, validationData })
      .then(async res => {
        resolve(res);
      })
      .catch(err => {
        resolve({
          ...err,
          error: 1
        });
      });
  });
};

export const cognitoSignOut = () => {
  Auth.signOut();
};

// Local variable to keep track of number of times Auth retries has triggered.
let retryAuthAttempt = 0;

export const configureAxiosUserHash = async userHash => (axios.defaults.headers.common['x-Authorization-hash'] = userHash);
export const hasAxiosUserHash = () => !!axios.defaults.headers.common['x-Authorization-hash'];

// Local variable to prevent stacking of response interceptors, configureAxios can be called multiple times (login, simulating user, syncing current user, refreshing tokens, etc.)
let responseInterceptorId = null;

export const configureAxios = (accessToken, idToken) => {
  // Pass through both access and ID tokens
  axios.defaults.headers.common['Authorization'] = accessToken;
  axios.defaults.headers.common['x-Authorization-id'] = idToken;

  // Disable caching on every API request.
  axios.defaults.headers.common['Cache-Control'] = 'no-cache';
  // Bypass cache results on every API request.
  axios.defaults.headers.common['x-apicache-bypass'] = 'true';

  // Remove the previous interceptor (if exists)
  if (responseInterceptorId !== null) {
    axios.interceptors.response.eject(responseInterceptorId);
  }

  // Retry requests of unauthorized failures.
  responseInterceptorId = axios.interceptors.response.use(
    response => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      return response;
    },
    async error => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger.
      try {
        // If Access Token is timed out, refresh this token.
        if (_.get(error, 'response.status') === 401 && retryAuthAttempt < MAX_401_RETRY) {
          const currentSession = await Auth.currentSession();
          const newAccessToken = _.get(currentSession, 'accessToken.jwtToken');
          const newIdAccessToken = _.get(currentSession, 'idToken.jwtToken');

          axios.defaults.headers.common['Authorization'] = newAccessToken;
          error.response.config.headers['Authorization'] = newAccessToken;
          axios.defaults.headers.common['x-Authorization-id'] = newIdAccessToken;
          error.response.config.headers['x-Authorization-id'] = newIdAccessToken;

          retryAuthAttempt += 1;
          return axios.request(error.config);
        }

        _.get(error, 'response.status') === 502 &&
          cogoToast.error('We are having trouble with your connection, please refresh the page and try again.', { hideAfter: 8 });

        retryAuthAttempt = 0;
        return Promise.reject(error?.response);
      } catch (err) {
        const errorName = _.get(error, 'response.data.name') || '';
        if (errorName.includes('TokenExpiredError') || errorName.includes('TokenNotFound')) {
          console.error(_.get(error, 'response.data'));
          cogoToast.error('Your session has timed out, please log out and log back in to continue.', { hideAfter: 8 });
        }
        return Promise.reject(error?.response);
      }
    }
  );
};

export const currentUser = () => {
  return Auth.currentUserInfo();
};
