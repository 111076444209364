import React, { Component } from 'react';
import PropTypes from 'prop-types';
import commaNumber from 'comma-number';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { faCheck, faBadgeCheck } from '@fortawesome/pro-solid-svg-icons';
import { faBadgeDollar } from '@fortawesome/pro-regular-svg-icons';
import './LinkResult.scss';

import RequiresPermissions from '../Managers/RequiresPermissions';
import Tooltip from '../General/Tooltip';
import PartershipStatusActions from '../PartnershipStatus/PartershipStatusActions';

import { copyToClipboard, getSmartImage } from '../../Helpers/helpers';
import { getRateDisplay } from '../../Helpers/formatting';
import { getDisplayForSource, cleanCommissionId, getDomainFromUrl, formatAmountInCurrency, getPrettyDate } from '../../Helpers/formatting';
import { getAdjPayoutRate, isBrand, getBrand, getRootUrlForUser, getNameWithS } from '../../Helpers/user_helpers';
import { blockOnRequiringSubscription, isSubscribedToFeature } from '../../Helpers/subscription_helpers';
import { isPinBeingMoved, isAdminControlMode } from '../../Helpers/ui_helpers';
import { getDisclaimerForMerchant, isAmazonProgramLink } from '../../Helpers/merchant_helpers';
import { hasUnlockedIncreasedReferralBonuses } from '../../Helpers/tier_helpers';
import { getShortPinLink } from '../../Helpers/attribution_helpers';

class LinkResult extends Component {
  static propTypes = {
    ui: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    result: PropTypes.object.isRequired,
    togglePinToMove: PropTypes.func.isRequired,
    editPin: PropTypes.func.isRequired,
    deletePin: PropTypes.func.isRequired,
    groupByMode: PropTypes.string.isRequired,
    isSuperAdminMode: PropTypes.bool.isRequired,
    openArtistModal: PropTypes.func.isRequired,
    openBrandModal: PropTypes.func.isRequired
  };

  getRootUrlForResult = () => getRootUrlForUser(this.props.result?.user || this.props.result);

  getUserCell = extraClasses => {
    const { result } = this.props;
    const user = result?.user || result;
    const { User_image, User_name, username } = user;
    return (
      <div onClick={this.viewArtist} className={cn('cell', extraClasses)}>
        {User_image ? <img alt={User_name} src={getSmartImage(User_image)} /> : this.getEmptyImage()}
        <div className='metadata'>
          <div target='_blank' rel='noopener noreferrer' className='title'>
            {User_name}
          </div>
          <div className='username'>@{username}</div>
        </div>
      </div>
    );
  };

  getDomainCell = extraClasses => {
    const { result, user } = this.props;
    const { domain, brand, target } = result;
    const isPartner = !!brand;
    const isTarget = !isPartner && !!target;
    const allowShowingTargetUpsell = hasUnlockedIncreasedReferralBonuses(user);

    const onClick = () =>
      brand
        ? this.props.openBrandModal({
            brand,
            initialTab: 'about'
          })
        : window.open(`https://${domain}`, '_blank');
    return (
      <div onClick={onClick} className={cn('domain cell', extraClasses)}>
        <div className={cn('data', { 'is-partner': isPartner })}>
          {domain}
          {isPartner ? (
            <div className='partner-badge'>
              <Tooltip
                message={`${brand.name} is a direct ShopMy Partner meaning the brand can see your performance, gift product, and may be able to offer commissionable discount codes or custom commission rates.`}
              >
                <FontAwesomeIcon icon={faBadgeCheck} />
              </Tooltip>
            </div>
          ) : isTarget && allowShowingTargetUpsell ? (
            <div className='target-badge'>
              <Tooltip
                message={`This site offers commission but is not an official ShopMy partner yet. If you're able to make an introduction, you're eligible for a referral bonus.`}
              >
                <FontAwesomeIcon icon={faBadgeDollar} />
              </Tooltip>
            </div>
          ) : (
            <div className='external-badge'>
              <FontAwesomeIcon icon={faExternalLink} />
            </div>
          )}
        </div>
      </div>
    );
  };

  getSelectCell = extraClasses => {
    const { result, ui, togglePinToMove } = this.props;
    const pin = result?.pin || result;
    const isMoving = isPinBeingMoved(pin, ui);
    return (
      <div onClick={() => togglePinToMove(pin)} className={cn('cell', { selected: isMoving }, extraClasses)}>
        <div className='checkbox'>
          <FontAwesomeIcon icon={faCheck} />
        </div>
      </div>
    );
  };

  getEmptyImage = () => (
    <div className='empty-img'>
      <div className='s'>S</div>
    </div>
  );

  getPinCell = extraClasses => {
    const { result, user, editPin, deletePin } = this.props;
    const { views, commissionTotal } = result || {};
    const pin = result?.pin || result;
    const { title, link, id, merchant_data, disable_monetization, username, User_name, image, variant } = pin;
    const brand = getBrand(user);
    const isOtherBrand =
      merchant_data &&
      (!brand ||
        !(
          link?.includes(brand.domain) ||
          brand.altDomains
            ?.split(',')
            .map(d => d.trim())
            .find(d => link.includes(d))
        ));
    const disclaimer = getDisclaimerForMerchant(merchant_data, user, variant);
    const hasStats = !!views || !!commissionTotal;
    const cleanedLink = link?.replace(/(https?:\/\/)?(www\.)?/i, '');
    return (
      <div className={cn('cell', extraClasses)}>
        {image ? <img alt={title || link} src={getSmartImage(image)} /> : this.getEmptyImage()}
        <div className='metadata'>
          <div className='title'>{title || `Link #${commaNumber(id)}`}</div>
          {isBrand(user) ? (
            <>
              <a href={link} target='_blank' rel='noopener noreferrer' className={cn('link', { merchant: isOtherBrand })}>
                {isOtherBrand ? merchant_data.name : cleanedLink}
              </a>
            </>
          ) : (
            <a href={link} target='_blank' rel='noopener noreferrer' className='link merchant'>
              {merchant_data?.name || getDomainFromUrl(link)}
            </a>
          )}
          {username && (
            <div onClick={this.viewArtist} className='link'>
              {User_name}
            </div>
          )}
          {!isBrand(user) && (
            <>
              <div className='bullets'>
                {hasStats && (
                  <>
                    {!!views && (
                      <div className='stat mobile-only'>
                        {commaNumber(views)} click{views === 1 ? '' : 's'}
                        <div className='bullet'>•</div>
                      </div>
                    )}
                    {!!commissionTotal && (
                      <div className='stat mobile-only'>
                        ${commaNumber(commissionTotal.toFixed(2))} earned
                        <div className='bullet'>•</div>
                      </div>
                    )}
                  </>
                )}
                {disable_monetization ? (
                  <div className='stat'>Commission disabled</div>
                ) : merchant_data ? (
                  <>
                    {isAmazonProgramLink(pin.link, user) ? (
                      <div className='stat'>Custom Program</div>
                    ) : (
                      <div className='stat'>{getAdjPayoutRate(user, merchant_data, variant).toFixed(0)}% commission</div>
                    )}
                    {disclaimer && (
                      <Tooltip
                        message={disclaimer}
                        getIconDiv={() => (
                          <div className='stat-info'>
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </div>
                        )}
                      />
                    )}
                  </>
                ) : (
                  <div className='stat'>No commission</div>
                )}
              </div>
              <div className='action-btns'>
                <div onClick={() => editPin(pin)} className='action-btn'>
                  EDIT
                </div>
                <div onClick={() => deletePin(pin)} className='action-btn'>
                  DELETE
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  getProductCell = extraClasses => {
    const { result } = this.props;
    const product = result?.product || result;
    const { title, Product_image, mentions } = product;
    return (
      <div className={cn('cell', extraClasses)}>
        <img alt={title} src={getSmartImage(Product_image)} />
        <div className='metadata'>
          <div className='product-title'>{title}</div>
          <div className='mentions'>
            {mentions} Mention{mentions === 1 ? '' : 's'}
          </div>
        </div>
      </div>
    );
  };

  getRelationshipCell = extraClasses => {
    const { result } = this.props;
    return (
      <div className={cn('cell', extraClasses)}>
        <PartershipStatusActions User_id={result.User_id} />
      </div>
    );
  };

  getAffiliateLinkCell = () => {
    const { result, isSuperAdminMode } = this.props;
    const pin = result;
    return (
      <div className='cell lg short-link'>
        {isSuperAdminMode ? (
          <a href={getShortPinLink(pin)} target='_blank' rel='noreferrer noopener' className='link'>
            {getShortPinLink(pin)}
          </a>
        ) : (
          <div className='link'>{getShortPinLink(pin)}</div>
        )}
        <div className='copy-btn' onClick={() => copyToClipboard(getShortPinLink(pin), true)}>
          Copy
        </div>
      </div>
    );
  };

  getCell = (display, extraClasses, { href, onClick, requiresFeature, requiresPermission, tooltipMessage } = {}) => {
    const hasAccessToFeature = !requiresFeature || isSubscribedToFeature(this.props.user, requiresFeature) || isAdminControlMode(this.props.ui);
    const blockAccess = () => blockOnRequiringSubscription(this.props.user, requiresFeature);
    return !hasAccessToFeature ? (
      <div onClick={blockAccess} className={cn('cell', extraClasses, 'upgrade')}>
        VIEW
      </div>
    ) : href ? (
      <a target='_blank' rel='noopener noreferrer' href={href} className={cn('cell', extraClasses)}>
        {display}
      </a>
    ) : onClick ? (
      <div onClick={onClick} className={cn('cell clickable', extraClasses, { empty: !display })}>
        {display || '-'}
      </div>
    ) : (
      <div className={cn('cell', extraClasses, { empty: !display })}>
        {requiresPermission ? (
          <RequiresPermissions permission={requiresPermission} hideMessage>
            {display || '-'}
          </RequiresPermissions>
        ) : (
          display || '-'
        )}
        {tooltipMessage && <Tooltip message={tooltipMessage} getIconDiv={() => <FontAwesomeIcon className='toolip-icon' icon={faInfoCircle} />} />}
      </div>
    );
  };

  viewArtist = () => {
    const { result } = this.props;
    const user = result?.user || result;
    this.props.openArtistModal({ id: user.User_id || user.id });
  };

  getOrderAmountDisplay = () => {
    const { result } = this.props;
    try {
      const rawObject = JSON.parse(result?.raw);
      const { currency, order_amount } = rawObject;
      if (currency && order_amount) return formatAmountInCurrency(order_amount, currency);
    } catch (error) {}
    return result?.order_amount && `$${commaNumber(result?.order_amount.toFixed(2))}`;
  };

  getCommissionAmountDisplay = () => {
    const { result } = this.props;
    try {
      const rawObject = JSON.parse(result?.raw);
      const { currency, commission_amount } = rawObject;
      if (currency && commission_amount) return formatAmountInCurrency(commission_amount, currency);
    } catch (error) {}
    return result?.commission_amount && `$${commaNumber(result?.commission_amount.toFixed(2))}`;
  };

  getCommissionRateForUser = () => {
    const { result, user } = this.props;
    const { merchant_data } = result;
    return getAdjPayoutRate(user, merchant_data);
  };

  getCommissionRateDisplay = () => {
    const { result } = this.props;
    const { merchant_data } = result;
    const rate = this.getCommissionRateForUser();
    return getRateDisplay(rate, merchant_data);
  };

  render() {
    const { result, user, isSuperAdminMode, groupByMode } = this.props;
    const {
      Brand_name,
      User_name,
      username,
      domain,
      Collection_name,
      Contract_title,
      ConsultResult_stub,
      ConsultResult_clientName,
      Consult_id,
      Consult_title,
      Collection_id,
      Contract_id,
      Pin_id,
      geolinks,
      commissionTotal,
      orderVolumeTotal,
      mentions,
      views,
      ordersTotal,
      estimatedMediaValue,
      createdAt,
      lastLinkedAt
    } = result;
    const collectionUrl = Collection_id && `/collections/${Collection_id}`;
    const consultUrl = Consult_id && `/consults/${Consult_id}`;
    const contractUrl = Contract_id && `/collaboration/${Contract_id}`;
    const consultResultUrl = ConsultResult_stub && `/consults/results/${ConsultResult_stub}`;
    const contentUrl = isBrand(user) ? collectionUrl || consultUrl || contractUrl : collectionUrl || consultResultUrl || consultUrl || contractUrl;
    const contentDisplay = isBrand(user)
      ? Collection_name || Contract_title || Consult_title || (Pin_id ? 'Quick Link' : '-')
      : Collection_name || Contract_title || (ConsultResult_clientName ? `${getNameWithS(result).full} Consult` : null);
    const commissionRate = this.getCommissionRateForUser();
    const additionalClasses = { 'stack-row-on-mobile': groupByMode === 'mentions' };
    return (
      <div className={cn('link-result row', groupByMode, additionalClasses)}>
        {groupByMode === 'users' ? (
          isSuperAdminMode ? (
            <>
              {this.getUserCell('xl user')}
              {this.getCell(mentions && commaNumber(mentions), 'sm desktop-only mentions-total')}
              {this.getCell(views && commaNumber(views), 'sm desktop-only views-total')}
              {this.getCell(ordersTotal, 'sm desktop-only order-total')}
              {this.getCell(orderVolumeTotal && `$${commaNumber(orderVolumeTotal.toFixed(2))}`, 'sm desktop-only order-volume-total')}
              {this.getCell(commissionTotal && `$${commaNumber(commissionTotal.toFixed(2))}`, 'sm desktop-only commission-total')}
              {this.getCell(estimatedMediaValue && `$${commaNumber(estimatedMediaValue.toFixed(2))}`, 'sm desktop-only emv-total')}
              {this.getCell(createdAt ? getPrettyDate(createdAt, 'MMM Do, YYYY', 'MMM Do, YYYY') : '-', 'md desktop-only created-at')}
            </>
          ) : (
            <>
              {this.getUserCell('lg user')}
              {this.getCell(mentions && commaNumber(mentions), 'sm desktop-only mentions-total', {
                requiresFeature: 'ANALYTICS'
              })}
              {this.getCell(views && commaNumber(views), 'sm desktop-only views-total', { requiresFeature: 'ANALYTICS' })}
              {this.getCell(ordersTotal, 'sm desktop-only order-total', { requiresFeature: 'ANALYTICS' })}
              {this.getCell(orderVolumeTotal && `$${commaNumber(orderVolumeTotal.toFixed(2))}`, 'sm desktop-only order-volume-total', {
                requiresFeature: 'ANALYTICS'
              })}
              {this.getCell(commissionTotal && `$${commaNumber(commissionTotal.toFixed(2))}`, 'sm desktop-only commission-total', {
                requiresFeature: 'ANALYTICS'
              })}
              {this.getCell(estimatedMediaValue && `$${commaNumber(estimatedMediaValue.toFixed(2))}`, 'sm desktop-only emv-total', {
                requiresFeature: 'SOCIAL_MENTIONS_FEED',
                tooltipMessage: !result.socials_linked?.length
                  ? estimatedMediaValue
                    ? `${User_name} has not linked any social accounts so this only represents their connected collections`
                    : `${User_name} has not linked any social accounts so we cannot track media value`
                  : null
              })}
              {this.getCell(createdAt ? getPrettyDate(createdAt, 'M/D/YY', 'M/D/YY') : '-', 'sm desktop-only created-at', {
                requiresFeature: 'ANALYTICS'
              })}
              {this.getCell(lastLinkedAt ? getPrettyDate(lastLinkedAt, 'M/D/YY', 'M/D/YY') : '-', 'sm desktop-only created-at', {
                requiresFeature: 'ANALYTICS'
              })}
              {this.getRelationshipCell('md desktop-only relationship')}
            </>
          )
        ) : groupByMode === 'products' ? (
          <>
            {this.getProductCell('xl product')}
            {isSuperAdminMode && this.getCell(Brand_name, 'sm desktop-only brand-name')}
            {this.getCell(mentions && commaNumber(mentions), 'sm desktop-only views-total', { requiresFeature: 'ANALYTICS' })}
            {this.getCell(views && commaNumber(views), 'sm desktop-only views-total', { requiresFeature: 'ANALYTICS' })}
            {this.getCell(ordersTotal, 'sm desktop-only order-total', { requiresFeature: 'ANALYTICS' })}
            {this.getCell(orderVolumeTotal && `$${commaNumber(orderVolumeTotal.toFixed(2))}`, 'sm desktop-only order-volume-total', {
              requiresFeature: 'ANALYTICS'
            })}
          </>
        ) : groupByMode === 'domains' ? (
          <>
            {this.getDomainCell('xl domain')}
            {this.getCell(commissionRate && this.getCommissionRateDisplay(), 'sm desktop-only mentions-total')}
            {this.getCell(mentions && commaNumber(mentions), 'sm desktop-only mentions-total')}
            {this.getCell(views && commaNumber(views), 'sm  views-total')}
            {this.getCell(ordersTotal, 'sm desktop-only order-total')}
            {this.getCell(orderVolumeTotal && `$${commaNumber(orderVolumeTotal.toFixed(2))}`, 'sm order-volume-total desktop-only ', {
              requiresPermission: 'canViewEarnings'
            })}
            {this.getCell(commissionTotal && `$${commaNumber(commissionTotal.toFixed(2))}`, 'sm commission-total', {
              requiresPermission: 'canViewEarnings'
            })}
          </>
        ) : groupByMode === 'orders' ? (
          <>
            {this.getCell(getPrettyDate(result.transaction_date), 'sm created-at')}
            {this.getCell(result?.user?.name || 'Best in Beauty', 'sm user-name', {
              onClick: this.viewArtist
            })}
            {this.getCell(contentDisplay, 'md desktop-only collection-name', {
              href: contentUrl && `${this.getRootUrlForResult()}${contentUrl}`
            })}

            {this.getCell(cleanCommissionId(result.commission_id), 'sm desktop-only order-num')}
            {this.getCell(result?.domain, 'sm desktop-only domain')}
            {this.getCell(getDisplayForSource(result?.source), 'sm desktop-only source', { requiresFeature: 'ANALYTICS' })}
            {this.getCell(this.getOrderAmountDisplay(), 'sm order-volume-total', {
              requiresFeature: 'ANALYTICS'
            })}
            {this.getCell(this.getCommissionAmountDisplay(), 'sm commission-total', {
              requiresFeature: 'ANALYTICS'
            })}
            {this.getCell(result?.code, 'sm desktop-only code')}
          </>
        ) : groupByMode === 'creator-orders' ? (
          <>
            {this.getCell(getPrettyDate(result.transaction_date), 'sm created-at')}
            {this.getCell(result?.merchant, 'sm desktop-only merchant')}
            {this.getCell(contentDisplay, 'md desktop-only collection-name', {
              href: contentUrl && `${this.getRootUrlForResult()}${contentUrl}`
            })}
            {this.getCell(orderVolumeTotal && `$${commaNumber(orderVolumeTotal.toFixed(2))}`, 'sm order-volume-total desktop-only ', {
              requiresPermission: 'canViewEarnings'
            })}
            {this.getCell(commissionTotal && `$${commaNumber(commissionTotal.toFixed(2))}`, 'sm commission-total', {
              requiresPermission: 'canViewEarnings'
            })}
            {this.getCell(result?.code, 'sm desktop-only code')}
          </>
        ) : isSuperAdminMode ? (
          <>
            {this.getCell(getPrettyDate(createdAt), 'sm desktop-only created-at')}
            {this.getPinCell('xl pin')}
            {this.getCell(User_name, 'md desktop-only user-name', { href: username && `${this.getRootUrlForResult()}/${username}` })}
            {this.getCell(contentDisplay, 'md desktop-only collection-name', {
              href: contentUrl && `${this.getRootUrlForResult()}${contentUrl}`
            })}
            {this.getCell(geolinks?.length || '-', 'sm desktop-only created-at')}
            {this.getCell(views && commaNumber(views), 'sm desktop-only views-total')}
            {this.getCell(ordersTotal, 'sm desktop-only order-total')}
            {this.getCell(orderVolumeTotal && `$${commaNumber(orderVolumeTotal.toFixed(2))}`, 'sm desktop-only order-volume-total')}
            {this.getCell(commissionTotal && `$${commaNumber(commissionTotal.toFixed(2))}`, 'sm desktop-only commission-total')}
            {this.getAffiliateLinkCell()}
          </>
        ) : isBrand(user) ? (
          <>
            {this.getCell(getPrettyDate(createdAt), 'sm desktop-only created-at')}
            {this.getPinCell('xl pin')}
            {this.getCell(User_name, 'md desktop-only user-name', {
              onClick: this.viewArtist
            })}
            {this.getCell(contentDisplay, 'md desktop-only collection-name', {
              href: contentUrl && `${this.getRootUrlForResult()}${contentUrl}`
            })}
            {this.getCell(views && commaNumber(views), 'sm desktop-only views-total', { requiresFeature: 'ANALYTICS' })}
            {this.getCell(ordersTotal, 'sm desktop-only order-total', { requiresFeature: 'ANALYTICS' })}
            {this.getCell(orderVolumeTotal && `$${commaNumber(orderVolumeTotal.toFixed(2))}`, 'sm desktop-only order-volume-total', {
              requiresFeature: 'ANALYTICS'
            })}
            {this.getCell(commissionTotal && `$${commaNumber(commissionTotal.toFixed(2))}`, 'sm desktop-only commission-total', {
              requiresFeature: 'ANALYTICS'
            })}
          </>
        ) : (
          <>
            {this.getSelectCell('xs select')}
            {this.getCell(getPrettyDate(createdAt), 'sm desktop-only created-at')}
            {this.getPinCell('xl pin has-select')}
            {this.getCell(contentDisplay, 'md desktop-only collection-name', {
              href: contentUrl
            })}
            {this.getCell(views && commaNumber(views), 'sm desktop-only views-total')}
            {this.getCell(ordersTotal, 'sm desktop-only order-total', { requiresPermission: 'canViewEarnings' })}
            {this.getCell(commissionTotal && `$${commaNumber(commissionTotal.toFixed(2))}`, 'sm desktop-only commission-total', {
              requiresPermission: 'canViewEarnings'
            })}
            {this.getAffiliateLinkCell()}
          </>
        )}
      </div>
    );
  }
}

export default LinkResult;
