import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

import './Contact.scss';
import './Basic.scss';

import { addEvent } from '../../APIClient/events';
import { openIntercomChat } from '../../Helpers/chat_helpers';

class Contact extends Component {
  componentDidMount() {
    addEvent('Contact Us - Page View');
  }

  render() {
    return (
      <div className='contact-outer-container basic-outer-container'>
        <MetaTags>
          <title>Contact Us | ShopMy</title>
          <meta property='description' content='To get in touch with us, send an email to team@shopmy.us' />
        </MetaTags>
        <div className='basic-header-container narrow'>
          <div className='basic-header-title-badge-container'>
            <h1 className='basic-header-title-badge'>CONTACT</h1>
          </div>
          <h2 className='basic-header-body simple'>
            To get in touch with us, send an email to <span className='email'>team@shopmy.us</span>. If you are a ShopMy creator,{' '}
            <span
              class='chat'
              onClick={() => {
                openIntercomChat();
              }}
            >
              please access our live chat support
            </span>{' '}
            or email <span className='email'>support@shopmy.us</span> for assistance.
          </h2>
        </div>
      </div>
    );
  }
}

export default Contact;
