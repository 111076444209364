import React from 'react';
import './SkeletonPanel.scss';

const SkeletonPanel = () => {
  const standardSubtitleHeight = 18;
  const subtitleLines = Math.floor(Math.random() * 3 + 1);
  const subtitleWidth = subtitleLines === 1 ? `calc(100% - ${Math.floor(Math.random() * 50)}%)` : '100%';
  const buttonLength = Math.floor(Math.random() * 2 + 2);

  const regularSkeleton = (
    <div className='skeleton-panel-outer'>
      <div className='skeleton-panel-inner'>
        <div className='skeleton-panel-title' />
        <div className='skeleton-panel-subtitle' style={{ height: standardSubtitleHeight * subtitleLines, width: subtitleWidth }} />
        <div className='skeleton-panel-input' />
      </div>
    </div>
  );

  const checkboxSkeleton = (
    <div className='skeleton-panel-outer'>
      <div className='skeleton-panel-inner'>
        <div className='skeleton-panel-title' />
        <div className='skeleton-panel-subtitle' style={{ height: standardSubtitleHeight * subtitleLines, width: subtitleWidth }} />
        <div className='skeleton-panel-checkboxes'>
          <div className='skeleton-panel-checkbox' />
          <div className='skeleton-panel-checkbox' />
          <div className='skeleton-panel-checkbox' />
        </div>
      </div>
    </div>
  );

  const splitButtonSkeleton = (
    <div className='skeleton-panel-outer'>
      <div className='skeleton-panel-inner'>
        <div className='skeleton-panel-title' />
        <div className='skeleton-panel-subtitle' style={{ height: standardSubtitleHeight * subtitleLines, width: subtitleWidth }} />
        <div className='skeleton-panel-split-buttons'>
          {new Array(buttonLength).fill(null).map((_, i) => (
            <div key={i} className='skeleton-panel-split-button' />
          ))}
        </div>
      </div>
    </div>
  );

  return Math.random() > 0.25 ? regularSkeleton : Math.random() > 0.5 ? checkboxSkeleton : splitButtonSkeleton;
};

export default SkeletonPanel;
