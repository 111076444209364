import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './UserLookbookOrderDetailsItem.scss';
import { getPrettyNumber } from '../../../Helpers/formatting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { getUserId, isBanned, isBrand } from '../../../Helpers/user_helpers';
import cogoToast from 'cogo-toast';
import { copyToClipboard } from '../../../Helpers/helpers';
import { createPin as createPinAPI } from '../../../APIClient/pins';
import { getLookbookCurrencySymbol, getLookbookOrderItemParts } from '../../../Helpers/lookbook_helpers';
import cn from 'classnames';
import _ from 'lodash';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import Image from '../../General/Image';

const UserLookbookOrderDetailsItem = props => {
  const { item, lookbook, user } = props;
  let { id: LookbookOrderItem_id, quantity, image, url, brand, price } = item;
  const { disableLinkGeneration } = lookbook;

  const { name, pinDisplay, nonSizeTag, sizeTag } = getLookbookOrderItemParts(item);

  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState(_.isEmpty(item?.pin) ? null : item.pin);
  const pinStats = pin?.stats;
  const { orders, clicks } = pinStats || {};

  const getPinStatBadge = () => {
    if (pin && !pinStats) return 'Talent generated link';
    else if (orders > 0) return 'Talent driving orders';
    else if (clicks > 0) return 'Talent driving clicks';
    else return '';
  };

  const createPin = async () => {
    if (loading) return;

    setLoading(true);
    const pin_data = {
      User_id: getUserId(user),
      LookbookOrderItem_id,
      title: brand ? `${brand.toUpperCase()} | ${pinDisplay}` : pinDisplay,
      link: url,
      image: image,
      disable_monetization: isBanned(user) ? true : false
    };

    try {
      const result = await createPinAPI(pin_data);
      const createdPin = result.pin;

      const pin_id = createdPin.id;
      const pin_url = `go.shopmy.us/p-${pin_id}`;
      copyToClipboard(pin_url);
      cogoToast.success('Copied commissionable link.');

      setPin(createdPin);
    } catch (e) {
      cogoToast.error('Error creating link. Please try again.');
    }

    setLoading(false);
  };

  const copyPinLink = () => {
    copyToClipboard(`go.shopmy.us/p-${pin.id}`);
    cogoToast.success('Copied commissionable link.');
  };

  let subtitle_parts = [];
  if (nonSizeTag) subtitle_parts.push(nonSizeTag);
  if (sizeTag) subtitle_parts.push(sizeTag);
  if (lookbook?.price_limit && parseInt(price)) {
    const currencyDisplay = getLookbookCurrencySymbol(lookbook);
    subtitle_parts.push(`${currencyDisplay}${getPrettyNumber(price)}`);
  }
  if (quantity > 1) subtitle_parts.push(`${quantity}x`);

  return (
    <div className='user-lookbook-order-details-item-outer'>
      <div className='user-lookbook-order-details-item-inner'>
        {isBrand(user) && !_.isEmpty(pin) && (
          <div className='user-promoting-badge'>
            <FontAwesomeIcon icon={faCheck} />
            {getPinStatBadge()}
          </div>
        )}
        <div className='image-container'>
          <Image src={image} alt={name} className='lookbook-order-item-image' />
        </div>
        <div className='details-container'>
          <div className='title-container'>
            <div className='title'>{name}</div>
            {subtitle_parts.length > 0 && <div className='subtitle'>{subtitle_parts.join(' • ')}</div>}

            {disableLinkGeneration ? (
              <div className={cn('not-available')}>Link Generation Unavailable</div>
            ) : url ? (
              <a href={url} target='_blank' className='external-link' rel='noopener noreferrer'>
                Visit Item <FontAwesomeIcon icon={faExternalLink} />
              </a>
            ) : (
              <div className={cn('not-available')}>Not Publicly Available</div>
            )}
          </div>
          {!isBrand(user) && url && !disableLinkGeneration ? (
            <div className={cn('generate-link-button', { copyButton: !!pin })} onClick={pin ? copyPinLink : url ? createPin : null}>
              {pin ? 'Copy Link' : url ? 'Generate Link' : 'Product Not Publically Available'}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

UserLookbookOrderDetailsItem.propTypes = {
  item: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  lookbook: PropTypes.object
};

export default UserLookbookOrderDetailsItem;
