import { getBrand } from './user_helpers';
import find from 'lodash/find';
import get from 'lodash/get';

export const getTopMerchantForBrand = user => {
  const brand = getBrand(user);
  const { merchants } = brand || {};
  return find(merchants, m => m.isSMSWinner);
};

export const getBrandBaseCommissionRate = user => {
  const ownCommissionRate = get(getBrand(user), 'commission_rate');
  const topCommissionRate = get(getTopMerchantForBrand(user), 'fullPayout');
  return ownCommissionRate || topCommissionRate;
};

export const getBrandCommissionRateReturning = user => {
  const brand = getBrand(user);
  const topMerchantForBrand = getTopMerchantForBrand(user);
  return topMerchantForBrand?.source === 'shopmyshelf' ? brand?.commission_rate_returning : null;
};
