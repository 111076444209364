import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getBrandId, getCanBrandSetCustomAffiliateRates, isBrand } from '../../Helpers/user_helpers';
import { CATALOG_VARIANTS_PAGE_SIZE, CATALOG_FETCH_DATA_TIMEOUT } from '../../Components/Catalog/Constants/catalog';
import { fetchCatalogVariants } from '../../APIClient/variants';
import { getCatalogGroups } from '../../APIClient/catalog_groups';
import { addCatalogSyncTask, fetchCatalogSyncTasksStatus } from '../../APIClient/catalog_tasks';
import ChatConnect from '../Chat/ChatConnect';
import { closeCatalogVariantModal } from '../../Actions/UIActions';

// HOC data loader for Catalog page
const CatalogDataLoader = WrappedComponent => {
  const HOC = ({ user, closeCatalogVariantModal, ...props }) => {
    const canSupportCustomRates = getCanBrandSetCustomAffiliateRates(user);
    const curTab = useParams()?.tab || props.tab || 'products';

    // State for search/filters
    const [controlBarActiveFilters, setControlBarActiveFilters] = useState({});
    const [controlBarSearchTerm, setControlBarSearchTerm] = useState('');

    // State for Products tab
    const [curVariantsSearchParams, setCurVariantsSearchParams] = useState({});
    const [curVariants, setCurVariants] = useState([]);
    const [curVariantsPage, setCurVariantsPage] = useState(1);
    const [isLoadingVariants, setIsLoadingVariants] = useState(true);
    const [numTotalVariants, setNumTotalVariants] = useState(0);
    const [numVariantsPages, setNumVariantsPages] = useState(1);
    const fetchVariantsTimeout = useRef(null);

    // State for Groups tab
    const [catalogGroups, setCatalogGroups] = useState([]);
    const [isLoadingCatalogGroups, setIsLoadingCatalogGroups] = useState(true);

    // State for catalog sync tasks
    const [isSyncingCatalogProducts, setIsSyncingCatalogProducts] = useState(false);
    const [isSyncingCatalogGroups, setIsSyncingCatalogGroups] = useState(false);
    const [isCatalogSyncStatusInitialized, setIsCatalogSyncStatusInitialized] = useState(false);
    const [forceRefreshVariants, setForceRefreshVariants] = useState(false);

    // Global Catalog Page State
    const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
    const catalogSyncTasksWS = useRef(null);

    const getVariantsSearchParams = () => {
      const params = {
        searchTerm: controlBarSearchTerm,
        limit: CATALOG_VARIANTS_PAGE_SIZE,
        offset: (curVariantsPage - 1) * CATALOG_VARIANTS_PAGE_SIZE
      };

      if (controlBarActiveFilters.CatalogGroup) {
        params.CatalogGroup_id = controlBarActiveFilters.CatalogGroup.id;
      }

      if (
        forceRefreshVariants ||
        !_.isEqual(_.pick(params, ['searchTerm', 'CatalogGroup_id']), _.pick(curVariantsSearchParams, ['searchTerm', 'CatalogGroup_id']))
      ) {
        params.refreshCount = true;
      }

      return params;
    };

    const fetchVariantsPage = async () => {
      setIsLoadingVariants(true);

      try {
        const Brand_id = getBrandId(user);
        const fetchParams = getVariantsSearchParams();
        const { variants, pageInfo } = await fetchCatalogVariants(Brand_id, fetchParams);
        if (pageInfo) {
          setNumTotalVariants(pageInfo.totalCount || 0);
          setNumVariantsPages(Math.ceil(pageInfo.totalCount / CATALOG_VARIANTS_PAGE_SIZE));
        }

        setCurVariants(variants);
        setCurVariantsSearchParams(fetchParams);
        setForceRefreshVariants(false);
      } catch (error) {
        console.error('Error fetching variants:', error);
        window.ALERT.error('There was an issue fetching your product catalog. Please try again.');
      } finally {
        setIsLoadingVariants(false);
      }
    };

    const debouncedFetchVariantsPage = () => {
      if (fetchVariantsTimeout.current) {
        clearTimeout(fetchVariantsTimeout.current);
      }
      const fetchParams = getVariantsSearchParams();
      if (!forceRefreshVariants && _.isEqual(_.omit(fetchParams, 'refreshCount'), _.omit(curVariantsSearchParams, 'refreshCount')))
        return setIsLoadingVariants(false);
      setIsLoadingVariants(true);

      fetchVariantsTimeout.current = setTimeout(() => {
        fetchVariantsPage();
      }, CATALOG_FETCH_DATA_TIMEOUT);
    };

    const fetchCatalogGroups = async () => {
      setIsLoadingCatalogGroups(true);
      try {
        const Brand_id = getBrandId(user);
        const catalogGroupsResp = await getCatalogGroups(Brand_id);
        setCatalogGroups(catalogGroupsResp.catalogGroups);
      } catch (err) {
        console.error('Error fetching catalog groups: ', err);
        window.ALERT.error('There was an issue loading your catalog groups. Please try again.');
      } finally {
        setIsLoadingCatalogGroups(false);
      }
    };

    const loadCatalogProductsTabData = async () => {
      await fetchVariantsPage();
    };

    const loadCatalogGroupsTabData = async () => {
      if (!canSupportCustomRates) return setIsLoadingCatalogGroups(false);
      await fetchCatalogGroups();
    };

    const startCatalogSyncTask = async catalogTaskType => {
      if (catalogTaskType === 'sync_catalog_products') {
        setIsSyncingCatalogProducts(true);
      } else if (catalogTaskType === 'sync_catalog_groups') {
        setIsSyncingCatalogGroups(true);
      }

      try {
        await addCatalogSyncTask({
          Brand_id: getBrandId(user),
          catalogTaskType
        });
      } catch (err) {
        if (err?.toLowerCase().includes('catalog task for this brand is already in progress.')) {
          console.info('Registered catalog sync task already in progress');
        } else {
          console.error('Error starting catalog sync task: ', err);
          window.ALERT.error(
            'There was an issue beginning your Catalog sync. If this issue persists, please reach out to your Account Manager for a resolution.'
          );
          setIsSyncingCatalogProducts(false);
          setIsSyncingCatalogGroups(false);
        }
      }
    };

    const initCatalogSyncTasksData = async () => {
      if (!isBrand(user)) return; // logged-in brand check

      try {
        const catalogSyncTasksResp = await fetchCatalogSyncTasksStatus(getBrandId(user));
        const activeCatalogSyncTasks = catalogSyncTasksResp.catalogTasks;
        const syncingProductsTask = activeCatalogSyncTasks.find(task => task.type === 'sync_catalog_products');
        const syncingGroupsTask = activeCatalogSyncTasks.find(task => task.type === 'sync_catalog_groups');

        if (syncingProductsTask) {
          setIsSyncingCatalogProducts(true);
        } else if (syncingGroupsTask) {
          setIsSyncingCatalogGroups(true);
        }

        if (!isCatalogSyncStatusInitialized) {
          setIsCatalogSyncStatusInitialized(true);
        }
      } catch (err) {
        console.error('Error polling catalog sync tasks: ', err);
        window.ALERT.error('There was an issue checking the synced status of your Catalog. Syncing is currently unavailable.', {
          hideAfter: 10
        });
        setIsCatalogSyncStatusInitialized(false);
      }
    };

    const onWebsocketConnect = () => {
      if (!isCatalogSyncStatusInitialized) {
        initCatalogSyncTasksData();
      }
    };

    const onWebsocketStatusUpdate = async data => {
      if (data.Brand_id !== getBrandId(user)) return;

      if (data.status_enum === 'COMPLETE') {
        window.ALERT.success(data.status_display, { hideAfter: 10 });
        setIsSyncingCatalogProducts(false);
        setIsSyncingCatalogGroups(false);

        if (data.type === 'sync_catalog_products') {
          // reload products tab data
          await closeCatalogVariantModal();
          setForceRefreshVariants(true);
        } else if (data.type === 'sync_catalog_groups') {
          // reload groups tab data
          await loadCatalogGroupsTabData();
        }
      } else if (data.status_enum === 'FAILURE') {
        window.ALERT.error(data.status_display, { hideAfter: 20 });
        setIsSyncingCatalogProducts(false);
        setIsSyncingCatalogGroups(false);
      }
    };

    const onWebsocketDisconnect = () => {
      setIsCatalogSyncStatusInitialized(false);
    };

    const loadInitialData = async () => {
      if (!isBrand(user)) return; // logged-in brand check

      if (curTab === 'products') {
        await loadCatalogProductsTabData();
        await loadCatalogGroupsTabData();
      } else {
        await loadCatalogGroupsTabData();
        await loadCatalogProductsTabData();
      }

      setIsInitialDataLoaded(true);
    };

    const handleControlBarFilterChange = (filterKey, filterValue) => {
      const newFilters = { ...controlBarActiveFilters };
      if (!filterValue) {
        delete newFilters[filterKey];
      } else {
        newFilters[filterKey] = filterValue;
      }

      if (curTab === 'products' && curVariantsPage !== 1) {
        setCurVariantsPage(1);
      }
      setControlBarActiveFilters(newFilters);
    };

    const handleControlBarSearchChange = newSearchTerm => {
      if (curTab === 'products' && curVariantsPage !== 1) {
        setCurVariantsPage(1);
      }
      setControlBarSearchTerm(newSearchTerm);
    };

    useEffect(() => {
      loadInitialData();

      return () => {
        if (fetchVariantsTimeout.current) {
          clearTimeout(fetchVariantsTimeout.current);
        }
      };
    }, []);

    useEffect(() => {
      // API request search/filter logic
      if (!isInitialDataLoaded) return;
      if (curTab === 'products') {
        debouncedFetchVariantsPage();
      }
    }, [controlBarActiveFilters, controlBarSearchTerm, curVariantsPage]);

    useEffect(() => {
      // Force refresh logic (from sync tasks)
      if (!isInitialDataLoaded) return;
      if (forceRefreshVariants) {
        debouncedFetchVariantsPage();
      }
    }, [forceRefreshVariants]);

    useEffect(() => {
      // Tab change logic
      if (!isInitialDataLoaded) return;
      handleControlBarFilterChange('CatalogGroup', null);
      setControlBarSearchTerm('');
    }, [curTab]);

    return (
      <>
        <ChatConnect
          user={user}
          setWebsocket={initializedWS => (catalogSyncTasksWS.current = initializedWS)}
          onConnect={onWebsocketConnect}
          onFailedAttempt={onWebsocketDisconnect}
          onFailMessage={'There was an issue checking the synced status of your Catalog. Syncing is currently unavailable.'}
          onStatusUpdate={onWebsocketStatusUpdate}
          receivedMessage={() => {}}
        />
        <WrappedComponent
          {...props}
          curVariants={curVariants}
          curVariantsPage={curVariantsPage}
          setCurVariantsPage={setCurVariantsPage}
          isLoadingVariants={isLoadingVariants}
          numVariantsPages={numVariantsPages}
          numTotalVariants={numTotalVariants}
          catalogGroups={catalogGroups}
          isLoadingCatalogGroups={isLoadingCatalogGroups}
          fetchCatalogGroups={fetchCatalogGroups}
          canSupportCustomRates={canSupportCustomRates}
          isCatalogSyncStatusInitialized={isCatalogSyncStatusInitialized}
          isSyncingCatalogProducts={isSyncingCatalogProducts}
          isSyncingCatalogGroups={isSyncingCatalogGroups}
          startCatalogSyncTask={startCatalogSyncTask}
          controlBarSearchTerm={controlBarSearchTerm}
          setControlBarSearchTerm={handleControlBarSearchChange}
          controlBarActiveFilters={controlBarActiveFilters}
          setControlBarFilterValue={handleControlBarFilterChange}
        />
      </>
    );
  };

  const mapStateToProps = state => {
    const { user } = state;
    return { user };
  };

  return connect(mapStateToProps, { closeCatalogVariantModal })(HOC);
};

export default CatalogDataLoader;
