import React, { useState } from 'react';
import './AddCatalogGroupModal.scss';
import PropTypes from 'prop-types';
import Modal from '../General/Modal';
import AddCatalogGroupShopifyCollections from './AddCatalogGroupShopifyCollections.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { connect } from 'react-redux';
import { addCatalogGroup } from '../../APIClient/catalog_groups.js';

const AddCatalogGroupModal = props => {
  const { close, user, syncCatalogGroups } = props;
  const [selectedAddOption, setSelectedAddOption] = useState(null);
  const [isConnecting, setIsConnecting] = useState(false);

  const addOptions = [
    {
      title: 'Connect Shopify Collection',
      type: 'shopify_collection',
      description: 'Browse and connect a product collection from your connected Shopify store(s).'
    }
    // {
    //   title: 'Create from Products',
    //   type: 'custom'
    // }
  ];

  const clearAddOption = () => {
    setSelectedAddOption(null);
  };

  const handleAddCatalogGroup = async catalogGroupData => {
    setIsConnecting(true);
    try {
      await addCatalogGroup(catalogGroupData);
      window.ALERT.success('Catalog group added successfully!');
      setIsConnecting(false);
      syncCatalogGroups();
      close();
    } catch (error) {
      console.error('Error adding catalog group: ', error);
      let alertMsg = 'There was an issue adding your catalog group, please try again.';

      if (error?.toLowerCase()?.includes('already connected')) {
        alertMsg = 'This Catalog Group is already connected, please choose another.';
      }
      window.ALERT.error(alertMsg, { hideAfter: 5 });
      setIsConnecting(false);
    }
  };

  const isBasePage = !selectedAddOption?.type;

  return (
    <Modal visible={true} close={close} innerClassName='add-catalog-group-modal' contentClassName='add-catalog-group-inner-modal' showClose={true}>
      <div className='add-catalog-group-modal-content'>
        <div className='modal-header'>
          {!isBasePage && <FontAwesomeIcon icon={faChevronLeft} onClick={clearAddOption} />}
          {selectedAddOption?.title || 'Add Catalog Group'}
        </div>
        {isBasePage && (
          <div className='modal-add-options'>
            {addOptions.map(option => {
              return (
                <div key={option.type} className='add-option-container' onClick={() => setSelectedAddOption(option)}>
                  <div className='img-container'>
                    <img className='shopify-synced-icon' src='https://static.shopmy.us/Icons/shopify.png' alt='shopify-icon' />
                  </div>
                  <div className='add-option-details'>
                    <div className='add-option-title'>{option.title}</div>
                    <div className='add-option-description'>{option.description}</div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {selectedAddOption?.type === 'shopify_collection' && (
          <AddCatalogGroupShopifyCollections user={user} onConnect={handleAddCatalogGroup} isConnecting={isConnecting} />
        )}
      </div>
    </Modal>
  );
};

AddCatalogGroupModal.propTypes = {
  user: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  syncCatalogGroups: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {})(AddCatalogGroupModal);
