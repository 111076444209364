import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './CheckboxButton.scss';

const CheckboxButton = props => {
  const { label, description, text, isChecked, onChange } = props;

  return (
    <button className={cn('checkbox-button-container-outer', { selected: isChecked })} onClick={onChange}>
      <div className={cn('checkbox-button-container-inner', { selected: isChecked })} onClick={onChange}>
        <div className='check'>{isChecked && <FontAwesomeIcon icon={faCheck} />}</div>
        <div className='check-text'>
          {label && <div className='check-label'>{label}</div>}
          {text && <div className='check-regular'>{text}</div>}
          {description && <div className='check-description'>{description}</div>}
        </div>
      </div>
    </button>
  );
};

CheckboxButton.propTypes = {
  description: PropTypes.string,
  label: PropTypes.string,
  text: PropTypes.string,
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default CheckboxButton;
