import _ from 'lodash';

export const isUserIdInPlan = (plan, User_id) => plan?.users.some(user => user.User_id === User_id);
export const isUserInPlan = (plan, user) => plan?.users.some(planUser => planUser.User_id === user.id);

export const getPlanUserFromUser = (plan, user) => plan?.users.find(planUser => planUser.User_id === user.id);

export const isPlanUserManuallyAdded = planUser =>
  !planUser.vol_per_link && !planUser.clicks_per_link && !planUser.views_per_mention && !planUser.emv_per_mention;
export const isManualPlan = plan => {
  /*
    If we have users who have no sort of projection, this must be a manually created plan and we should
    ignore the advanced metrics.
  */
  return !plan.users?.length || plan.users.some(isPlanUserManuallyAdded);
};

export const getBudgetForPlan = plan => {
  const users = plan?.users || [];
  const primaryUsers = users.filter(u => !u.isBackup);
  return _.sumBy(primaryUsers, 'recommendedFixedFee') || 0;
};
