import React from 'react';
import PropTypes from 'prop-types';
import './ShopBuildingSettingsPanel.scss';

import { getSettings } from '../../../../Helpers/user_helpers';
import CheckboxButton from '../../../General/Buttons/CheckboxButton';

const ShopBuildingSettingsPanel = props => {
  const { user } = props;
  const settings = getSettings(user);
  const { addPinsToTopInCollections } = settings;

  return (
    <div className='shop-building-settings-panel-outer'>
      <div className='shop-building-settings-panel-inner'>
        <div className='settings-section'>
          <div className='settings-section-title'>Collection Settings</div>
          <div className='settings-section-subtitle'>Customize your shop building experience across all the collections you create.</div>

          <div className='settings-section-list'>
            <CheckboxButton
              isChecked={!addPinsToTopInCollections}
              onChange={() => props.updateUserSettings({ addPinsToTopInCollections: false })}
              text='Add Links to Bottom of Collections'
            />
            <CheckboxButton
              isChecked={!!addPinsToTopInCollections}
              onChange={() => props.updateUserSettings({ addPinsToTopInCollections: true })}
              text='Add Links to Top of Collections'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ShopBuildingSettingsPanel.propTypes = {
  user: PropTypes.object.isRequired,
  updateUserSettings: PropTypes.func.isRequired
};

export default ShopBuildingSettingsPanel;
