import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './Settings.scss';

import { updateUserSettings } from '../../Actions/UserActions';
import {
  isBrand,
  isManager,
  getTokens,
  getAllShopifyIntegrations,
  isSMSAffiliatePartnerBrand,
  getBrandSettings,
  getManages,
  isLoggedIn
} from '../../Helpers/user_helpers';
import { isCurrentlyManaging } from '../../Helpers/manager_helpers';
import { setUrlParam, getUrlParam } from '../../Helpers/helpers';

import ScrollToTop from '../../Components/General/ScrollToTop';
import RequiresPermissions from '../../Components/Managers/RequiresPermissions';
import RequiresLoginPanel from '../../Components/General/RequiresLoginPanel';

// GENERAL PANELS
import GeneralProfileSettingsPanel from '../../Components/Settings/Panels/GeneralPanels/GeneralProfileSettingsPanel';
import SettingsSidePanel from '../../Components/Settings/SettingsSidePanel';
import SkeletonPanel from '../../Components/Settings/Panels/GeneralPanels/SkeletonPanel';

// MANAGER SPECIFIC PANELS
import ManagerRosterPanel from '../../Components/Settings/Panels/ManagerPanels/ManagerRosterPanel';

// USER SPECIFIC PANELS
import CodePreferencesPanel from '../../Components/Settings/Panels/UserPanels/CodePreferencesPanel';
import ConnectSocialsPanel from '../../Components/Settings/Panels/UserPanels/ConnectSocialsPanel';
import NotificationSettingsPanel from '../../Components/Settings/Panels/UserPanels/NotificationSettingsPanel';
import ShopBuildingSettingsPanel from '../../Components/Settings/Panels/UserPanels/ShopBuildingSettingsPanel';
import UserManagersPanel from '../../Components/Settings/Panels/UserPanels/UserManagersPanel';
import UserTagsPanel from '../../Components/Settings/Panels/UserPanels/UserTagsPanel';
import UserTierPanel from '../../Components/Settings/Panels/UserPanels/UserTierPanel';
import UserTokensPanel from '../../Components/Settings/Panels/UserPanels/UserTokensPanel';
import PaymentAccountsPanel from '../../Components/Settings/Panels/UserPanels/PaymentAccountsPanel';
import LocationDataPanel from '../../Components/Settings/Panels/UserPanels/LocationDataPanel';
import AdvancedSettingsPanel from '../../Components/Settings/Panels/UserPanels/AdvancedSettingsPanel';

// BRAND SPECIFIC PANELS
import ShopifyIntegrationPanel from '../../Components/Settings/Panels/BrandPanels/ShopifyIntegrationPanel';
import CreateShopifyIntegrationPanel from '../../Components/Settings/Panels/BrandPanels/CreateShopifyIntegrationPanel';
import BlacklistedCodesPanel from '../../Components/Settings/Panels/BrandPanels/BlacklistedCodesPanel';
import DeveloperKeyPanel from '../../Components/Settings/Panels/BrandPanels/DeveloperKeyPanel';
import UTMPanel from '../../Components/Settings/Panels/BrandPanels/UTMPanel';
import CompetitorBrandSettingsPanel from '../../Components/Settings/Panels/BrandPanels/CompetitorBrandSettingsPanel';
import StripeConfigurationPanel from '../../Components/Settings/Panels/BrandPanels/StripeConfigurationPanel';

import {
  faGear,
  faCircleCheck,
  faLink,
  faBell,
  faPerson,
  faCode,
  faChartColumn,
  faChartUser,
  faChartPieSimple,
  faCreditCardBlank,
  faPeopleRoof,
  faWrench
} from '@fortawesome/pro-light-svg-icons';

const Settings = props => {
  const { user, manager } = props;

  const [activePanelTitle, setActivePanelTitle] = useState(isManager(user) ? 'My Talent' : 'All');
  const [searchValue, setSearchValue] = useState('');

  const setActivePanel = title => {
    setSearchValue('');

    if (title === 'All') {
      setActivePanelTitle(title);
      setUrlParam('tab', 'All');
      return;
    }

    let section = sections.find(section => section.title === title);
    if (!section) section = sections.find(section => section.title === 'Account Settings');

    setActivePanelTitle(section.title);
    setUrlParam('tab', section.title);
  };

  useEffect(() => {
    const tab = getUrlParam('tab');
    if (tab) setActivePanel(tab);
  }, [user.profile?.id]);

  const sections = [
    /*************************************************** */
    // Manager
    /*************************************************** */
    {
      title: 'My Talent',
      icon: faPeopleRoof,
      panels: [
        {
          component: <ManagerRosterPanel user={user} />,
          keywords: 'roster talent manage invite',
          canView: isManager(user) || isCurrentlyManaging(manager) || getManages(user).length
        }
      ]
    },

    /*************************************************** */
    // General
    /*************************************************** */
    {
      title: 'Account Settings',
      icon: faGear,
      panels: [
        {
          component: <GeneralProfileSettingsPanel user={user} />,
          keywords: 'profile password email address phone login commission',
          canView: true
        },
        {
          component: <BlacklistedCodesPanel />,
          keywords: 'blacklist codes discount',
          canView: isBrand(user)
        }
      ]
    },

    /*************************************************** */
    // Creator
    /*************************************************** */
    {
      title: 'Creator Tier',
      icon: faCircleCheck,
      panels: [
        {
          component: <UserTierPanel user={user} updateUserSettings={props.updateUserSettings} />,
          keywords: 'tier score progress enthusiast ambassador trendsetter icon bonus scale volume orders clicks bio photo traffic monthly referrals',
          canView: !isBrand(user) && !isManager(user)
        }
      ]
    },
    {
      title: 'Connected Accounts',
      icon: faLink,
      panels: [
        {
          component: <ConnectSocialsPanel />,
          keywords: 'instagram tiktok youtube connect social media link',
          canView: !isManager(user) // Brands can now link their social accounts too!
        },
        {
          component: <PaymentAccountsPanel />,
          keywords: 'paypal stripe payment connect link',
          canView: !isBrand(user)
        }
      ]
    },
    {
      title: 'Account Management',
      icon: faPerson,
      panels: [
        {
          component: <UserManagersPanel />,
          keywords: 'manage',
          canView: !isBrand(user) && !isManager(user) && !isCurrentlyManaging(manager) && !getManages(user).length
        },
        {
          component: <UserTagsPanel />,
          keywords: 'tag profession category department country location brand',
          canView: !isBrand(user) && !isManager(user)
        },
        {
          component: <ShopBuildingSettingsPanel user={user} updateUserSettings={props.updateUserSettings} />,
          keywords: 'collection link top bottom shop build',
          canView: !isBrand(user) && !isManager(user)
        },
        {
          component: <CodePreferencesPanel user={user} updateUserSettings={props.updateUserSettings} />,
          keywords: 'code brand discount commission custom rate format',
          canView: !isBrand(user) && !isManager(user)
        },
        {
          component: <LocationDataPanel user={user} updateUserSettings={props.updateUserSettings} />,
          keywords: 'location data country city state',
          canView: !isBrand(user) && !isManager(user)
        }
      ]
    },
    {
      title: 'Notifications',
      icon: faBell,
      panels: [
        {
          component: <NotificationSettingsPanel />,
          keywords: 'notification email text message chat alert',
          canView: !isBrand(user) && !isManager(user)
        }
      ]
    },
    {
      title: 'Advanced',
      icon: faWrench,
      panels: [
        {
          component: <AdvancedSettingsPanel user={user} updateUserSettings={props.updateUserSettings} />,
          keywords: 'advanced auto link script domain',
          canView: !isBrand(user) && !isManager(user)
        }
      ]
    },

    /*************************************************** */
    // Brand
    /*************************************************** */
    {
      title: 'Integrations and Tracking',
      shortTitle: 'Integrations & Tracking',
      icon: faChartColumn,
      panels: [
        {
          component: <CreateShopifyIntegrationPanel />,
          keywords: 'shopify integration connect store key',
          canView: isBrand(user) && !getAllShopifyIntegrations(user).length
        },
        {
          component: <ShopifyIntegrationPanel />,
          keywords: 'shopify integration store gifting code tracking discount catalog product inventory order customer lookbook',
          canView: isBrand(user) && getAllShopifyIntegrations(user).length
        }
      ]
    },
    {
      title: 'Affiliates',
      icon: faChartUser,
      panels: [
        {
          component: <UTMPanel />,
          keywords: 'utm source medium campaign content referrer affiliate',
          canView: isBrand(user) && isSMSAffiliatePartnerBrand(user) && getBrandSettings(user)
        }
      ]
    },
    {
      title: 'Advanced Analytics',
      icon: faChartPieSimple,
      panels: [
        {
          component: <CompetitorBrandSettingsPanel />,
          keywords: 'similar competitor brand analytic recommendation',
          canView: isBrand(user)
        }
      ]
    },
    {
      title: 'Billing and Payments',
      shortTitle: 'Billing & Payments',
      icon: faCreditCardBlank,
      panels: [
        {
          component: <StripeConfigurationPanel />,
          keywords: 'stripe payment billing auto charge invoice',
          canView: isBrand(user) && getBrandSettings(user)
        }
      ]
    },

    {
      title: 'Tokens',
      icon: faCode,
      panels: [
        {
          component: <DeveloperKeyPanel />,
          keywords: 'developer key token access api',
          canView: isBrand(user)
        },
        {
          component: <UserTokensPanel />,
          keywords: 'developer key token access api',
          canView: !isBrand(user) && !!getTokens(user)?.length
        }
      ]
    }
  ];

  if (!isLoggedIn(user)) return <RequiresLoginPanel />;

  const SEARCH_VALUE_BREAKPOINT = 2;
  const activeSections =
    searchValue.length > SEARCH_VALUE_BREAKPOINT
      ? []
      : activePanelTitle === 'All'
      ? sections
      : sections.filter(section => section.title === activePanelTitle);
  const shouldShowActiveSectionTitle = activeSections.length === 1 && searchValue.length < SEARCH_VALUE_BREAKPOINT;

  const splitSearchValue = searchValue.split(' ');
  const searchResultPanels =
    searchValue.length > SEARCH_VALUE_BREAKPOINT
      ? sections.reduce((acc, section) => {
          const { panels } = section;
          const filteredPanels = panels.filter(panel => {
            const { keywords, canView } = panel;
            const splitKeywords = keywords.split(' ');

            // Any word in the search value is fully or partially included in the keywords
            const keywordMatch = splitSearchValue.some(searchWord => {
              if (searchWord.length < SEARCH_VALUE_BREAKPOINT) return false;
              return splitKeywords.some(keyword => keyword.includes(searchWord) || searchWord.includes(keyword));
            });

            return keywordMatch && canView;
          });

          return [...acc, ...filteredPanels];
        }, [])
      : [];

  return (
    <RequiresPermissions permission='canEditSettings'>
      <ScrollToTop />

      <div className='all-settings-outer'>
        <div className='all-settings-inner'>
          <SettingsSidePanel
            sections={sections}
            activePanelTitle={activePanelTitle}
            setActivePanelTitle={setActivePanel}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />

          {user.isSyncing ? (
            <div className='settings-content'>
              <div className='active-section-title skeleton' />

              {new Array(7).fill(0).map((_, idx) => (
                <SkeletonPanel key={`skeleton-panel-${idx}`} />
              ))}
            </div>
          ) : (
            <div className='settings-content'>
              {searchValue.length > SEARCH_VALUE_BREAKPOINT && (
                <>
                  <div className='active-section-title'>
                    Search Results
                    <div className='active-section-subtitle'>
                      {searchResultPanels.length
                        ? `Showing ${searchResultPanels.length} result${searchResultPanels.length === 1 ? '' : 's'} for "${searchValue}"`
                        : `No results found for "${searchValue}"`}
                    </div>
                  </div>
                  <div className='active-section'>
                    {searchResultPanels.map((panel, idx) => {
                      if (!panel.canView) return null;
                      return <div key={`all-settings-search-result-${idx}`}>{panel.component}</div>;
                    })}
                  </div>
                </>
              )}
              {activeSections.map(section => {
                const { title, panels } = section;
                const atLeastOnePanelIsVisible = panels.some(panel => panel.canView);

                if (!atLeastOnePanelIsVisible) return null;
                return (
                  <div key={`all-settings-section-${title}`}>
                    {shouldShowActiveSectionTitle && <div className={cn('active-section-title', { shouldShowActiveSectionTitle })}>{title}</div>}
                    <div className='active-section'>
                      {panels.map((panel, i) => {
                        if (!panel.canView) return null;

                        return <Fragment key={`all-settings-section-${title}-${i}`}>{panel.component}</Fragment>;
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </RequiresPermissions>
  );
};

Settings.propTypes = {
  user: PropTypes.object.isRequired,
  manager: PropTypes.object.isRequired,
  updateUserSettings: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, manager } = state;
  return { user, manager };
};

export default connect(mapStateToProps, {
  updateUserSettings
})(withRouter(Settings));
