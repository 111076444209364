import React, { useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { connect } from 'react-redux';
import _ from 'lodash';
import './Careers.scss';

import { addEvent } from '../../APIClient/events';
import { getAllJobs } from '../../Actions/FeaturedActions';
import Loader from '../../Components/Loader/Loader';
import CareerPerk from '../../Components/Careers/CareerPerk';

const Careers = props => {
  const { featured, getAllJobs } = props;
  const jobs = featured?.jobs || [];

  const getJobsGroupedByDepartment = () => {
    const gruopedJobs = _.groupBy(jobs, 'department');
    const finalJobs = Array.from(Object.entries(gruopedJobs)).map(([department, jobs]) => ({ department, jobs }));

    return finalJobs;
  };

  useEffect(() => {
    addEvent('Careers - Page View');
    getAllJobs();
  }, [jobs.length]);

  return (
    <div className='careers-outer-container'>
      <MetaTags>
        <title>Careers | ShopMy</title>
        <meta property='description' content="We're hiring! Please apply here or email us at careers@shopmy.us." />
      </MetaTags>

      <div className='careers-about-container'>
        <h1>Careers at ShopMy</h1>
        <h2>Join us and help revolutionize commerce and brand partnerships with cutting-edge technology and our best-in-class creator network.</h2>
        <p className='company-description'>
          ShopMy is an industry-leading affiliate and influencer marketing platform designed to help forward-thinking brands and creators supercharge
          their growth. Our passionate team is made up of highly-motivated, multi-disciplinary experts who are dedicated to redefining the creator
          economy, one meaningful connection at a time. Our flexible, fully-remote work culture prioritizes creativity, problem-solving, and growth
          opportunities.
        </p>
        <p className='company-description'>
          At ShopMy, you'll be at the forefront of creator marketing, a rapidly growing industry. Our platform connects you with top content creators
          and brands globally, offering a unique opportunity to work in an exciting and dynamic environment.
        </p>
      </div>

      <div className='careers-image-container'>
        <img className='careers-image' src='https://static.shopmy.us/uploads/img-lookbook_item_sibling_47322-1700493782177' alt='shopmy_img' />
        <img className='careers-image' src='https://static.shopmy.us/nyfw_2024.jpg' alt='NYFW 2024' />
        <img className='careers-image' src='https://static.shopmy.us/uploads/02635319-00bb-4c99-ab98-42a7d4c10a66shopmy_sign.jpg' alt='shopmy_img' />
        <img
          className='careers-image'
          src='https://static.shopmy.us/uploads/cd1f6de3-b7e4-43ef-9147-d80897e7e5d7coachella_shopmy_branded_balls.jpg'
          alt='shopmy_img'
        />
      </div>

      <div className='shopmy-perks-container'>
        <div className='shopmy-perks-container-inner'>
          <h2 className='perks-header'>Why Work At ShopMy?</h2>
          <p className='company-description'>
            At ShopMy, we invest in our employees' well-being and growth with competitive salaries, comprehensive health benefits, and flexible paid
            time off. Our inclusive and flexible work culture ensures every team member feels valued and supported.
          </p>
          <div className='perk-list'>
            <CareerPerk
              title='Health & Wellness'
              perks={['Medical & Dental Insurance 70% covered', 'Vision Insurance Available', '14 Weeks Paid Parental Leave', 'Classpass Membership']}
            />

            <CareerPerk title='Financial & Retirement' perks={['401K 3% Contribution', 'ShopMy Equity']} />

            <CareerPerk title='Company Culture' perks={['Remote First', 'Flexible PTO', 'Monthly Co-Worker Meet Up Stipends', 'Company Retreats']} />
          </div>
        </div>
      </div>

      <div className='careers-jobs-container'>
        <div className='all-jobs' id='all-jobs'>
          {!jobs?.length ? (
            <Loader />
          ) : (
            <div className='departments'>
              {getJobsGroupedByDepartment().map(departmentObject => {
                const { department, jobs } = departmentObject;

                return (
                  <div key={department} className='department'>
                    <div className='section-header'>{department}</div>
                    <div className='job-listing-container'>
                      {jobs.map(job => {
                        const { title, location, url } = job;

                        return (
                          <a href={url} target='_blank' rel='noreferrer' key={title} className='job-listing'>
                            <div className='job-title'>{title}</div>
                            <div className='job-location'>{location}</div>
                          </a>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>

      <div className='backup-option-container'>
        <div className='backup-option'>
          <h2 className='backup-option-title'>Don't see the open role for you?</h2>

          <p className='backup-option-description'>
            We’re always on the lookout for talented individuals who are excited about ShopMy to join our team. If you don’t see a role that fits your
            skills and experience, we still want to hear from you! Send an email telling us more about yourself to careers@shopmy.us. If a suitable
            opportunity arises, we’ll reach out to see if you’re a fit for our team.
          </p>

          <a className='backup-option-button' href='mailto:careers@shopmy.us'>
            Chat With Us
          </a>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { ui, featured } = state;
  return { ui, featured };
};

export default connect(mapStateToProps, { getAllJobs })(Careers);
