import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';
import cogoToast from 'cogo-toast';
import commaNumber from 'comma-number';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { saveCollection } from '../../APIClient/collections';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import '../../Components/Analytics/Analytics.scss';
import './CollectionPreview.scss';
import './CollectionPostPreview.scss';
import './CollectionGiftGuidePreview.scss';

import Loader from '../Loader/Loader';
import CollectionActionItemsContainer from './CollectionActionItemsContainer';

import { duplicateCollection } from '../../APIClient/collections';

import { canEditCollection } from '../../Helpers/user_helpers';
import { isPublicPage, getSmartImage, getCollectionsTitle } from '../../Helpers/helpers';
import { isVideoString, getThumbnailImageFromVideoUrl } from '../../Helpers/media_helpers';
import { getGMTTime } from '../../Helpers/formatting';

class CollectionPreview extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    collection: PropTypes.object.isRequired,
    sections: PropTypes.array.isRequired,
    isFetching: PropTypes.bool,

    // UI
    tabType: PropTypes.string.isRequired,
    isShelf: PropTypes.bool.isRequired,
    isPost: PropTypes.bool.isRequired,
    isEditorial: PropTypes.bool.isRequired,

    // Needed if editable
    syncProfile: PropTypes.func,
    fetchingAnalytics: PropTypes.bool,
    analyticsOn: PropTypes.bool,
    analytics: PropTypes.object,
    index: PropTypes.number,
    moveToTop: PropTypes.func,
    deleteCollection: PropTypes.func,

    // Needed if homepage
    feature: PropTypes.object,

    // Design elements
    fluid: PropTypes.bool
  };

  state = {
    updatingCollection: false,
    deletingCollection: false,
    togglingVisibility: false,
    showingActionItems: false,

    imagesLoaded: false
  };

  goToShop = (event, user) => {
    event.stopPropagation();
    event.preventDefault();
    this.props.history.push(`/${user.username}`);
    window.scrollTo(0, 0);
  };

  goToCollection = event => {
    event.stopPropagation();
    event.preventDefault();
    this.props.history.push(`/collections/${isPublicPage() ? 'public/' : ''}${this.props.collection.id}`);
    window.scrollTo(0, 0);
  };

  duplicateCollection = async () => {
    if (this.state.isDuplicating) return;
    this.setState({ isDuplicating: true });
    try {
      const collection = await duplicateCollection(this.props.collection);
      if (collection.id) {
        await this.props.syncProfile();
        // this.props.history.push(`/collections/${isPublicPage() ? 'public/' : ''}${collection?.id}`);
        // window.scrollTo(0, 0);
      } else {
        cogoToast.error(`There was an error duplicating that ${getCollectionsTitle()}, please try again.`);
      }
    } catch (error) {
      cogoToast.error(error || `There was an error duplicating that ${getCollectionsTitle()}, please try again.`);
    }
    this.setState({ isDuplicating: false });
  };

  deleteCollection = () => {
    const { collection, syncProfile, deleteCollection } = this.props;
    const { deletingCollection } = this.state;
    if (deletingCollection) return;

    const numPins = _.get(collection, ['pins', 'length'], 0);

    const confirmDelete = async () => {
      this.setState({ deletingCollection: true });
      deleteCollection(collection).then(async () => {
        await syncProfile();
        this.setState({ deletingCollection: false });
      });
    };

    if (numPins > 0) {
      confirmAlert({
        title: 'Just confirming',
        message: 'Are you sure you want to delete this collection?',
        buttons: [
          { label: 'No', className: 'cancel', onClick: () => {} },
          { label: 'Yes', onClick: confirmDelete }
        ]
      });
    } else {
      confirmDelete();
    }
  };

  updateCollection = async updates => {
    const { collection, syncProfile } = this.props;
    const editResp = await saveCollection(collection.id, updates);
    if (_.get(editResp, 'error')) {
      const errorMessage = _.get(editResp, ['error', 'message']);
      cogoToast.error(errorMessage || 'There was an issue editing this collection, please reload the page and try again.');
    } else {
      await syncProfile();
    }
  };

  updateSection = async section => {
    await this.updateCollection({ Section_id: section.id, sortOrderRank: getGMTTime() * -1, isArchived: false });
  };

  closeActionMenu = () => this.setState({ showingActionItems: false });

  render() {
    const { collection, analytics, isFetching, feature, user, tabType, isPost, isEditorial, analyticsOn, fetchingAnalytics, fluid } = this.props;
    const { showingActionItems, deletingCollection } = this.state;
    const { pins, name, id, image, num_pins } = collection;
    const { num_views, earnings } = analytics || {};
    const isFeatured = !!feature;
    const numPicks = num_pins || _.get(pins, 'length', 0);
    const pinsWithImages = _.filter(_.orderBy(pins, 'sortOrderRank'), pin => !!pin.image);
    const empty = pinsWithImages.length === 0;
    const canEdit = canEditCollection(collection, user);
    const fromCollection = _.get(this.props.lastLocation, 'pathname', '').includes('collection');
    const isHidden = !!collection.private;
    const postImage = image || pinsWithImages[0]?.image;
    const iconMenu = canEdit && !isFeatured && (
      <div className='icn-show'>
        <div
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ showingActionItems: !showingActionItems });
          }}
          className='icn show-action-items'
        >
          <FontAwesomeIcon icon={faEllipsisH} />
        </div>
        {showingActionItems && (
          <CollectionActionItemsContainer
            sections={this.props.sections}
            collection={collection}
            hideActionItems={this.closeActionMenu}
            deleteCollection={this.deleteCollection}
            duplicateCollection={this.duplicateCollection}
            toggleVisibility={async () => {
              await this.updateCollection({ private: !collection.private });
              this.closeActionMenu();
            }}
            toggleArchive={async () => {
              await this.updateCollection({
                isArchived: !collection.isArchived,
                sortOrderRank: -1 * getGMTTime(), // Move to top if unarchiving
                private: false
              });
              this.closeActionMenu();
            }}
            updateSection={this.updateSection}
            isDuplicating={this.state.isDuplicating}
            index={this.props.index}
            moveToTop={this.props.moveToTop}
          />
        )}
      </div>
    );

    return (
      <Link
        itemScope
        itemProp='url'
        to={`/collections/${isPublicPage() ? 'public/' : ''}${id}`}
        onClick={this.goToCollection}
        className={cn(
          isPost ? 'collection-post-preview-container' : isEditorial ? 'gift-guide-preview-outer-container' : 'collection-preview-container',
          tabType,
          {
            fluid,
            deleting: deletingCollection,
            'no-edit': !canEdit,
            'can-edit': canEdit
          }
        )}
      >
        {!isPost && !isEditorial && (
          <div
            className={cn('products-shelf-container', {
              private: !!collection.private,
              archived: !!collection.isArchived
            })}
          >
            {!!collection.private && canEdit && (
              <div className='tag-container'>
                <div className='notification-tag'>HIDDEN</div>
              </div>
            )}
            {_.map([...pinsWithImages, ...Array(8).keys()].slice(0, 4), (pin, idx) => {
              return (
                <div
                  key={idx}
                  className={cn('collection-preview-product-image-preview', {
                    smooth: !canEdit && !fromCollection,
                    hidden: isHidden
                  })}
                >
                  {_.get(pin, ['image', 'length']) && !isFetching ? (
                    <img alt={_.get(pin, 'title')} src={getSmartImage(pin.image)} className={cn('collection-preview-image', 'full')} />
                  ) : (
                    <div
                      alt='Empty Example'
                      className={cn('collection-preview-image', {
                        empty: empty || isFetching,
                        invisible: idx !== 0 && !isFetching
                      })}
                    >
                      {empty && canEdit && !isFetching ? '+' : ''}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
        {isPost && !collection.isArchived ? (
          <div
            className={cn('collection-preview-post-image-container', {
              hidden: !!collection.private,
              youtube: _.includes(collection.social_links, 'youtube') || _.includes(collection.social_links, 'youtu.be')
            })}
          >
            <img
              alt={name}
              src={getSmartImage(isVideoString(postImage) ? getThumbnailImageFromVideoUrl(postImage).fullPath : postImage)}
              className='collection-preview-post-image'
            />
            <div className='collection-preview-post-name'>{name}</div>
            {iconMenu}
            {!!collection.private && canEdit && (
              <div className='tag-container'>
                <div className='notification-tag'>HIDDEN</div>
              </div>
            )}
          </div>
        ) : isEditorial && !collection.isArchived ? (
          <div className='gift-guide-preview-container'>
            <div className={cn('gift-guide-preview-inner-container', { hidden: collection.private })}>
              {iconMenu}
              {collection.pins?.length ? (
                <div className='cover-image-container'>
                  <img
                    className={cn('cover-image', { public: !canEdit, cover: !!collection.image })}
                    src={collection.image || collection.pins[0]?.image}
                    alt='Product One'
                  />
                </div>
              ) : (
                <div className='background-image-container' />
              )}
              <div className={cn('content', { 'has-image': collection.coverImage || canEdit })}>
                {/* <div className='headline'> */}
                {/*   <div className='el date'>{moment(collection.createdAt).format('MMMM YYYY')}</div> */}
                {/* </div> */}
                <h2 className='title'>{collection.name || '-'}</h2>
                {collection.description && <h3 className={cn('subtitle', { empty: !collection.description })}>{collection.description}</h3>}
                <div className='get-started-link'>
                  VIEW PRODUCTS
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
            </div>
            {!!collection.private && canEdit && (
              <div className='tag-container'>
                <div className='notification-tag'>HIDDEN</div>
              </div>
            )}
            {canEdit && analyticsOn && (
              <div className='analytics-preview-container loaded'>
                {fetchingAnalytics ? (
                  <Loader size={20} />
                ) : (
                  <div className='header'>
                    <div>
                      {commaNumber(num_views) || '0'} view
                      {num_views === 1 ? '' : 's'}
                    </div>
                    <div>{earnings ? `$${earnings.toFixed(2)}` : ''}</div>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className='collection-preview-description'>
            <div className='collection-preview-content'>
              {isFeatured && feature.tag && <div className='collection-preview-tag'>{feature.tag}</div>}
              <div className='collection-preview-header'>
                <h2>{!isFetching && name}</h2>
                {iconMenu}
              </div>
              {isFetching ? null : isFeatured && feature.user ? (
                <div onClick={e => this.goToShop(e, feature.user)}>
                  <div className='collection-preview-subheader featured'>
                    by <span className='username'>{feature.user.name}</span>
                  </div>
                </div>
              ) : (
                <div className='collection-preview-subheader'>{`${numPicks} product${numPicks === 1 ? '' : 's'}`}</div>
              )}
            </div>
            <div className='collection-preview-description-shade' />
          </div>
        )}
        {canEdit && analyticsOn && !isEditorial && (
          <div className='analytics-preview-container loaded'>
            {fetchingAnalytics ? (
              <Loader size={20} />
            ) : (
              <div className='header'>
                <div>
                  {commaNumber(num_views) || '0'} view
                  {num_views === 1 ? '' : 's'}
                </div>
                <div>{earnings ? `$${earnings.toFixed(2)}` : ''}</div>
              </div>
            )}
          </div>
        )}
      </Link>
    );
  }
}

export default withLastLocation(withRouter(CollectionPreview));
