import React, { useState, useEffect, useRef } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import './CatalogGroups.scss';
import PropTypes from 'prop-types';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddCatalogGroupModal from './AddCatalogGroupModal';
import { getBrandBaseCommissionRate } from '../../Helpers/catalog_helpers.js';
import CatalogGroup from './CatalogGroup.js';
import { deleteCatalogGroupById } from '../../APIClient/catalog_groups.js';
import { CATALOG_FETCH_DATA_TIMEOUT, SHOPIFY_CATALOG_GROUP_TYPES } from './Constants/catalog.js';
import ConfirmPrompt from '../General/ConfirmPrompt.js';

const CatalogGroups = props => {
  const {
    user,
    catalogGroups,
    fetchCatalogGroups,
    canSupportCustomRates,
    isLoadingCatalogGroups,
    isLoadingAnalytics,
    catalogGroupRatesMap,
    saveGroupCustomAffiliateRate,
    controlBarSearchTerm,
    navigateToCatalogGroupProducts
  } = props;
  const [isAddGroupModalOpen, setIsAddGroupModalOpen] = useState(false);
  const [isDeletingCatalogGroup, setIsDeletingCatalogGroup] = useState(false);
  const [displayCatalogGroups, setDisplayCatalogGroups] = useState([]);
  const [isFilteringCatalogGroups, setIsFilteringCatalogGroups] = useState(false);
  const filterCatalogGroupsTimeout = useRef(null);

  const debouncedFilterCatalogGroups = (catalogGroupOptions, filterOptions) => {
    const { searchTerm } = filterOptions;
    if (filterCatalogGroupsTimeout.current) {
      clearTimeout(filterCatalogGroupsTimeout.current);
    }

    if (!searchTerm?.length) {
      setDisplayCatalogGroups(catalogGroupOptions);
      return setIsFilteringCatalogGroups(false);
    }

    setIsFilteringCatalogGroups(true);

    filterCatalogGroupsTimeout.current = setTimeout(() => {
      let filteredGroups = catalogGroupOptions;
      if (searchTerm?.length) {
        filteredGroups = catalogGroupOptions.filter(group => group.title.toLowerCase().includes(searchTerm.toLowerCase()));
      }

      setDisplayCatalogGroups(filteredGroups);
      setIsFilteringCatalogGroups(false);
    }, CATALOG_FETCH_DATA_TIMEOUT);
  };

  useEffect(() => {
    const filterOptions = {
      searchTerm: controlBarSearchTerm
    };
    debouncedFilterCatalogGroups(catalogGroups, filterOptions);

    return () => {
      if (filterCatalogGroupsTimeout.current) {
        clearTimeout(filterCatalogGroupsTimeout.current);
      }
    };
  }, [controlBarSearchTerm, catalogGroups]);

  const openAddGroupModal = () => {
    setIsAddGroupModalOpen(true);
  };

  const closeAddGroupModal = () => {
    setIsAddGroupModalOpen(false);
  };

  const deleteCatalogGroup = async catalogGroup => {
    setIsDeletingCatalogGroup(true);
    try {
      await deleteCatalogGroupById(catalogGroup.id);
      await fetchCatalogGroups();
    } catch (error) {
      window.ALERT.error(error || 'There was an issue deleting this Catalog Group. Please try again.');
      console.error('Error deleting catalog group: ', error);
    } finally {
      setIsDeletingCatalogGroup(false);
    }
  };

  const handleDeleteCatalogGroup = catalogGroup => {
    if (isDeletingCatalogGroup) return window.ALERT.info('Please wait for the current deletion to complete.');
    return confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          header='Are you sure?'
          subheader={`Are you sure you want to ${SHOPIFY_CATALOG_GROUP_TYPES.includes(catalogGroup.type) ? 'disconnect' : 'delete'} "${
            catalogGroup.title
          }"? Removing this Catalog Group will also remove any associated commission rates.`}
          onCancel={() => {
            onClose();
          }}
          submitBtnDisplay={'Yes'}
          onSubmit={() => deleteCatalogGroup(catalogGroup)}
          hideFormFields={true}
        />
      )
    });
  };

  const baseCommissionRate = getBrandBaseCommissionRate(user);

  if (!canSupportCustomRates) {
    return (
      <div className='catalog-groups-container'>
        <div className='catalog-groups-disabled'>
          Catalog Group customization is not yet supported for your account. Please contact your account manager for more information.
        </div>
      </div>
    );
  }

  const getCatalogGroupButton = () => {
    return (
      <div className='add-group-button' onClick={openAddGroupModal}>
        <FontAwesomeIcon icon={faPlus} />
        New Group
      </div>
    );
  };

  const isLoading = isLoadingCatalogGroups || isLoadingAnalytics || isFilteringCatalogGroups;
  const catalogGroupPlaceholders = isLoading ? Array.from({ length: 3 }, (_, i) => ({ id: i + 1 })) : null;
  const showNoResults = !isLoading && displayCatalogGroups.length === 0 && controlBarSearchTerm.length > 0;
  const showSetup = !isLoading && displayCatalogGroups.length === 0 && controlBarSearchTerm.length === 0;

  return (
    <div className='catalog-groups-container'>
      {!showSetup && getCatalogGroupButton()}
      <div className='connected-groups-list'>
        {(catalogGroupPlaceholders || displayCatalogGroups).map(catalogGroup => (
          <CatalogGroup
            key={catalogGroup.id}
            catalogGroup={catalogGroup}
            baseCommissionRate={baseCommissionRate}
            existingRate={catalogGroupRatesMap[catalogGroup.id]}
            saveCustomAffiliateRate={saveGroupCustomAffiliateRate}
            user={user}
            handleDeleteCatalogGroup={handleDeleteCatalogGroup}
            isDeletingCatalogGroup={isDeletingCatalogGroup}
            isSkeleton={isLoading}
            navigateToCatalogGroupProducts={navigateToCatalogGroupProducts}
          />
        ))}
      </div>
      {showNoResults && <div className='no-results'>We could not find any Catalog Groups matching your search criteria. Please try again.</div>}
      {showSetup && (
        <div className='catalog-groups-setup'>
          <div className='setup-header'>Add a Catalog Group to get started.</div>
          <div className='setup-subheader'>
            Catalog Groups are a way to organize your products into groups for easier management and rate assignment.
          </div>
          {getCatalogGroupButton()}
        </div>
      )}
      {isAddGroupModalOpen && <AddCatalogGroupModal close={closeAddGroupModal} syncCatalogGroups={fetchCatalogGroups} />}
    </div>
  );
};

CatalogGroups.propTypes = {
  user: PropTypes.object.isRequired,
  catalogGroups: PropTypes.array.isRequired,
  fetchCatalogGroups: PropTypes.func.isRequired,
  canSupportCustomRates: PropTypes.bool.isRequired,
  isLoadingCatalogGroups: PropTypes.bool.isRequired,
  isLoadingAnalytics: PropTypes.bool.isRequired,
  catalogGroupRatesMap: PropTypes.object.isRequired,
  saveGroupCustomAffiliateRate: PropTypes.func.isRequired,
  controlBarSearchTerm: PropTypes.string.isRequired,
  navigateToCatalogGroupProducts: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {})(CatalogGroups);
