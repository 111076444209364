import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEqual, get } from 'lodash';
import './UTMPanel.scss';

import { getBrandUtmParams, isBrand, getBrandSettings, isSMSAffiliatePartnerBrand } from '../../../../Helpers/user_helpers';
import { updateBrandSettings } from '../../../../Actions/BrandActions';
import CheckboxButton from '../../../General/Buttons/CheckboxButton';

const UTMPanel = props => {
  const { user } = props;
  const brandUtmParams = getBrandUtmParams(user);

  const [isUpdatingUtmParams, setIsUpdatingUtmParams] = useState(false);
  const [utmParams, setUtmParams] = useState(brandUtmParams);

  const updateUtmParamsField = (field, value) => setUtmParams({ ...utmParams, [field]: value });
  const updateUtmParams = () => {
    if (isUpdatingUtmParams) return;
    setIsUpdatingUtmParams(true);

    props
      .updateBrandSettings({ utmParams })
      .then(
        () => window.ALERT.success('UTM Parameters Updated!'),
        err => window.ALERT.error(get(err, 'message') || 'Invalid Update.')
      )
      .finally(() => setIsUpdatingUtmParams(false));
  };

  if (!(isBrand(user) && isSMSAffiliatePartnerBrand(user) && getBrandSettings(user))) return null;
  return (
    <div className='utm-panel-outer'>
      <div className='utm-panel-inner'>
        {[
          {
            display: 'UTM Source',
            variable: 'utm_source',
            defaultValue: 'ShopMy'
          },
          {
            display: 'UTM Medium',
            variable: 'utm_medium',
            defaultValue: 'affiliate'
          },
          {
            display: 'UTM Campaign',
            variable: 'utm_campaign',
            defaultValue: 'USER_NAME',
            canSelectFromEnum: true
          },
          {
            display: 'UTM Content',
            variable: 'utm_content',
            defaultValue: 'CONTENT_NAME',
            canSelectFromEnum: true
          },
          {
            display: 'UTM Referrer',
            variable: 'utm_referrer',
            defaultValue: 'CONTENT_REFERRER',
            fixedResultDisplay: 'Domain the click was generated from, ex: shopmy.us, instagram.com, substack.com, etc.'
          }
        ].map(({ display, variable, defaultValue, canSelectFromEnum, fixedResultDisplay }) => {
          const value = utmParams[variable];
          const isUserName = value === 'USER_NAME' || (!value && defaultValue === 'USER_NAME');
          const isUserUsername = value === 'USER_USERNAME' || (!value && defaultValue === 'USER_USERNAME');
          const isContent = value === 'CONTENT_NAME' || (!value && defaultValue === 'CONTENT_NAME');
          const isCustom = !isUserName && !isUserUsername && !isContent;
          const inputField = (
            <input
              className='settings-input'
              placeholder={defaultValue}
              type='text'
              onChange={({ target }) => updateUtmParamsField(variable, target.value)}
              value={utmParams[variable] || ''}
            />
          );
          return (
            <div key={variable} className='settings-section'>
              <div className='settings-section-title'>{display}</div>
              {fixedResultDisplay ? (
                <div className='settings-section-list'>
                  <CheckboxButton text={fixedResultDisplay} isChecked={true} />
                </div>
              ) : canSelectFromEnum ? (
                <div className='settings-section-list'>
                  <CheckboxButton
                    text='Name of the Talent, ex: Katie Jane Hughes'
                    isChecked={isUserName}
                    onChange={() => updateUtmParamsField(variable, 'USER_NAME')}
                  />
                  <CheckboxButton
                    text='Username of the Talent, ex: katiejanehughes'
                    isChecked={isUserUsername}
                    onChange={() => updateUtmParamsField(variable, 'USER_USERNAME')}
                  />
                  <CheckboxButton
                    text='Name of the Content, ex: My Favorite Products of {current year}'
                    isChecked={isContent}
                    onChange={() => updateUtmParamsField(variable, 'CONTENT_NAME')}
                  />
                  <CheckboxButton text='Custom Value' isChecked={isCustom} onChange={() => updateUtmParamsField(variable, 'Custom Value')} />
                  {isCustom && inputField}
                </div>
              ) : (
                inputField
              )}
            </div>
          );
        })}

        <div className='settings-section'>
          {!isEqual(getBrandUtmParams(user), utmParams) && (
            <button onClick={updateUtmParams} className='settings-button dark fit'>
              {isUpdatingUtmParams ? 'Updating...' : 'Update UTM Parameters'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

UTMPanel.propTypes = {
  user: PropTypes.object.isRequired,
  updateBrandSettings: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  updateBrandSettings
})(withRouter(UTMPanel));
