import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import PropTypes from 'prop-types';

import './MoreOptionsMenu.scss';

import {
  isBrand,
  isManager,
  getUsername,
  getRequests,
  getNewRequests,
  getNewContractProposals,
  getUnreadChatNotifications,
  getIncompleteConsultResults,
  getOutstandingOpportunityRequests,
  getNumUnpaidInvoicesPastDue,
  getCodes
} from '../../../Helpers/user_helpers';
import { CREATOR_HOME_NAV, BRAND_HOME_NAV } from '../../../Helpers/home_helpers';
import { hasPermission } from '../../../Helpers/manager_helpers';
import { isAdminControlMode } from '../../../Helpers/ui_helpers';

class MoreOptionsMenu extends Component {
  static propTypes = {
    ui: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    manager: PropTypes.object.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    openAuthModal: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired,
    isScrolled: PropTypes.bool.isRequired
  };

  getMenuItem = (label, data, additionalClasses = {}) => {
    const { to, href, isNew, isCount, count, onClick } = data || {};
    const active = window.location.href.includes(to);

    const handleClick = () => {
      onClick && onClick();
      this.props.close();
    };

    const newIcon = <div className='new-badge'>NEW</div>;
    const countBadge = (
      <div className='count-badge'>
        <div className='count'>{count}</div>
      </div>
    );
    return to ? (
      <li className={cn('menu-item-container', additionalClasses)}>
        <Link to={to} className={cn('menu-item', { active, ...additionalClasses })} onClick={handleClick}>
          <div className='menu-item-text'>
            <div className='label'>{label}</div>
            {isNew && newIcon}
            {isCount && countBadge}
          </div>
        </Link>
      </li>
    ) : href ? (
      <li className={cn('menu-item-container', additionalClasses)}>
        <a className={cn('menu-item', { ...additionalClasses })} rel='noopener nofollow' href={href} onClick={handleClick}>
          <div className='menu-item-text'>
            {label}
            {isNew && newIcon}
            {isCount && countBadge}
          </div>
        </a>
      </li>
    ) : (
      <li className={cn('menu-item-container', additionalClasses)}>
        <button className={cn('menu-item', { active, ...additionalClasses })} onClick={handleClick}>
          <div className='menu-item-text'>
            {label}
            {isNew && newIcon}
            {isCount && countBadge}
          </div>
        </button>
      </li>
    );
  };

  render() {
    const { isLoggedIn, openAuthModal, close, user, manager, active, isScrolled } = this.props;
    const requests = getRequests(user);
    const codes = getCodes(user);
    const newChatNotifications = getUnreadChatNotifications(user);
    const newRequests = getNewRequests(user);
    const newContractProposals = getNewContractProposals(user);
    const newConsultResults = getIncompleteConsultResults(user);
    const newOpportunityRequests = getOutstandingOpportunityRequests(user);
    const numUnpaidInvoicesPastDue = getNumUnpaidInvoicesPastDue(user);
    const shouldShowCatalogMenuItem = isAdminControlMode(this.props.ui) || window.__IS_CUSTOM_AFFILIATE_RATES_LIVE__(user);

    const totalBrandNotifications = isBrand(user) ? newRequests.length + newContractProposals.length + newChatNotifications.length : 0;
    return (
      <>
        <div onClick={close} className={cn('more-options-menu-fade-container', { active })} />
        <div
          className={cn('more-options-menu-outer-container', {
            active,
            scrolled: isScrolled
          })}
        >
          <nav className='more-options-menu-container'>
            {!isLoggedIn ? (
              <>
                <div className='section main'>
                  {this.getMenuItem('For Creators', { to: '/home/creators' })}
                  <div className='subpage-container'>
                    {CREATOR_HOME_NAV.slice(1).map(nav => {
                      return <React.Fragment key={nav.to}>{this.getMenuItem(nav.header, { to: nav.to }, { subpage: true })}</React.Fragment>;
                    })}
                  </div>
                  {this.getMenuItem('For Brands', { to: '/home/brands' })}
                  <div className='subpage-container'>
                    {BRAND_HOME_NAV.slice(1).map(nav => {
                      return <React.Fragment key={nav.to}>{this.getMenuItem(nav.header, { to: nav.to }, { subpage: true })}</React.Fragment>;
                    })}
                  </div>
                </div>
                <div className='section secondary'>
                  {this.getMenuItem('About Us', { to: `/team` })}
                  {this.getMenuItem('Shop Top Products', { to: `/shop` })}
                  {this.getMenuItem('Blog', { to: `/blog` })}
                  {this.getMenuItem('Careers', { to: `/careers` })}
                  {this.getMenuItem('Contact', { to: `/contact` })}
                </div>
              </>
            ) : isBrand(user) ? (
              <>
                <div className='section main'>
                  {this.getMenuItem('Talent', { to: '/talent', isCount: !!totalBrandNotifications, count: totalBrandNotifications })}
                  {this.getMenuItem('Discover', { to: '/discover' })}
                  {this.getMenuItem('Gifting', { to: `/requests` })}
                  {this.getMenuItem('Opportunities', { to: `/opportunities` })}
                  {this.getMenuItem('Analytics', { to: `/links` })}
                  {this.getMenuItem('Chat', {
                    to: `/chat`
                    // isCount: !!newChatNotifications.length,
                    // count: newChatNotifications.length
                  })}
                  {this.getMenuItem('Social Mentions', {
                    to: '/mentions'
                  })}
                  {shouldShowCatalogMenuItem && this.getMenuItem('Catalog', { to: `/catalog/products`, isNew: true })}
                </div>
                <div className='section secondary'>
                  {this.getMenuItem('Collaborations', {
                    to: '/collaborations'
                  })}
                  {this.getMenuItem('Orders Log', { to: `/commissions` })}
                  {this.getMenuItem('Custom Codes', { to: `/codes` })}
                  {this.getMenuItem('Account Settings', { to: `/settings` })}
                  {this.getMenuItem('Invoices & Subscriptions', {
                    to: `/subscriptions`,
                    isCount: !!numUnpaidInvoicesPastDue,
                    count: numUnpaidInvoicesPastDue
                  })}
                  {this.getMenuItem('Program Report', { to: `/report` })}
                  {this.getMenuItem('How-To Guide', { href: `https://brands.shopmy.us` })}
                  {this.getMenuItem('Invite Talent', { onClick: this.props.openInviteTalentModal })}
                </div>
              </>
            ) : isManager(user) ? (
              <>
                <div className='section main'>
                  {this.getMenuItem('My Talent', { to: `/settings?tab=My+Talent` })}
                  {this.getMenuItem('Earnings', { to: `/payouts` })}
                </div>
                <div className='section secondary'>
                  {this.getMenuItem('Account Settings', { to: `/settings` })}
                  {this.getMenuItem('Invite Your Talent', { to: `/invite/${getUsername(user)}` })}
                </div>
              </>
            ) : (
              <>
                <div className='section main'>
                  {hasPermission(user, manager, 'canEditShop') &&
                    this.getMenuItem('My Shop', {
                      to: `/${getUsername(user)}`,
                      isCount: !!newConsultResults.length,
                      count: newConsultResults.length
                    })}
                  {hasPermission(user, manager, 'canViewEarnings') && this.getMenuItem('Links', { to: `/links` })}
                  {hasPermission(user, manager, 'canCreateLinks') && this.getMenuItem('Earnings', { to: `/payouts` })}
                  {hasPermission(user, manager, 'canAnswerCollaborations') &&
                    this.getMenuItem('Collaborations', {
                      to: '/collaborations',
                      isCount: !!newContractProposals.length,
                      count: newContractProposals.length
                    })}
                  {hasPermission(user, manager, 'canAnswerOpportunities') &&
                    this.getMenuItem('Opportunities', {
                      to: '/partners/opportunities',
                      isCount: !!newOpportunityRequests.length,
                      count: newOpportunityRequests.length
                    })}
                  {this.getMenuItem('Latest', { to: `/latest` })}
                  {this.getMenuItem('Refer & Earn', { to: `/invite/${getUsername(user)}`, isNew: true })}
                </div>
                <div className='section secondary'>
                  {hasPermission(user, manager, 'canAnswerChats') &&
                    this.getMenuItem(isBrand(user) ? 'Chat' : 'Chat with Brands', {
                      to: `/chat`,
                      isCount: !!newChatNotifications.length,
                      count: newChatNotifications.length
                    })}
                  {/* {(!!requests.length || !!codes.length) &&
                    hasPermission(user, manager, 'canAcceptGifting') &&
                    this.getMenuItem('Gifting & Codes', { to: `/partners/gifting`, isCount: !!newRequests.length, count: newRequests.length })} */}
                  {(!!requests.length || !!codes.length) &&
                    hasPermission(user, manager, 'canAcceptGifting') &&
                    this.getMenuItem('Gifting & Codes', { to: `/partners/gifting`, isCount: !!newRequests.length, count: newRequests.length })}
                  {hasPermission(user, manager, 'canEditSettings') && this.getMenuItem('Account Settings', { to: `/settings` })}
                  {this.getMenuItem('How-To Guide', { href: `https://guide.shopmy.us` })}
                </div>
              </>
            )}
            {isLoggedIn ? (
              <div className='section actions'>
                {this.getMenuItem('Sign Out', {
                  onClick: () => {
                    this.props.logoutUser().then(() => {
                      !window.__IS_SMS__ && this.props.history.push('/');
                    });
                  }
                })}
              </div>
            ) : (
              <div className='section actions'>
                {this.getMenuItem('Sign Up', {
                  onClick: () => {
                    openAuthModal('apply-select-type');
                    close();
                  }
                })}
                {this.getMenuItem('Log In', {
                  onClick: () => {
                    openAuthModal('');
                    close();
                  }
                })}
              </div>
            )}
            <div className='close-menu-icon' onClick={close}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </nav>
        </div>
      </>
    );
  }
}

export default withRouter(MoreOptionsMenu);
