import React from 'react';
import './CatalogVariants.scss';
import PropTypes from 'prop-types';
import CatalogVariant from './CatalogVariant';
import { CATALOG_VARIANTS_PAGE_SIZE } from './Constants/catalog';
import ShopMyPagination from '../General/ShopMyPagination';

const CatalogVariants = props => {
  const {
    isLoadingVariants,
    isLoadingAnalytics,
    variants,
    curVariantsPage,
    numVariantsPages,
    handleVariantsPageChange,
    shouldShowVariantDomains
  } = props;

  const isLoading = isLoadingVariants || isLoadingAnalytics;
  const variantPlaceholders = isLoading ? Array.from({ length: CATALOG_VARIANTS_PAGE_SIZE }, (_, i) => ({ id: i + 1 })) : null;

  return (
    <div className='catalog-variants-container'>
      <div className='variants-list'>
        {(variantPlaceholders || variants).map(variant => {
          return <CatalogVariant key={variant.id} variant={variant} isSkeleton={isLoading} shouldShowVariantDomains={shouldShowVariantDomains} />;
        })}
      </div>
      {!isLoading && variants.length === 0 && (
        <div className='no-results'>We could not find any products matching your search criteria. Please try again.</div>
      )}
      <div className='footer-container'>
        {numVariantsPages > 1 && <ShopMyPagination curPage={curVariantsPage} totalPages={numVariantsPages} goToPage={handleVariantsPageChange} />}
      </div>
    </div>
  );
};

CatalogVariants.propTypes = {
  isLoadingVariants: PropTypes.bool.isRequired,
  isLoadingAnalytics: PropTypes.bool.isRequired,
  variants: PropTypes.array.isRequired,
  curVariantsPage: PropTypes.number.isRequired,
  numVariantsPages: PropTypes.number.isRequired,
  handleVariantsPageChange: PropTypes.func.isRequired,
  shouldShowVariantDomains: PropTypes.bool
};

export default CatalogVariants;
