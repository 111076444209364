import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';
import classnames from 'classnames';
import MetaTags from 'react-meta-tags';
import _ from 'lodash';

import { getVisibleCollection, setVisibleProduct, getCustomRates } from '../../Actions/StoreActions';
import { setAnalyticsMode, clearEditingPins, togglePinToMove, toggleBodyScrollLock, setCompleteMovingPinsFn } from '../../Actions/UIActions';
import { getCollectionAnalytics as getCollectionAnalyticsAPI } from '../../APIClient/collections';
import { Loader } from '../../Components';

import CollectionSettings from '../../Components/Collection/Elements/CollectionSettings';
import CollectionHeader from '../../Components/Collection/CollectionHeader';
import AddPinModal from '../../Components/AddPinModal/AddPinModal';
import CollectionProducts from '../../Components/Collection/CollectionProducts';
import ProfileManagementHeader from '../../Components/Profile/ProfileManagementHeader';
import RequiresPermissions from '../../Components/Managers/RequiresPermissions';
import ScrollToTop from '../../Components/General/ScrollToTop';

import './Collection.scss';

import { isContentRendered } from '../../Helpers/helpers';
import { getUserId, canEditCollection, getNameWithS } from '../../Helpers/user_helpers';
import { isAdminControlMode } from '../../Helpers/ui_helpers';

const TEST_SEARCH_TERM = null;
const TEST_YOUR_PINS = false;

class Collection extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    getCustomRates: PropTypes.func.isRequired,
    ui: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    getVisibleCollection: PropTypes.func.isRequired,
    setCompleteMovingPinsFn: PropTypes.func.isRequired
  };

  state = {
    // Editing
    inAddMode: false,
    isSettingsPanelOpen: false,
    pinBeingEdited: null,

    // Analytics
    fetchingAnalytics: false,
    analytics: {},

    // UI Handling
    waitingOnSSR: isContentRendered() // ensure admin specific elements wait on SSR to complete before loading to ensure hydration completes accurately
  };

  componentDidMount() {
    const { store, match } = this.props;
    const { visibleCollection } = store;
    const isNew = match.params.id !== String(_.get(visibleCollection, 'id'));

    this.syncCollection(isNew).then(collection => {
      if (collection) {
        if (canEditCollection(collection, this.props.user)) {
          this.props.getCustomRates(getUserId(this.props.user));
        }
        this.syncAnalytics(collection);
      }
    });

    this.props.clearEditingPins();
    this.props.setCompleteMovingPinsFn(this.syncCollection);

    this.state.waitingOnSSR && this.setState({ waitingOnSSR: false });
  }

  componentWillUnmount() {
    this.props.setCompleteMovingPinsFn(null);
  }

  componentDidUpdate(prevProps) {
    const { store, match } = this.props;

    // We have a new collection
    if (match.params.id !== prevProps.match.params.id) {
      this.syncCollection(true);
    }

    // Update analytics when adding a pin
    const thisCollection = _.get(store, 'visibleCollection');
    const prevCollection = _.get(prevProps.store, 'visibleCollection');

    if (
      _.get(thisCollection, 'id') === _.get(prevCollection, 'id') &&
      _.get(thisCollection, ['pins', 'length'], 0) > _.get(prevCollection, ['pins', 'length'], 0)
    ) {
      this.syncAnalytics(thisCollection);
    }

    // Update analytics when first opening analytics mode
    if (_.get(this.props.ui, 'inAnalyticsMode') && !_.get(prevProps.ui, 'inAnalyticsMode') && _.isEmpty(this.state.analytics)) {
      this.syncAnalytics(thisCollection);
    }
  }

  syncCollection = async showLoader => {
    const collection = await this.props.getVisibleCollection(this.props.match.params.id, { showLoader });
    return _.get(collection, 'visibleCollection');
  };

  syncAnalytics = collection => {
    const { user, ui } = this.props;
    const inAnalyticsMode = _.get(ui, 'inAnalyticsMode');
    const canEdit = canEditCollection(collection, user);

    if (canEdit && inAnalyticsMode) {
      this.setState({ fetchingAnalytics: true });
      getCollectionAnalyticsAPI(collection.id).then(
        ({ analytics } = {}) => {
          this.setState({ analytics, fetchingAnalytics: false });
        },
        err => this.setState({ fetchingAnalytics: false })
      );
    }
  };

  getCustomUiViaUrlParams = () => {
    /*
      This allows users to pass in custom UI params via the URL in order to better
      integrate with their own site. This is useful for things like hiding the header
      or any other small UI elements that we would not use in our standard UI.
    */
    const urlSearchParams = new URLSearchParams(window.location.search);
    return {
      noHeader: !!urlSearchParams.get('noHeader') // Hide title and back button
    };
  };

  visibleCollection = () => _.get(this.props.store, ['visibleCollection']);

  toggleAddingMode = () => {
    const comingFromEdit = !!this.state.pinBeingEdited;
    const toggleOn = !comingFromEdit && !this.state.inAddMode;
    this.setState({
      inAddMode: toggleOn,
      pinBeingEdited: null
    });

    this.props.toggleBodyScrollLock(toggleOn);
  };

  toggleSettingsPanel = () => {
    const { isSettingsPanelOpen } = this.state;
    this.setState({ isSettingsPanelOpen: !isSettingsPanelOpen });
    this.props.toggleBodyScrollLock(!isSettingsPanelOpen);
  };

  toggleEditMode = pin => {
    this.setState({ pinBeingEdited: pin });
    this.props.toggleBodyScrollLock(true);
  };

  isFetching = () => this.visibleCollection()?.id !== parseFloat(_.get(this.props.match, ['params', 'id']));

  getMetaTags = () => {
    const collection = this.visibleCollection();
    const { pins } = collection || {};
    const storeUser = _.get(collection, 'user', {});
    const storeUserName = _.get(storeUser, 'name');
    const pluralizedName = getNameWithS(storeUser);
    return (
      <MetaTags>
        <title>
          {collection.name || 'Recommendations'} | {storeUserName || 'ShopMy'}
        </title>
        <meta property='og:title' content={`${storeUserName || 'ShopMy'} - ${collection.name}`} />
        <meta
          property='description'
          content={`See all of ${pluralizedName.full} product recommendations from the ${window.__IS_SMS__ ? 'list' : 'collection'} - ${
            collection.name
          }`}
        />
        <meta
          property='og:description'
          content={`See all of ${pluralizedName.full} product recommendations from the ${window.__IS_SMS__ ? 'list' : 'collection'} - ${
            collection.name
          }`}
        />
        {pins && _.map(pins.slice(0, 3), pin => <meta key={pin.id} property='og:image' content={pin.image} />)}
      </MetaTags>
    );
  };

  render() {
    const { store, ui, user } = this.props;
    const { inAddMode, analytics, pinBeingEdited } = this.state;
    const { failureFetchingVisibleCollection } = store || {};
    const collection = this.visibleCollection();
    const storeUser = _.get(collection, 'user', {});
    const canEdit = canEditCollection(collection, user) && !this.state.waitingOnSSR;
    const isFetching = this.isFetching();
    const adminControlMode = isAdminControlMode(ui);
    const customUI = this.getCustomUiViaUrlParams();
    return (
      <RequiresPermissions permission='canEditShop'>
        <ScrollToTop from={['/latest']} />
        {collection && this.getMetaTags()}
        {!isFetching && canEdit && (
          <ProfileManagementHeader
            ui={ui}
            collection={collection}
            setAnalyticsMode={this.props.setAnalyticsMode}
            openSettingsPanel={this.toggleSettingsPanel}
          />
        )}
        <div className='collection-content'>
          <div
            className={classnames('collection-content-inner-container', collection?.skinType, {
              'modal-open': inAddMode
            })}
          >
            {(isFetching || failureFetchingVisibleCollection) && (
              <div className='collection-loader-container'>
                {failureFetchingVisibleCollection ? (
                  <div className='collection-loading-failure' onClick={() => this.syncCollection(true)}>
                    Click to try again
                  </div>
                ) : (
                  <Loader />
                )}
              </div>
            )}
            {collection && !isFetching && (
              <>
                {!customUI.noHeader && (
                  <CollectionHeader user={user} collection={collection} canEdit={canEdit} syncCollection={this.syncCollection} />
                )}
                <CollectionProducts
                  syncCollection={this.syncCollection}
                  toggleAddingMode={this.toggleAddingMode}
                  setVisibleProduct={this.props.setVisibleProduct}
                  togglePinToMove={this.props.togglePinToMove}
                  toggleEditMode={this.toggleEditMode}
                  collection={collection}
                  analytics={analytics}
                  fetchingAnalytics={this.state.fetchingAnalytics}
                  lastLocation={this.props.lastLocation}
                  user={this.props.user}
                  ui={this.props.ui}
                  adminControlMode={adminControlMode}
                  setAnalyticsMode={this.props.setAnalyticsMode}
                />
                {canEdit && (
                  <>
                    <AddPinModal
                      inAddMode={!!TEST_SEARCH_TERM || !!TEST_YOUR_PINS || inAddMode}
                      pinBeingEdited={pinBeingEdited}
                      toggleAddingMode={this.toggleAddingMode}
                      adminControlMode={adminControlMode}
                      TEST_SEARCH_TERM={TEST_SEARCH_TERM}
                      TEST_YOUR_PINS={TEST_YOUR_PINS}
                      syncExternalState={this.syncCollection}
                      Editing_User_id={storeUser.id}
                      Editing_Collection_id={collection.id}
                    />
                    <CollectionSettings
                      isVisible={this.state.isSettingsPanelOpen}
                      syncCollection={this.syncCollection}
                      closePanel={this.toggleSettingsPanel}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </RequiresPermissions>
    );
  }
}

const mapStateToProps = state => {
  const { user, store, ui } = state;
  return { user, store, ui };
};

export default connect(mapStateToProps, {
  getVisibleCollection,
  clearEditingPins,
  setVisibleProduct,
  toggleBodyScrollLock,
  togglePinToMove,
  setCompleteMovingPinsFn,
  getCustomRates,
  setAnalyticsMode
})(withLastLocation(withRouter(Collection)));
