import _ from 'lodash';
import slugify from 'slugify';

import { getDomainFromUrl } from '../Helpers/formatting';
import { getCollectionType } from '../Helpers/shop_helpers';

export const getMerchantMap = store => _.get(store, 'merchantData', {});
export const getMerchant = (store, domain) => _.get(getMerchantMap(store), domain);
export const getLockedCatalogBrand = store => _.get(store, 'lockedCatalogBrand') || null;
export const getTabUrlSlugForCollection = collection => {
  if (collection.section) return slugify(collection.section.title).toLowerCase();

  // Deprecated after May 2, 2023, remove if all collections have section_ids
  const type = getCollectionType(collection);
  if (type === 'shelf') return 'shelves';
  if (type === 'post') return 'post';
  if (type === 'gift-guide') return 'gift-guides';
  if (type === 'closet') return 'closet';
  if (type === 'home') return 'home';
  return null;
};

export const getRepresentativeImageForCollection = collection => {
  const pins = collection.pins || collection.basic_pins;
  const validPins = pins.filter(p => p.image);
  const sortedPins = _.orderBy(validPins, 'sortOrderRank');
  return _.get(sortedPins, '0.image');
};

export const getCodeForPin = (pin, collection) => {
  const allCodes = collection?.user?.codes;
  if (_.isNil(allCodes)) {
    console.warn(`You must pass through collection.user.codes to use this function`);
    return null;
  }
  return getCodeForPinFromCodes(pin, allCodes);
};

export const getCodeForPinFromCodes = (pin, codes) => {
  const pinDomain = getDomainFromUrl(pin.link);
  const code = _.orderBy(codes, 'rate', 'desc').find(code => {
    const domains = _.concat(code?.brand?.domain, code?.brand?.altDomains?.split(',') || []);
    return domains.find(d => d === pinDomain);
  });
  return code;
};
