import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './LookbookReportProduct.scss';
import cn from 'classnames';
import Image from '../../../General/Image';

const LookbookReportProduct = props => {
  const { product, lookbook } = props;
  const { imageStyle } = lookbook;
  const { title, totalQuantity, siblingBreakdown, displaySibling, orderItems } = product;

  const [selectedImage, setSelectedImage] = useState(() => {
    const displaySiblingImage = displaySibling?.image;
    const displaySiblingReducedSiblingsImage = displaySibling?.reduced_siblings?.[0]?.image;
    const orderItemsImage = orderItems?.[0]?.image;

    let defaultImage;
    if (displaySiblingReducedSiblingsImage) defaultImage = displaySiblingReducedSiblingsImage;
    else if (displaySiblingImage) defaultImage = displaySiblingImage;
    else if (orderItemsImage) defaultImage = orderItemsImage;

    return defaultImage || null;
  });

  return (
    <div className='lookbook-report-product-outer'>
      <div className='lookbook-report-product-inner'>
        <Image src={selectedImage} className={cn('product-image', imageStyle)} alt={`${title}`} />
        <div className='product-text-container'>
          <div className='product-title'>{title}</div>
          <div className='product-quantity'>
            Selected {totalQuantity} time{totalQuantity > 1 ? 's' : ''}
          </div>
          <div className='sibling-selection-breakdown-container'>
            {siblingBreakdown.map(siblingBreakdown => {
              const { sibling, quantity } = siblingBreakdown;
              const { id, tag, image } = sibling;

              return (
                <div className='sibling-selection-breakdown' key={`sibling-breakdown-${id}`} onClick={() => setSelectedImage(image)}>
                  {tag || 'Default'}
                  <span>{quantity}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

LookbookReportProduct.propTypes = {
  lookbook: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
};

export default LookbookReportProduct;
