import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import './ImageUploader.scss';

import UploadImage from './UploadImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFileUpload } from '@fortawesome/pro-regular-svg-icons';
import DropUploader from '../Uploader/DropUploader';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';
import 'react-image-crop/dist/ReactCrop.css';
import { cropImage } from '../../APIClient/images';
import Loader from '../Loader/Loader';

/**
 * This is a modal that allows for the user to upload an image or enter an image URL.
 * When they click save, whichever image option they have selected will be returned
 * in a callback function. In that callback function, you can define how you want to
 * handle the image.
 */

const ImageUploaderComponent = (props, ref) => {
  const { initialImageUrl, isVisible, setIsVisible, onSaveCallback, previewAspectRatio, allowCrop } = props;

  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [image, setImage] = useState();
  const [url, setUrl] = useState(initialImageUrl);
  const [crop, setCrop] = useState({});
  const [isCropping, setIsCropping] = useState(false);

  const hasImage = image || url;
  const isAbleToClear = !hasImage && initialImageUrl;
  const isDragging = useRef(false);

  const handleUrlInput = event => setUrl(event.target.value);

  useImperativeHandle(ref, () => ({
    getImageFile: () => image,
    getImageUrl: () => url
  }));

  const clearInputs = () => {
    if (isCropping) return;
    setImage('');
    setUrl('');
    setCrop({});
  };

  const saveAndExit = async () => {
    if (uploading || isCropping) return;
    let final_url = image || url;
    if (crop.width && crop.height) {
      setIsCropping(true);
      const [cropFrame] = document.getElementsByClassName('ReactCrop');
      const { width, height } = cropFrame.getBoundingClientRect();
      try {
        const { url } = await cropImage(final_url, { ...crop, frame: { width, height } });
        final_url = url;
      } catch (err) {
        window.ALERT.error('There was an error uploading your image.');
      } finally {
        setIsCropping(false);
      }
    }
    setCrop({});
    await onSaveCallback(final_url);
    setIsVisible(false);
  };

  const completeUpload = url => {
    setImage(url);
    setCrop({});
    setUploading(false);
  };

  const onUploadProgress = progress => {
    setUploading(true);
    setUploadProgress(progress);
  };

  const handleDragUpload = fileUrl => {
    setUrl(fileUrl);
    setCrop({});
    window.ALERT.success('Image uploaded successfully.');
  };

  const handleDragDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) return window.ALERT.error('Only image files are supported.');
    setUploading(false);
  };

  if (!isVisible) return null;
  return (
    <div
      className='image-uploader-outer'
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        if (isDragging.current) return;
        setIsVisible(false);
      }}
    >
      <div
        className='image-uploader-inner'
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <DropUploader onDrop={handleDragDrop} onUpload={handleDragUpload} onlyAllowImages>
          <div className='heading'>
            <div className='image-uploader-header'>Enter An Image</div>
            <div className='image-uploader-sub-header'>Only .jpg, .jpeg, .png, and .webp files are supported.</div>
          </div>
          {/* <input type='file' accept='image/*' onChange={handleImageUpload} onClick={e => e.stopPropagation()} /> */}
          <UploadImage
            basicContent={
              image ? (
                <div className='upload-image-container'>
                  Upload Complete
                  <FontAwesomeIcon icon={faCheck} />
                </div>
              ) : (
                <div className='upload-image-container'>
                  Upload Image
                  <FontAwesomeIcon icon={faFileUpload} />
                  {uploading && <span> ({uploadProgress})</span>}
                </div>
              )
            }
            basicUploader={true}
            onUploadProgress={onUploadProgress}
            completeUpload={completeUpload}
            imagesOnly={true}
          />
          <input type='text' className='image-uploader-input' placeholder='Or enter an image URL' onChange={handleUrlInput} value={url} />
          {hasImage &&
            (allowCrop ? (
              <>
                <ReactCrop
                  src={image || url}
                  crop={crop}
                  onChange={c => setCrop(c)}
                  onDragStart={() => {
                    isDragging.current = true;
                  }}
                  onDragEnd={() => {
                    window.setTimeout(() => {
                      isDragging.current = false;
                    }, 0);
                  }}
                />
                <p>Drag on the image to apply a crop</p>
              </>
            ) : (
              <img src={image || url} alt='user input' style={{ aspectRatio: previewAspectRatio ? `${previewAspectRatio}` : 'unset' }} />
            ))}
          {(hasImage || isAbleToClear) && (
            <div className='buttons'>
              {hasImage ? (
                <button className='clear-button' disabled={isCropping} onClick={clearInputs}>
                  {isCropping ? <Loader size={40} /> : 'Clear'}
                </button>
              ) : null}
              <button className='save-button' disabled={isCropping} onClick={saveAndExit}>
                {isCropping ? <Loader size={40} /> : 'Save'}
              </button>
            </div>
          )}
        </DropUploader>
      </div>
    </div>
  );
};

const ImageUploader = forwardRef(ImageUploaderComponent);
ImageUploader.propTypes = {
  initialImageUrl: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
  onSaveCallback: PropTypes.func.isRequired,
  previewAspectRatio: PropTypes.number,
  allowCrop: PropTypes.bool
};
export default ImageUploader;
