import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import _ from 'lodash';
import cn from 'classnames';
import './UserManagersPanel.scss';

import ConfirmPrompt from '../../../General/ConfirmPrompt';

import { sendUserManagerInvite } from '../../../../APIClient/managers';
import { updateUserManager, deleteUserManager } from '../../../../Actions/ManagerActions';
import { syncCurrentUser } from '../../../../Actions/UserActions';

import {
  userManagerPermissions,
  userManagerPayoutConfigs,
  getPermissionsForSpecificUserManager,
  getPayoutConfigsForSpecificUserManager,
  isCurrentlyManaging
} from '../../../../Helpers/manager_helpers';
import { getUserTierDisplay } from '../../../../Helpers/tier_helpers';
import { getManagers, isManager, getUserId, isBanned } from '../../../../Helpers/user_helpers';
import { isValidEmail } from '../../../../Helpers/formatting';

const UserManagersPanel = props => {
  const { user, manager } = props;
  const managers = getManagers(user);

  const addManager = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          header='Configure Their Permissions'
          subheader='Please enter the email address for your manager. If they already have an account, we will match you to them. If they do not have an account we will send them an email to get them signed up as your manager.'
          placeholder='Add your message here.'
          onCancel={onClose}
          customInputFields={[
            {
              placeholder: 'Email Address',
              value: 'email',
              isSingleLine: true
              // preloaded: `hrein+${Math.floor(Math.random() * 100000)}@alum.mit.edu`
            },
            ...userManagerPermissions
              .filter(m => !m.isRestrictionOnUser)
              .map(permission => ({
                isBoolean: true,
                label: permission.display,
                value: permission.variable,
                preloaded: permission.default
              })),
            ...userManagerPayoutConfigs.map(config => ({
              display: config.display,
              value: config.variable,
              isSingleLine: true,
              placeholder: config.placeholder
            }))
          ]}
          onSubmit={async responseValues => {
            const email = responseValues.email;
            const permissions = {};
            userManagerPermissions.forEach(p => {
              if (responseValues[p.variable] !== undefined) permissions[p.variable] = responseValues[p.variable];
            });
            userManagerPayoutConfigs.forEach(c => {
              if (responseValues[c.variable] !== undefined) permissions[c.variable] = +responseValues[c.variable];
            });

            if (!isValidEmail(email)) return window.ALERT.warn(`Please enter a valid email address.`);

            window.ALERT.info(`Sending invitation...`, { hideAfter: 0.75 });
            try {
              const resp = await sendUserManagerInvite({ email, permissions, User_id: getUserId(user) });
              await props.syncCurrentUser(); // In case we matched to an existing user
              window.ALERT.success(resp.message, { hideAfter: 8 });
            } catch (error) {
              window.ALERT.error(typeof error === 'string' ? error : 'There was an error sending this invitation, please try again.');
            }
          }}
        />
      )
    });
  };

  let title, subtitle;
  if (isManager(user)) {
    title = 'Accounts You Manage';
    subtitle = 'You can manage any of the following accounts by toggling the profile using the profile photo in the navigation bar.';
  } else {
    title = 'Your Shop Managers';
    subtitle = 'The following accounts can manage your account based on the level of control you configure for them.';
  }

  return (
    <div className='user-managers-panel-outer'>
      <div className='user-managers-panel-inner'>
        <div className='settings-section'>
          <div className='settings-section-title'>{title}</div>
          <div className='settings-section-subtitle'>{subtitle}</div>

          {!!managers.length && (
            <div className='rows'>
              {managers.map(userManager => (
                <UserManagerRow key={userManager.id} {...props} userManager={userManager} />
              ))}
            </div>
          )}

          {!isManager(user) && !isCurrentlyManaging(manager) && (
            <button onClick={addManager} className='settings-button restricted' disabled={isBanned(user)}>
              <FontAwesomeIcon icon={faPlus} />
              Add Manager
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const UserManagerRow = props => {
  const { userManager, manager } = props;
  const [isEditing, setIsEditing] = useState(false);
  const youAreTheManager = getUserId(props.user) === userManager.Manager_id;
  const userObject = youAreTheManager ? userManager.user : userManager.manager;
  const allManagerPermissions = getPermissionsForSpecificUserManager(userManager);
  const allPayoutConfigs = getPayoutConfigsForSpecificUserManager(userManager);
  const managerPermissions = allManagerPermissions.filter(p => (window.__ADMIN_CONTROL_MODE__ ? true : !p.isRestrictionOnUser));
  const activePermissions = managerPermissions.filter(p => p.selected);
  const youAreBeingManaged = !youAreTheManager;
  const canEdit = !isCurrentlyManaging(manager);

  const metadata = [];
  if (!userObject) return null;

  if (userObject.managerCompany) metadata.push(userObject.managerCompany);
  if (userObject.managerPosition) metadata.push(userObject.managerPosition);
  if (userObject.email) metadata.push(userObject.email);
  if (userObject.tier) metadata.push(getUserTierDisplay(userObject.tier.tier));
  if (userObject.tier) metadata.push(Math.floor(userObject.tier.score).toFixed(0));

  // Actions Setup
  const permissions = managerPermissions.map(data => {
    const { selected, display } = data;
    return {
      display,
      selected,
      toggle: () => props.updateUserManager(userManager, { [data.variable]: !selected })
    };
  });

  // UI Setup
  let statusDisplays = [];
  userManagerPayoutConfigs.forEach(config => {
    const value = userManager[config.variable];
    if (value) statusDisplays.push(config.formatValueForDisplay(value));
  });
  activePermissions.forEach(p => statusDisplays.push(p.display));

  const toggleEdit = () => (youAreTheManager || !canEdit ? null : setIsEditing(!isEditing));
  const remove = () => {
    confirmAlert({
      title: 'Just Confirming',
      message: youAreTheManager
        ? `Are you sure you want to stop managing ${userObject.name}?`
        : `Are you sure you want to remove ${userObject.name} as a manager?`,
      buttons: [
        { label: 'No', className: 'cancel', onClick: () => {} },
        { label: 'Yes', onClick: () => props.deleteUserManager(userManager) }
      ]
    });
  };

  return (
    <div className={cn('row', { editing: isEditing })}>
      <div className='manager-data settings-section-split-input'>
        <div className='main meta'>
          <div className='name'>{userObject.name}</div>
          <div onClick={toggleEdit} className='display'>
            {statusDisplays.length} permission{statusDisplays.length > 1 ? 's' : ''}
          </div>
        </div>
        {canEdit && (
          <div className='actions'>
            {isEditing ? (
              <button onClick={toggleEdit} className='settings-button'>
                Done
              </button>
            ) : (
              <>
                {youAreBeingManaged ? (
                  <>
                    <button onClick={toggleEdit} className='settings-button fit'>
                      Edit
                    </button>
                    <button onClick={remove} className='settings-button dark fit'>
                      Remove
                    </button>
                  </>
                ) : (
                  <>
                    <button onClick={remove} className='settings-button fit'>
                      Stop Managing
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
      {isEditing && (
        <div className='sections'>
          <div className='section'>
            <div className='section-label'>Permissions</div>
            <div className='permissions'>
              {_.chunk(permissions, 3).map((permissions, idx) => {
                return (
                  <div key={idx} className='permissions-block'>
                    {permissions.map((vals, idx) => {
                      const { display, selected, toggle } = vals;
                      return (
                        <div className={cn('permission', { selected })} onClick={toggle} key={idx}>
                          <div className='checkbox'>
                            <FontAwesomeIcon icon={faCheck} />
                          </div>
                          {display}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
          <div className='section'>
            <div className='section-label'>Payout Configurations</div>
            <div className='payout-configs'>
              {allPayoutConfigs.map(config => (
                <UserManagerPanelPayoutConfig
                  key={config.display}
                  userManager={userManager}
                  updateUserManager={props.updateUserManager}
                  config={config}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const UserManagerPanelPayoutConfig = props => {
  const { userManager, updateUserManager, config } = props;
  const { display, variable, placeholder, formatValueForServer, formatValueForInput } = config;

  const saveDebounce = React.useRef(null);
  const [value, setValue] = useState(config.value || '');
  const updateValue = newValue => {
    setValue(newValue);
    const newPermissions = { [variable]: formatValueForServer(newValue || null) };
    clearTimeout(saveDebounce.current);
    saveDebounce.current = setTimeout(() => updateUserManager(userManager, newPermissions), 500);
  };
  return (
    <div key={variable} className='payout-config'>
      <div className='label'>{display}</div>
      <input
        type='text'
        placeholder={placeholder}
        value={formatValueForInput ? formatValueForInput(value) : value}
        onChange={e => updateValue(e.target.value)}
      />
    </div>
  );
};

UserManagersPanel.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  manager: PropTypes.object.isRequired,
  updateUserManager: PropTypes.func.isRequired,
  deleteUserManager: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, ui, manager } = state;
  return { user, ui, manager };
};

export default connect(mapStateToProps, {
  updateUserManager,
  deleteUserManager,
  syncCurrentUser
})(UserManagersPanel);
