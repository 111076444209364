import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import commaNumber from 'comma-number';
import cn from 'classnames';
import './OpportunityPlanTalent.scss';

import { getPrettyNumber } from '../../../Helpers/formatting';
import { getOpportunityRequestsForOpportunity } from '../../../Helpers/brand_helpers';
import { isManualPlan } from '../../../Helpers/planning_helpers';

import Select from '../../General/Select';
import Loader from '../../Loader/Loader';
import Tooltip from '../../General/Tooltip';
import OpportunityPlanUser from './OpportunityPlanUser';
import SearchContentModal from '../../Modals/SearchContentModal';

const OpportunityPlanTalent = props => {
  const { user, analytics, activePlan, opportunity } = props;
  const { linksExpected, linkingDaysExpected, mentionsExpected } = activePlan;
  const users = activePlan.users || [];
  const requests = getOpportunityRequestsForOpportunity(analytics, opportunity);
  const isManuallyCreated = isManualPlan(activePlan);
  const hasUsers = users.length > 0;

  const analyzeOnLinks = !!linksExpected || !!linkingDaysExpected;
  const analyzeOnViews = !analyzeOnLinks;

  const defaultSort = isManuallyCreated ? 'fixedFee' : analyzeOnLinks ? 'Projected ROI' : 'CPM';
  const defaultSortDirection = isManuallyCreated ? 'desc' : analyzeOnLinks ? 'desc' : 'asc';
  const [sortLabel, setSortLabel] = React.useState(defaultSort);
  const [sortDirection, setSortDirection] = React.useState(defaultSortDirection);
  const changeSort = newSortLabel => {
    const isActive = newSortLabel === sortLabel;
    if (isActive) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortLabel(newSortLabel);
      const sortData = dataFields.find(field => field.label === newSortLabel).sortData;
      setSortDirection(sortData?.defaultDirection || 'asc');
    }
  };
  React.useEffect(() => {
    setSortLabel(defaultSort);
    setSortDirection(defaultSortDirection);
  }, [isManuallyCreated]);

  /*
    We want to allow admins to force certain talent into the plan.
  */
  const [isSearchingForTalent, setIsSearchingForTalent] = React.useState(false);
  const [lockedTalent, setLockedTalent] = React.useState([]);
  const [blockedTalent, setBlockedTalent] = React.useState([]);
  const [isSearchingForLockedTalent, setIsSearchingForLockedTalent] = React.useState(false); // Locked or blocked
  const hasForceTalentOverrides = lockedTalent.length > 0 || blockedTalent.length > 0;
  const toggleSearchingForLockedTalent = () => {
    setIsSearchingForLockedTalent(true);
    toggleSearchingForTalent();
  };
  const toggleSearchingForBlockedTalent = () => {
    setIsSearchingForLockedTalent(false);
    toggleSearchingForTalent();
  };
  const toggleSearchingForTalent = () => {
    setIsSearchingForTalent(!isSearchingForTalent);
  };
  const lockCreatorToPlan = user => {
    const isCreatorAlreadyInPlan = lockedTalent.find(u => u.id === user.id);
    setLockedTalent(isCreatorAlreadyInPlan ? lockedTalent.filter(u => u.id !== user.id) : [...lockedTalent, user]);
  };
  const blockCreatorFromPlan = user => {
    const isCreatorAlreadyBlocked = blockedTalent.find(u => u.id === user.id);
    setBlockedTalent(isCreatorAlreadyBlocked ? blockedTalent.filter(u => u.id !== user.id) : [...blockedTalent, user]);
  };
  const planUserIdsHash = users.map(u => u.User_id).join(',');
  const rerunPlanWithForceTalentOverrides = () =>
    props.openNewPlanModal({ LockedUser_ids: lockedTalent.map(u => u.id), BlockedUser_ids: blockedTalent.map(u => u.id) });

  // Make sure when we rerun, we remove them from locked if they were added
  React.useEffect(() => {
    const usersInPlan = users.map(u => u.User_id);
    const forceAddedWithoutPlan = lockedTalent.filter(u => !usersInPlan.includes(u.id));
    setLockedTalent(forceAddedWithoutPlan);
  }, [planUserIdsHash]);

  const getTrustLabelFromConversionFactor = conversion => {
    if (conversion > 2) return 'Elite';
    if (conversion > 1.5) return 'Very High';
    if (conversion > 1) return 'High';
    if (conversion > 0.5) return 'Medium';
    if (conversion > 0.25) return 'Low';
    return 'Very Low';
  };

  const dataFields = [
    {
      label: isManuallyCreated ? 'Fixed Fee' : 'Suggested Fee',
      sortData: {
        getValue: planUser => planUser.recommendedFixedFee,
        defaultDirection: 'desc'
      },
      canEditConfig: {
        variable: 'recommendedFixedFee'
      },
      getValue: planUser => planUser.recommendedFixedFee,
      getDisplayValue: planUser => `$${commaNumber(planUser.recommendedFixedFee)}`
    },
    // {
    //   label: 'Creator Tier',
    //   sortData: {
    //     getValue: planUser => planUser.user.tier?.score || 0,
    //     defaultDirection: 'desc'
    //   },
    //   getValue: planUser => planUser.user.tier?.score || 0,
    //   getDisplayValue: planUser => getUserTierDisplay(planUser.user.tier?.tier) || 'Unknown'
    // },
    {
      label: 'Conversion',
      isHidden: !analyzeOnLinks || isManuallyCreated,
      sortData: {
        getValue: planUser => planUser.conversionFactor,
        defaultDirection: 'desc'
      },
      getValue: planUser => planUser.conversionFactor,
      getDisplayValue: planUser => getTrustLabelFromConversionFactor(planUser.conversionFactor)
    },
    {
      label: 'CPC',
      isHidden: !analyzeOnLinks || isManuallyCreated,
      sortData: {
        sortLabel: 'Cost Per Click',
        getValue: planUser => planUser.costPerClick,
        defaultDirection: 'asc'
      },
      getValue: planUser => planUser.costPerClick,
      getDisplayValue: planUser => `$${planUser.costPerClick.toFixed(2)}`
    },
    {
      label: 'Projected ROI',
      isHidden: !analyzeOnLinks || isManuallyCreated,
      sortData: {
        getValue: planUser => planUser.expectedROIVolume,
        defaultDirection: 'desc'
      },
      getValue: planUser => planUser.expectedROIVolume,
      // getDisplayValue: planUser => getTrustLabelFromConversionFactor(planUser.conversionFactor)
      getDisplayValue: planUser => planUser.expectedROIVolume.toFixed(1) + 'x'
    },
    {
      label: 'Expected Views',
      isHidden: !analyzeOnViews || isManuallyCreated,
      sortData: {
        getValue: planUser => planUser.expectedViews,
        defaultDirection: 'desc'
      },
      getValue: planUser => planUser.expectedViews,
      getDisplayValue: planUser => getPrettyNumber(planUser.expectedViews)
    },
    {
      label: 'CPM',
      isHidden: !analyzeOnViews || isManuallyCreated,
      sortData: {
        sortLabel: 'Cost Per 1000 Views',
        getValue: planUser => planUser.costPer1000Views,
        defaultDirection: 'asc'
      },
      getValue: planUser => planUser.costPer1000Views,
      getDisplayValue: planUser => `$${planUser.costPer1000Views.toFixed(2)}`
    }
  ]
    .filter(field => !field.isHidden)
    .map(field => ({
      ...field,
      isFeatured: field.label === sortLabel
    }));

  const sortOptions = [
    {
      value: 'Creator Name',
      label: 'Creator Name',
      sortData: {
        getValue: planUser => planUser.user.name?.toLowerCase(),
        defaultDirection: 'asc'
      }
    },
    ...dataFields.map(field => ({
      value: field.label,
      label: field.sortData?.sortLabel || field.label
    }))
  ].map(option => ({
    ...option,
    isSelected: option.value === sortLabel
  }));
  const currentSortData = dataFields.find(field => field.label === sortLabel)?.sortData || sortOptions.find(o => o.value === sortLabel)?.sortData;

  // Get Final Sorting

  /*
    Add aggregated values to the user objects to make sorting easier
  */
  const augmentedUsers = users.map(user => {
    const expectedVolume = linksExpected * user.vol_per_link;
    const expectedClicks = linksExpected * user.clicks_per_link;
    const expectedViews = mentionsExpected * user.views_per_mention;
    const costPerClick = expectedClicks ? user.recommendedFixedFee / expectedClicks : 0;
    const costPer1000Views = expectedViews ? (1000 * user.recommendedFixedFee) / expectedViews : 0;
    const conversionFactor = expectedVolume / expectedClicks;

    const expectedROIVolume = (linksExpected * user.vol_per_link) / user.recommendedFixedFee;

    return {
      ...user,
      expectedClicks,
      expectedViews,
      costPerClick,
      costPer1000Views,
      expectedROIVolume,
      conversionFactor
    };
  });

  const sortedAugmentedUsers = _.orderBy(
    augmentedUsers.filter(u => !u.isBackup),
    user => currentSortData?.getValue(user) || (() => 0),
    sortDirection
  );
  const sortedBackupUsers = _.orderBy(
    augmentedUsers.filter(u => u.isBackup),
    user => currentSortData?.getValue(user) || (() => 0),
    sortDirection
  );

  // Make sure they can run the plan
  let cannotExecutePlan = false;
  let cannotExecutePlanMessage = '';

  const hasSentRequests = requests.length > 0;
  if (hasSentRequests) {
    const expectedFromPlan = {
      linksExpected: activePlan.linksExpected || 0,
      mentionsExpected: activePlan.mentionsExpected || 0,
      linkingDaysExpected: activePlan.linkingDaysExpected || 0,
      mentionDaysExpected: activePlan.mentionDaysExpected || 0
    };
    const expectedFromOpportunity = {
      linksExpected: opportunity.linksExpected || 0,
      mentionsExpected: opportunity.mentionsExpected || 0,
      linkingDaysExpected: opportunity.linkingDaysExpected || 0,
      mentionDaysExpected: opportunity.mentionDaysExpected || 0
    };
    const differsFromOpportunity = !_.isEqual(expectedFromPlan, expectedFromOpportunity);

    if (differsFromOpportunity) {
      cannotExecutePlan = true;
      cannotExecutePlanMessage = 'You have already sent requests for this opportunity with different expectations than this plan.';
    }
  }

  const validPlanUsers = users.filter(u => !requests.find(r => r.User_id === u.User_id) && !u.isBackup);
  const numOffers = validPlanUsers.length;

  if (!users.length) {
    cannotExecutePlan = true;
    cannotExecutePlanMessage = 'You need to add creators to this plan before you can execute it.';
  }

  if (!numOffers && users.length) {
    cannotExecutePlan = true;
    cannotExecutePlanMessage = 'You need to add new creators to this plan before you can execute it.';
  }

  return (
    <div className='opportunity-plan-talent-and-backups'>
      {isSearchingForTalent && (
        <SearchContentModal
          user={user}
          closeModal={toggleSearchingForTalent}
          type='users'
          selectUser={isSearchingForLockedTalent ? lockCreatorToPlan : blockCreatorFromPlan}
          allowMultiSelect
          alreadySelectedIds={isSearchingForLockedTalent ? lockedTalent.map(u => u.id) : blockedTalent.map(u => u.id)}
        />
      )}
      <div className='opportunity-plan-talent'>
        {!!users.length && (
          <div className='results-controls'>
            <div></div>
            <div className='sort-container'>
              <div className='sort-label'>Sort By:</div>
              <Select noStyling options={sortOptions} value={sortLabel} onChangeValue={value => changeSort(value)} />
            </div>
          </div>
        )}
        <div className='talent-results'>
          {sortedAugmentedUsers.map(planUser => {
            return (
              <OpportunityPlanUser
                key={planUser.id}
                user={user}
                analytics={analytics}
                opportunity={opportunity}
                openRequestModal={props.openRequestModal}
                updateOpportunityPlanUser={props.updateOpportunityPlanUser}
                deleteOpportunityPlanUser={props.deleteOpportunityPlanUser}
                createOpportunityPaymentTier={props.createOpportunityPaymentTier}
                deleteOpportunityPaymentTier={props.deleteOpportunityPaymentTier}
                planUser={planUser}
                dataFields={dataFields}
                openArtistModal={props.openArtistModal}
              />
            );
          })}
          {hasForceTalentOverrides && (
            <div className='force-added'>
              <div className='force-added-title-container'>
                <div className='force-added-title'>Locked or Blocked Creators</div>
                <div className='force-added-subtitle'>These overrides are so you can either lock or block creators from the final plan.</div>
              </div>
              <div className='force-added-creators'>
                {lockedTalent.map(user => (
                  <div key={user.id} className='force-added-creator'>
                    <div className='force-added-creator-data'>
                      <div className='name'>Locked: {user.name}</div>
                      <div className='username'>@{user.username}</div>
                    </div>
                    <div onClick={() => lockCreatorToPlan(user)} className='force-added-creator-remove'>
                      Remove
                    </div>
                  </div>
                ))}
                {blockedTalent.map(user => (
                  <div key={user.id} className='force-added-creator'>
                    <div className='force-added-creator-data'>
                      <div className='name'>Blocked: {user.name}</div>
                      <div className='username'>@{user.username}</div>
                    </div>
                    <div onClick={() => blockCreatorFromPlan(user)} className='force-added-creator-remove'>
                      Remove
                    </div>
                  </div>
                ))}
              </div>
              <div className='force-added-actions'>
                <div onClick={rerunPlanWithForceTalentOverrides} className='action primary'>
                  Rerun Plan with Overrides
                </div>
              </div>
            </div>
          )}
        </div>

        <div className='actions'>
          {window.__ADMIN_CONTROL_MODE__ && !isManuallyCreated && (
            <>
              <div onClick={toggleSearchingForLockedTalent} className='action secondary'>
                Add {lockedTalent.length ? 'Another' : 'Locked Creator'}
              </div>
              <div onClick={toggleSearchingForBlockedTalent} className='action secondary'>
                Block Creator
              </div>
            </>
          )}
          <div onClick={props.removeActivePlan} className='action secondary'>
            Remove Plan
          </div>
          {isManuallyCreated && (
            <div
              onClick={props.openPlanner}
              className={cn('action', {
                secondary: hasUsers,
                primary: !hasUsers
              })}
            >
              Add Creator
            </div>
          )}
          {!hasUsers ? null : cannotExecutePlan ? (
            <Tooltip message={cannotExecutePlanMessage}>
              <div className='action primary disabled'>{numOffers ? `Send ${numOffers} Offer${numOffers === 1 ? '' : 's'}` : 'Send Offers'}</div>
            </Tooltip>
          ) : (
            <div onClick={props.executeOnActivePlan} className={cn('action primary', { running: props.isExecutingOnActivePlan })}>
              Send {numOffers} Offer{numOffers === 1 ? '' : 's'}
              {props.isExecutingOnActivePlan && <Loader size={40} />}
            </div>
          )}
        </div>
      </div>
      {!!sortedBackupUsers.length && (
        <div className='opportunity-plan-backups'>
          <div className='backup-title-container'>
            <div className='backup-title'>Backup Talent</div>
            <div className='backup-subtitle'>
              These creators are available to replace any of those in the plan above or as a fallback in case creators do not accept the opportunity.
            </div>
          </div>
          <div className='backup-results'>
            {sortedBackupUsers.map(planUser => {
              return (
                <OpportunityPlanUser
                  key={planUser.id}
                  user={user}
                  analytics={analytics}
                  opportunity={opportunity}
                  openRequestModal={props.openRequestModal}
                  updateOpportunityPlanUser={props.updateOpportunityPlanUser}
                  createOpportunityPaymentTier={props.createOpportunityPaymentTier}
                  deleteOpportunityPaymentTier={props.deleteOpportunityPaymentTier}
                  planUser={planUser}
                  dataFields={dataFields}
                  openArtistModal={props.openArtistModal}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

OpportunityPlanTalent.propTypes = {
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  opportunity: PropTypes.object.isRequired,
  activePlan: PropTypes.object.isRequired,
  openRequestModal: PropTypes.func.isRequired,
  removeActivePlan: PropTypes.func.isRequired,
  executeOnActivePlan: PropTypes.func.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  openNewPlanModal: PropTypes.func.isRequired,
  openPlanner: PropTypes.func.isRequired,
  createOpportunityPaymentTier: PropTypes.func.isRequired,
  deleteOpportunityPaymentTier: PropTypes.func.isRequired,
  updateOpportunityPlanUser: PropTypes.func.isRequired,
  deleteOpportunityPlanUser: PropTypes.func.isRequired
};

export default OpportunityPlanTalent;
