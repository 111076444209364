import React from 'react';
import PropTypes from 'prop-types';
import commaNumber from 'comma-number';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPencilAlt } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import './OpportunityPlannerAllTalent.scss';

import Loader from '../../Loader/Loader';
import CostReasoningOverlay from '../Elements/CostReasoningOverlay';
import CustomTooltip from '../../General/CustomTooltip';

import { getPlanUserFromUser } from '../../../Helpers/planning_helpers';
import { getUserTierDisplay } from '../../../Helpers/tier_helpers';

const OpportunityPlannerAllTalent = props => {
  const { results, activePlan } = props;

  // Modifying UI Elements
  const [modifyingUserIds, setModifyingUserIds] = React.useState([]);
  const startModifyingUser = user => setModifyingUserIds([...modifyingUserIds, user.id]);
  const stopModifyingUser = user => setModifyingUserIds(modifyingUserIds.filter(id => id !== user.id));
  const isUserBeingModified = user => modifyingUserIds.includes(user.id);

  return (
    <div className='opportunity-planner-all-talent'>
      <div
        className={cn('talent-results', {
          'fetching-first-page': props.isFetchingFirstPage
        })}
      >
        {results.map((user, idx) => {
          const { name, image, tier, recommended_cost, objectID } = user;
          const isBeingModified = isUserBeingModified(user);
          const planUser = getPlanUserFromUser(activePlan, user);
          const isInPlan = !!planUser;
          const cost = planUser ? planUser.recommendedFixedFee : recommended_cost;

          const add = async () => {
            startModifyingUser(user);
            await props.addCreatorToPlan(user);
            stopModifyingUser(user);
          };
          const remove = async () => {
            await props.removeCreatorFromPlan(planUser);
          };
          const openProfile = () => props.openArtistModal(user);

          const actionClasses = {
            remove: isInPlan,
            add: !isInPlan,
            loading: isBeingModified
          };
          const additionalClasses = { active: isInPlan };
          return (
            <div key={objectID + idx} className={cn('talent-result', additionalClasses)}>
              <div className='image-container'>{image ? <img src={image} alt={name} /> : <div className='image empty'>S</div>}</div>
              <div className='data-container'>
                <div className='user-data'>
                  <div onClick={openProfile} className='name'>
                    {name}
                  </div>
                  <div className='tier'>{getUserTierDisplay(tier)}</div>
                </div>
                <div className='cost-and-actions'>
                  <div onClick={() => props.specifyCostForUser(user)} className='cost-container'>
                    {window.__ADMIN_CONTROL_MODE__ && user.recommended_cost_reasoning ? (
                      <CustomTooltip tooltipEl={<CostReasoningOverlay reasoning={user.recommended_cost_reasoning} delayBeforeOpening={100} />}>
                        <div className='cost'>${commaNumber(cost)}</div>
                      </CustomTooltip>
                    ) : (
                      <div className='cost'>${commaNumber(cost)}</div>
                    )}
                    <div className='label'>
                      <div className={cn('edit-icon', additionalClasses)}>
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </div>
                      {isInPlan ? 'Fixed Fee' : 'Recommended Cost'}
                    </div>
                  </div>
                  <div className={cn('action', actionClasses)} onClick={isInPlan ? remove : add}>
                    {isInPlan && <FontAwesomeIcon icon={faCheck} />}
                    {isInPlan ? 'Added' : 'Add'}
                    {isBeingModified && <Loader size={48} />}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

OpportunityPlannerAllTalent.propTypes = {
  // Data
  results: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  opportunity: PropTypes.object.isRequired,
  activePlan: PropTypes.object,

  // UI
  isFetchingFirstPage: PropTypes.bool.isRequired,
  isFetchingNextPage: PropTypes.bool.isRequired,

  // Actions
  addCreatorToPlan: PropTypes.func.isRequired,
  removeCreatorFromPlan: PropTypes.func.isRequired,
  updateCreatorInPlan: PropTypes.func.isRequired
};

export default OpportunityPlannerAllTalent;
