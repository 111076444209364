import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import cn from 'classnames';
// import _ from 'lodash'

import './Tooltip.scss';

class BrandedTooltip extends Component {
  static propTypes = {
    message: PropTypes.string,

    // One of the two are required
    children: PropTypes.node,
    getIconDiv: PropTypes.func,

    // Optional props
    interactive: PropTypes.bool, // Allows you to interact with the tooltip for copying text, etc.
    position: PropTypes.string, // 'top', 'bottom', 'left', 'right'

    // Optional Styling
    outerClassName: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.string // 'small', 'regular', 'big'
  };

  render() {
    const { getIconDiv, message, size, position, className, outerClassName, interactive, children } = this.props;
    return (
      <div className={outerClassName}>
        <Tooltip
          className={cn('tooltip-outer-container', className || '')}
          size={size || 'small'}
          title={message}
          disabled={!message}
          position={position || 'top'}
          animation='fade'
          interactive={interactive}
          arrow
        >
          {children ? children : getIconDiv()}
        </Tooltip>
      </div>
    );
  }
}

export default BrandedTooltip;
