import _ from 'lodash';
import algoliasearch from 'algoliasearch';

const client = algoliasearch('BLB7XGVAAJ', '69dbc6317a3a5f2a8106abf1b73de68a');
export const productIndex = client.initIndex(`sms_products_${process.env.NODE_ENV === 'production' ? 'production' : 'staging'}`);

/** @param {('sms_products_production'|'sms_products_staging'|'production_autocomplete'|'sms_users_production'|'brands_production')} type */
export const fetchAlgoliaIndex = type => {
  const index = client.initIndex(type);
  return index;
};

export const customSearchResultsRanking = results => {
  /*
    Currently not used, this function allows for a final override
    filter on the client to adjust the final 25 algolia results.
  */
  return results;
};

export const cleanAutoCompleteResults = (results, searchVal) => {
  const isMatch = (a, b) => a.toLowerCase().trim() === b.toLowerCase().trim();
  const newResults = results.filter(a => !isMatch(a.query, searchVal));
  if (results.length === 1 && isMatch(searchVal, _.get(results, '0.query'))) return [];

  const searchNotInResults = !newResults.find(a => isMatch(a.query, searchVal));
  if (newResults.length && searchNotInResults) {
    return [...newResults.slice(0, 1), { query: searchVal }, ...newResults.slice(1)];
  }

  return results;
};

export const matchesSearchTerm = (term, searchTerm, options = {}) => {
  if (typeof term !== 'string' || typeof searchTerm !== 'string') return false;
  const { directMatch, perfectMatch } = options;

  if (perfectMatch) {
    return term.includes(searchTerm);
  }

  if (directMatch) {
    return term.toLowerCase().includes(searchTerm.toLowerCase());
  }

  let match = true;
  const parts = searchTerm.split(' ');
  parts.forEach(part => {
    match = match && term.toLowerCase().includes(part.toLowerCase());
  });

  return match;
};
