import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

/*
 * Custom hook for allowing an external hover on a div to open another div
 * on hover of the first div and only closing on hover-away.
 */
const useShowPanelOnTriggerHover = (props, callback) => {
  /*
    Example:
      const [visible, setVisible] = React.useState(false);
      const panelDebouce = React.useRef(null);
      const triggerRef = React.useRef(null);
      const DELAY_BEFORE_OPENING = 200; // Ensure the user doesn't accidentally open the panel by dragging by it

      <div ref={triggerRef}
        onClick={() => !visible && setVisible(true)}
        onMouseMove={() => (panelDebouce.current = panelDebouce.current || setTimeout(() => setVisible(true), DELAY_BEFORE_OPENING))}
        onMouseOut={() => {
          clearTimeout(panelDebouce.current);
          panelDebouce.current = null;
        }}
      >
        Trigger Element
      </div>
      <OverlapPanel
        triggerRef={triggerRef}
        visible={visible}
        closePanel={() => setVisible(false)}
      />

      const OverlapPanel = props => {
        const panelRef = React.createRef();
        useShowPanelOnTriggerHover({ triggerRef, panelRef, closePanel, visible });
        return <div><div>panel here</div></div>
    }
  */
  const { panelRef, triggerRef, closePanel, visible, debounceDuration } = props;

  const dismissDebounce = React.useRef(null);
  React.useEffect(() => {
    const checkToDismiss = e => {
      const isScrollWithinPanel = panelRef?.current?.contains(e.target) || triggerRef?.current?.contains(e.target);

      if (isScrollWithinPanel) {
        clearTimeout(dismissDebounce.current);
        dismissDebounce.current = null;
      } else {
        dismissDebounce.current =
          dismissDebounce.current ||
          setTimeout(
            () => {
              closePanel();
              dismissDebounce.current = null;
            },
            _.isNil(debounceDuration) ? 250 : debounceDuration
          ); // Delay to allow for quick mouse movements outside
      }
    };
    visible && window.addEventListener('mousemove', checkToDismiss);
    return () => window.removeEventListener('mousemove', checkToDismiss);
  }, [visible]);
};

useShowPanelOnTriggerHover.propTypes = {
  panelRef: PropTypes.object.isRequired,
  triggerRef: PropTypes.object.isRequired,
  closePanel: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  logDebug: PropTypes.func,

  // Optional
  debounceDuration: PropTypes.number // Optional debounce duration for hover away timing
};

export default useShowPanelOnTriggerHover;
