import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './StripeConfigurationPanel.scss';

import { updateBrandSettings } from '../../../../Actions/BrandActions';
import { getBrandSettings } from '../../../../Helpers/user_helpers';
import { confirmAlert } from 'react-confirm-alert';

import CheckboxButton from '../../../General/Buttons/CheckboxButton';
import Loader from '../../../Loader/Loader';

const StripeConfigurationPanel = props => {
  const { user } = props;
  const brandSettings = getBrandSettings(user);
  const { mustInvoiceManually, canAutoCharge } = brandSettings;

  const [isSaving, setIsSaving] = useState(false);
  const [allowedBudgetOverage, setAllowedBudgetOverage] = useState(brandSettings.allowedBudgetOverage);
  const canUpdateBudgetOverage =
    window.__ADMIN_CONTROL_MODE__ && allowedBudgetOverage !== '' && allowedBudgetOverage !== brandSettings.allowedBudgetOverage;

  const turnOnAutoCharge = () => {
    if (window.__ADMIN_CONTROL_MODE__) props.updateBrandSettings({ canAutoCharge: true });
    else {
      return confirmAlert({
        title: 'Just confirming',
        message:
          'By enabling this feature, your monthly invoice will be automatically paid by your default payment method without your review. Only your account manager will be able to turn it back off once enabled. Are you sure you want to enable this?',
        buttons: [
          { label: 'No', onClick: () => {}, className: 'cancel' },
          {
            label: 'Yes',
            onClick: () => {
              props.updateBrandSettings({ canAutoCharge: true });
            }
          }
        ]
      });
    }
  };

  const turnOffAutoCharge = () => {
    if (window.__ADMIN_CONTROL_MODE__) props.updateBrandSettings({ canAutoCharge: false });
    else
      return confirmAlert({
        title: 'Confirm With Your Manager',
        message: `Before turning this feature off, please first confirm with your brand success manager. By disabling this, you will have to manually review and pay your invoice each month.`,
        buttons: [{ label: 'Ok', onClick: () => {}, className: 'cancel' }]
      });
  };

  const updateAllowedBudgetOverage = async () => {
    if (!window.__ADMIN_CONTROL_MODE__) return;
    setIsSaving(true);
    const response = await props.updateBrandSettings({ allowedBudgetOverage });
    setIsSaving(false);

    if (response?.error) window.ALERT.error(response.error);
    else window.ALERT.success(`Updated allowed budget overage to $${allowedBudgetOverage}`);
  };

  return (
    <div className='stripe-configuration-panel-outer'>
      <div className='stripe-configuration-panel-inner'>
        <div className='settings-section'>
          <div className='settings-section-title'>Configure Invoicing</div>

          <div className='settings-section-list'>
            {window.__ADMIN_CONTROL_MODE__ && (
              <CheckboxButton
                text='Invoice Manually'
                description='Instead of being automatically sent an invoice, you will have to manually pay in some other method.'
                isChecked={!!mustInvoiceManually}
                onChange={() => props.updateBrandSettings({ mustInvoiceManually: !mustInvoiceManually })}
              />
            )}

            <CheckboxButton
              text='Allow Auto Charging'
              description='When enabled, instead of receiving an invoice that you must pay manually, we will automatically charge your card for the amount due.'
              isChecked={!!canAutoCharge}
              onChange={!!canAutoCharge ? turnOffAutoCharge : turnOnAutoCharge}
            />
          </div>

          <div className='settings-section-title'>Allowed Budget Overage</div>
          <div className='settings-section-subtitle'>
            The amount your spending may exceed to allow creators to accept & complete opportunities. Contact your brand success manager to configure.
          </div>

          <div className='advanced-split-input'>
            <p>$</p>
            <input
              className='settings-input'
              type='number'
              value={allowedBudgetOverage ?? 0}
              disabled={isSaving || !window.__ADMIN_CONTROL_MODE__}
              onChange={e => setAllowedBudgetOverage(e.target.value)}
            />
            {canUpdateBudgetOverage && (
              <button className='settings-button dark' disabled={isSaving} onClick={updateAllowedBudgetOverage}>
                {isSaving ? <Loader size={30} /> : 'Update'}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

StripeConfigurationPanel.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user, ui } = state;
  return { user, ui };
};

export default connect(mapStateToProps, {
  updateBrandSettings
})(StripeConfigurationPanel);
