import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift, faTag, faPercent, faChartLine } from '@fortawesome/pro-regular-svg-icons';
import {
  faGift as faGiftSolid,
  faTag as faTagSolid,
  faPercent as faPercentSolid,
  faChartLine as faChartLineSolid
} from '@fortawesome/pro-solid-svg-icons';
import './PartershipStatusActions.scss';

import { blockOnRequiringSubscription } from '../../Helpers/subscription_helpers';
import { getTalentById } from '../../Helpers/talent_helpers';
import { formatUsersForBulkRequests } from '../../Helpers/gifting_helpers';
import { setCustomRate } from '../../Helpers/rate_helpers';

import { openRequestModal, openCodesModal } from '../../Actions/UIActions';
import { syncTalentResult } from '../../Actions/TalentActions';
import { setCustomCommissionRate } from '../../Actions/AnalyticsActions';

import Tooltip from '../General/Tooltip';

const PartershipStatusActions = props => {
  const { user, talent, User_id } = props;

  const result = getTalentById(talent, User_id);
  const syncTalentResult = async () => props.syncTalentResult(result);

  if (!result) return null;

  const iconActions = [
    {
      action_enum: 'GIFTING',
      isActive: result.has_request,
      inactiveIcon: faGift,
      activeIcon: faGiftSolid,
      onClick: () => {
        if (blockOnRequiringSubscription(user, 'GIFTING')) return null;
        props.openRequestModal({
          params: {
            preselectedUsers: formatUsersForBulkRequests([result])
          },
          submitCallback: syncTalentResult
        });
      },
      tooltip: 'Send Gifting'
    },
    {
      action_enum: 'CODE',
      isActive: result.has_code,
      inactiveIcon: faTag,
      activeIcon: faTagSolid,
      onClick: () => {
        if (blockOnRequiringSubscription(user, 'CUSTOM_CODES')) return null;
        props.openCodesModal({
          params: {
            User_id: result.id,
            name: result.name,
            showTemplates: true
          },
          closeCallback: syncTalentResult
        });
      },
      tooltip: 'Set Custom Code'
    },
    {
      action_enum: 'RATE',
      isActive: result.has_rate,
      inactiveIcon: faPercent,
      activeIcon: faPercentSolid,
      onClick: () => {
        if (blockOnRequiringSubscription(user, 'CUSTOM_RATES', { directIntegrationRequired: true })) return null;
        setCustomRate({
          user,
          artist: result,
          analytics: props.analytics,
          setCustomCommissionRate: props.setCustomCommissionRate,
          closeCallback: syncTalentResult
        });
      },
      tooltip: 'Offer Custom Rate'
    },
    {
      action_enum: 'OPPORTUNITY',
      isActive: result.has_opportunity_request,
      inactiveIcon: faChartLine,
      activeIcon: faChartLineSolid,
      onClick: () => {
        if (blockOnRequiringSubscription(user, 'OPPORTUNITIES')) return null;
        props.openRequestModal({
          params: {
            type: 'opportunities',
            preselectedUsers: formatUsersForBulkRequests([result])
          },
          submitCallback: syncTalentResult
        });
      },
      tooltip: 'Send Opportunity'
    }
  ];

  return (
    <div className='partnership-status-actions'>
      {iconActions.map((action, idx) => (
        <Tooltip key={idx} message={action.tooltip}>
          <div
            onClick={action.onClick}
            className={cn('result-action-icon', {
              active: action.isActive
            })}
          >
            <FontAwesomeIcon icon={action.isActive ? action.activeIcon : action.inactiveIcon} />
          </div>
        </Tooltip>
      ))}
    </div>
  );
};

PartershipStatusActions.propTypes = {
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  talent: PropTypes.object.isRequired,
  syncTalentResult: PropTypes.func.isRequired,
  openRequestModal: PropTypes.func.isRequired,
  openCodesModal: PropTypes.func.isRequired,
  setCustomCommissionRate: PropTypes.func.isRequired,

  // From outside
  User_id: PropTypes.number.isRequired
};

const mapStateToProps = state => {
  const { user, talent, analytics } = state;
  return { user, talent, analytics };
};

export default connect(mapStateToProps, {
  syncTalentResult,
  openRequestModal,
  openCodesModal,
  setCustomCommissionRate
})(PartershipStatusActions);
