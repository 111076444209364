import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import cn from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import './ManagerRosterPanel.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faSearch, faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import Image from '../../../General/Image';
import Select from 'react-select';
import SelectOption from '../../../General/SelectOption';

import { deleteUserManager, selectUserToManage } from '../../../../Actions/ManagerActions';
import { syncCurrentUser } from '../../../../Actions/UserActions';

import { getManages, getUserId } from '../../../../Helpers/user_helpers';
import { isCurrentlyManaging, getManagerManages, getCurrentManager, getNotificationCountForManagesUser } from '../../../../Helpers/manager_helpers';
import { getUserTierDisplay } from '../../../../Helpers/tier_helpers';
import { getPrettyNumber, getPrettyDate } from '../../../../Helpers/formatting';
import { scrollToTop } from '../../../../Helpers/helpers';

const ManagerRosterPanel = props => {
  const { user, manager } = props;
  const realUser = getCurrentManager(manager);
  const managedUsers = isCurrentlyManaging(manager) ? getManagerManages(manager) : getManages(user);

  const sortByOptions = [
    {
      value: 'name',
      label: 'Name',
      sublabel: 'Sort your talent in alphabetical order.',
      order: managedUsers => _.orderBy(managedUsers, ['user.name'], ['asc'])
    },
    {
      value: 'score',
      label: 'Score',
      sublabel: 'Talent with higher score will appear first.',
      order: managedUsers => _.orderBy(managedUsers, ['user.tier.score'], ['desc'])
    },
    {
      value: 'volume',
      label: 'Volume',
      sublabel: 'Talent with more order volume will appear first.',
      order: managedUsers => _.orderBy(managedUsers, ['user.stats.totalVolume'], ['desc'])
    },
    {
      value: 'clicks',
      label: 'Clicks',
      sublabel: 'Talent driving high clicks will appear first',
      order: managedUsers => _.orderBy(managedUsers, ['user.stats.totalClicks'], ['desc'])
    },
    {
      value: 'notifications',
      label: 'Notifications',
      sublabel: 'Talent with the most notifications will appear first.',
      order: managedUsers => _.orderBy(managedUsers, user => getNotificationCountForManagesUser(user.user, manager), 'desc')
    },
    {
      value: 'last_logged_in',
      label: 'Last Logged In',
      sublabel: 'Talent who have logged in most recently will appear first.',
      order: managedUsers => _.orderBy(managedUsers, user => moment(user.user.lastLoggedIn).unix(), 'desc')
    }
  ];

  const [searchVal, setSearchVal] = useState('');
  const [sortBy, setSortBy] = useState(sortByOptions.find(option => option.value === 'volume'));
  const filteredManagedUsers = sortBy
    .order(managedUsers)
    .filter(managedUser => (searchVal ? managedUser.user.name.toLowerCase().includes(searchVal.toLowerCase()) : true));

  const selectUserToManage = async userToSelect => {
    const isBannedManagedAccount = userToSelect?.isBanned;
    if (isBannedManagedAccount) return window.ALERT.warn("This user's account is currently under review.");

    await props.selectUserToManage(userToSelect);
    scrollToTop();
  };

  const stopManagingTalent = userManagerObject => {
    confirmAlert({
      title: 'Just Confirming',
      message: `Are you sure you want to stop managing ${userManagerObject.user.name}?`,
      buttons: [
        { label: 'No', className: 'cancel', onClick: () => {} },
        { label: 'Yes', onClick: () => props.deleteUserManager(userManagerObject) }
      ]
    });
  };

  return (
    <div className='manager-roster-panel-outer'>
      <div className='manager-roster-panel-inner'>
        <div className='settings-section'>
          <div className='settings-section-title'>Accounts You Manage ({managedUsers.length})</div>
          <div className='settings-section-subtitle'>
            You can manage any of the following accounts by toggling the profile using the profile photo in the navigation bar.
          </div>

          <div className='manager-roster-controls-container'>
            <div>
              <FontAwesomeIcon icon={faSearch} className='manager-roster-search-icon' />
              <input
                type='text'
                className='manager-roster-search'
                placeholder='Search for a user'
                value={searchVal}
                onChange={e => setSearchVal(e.target.value)}
              />
              {!!realUser?.id && (
                <button className='manager-roster-refresh' onClick={() => selectUserToManage(null)}>
                  <FontAwesomeIcon icon={faArrowLeft} className='manager-roster-refresh-icon' />
                  Return To My Account
                </button>
              )}
            </div>
            <div>
              <Select
                placeholder='Add Variable'
                className='manager-roster-sort'
                onChange={option => setSortBy(option)}
                options={sortByOptions}
                value={sortBy}
                components={{ Option: SelectOption }}
              />
            </div>
          </div>

          <div className='manager-roster-users'>
            {filteredManagedUsers.map(managedUser => (
              <ManagedUser
                key={`managed-user-${managedUser.User_id}`}
                user={managedUser}
                selectUserToManage={selectUserToManage}
                stopManagingTalent={stopManagingTalent}
                isSelected={getUserId(user) === managedUser.User_id}
                manager={manager}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const ManagedUser = props => {
  const { isSelected, manager } = props;
  const user = props.user.user;
  const { social_accounts, stats, tier } = user;

  // profile
  const name = user.name;
  const imageFailedText = name ? name.split(' ').map(n => n[0]).join('') : 'N/A'; // prettier-ignore
  const image = user.image;
  const managedSince = props.user.createdAt;
  const totalCommissions = stats.totalCommissions;
  const conversionRate = (stats.totalOrders / stats.totalPinClicks) * 100;
  const notificationCount = getNotificationCountForManagesUser(user, manager);

  // actions
  const socialsLinked = !!social_accounts.length;
  const hasApp = !!user.hasMobileApp;
  const hasSnapshop = !!user.hasSnapshop;
  const linking = stats.monthlyPins > 0;

  const lastLoggedIn = user.lastLoggedIn;

  const stopManaging = e => {
    e.stopPropagation();
    e.preventDefault();
    props.stopManagingTalent(props.user);
  };

  const selectUser = () => {
    if (isSelected) return window.ALERT.warn('This is the account you are currently viewing.');
    props.selectUserToManage(user);
  };

  return (
    <div className={cn('managed-user-outer', { isSelected })} onClick={selectUser}>
      <div className='managed-user-inner'>
        <div className='managed-user-profile'>
          <div className='managed-user-image-container'>
            {notificationCount > 0 && <div className='managed-user-notification-count'>{notificationCount}</div>}
            <Image className='managed-user-image' src={image} failedText={imageFailedText} useGenericImageStyles useGenericFailedStyles />
          </div>

          <div className='managed-user-profile-data'>
            <div className='managed-user-profile-bold'>{name}</div>
            <div className='managed-user-profile-regular'>Managed since {getPrettyDate(managedSince)}</div>
            <div className='managed-user-profile-light'>Earned ${getPrettyNumber(totalCommissions)}</div>
            {window.__ADMIN_CONTROL_MODE__ ? (
              <div className='managed-user-profile-light'>{Number.isNaN(conversionRate) ? '0.000' : conversionRate.toFixed(3)}% click conversion</div>
            ) : null}
          </div>
        </div>

        <div className='managed-user-metrics'>
          <div className='managed-user-metrics-header'>Key Actions</div>

          <div className={cn('managed-user-metrics-metric', { active: socialsLinked })}>
            <FontAwesomeIcon icon={faCircleCheck} className='managed-user-metrics-icon' />
            <div>Socials Linked</div>
          </div>
          <div className={cn('managed-user-metrics-metric', { active: hasApp })}>
            <FontAwesomeIcon icon={faCircleCheck} className='managed-user-metrics-icon' />
            <div>Has App</div>
          </div>
          <div className={cn('managed-user-metrics-metric', { active: hasSnapshop })}>
            <FontAwesomeIcon icon={faCircleCheck} className='managed-user-metrics-icon' />
            <div>Has Snapshop</div>
          </div>
          <div className={cn('managed-user-metrics-metric', { active: linking })}>
            <FontAwesomeIcon icon={faCircleCheck} className='managed-user-metrics-icon' />
            <div>Linking</div>
          </div>
        </div>

        <div className='managed-user-actions'>
          <div className='managed-user-actions-tier'>
            <div className='managed-user-actions-tier-name'>{getUserTierDisplay(tier.tier)}</div>
            <div className='managed-user-actions-tier-score'>{parseFloat(tier.score).toFixed(1)} / 100</div>
          </div>

          <div className='managed-user-actions-buttons'>
            <div className='managed-user-actions-buttons-last-logged-in'>Last logged in {getPrettyDate(lastLoggedIn)}</div>
          </div>

          <div className='managed-user-actions-buttons-hidden'>
            <button className='managed-user-actions-buttons-hidden-button' onClick={stopManaging}>
              Stop Managing
            </button>
            <button className='managed-user-actions-buttons-hidden-button'>Log In As User &gt;</button>
          </div>
        </div>
      </div>
    </div>
  );
};

ManagerRosterPanel.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  manager: PropTypes.object.isRequired,
  deleteUserManager: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, manager } = state;
  return { user, manager };
};

export default connect(mapStateToProps, {
  deleteUserManager,
  syncCurrentUser,
  selectUserToManage
})(ManagerRosterPanel);
