import React from 'react';
import commaNumber from 'comma-number';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './CostReasoningOverlay.scss';

import { getPrettyNumber } from '../../../Helpers/formatting';

const CostReasoningOverlay = props => {
  const { baseline, multipliers, clamps, other_data } = props.reasoning;

  // Baseline
  const Follower_CPE = baseline['Follower CPE'];
  const Volume_Bonus_CPE = baseline['Volume Bonus CPE'];
  const Total_Baseline = baseline['Total Baseline CPE'];
  const Monthly_Volume = other_data['Monthly Volume'];

  // Multipliers - Brand Popularity and Conversion
  const Brand_Popularity_Adjustment = multipliers['Brand Popularity Adjustment'];
  const Brand_Conversion_Adjustment = multipliers['Brand Conversion Adjustment'];
  const Brand_Volume_Per_Click = other_data['Brand Volume Per Click'];

  // Multipliers - CreatorPerformance
  const Performance_Adjustment = multipliers['Performance Adjustment'];
  const Performance_Adjustment_Reasoning = other_data['Performance Adjustment Reasoning'] || {};
  const Performance_Adjustment_Reasoning_Num_Opps_Last_90 = Performance_Adjustment_Reasoning['# Opps Completed Last 90'];
  const Performance_Adjustment_Reasoning_ROI_Last_90 = Performance_Adjustment_Reasoning['ROI Last 90'];
  const Performance_Adjustment_Reasoning_Cost_Adjustment = Performance_Adjustment_Reasoning['Cost Adjustment'];
  const Performance_Adjustment_Reasoning_Adjustment_Confidence = Performance_Adjustment_Reasoning['Adjustment Confidence'];
  const Social_Engagement_Adjustment = multipliers['Social Engagement Adjustment'];
  const Cost_Multiplier = multipliers['Cost Multiplier'];
  const Generosity_Multiplier = multipliers['Generosity Multiplier'];

  // Final clamps based on acceptance
  const Recently_Accepted_CPE = other_data['Recently Accepted CPE'];
  const Recently_Accepted_CPE_Count = other_data['Recently Accepted CPE Count'];
  const Min_CPE_Allowed_Dollar_Clamp = clamps['Min CPE Allowed Dollar Clamp'];
  const CPE_Desired_Pre_Min_CPE_Allowed_Clamp = other_data['CPE Desired Pre Min CPE Allowed Clamp'];
  const Min_CPE_Allowed = other_data['Min CPE Allowed'];
  const Min_CPE_Allowed_Ratio = other_data['Min CPE Allowed Ratio'];

  // Other Data
  const Follower_Count = other_data['Follower Count'];
  const Follower_Count_Platform = other_data['Follower Count Platform'];
  const True_Follower_Count = other_data['True Follower Count'];
  const Repeat_Adjustment = other_data['Repeat Adjustment'];

  // Totals
  const Num_Expectations = other_data['Num Expectations'];
  const Final_CPE = other_data['Final CPE'];
  const applyExpectationMultiplier = value => value * Num_Expectations * Repeat_Adjustment;
  const applyExpectationMultiplierWithoutRepeatAdjustment = value => value * Num_Expectations;

  // Explanation Sections
  const getPercentageChangeDisplayValueFromMultiplier = multiplier => {
    return `${multiplier > 1 ? '+' : ''}${(100 * (multiplier - 1)).toFixed(0)}%`;
  };
  const explanationSections = [
    {
      title: 'Baseline',
      items: [
        {
          title: 'Total Baseline',
          value: Total_Baseline ? '$' + commaNumber(applyExpectationMultiplier(Total_Baseline).toFixed(0)) : '-',
          subitems: [
            {
              title: Follower_Count ? `${getPrettyNumber(Follower_Count)} ${Follower_Count_Platform} Followers` : 'Unknown Followers',
              value: Follower_CPE ? '$' + commaNumber(applyExpectationMultiplier(Follower_CPE).toFixed(0)) : '-',
              subitems: [
                ...(True_Follower_Count !== Follower_Count
                  ? [
                      {
                        title: '"True" Followers',
                        value: True_Follower_Count ? getPrettyNumber(True_Follower_Count) : '-'
                      }
                    ]
                  : [])
              ]
            },
            {
              title: Monthly_Volume ? `$${getPrettyNumber(Monthly_Volume)} Monthly Volume` : 'Unknown Monthly Volume',
              value: Monthly_Volume ? '$' + commaNumber(applyExpectationMultiplier(Volume_Bonus_CPE).toFixed(0)) : '-'
            }
          ]
        }
      ]
    },
    {
      title: `Brand Adjustments`,
      items: [
        {
          title: 'Popularity Savings',
          value: Brand_Popularity_Adjustment ? getPercentageChangeDisplayValueFromMultiplier(Brand_Popularity_Adjustment) : '-'
        },
        {
          title: Brand_Conversion_Adjustment > 1 ? `Tougher to Convert` : `Easier to Convert`,
          titleExtension: Brand_Volume_Per_Click ? `($${Brand_Volume_Per_Click.toFixed(2)}/click)` : null,
          value: Brand_Conversion_Adjustment ? getPercentageChangeDisplayValueFromMultiplier(Brand_Conversion_Adjustment) : '-'
        }
      ]
    },
    {
      title: `Custom Adjustments`,
      items: [
        {
          title: 'Cost Multiplier',
          value: Cost_Multiplier ? `${Cost_Multiplier.toFixed(2)}x` : '-',
          hidden: !Cost_Multiplier || Cost_Multiplier === 1
        },
        {
          title: 'Generosity Multiplier',
          value: Generosity_Multiplier ? `${Generosity_Multiplier.toFixed(2)}x` : '-',
          hidden: !Generosity_Multiplier || Generosity_Multiplier === 1
        }
      ]
    },
    {
      title: 'Creator Adjustments',
      items: [
        {
          title: 'Social Engagement',
          value: Social_Engagement_Adjustment ? `${Social_Engagement_Adjustment.toFixed(2)}x` : '-',
          hidden: !Social_Engagement_Adjustment || Social_Engagement_Adjustment === 1
        },
        {
          title: 'Past Performance',
          titleExtension: Performance_Adjustment_Reasoning_ROI_Last_90
            ? `(${Performance_Adjustment_Reasoning_ROI_Last_90.toFixed(1)}x on ${Performance_Adjustment_Reasoning_Num_Opps_Last_90} Opp${
                Performance_Adjustment_Reasoning_Num_Opps_Last_90 === 1 ? '' : 's'
              })`
            : null,
          value: Performance_Adjustment_Reasoning_Num_Opps_Last_90 > 0 ? getPercentageChangeDisplayValueFromMultiplier(Performance_Adjustment) : '-',
          subitems: [
            {
              title: 'Desired Adjustment',
              value: Performance_Adjustment_Reasoning_Cost_Adjustment
                ? getPercentageChangeDisplayValueFromMultiplier(Performance_Adjustment_Reasoning_Cost_Adjustment)
                : '-'
            },
            {
              title: `Confidence in Adjustment`,
              value: Performance_Adjustment_Reasoning_Adjustment_Confidence
                ? `${(100 * Performance_Adjustment_Reasoning_Adjustment_Confidence).toFixed(0)}%`
                : '-'
            }
          ]
        }
      ]
    },
    {
      title: 'Final Clamps',
      items: [
        {
          title: 'Reducing Drastic Underpay',
          value: Min_CPE_Allowed
            ? `+$${commaNumber(applyExpectationMultiplierWithoutRepeatAdjustment(Min_CPE_Allowed_Dollar_Clamp).toFixed(0))}`
            : '-',
          hidden: !Min_CPE_Allowed,
          subitems: [
            {
              title: 'Desired Payment',
              value: CPE_Desired_Pre_Min_CPE_Allowed_Clamp
                ? `$${commaNumber(applyExpectationMultiplierWithoutRepeatAdjustment(CPE_Desired_Pre_Min_CPE_Allowed_Clamp).toFixed(0))}`
                : '-'
            },
            {
              title: 'Recently Accepted',
              titleExtension: Recently_Accepted_CPE_Count ? `(Avg over ${Recently_Accepted_CPE_Count} Opps)` : null,
              value: Recently_Accepted_CPE
                ? `$${commaNumber(applyExpectationMultiplierWithoutRepeatAdjustment(Recently_Accepted_CPE).toFixed(0))}`
                : '-'
            },
            {
              title: 'Minimum Allowed',
              titleExtension: Min_CPE_Allowed_Ratio ? `${(100 * Min_CPE_Allowed_Ratio).toFixed(0)}% of Recently Accepted` : null,
              value: Min_CPE_Allowed ? `$${commaNumber(applyExpectationMultiplierWithoutRepeatAdjustment(Min_CPE_Allowed).toFixed(0))}` : '-'
            },
            {
              title: 'Underpay Reduction Adjustment',
              value: Min_CPE_Allowed_Dollar_Clamp
                ? `+$${commaNumber(applyExpectationMultiplierWithoutRepeatAdjustment(Min_CPE_Allowed_Dollar_Clamp).toFixed(0))}`
                : '-'
            }
          ]
        }
      ]
    },
    {
      title: 'Final',
      items: [
        {
          title: `Cost for ${Num_Expectations} Links`,
          value: Final_CPE ? `$${commaNumber((Final_CPE * Num_Expectations).toFixed(0))}` : '-',
          subitems: [
            {
              title: `Recently Accepted`,
              titleExtension: Recently_Accepted_CPE_Count ? `(Avg over ${Recently_Accepted_CPE_Count} Opps)` : null,
              value: Recently_Accepted_CPE
                ? `$${commaNumber(applyExpectationMultiplierWithoutRepeatAdjustment(Recently_Accepted_CPE).toFixed(0))}`
                : '-'
            }
          ]
        }
      ]
    }
  ].filter(section => section.items.some(item => !item.hidden));

  return (
    <div className='cost-reasoning-overlay'>
      <div className='cr-sections'>
        {explanationSections.map((section, index) => {
          const { title, items } = section;
          const visibleItems = items.filter(item => !item.hidden);
          return (
            <div key={index} className='cr-section'>
              <div className='cr-section-title'>{title}</div>
              <div className='cr-items'>
                {visibleItems.map((item, index) => {
                  const { title, titleExtension, value, subitems } = item;
                  return (
                    <React.Fragment key={index}>
                      <div className='cr-item'>
                        <div className='cr-title'>
                          {title}
                          {titleExtension && <span className='cr-title-extension'>{titleExtension}</span>}
                        </div>
                        <div className='cr-value'>{value}</div>
                      </div>
                      {subitems?.map((subitem, subindex) => {
                        return (
                          <div key={subindex} className={cn('cr-item intermittent', {})}>
                            <div className='cr-title'>
                              {subitem.title}
                              {subitem.titleExtension && <span className='cr-title-extension'>{subitem.titleExtension}</span>}
                            </div>
                            <div className='cr-value'>{subitem.value}</div>
                          </div>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

CostReasoningOverlay.propTypes = {
  reasoning: PropTypes.object.isRequired
};

export default CostReasoningOverlay;
