import _ from 'lodash';
import moment from 'moment';
import { isSubscribedToFeature } from './subscription_helpers';
import { getBrand, getShopifyIntegration, getAllShopifyIntegrations } from './user_helpers';
import { faBox, faTruck, faStore, faPersonCarry, faCheck, faTimes, faBan } from '@fortawesome/pro-regular-svg-icons';
import { getDomainFromUrl } from './formatting';
import getSymbolFromCurrency from 'currency-symbol-map';

export const getCartForLookbook = (lookbooks, lookbook) => lookbooks?.carts?.[lookbook.id] || {};
export const getCartItemsForLookbook = (lookbooks, lookbook) => {
  const lookbookCart = getCartForLookbook(lookbooks, lookbook);
  const selectedSiblingIds = _.keys(lookbookCart);
  let cartItems = [];
  lookbook.items?.forEach(item => {
    const siblingsInCart = item.siblings.filter(item => selectedSiblingIds.includes(String(item.id)));
    siblingsInCart.forEach(sibling => {
      cartItems.push({
        item,
        sibling,
        quantity: lookbookCart[sibling.id]
      });
    });
  });
  return cartItems;
};

export const getCartItemsForLookbookItem = (lookbooks, lookbook, item) => {
  const lookbookCart = getCartForLookbook(lookbooks, lookbook);
  const selectedSiblingIds = _.keys(lookbookCart);
  const siblingsInCart = item.siblings.filter(item => selectedSiblingIds.includes(String(item.id)));
  return siblingsInCart;
};

export const getAllLookbookOrderItems = requests => {
  const orderItems = [];
  for (const request of requests) {
    if (!request?.lookbook_order?.items?.length) continue;
    orderItems.push(...request.lookbook_order.items);
  }
  return orderItems;
};

/**
 * Helper function that will return an array of what siblings were selected
 * in orders for a given lookbook item. This is important because it is the
 * only way for brands to see how many of each size / color was selected
 *
 * @param {Object} item
 * @param {Object} item.reduced_siblings - array of high level siblings (has all size variations included in it)
 * @param {Object[]} orders
 */
export const getLookbookItemOrderSiblingBreakdown = (item, requests) => {
  if (!item?.reduced_siblings?.length) return [];
  else if (!requests?.length) return [];

  const orders = getAllLookbookOrderItems(requests);

  // STEP 1 create a map of all the sibling ids that are associated to which larger sibling
  // [size_id, size_id, size_id] => reduced_sibling_id
  const { reduced_siblings } = item;
  const siblingIdMap = {};
  for (const reduced_sibling of reduced_siblings) {
    const size_variations = reduced_sibling?.size_variations || [];
    for (const size_variation of size_variations) siblingIdMap[size_variation.id] = reduced_sibling.id;
  }

  // STEP 2 iterate through all orders and create a map of sibling ids to quantity if they exist in the siblingIdMap
  const siblingQuantityMap = {};
  for (const order of orders) {
    const { LookbookItemSibling_id } = order;
    const isRelevantOrder = !!siblingIdMap[LookbookItemSibling_id];
    if (!isRelevantOrder) continue;

    const reducedSiblingId = siblingIdMap[LookbookItemSibling_id];
    if (!siblingQuantityMap[reducedSiblingId]) siblingQuantityMap[reducedSiblingId] = 0;
    siblingQuantityMap[reducedSiblingId] += 1;
  }

  // STEP 3 iterate through the keys of the siblingQuantityMap and create an array of objects
  // that contain the sibling and the quantity
  const siblingBreakdown = [];
  for (const siblingId in siblingQuantityMap) {
    const sibling = reduced_siblings.find(sibling => sibling.id === parseInt(siblingId));
    const quantity = siblingQuantityMap[siblingId];
    siblingBreakdown.push({ sibling, quantity });
  }

  return _.orderBy(siblingBreakdown, 'quantity', 'desc');
};

export const getLookbookItemInitialSibling = item => {
  const { reduced_siblings } = item || {};
  if (!reduced_siblings?.length) return null;
  return (
    reduced_siblings.find(sibling => sibling.isDefault) ||
    reduced_siblings.find(sibling => sibling.isAvailable && !sibling.isHidden) ||
    reduced_siblings.find(sibling => !sibling.isAvailable && !sibling.isHidden) ||
    reduced_siblings[0]
  );
};

export const getLookbookItemStartingImage = item => {
  if (!item) return null;
  const initialSibling = getLookbookItemInitialSibling(item);
  return initialSibling?.image || item.image;
};

export const getLookbookItemPrice = LookbookItem =>
  LookbookItem?.overridePrice || LookbookItem?.defaultSibling?.price || LookbookItem?.fallbackPrice || LookbookItem?.variant?.price;

export const getLookbookItemIsCustom = LookbookItem =>
  !LookbookItem?.Variant_id &&
  !LookbookItem?.fallbackUrl &&
  !LookbookItem?.fallbackPrice &&
  !LookbookItem?.fallbackTitle &&
  !LookbookItem?.fallbackBrand &&
  !LookbookItem?.fallbackDescription;

export const getLookbookItemSelectedCount = (LookbookItem, lookbookCart) => {
  const { id } = LookbookItem;
  let count = 0;
  for (const key in lookbookCart) if (lookbookCart[key].item.id === id) count += lookbookCart[key].quantity;
  return count;
};

export const calculateLookbookCartItems = lookbookCart => {
  let cartItemCount = 0;
  let cartPrice = 0;
  for (const key in lookbookCart) {
    const { item, sibling, quantity } = lookbookCart[key];
    const price = sibling?.price || getLookbookItemPrice(item);
    cartPrice += price * quantity;
    cartItemCount += quantity || 1;
  }
  return { cartItemCount, cartPrice };
};

export const getDomainsForLookbook = lookbook => {
  const reduced_siblings = _.flatten(
    _.map(_.flatten(lookbook.items.map(item => item.reduced_siblings)), s => (s.size_variations?.length ? s.size_variations : [s]))
  );
  const siblingDomains = _.uniq(reduced_siblings.map(sibling => getDomainFromUrl(sibling.url))).filter(Boolean);
  return siblingDomains;
};

export const getDomainsForLookbookItem = item => {
  const reduced_siblings = _.flatten(_.map(item.reduced_siblings, s => (s.size_variations?.length ? s.size_variations : [s])));
  const siblingDomains = _.uniq(reduced_siblings.map(sibling => getDomainFromUrl(sibling.url))).filter(Boolean);
  return siblingDomains;
};

export const isLookbookConnectedToShopify = (user, lookbook) => {
  const siblings = _.flatten(lookbook.items.map(item => item.siblings));
  const someSiblingsCoveredByShopify = siblings.some(sibling => sibling.shopifyVariantId);
  const lookbookDomains = getDomainsForLookbook(lookbook);

  // Shopify Cases
  /*
  cases 
    1. Not connected to shopify: no siblings have shopifyVariantIDs
    2. Partially connected to shopify: some siblings have shopifyVariantIDs, but no items have a pdp url to determine domain from
    3. Partially connected to shopify: some siblings have shopifyVariantIDs, some items have a pdp url, but multiple domains are present
  */
  if (!someSiblingsCoveredByShopify) {
    return {
      isConnectedToShopify: false,
      reason: 'No items in the lookbook are connected to Shopify.'
    };
  } else if (lookbookDomains.length > 1)
    return {
      isConnectedToShopify: false,
      reason: `There cannot be more than one shopify domain across lookbook items. Right now there are ${
        lookbookDomains.length
      } domains represented by items in this lookbook. (${lookbookDomains.join(
        ', '
      )}) You can view what shopify domain each item is connected to by hovering over the Shopify icon.`
    };

  const lookbookDomain = lookbookDomains.length ? lookbookDomains[0] : getBrand(user)?.domain || undefined; // fallback to brand domain

  const shopifyIntegration = getShopifyIntegration(user, lookbookDomain);
  const integrationExistsAndAllowsOrderCreation = someSiblingsCoveredByShopify && shopifyIntegration.allowOrderCreation;

  if (integrationExistsAndAllowsOrderCreation) {
    return {
      isConnectedToShopify: true,
      isPartiallyConnected: !siblings.every(sibling => sibling.shopifyVariantId),
      forDomain: lookbookDomain,
      reason: 'All siblings have the same domain, and that domain supports order creation by a shopify integration.'
    };
  } else {
    return {
      isConnectedToShopify: false,
      reason: `There is no shopify integration that allows order creation for domain: ${lookbookDomain}`
    };
  }
};

export const getLookbookOrderItemParts = item => {
  /*
    LookbookOrderItem.title is formatted as follows:
      name - nonSizeTag - sizeTag
  */
  const { title } = item;
  const name = title.split(' - ')[0];
  const nonSizeTag = title.split(' - ')[1];
  const sizeTag = title.split(' - ')[2];
  const pinDisplay = _.filter([name, nonSizeTag]).join(' - ');
  const siblingName = _.filter([sizeTag, nonSizeTag]).join(' - ');
  return {
    name,
    pinDisplay,
    nonSizeTag,
    sizeTag,
    siblingName
  };
};

export const getOrderHandledByData = (user, lookbook) => {
  /* 
    Orders can be handled in three ways:

    By Shopify - all siblings must have shopifyVariantIDs, same domain, and integration for domain enabled
    By ShopMy - brand must be on a high enough package
    By Brand - no requirements
 */
  const handledByOverride = lookbook?.orderHandledBy;
  const hasUnlockedShopMyHandling = isSubscribedToFeature(user, 'LOOKBOOK_SHOPMY_ORDER_HANDLING');

  // Core data variables
  let handledBy, reasoning, showReasoning, display;
  let isPartiallyConnected = false;
  let showSwitchToManual = false;

  const hasShopifyIntegrations = getAllShopifyIntegrations(user).length;
  const lookbookIntegrationData = isLookbookConnectedToShopify(user, lookbook);

  // Override case
  if (handledByOverride) {
    handledBy = handledByOverride;
    reasoning = 'Lookbook shipping has been explicitly set in the settings modal';
    display = handledByOverride === 'brand' ? 'Orders handled manually by your team' : 'Orders placed by ShopMy team';
    showReasoning = true;
  }

  // ShopMy Case
  else if (hasUnlockedShopMyHandling) {
    handledBy = 'shopmy';
    reasoning = 'Brand is on a high enough package';
    display = 'Orders placed by ShopMy team';
    showReasoning = false;
  } else if (!lookbookIntegrationData.isConnectedToShopify) {
    if (hasShopifyIntegrations) {
      handledBy = 'default';
      reasoning = lookbookIntegrationData.reason;
      display = 'Orders handled manually by your team due to Shopify integration restrictions';
      showReasoning = true;
      showSwitchToManual = true;
    } else {
      // Brand Case
      handledBy = 'brand';
      reasoning = 'You are on a package that does not support order handling from the ShopMy team';
      display = 'Orders handled manually by your team';
      showReasoning = true;
    }
  }

  // Shopify Case
  else {
    handledBy = 'shopify';
    isPartiallyConnected = lookbookIntegrationData.isPartiallyConnected;
    reasoning = lookbook.items.length
      ? `Brand has order creation enabled in profile and ${
          lookbookIntegrationData.isPartiallyConnected ? 'some' : 'all'
        } siblings have shopifyVariantIDs. Therefore orders can be automatically fulfilled by Shopify`
      : 'Brand has order creation enabled in profile';
    display = `Orders automatically placed in Shopify for ${lookbookIntegrationData.forDomain}${
      isPartiallyConnected ? ' for connected products' : ''
    }`;
    showReasoning = false;
  }

  return {
    handledBy,
    isPartiallyConnected,
    display,
    reasoning,
    showReasoning,
    showSwitchToManual
  };
};

export const calculateOrderTotalItems = items => {
  if (!items?.length) return 0;
  let count = 0;
  for (const { quantity } of items) count += quantity;
  return count;
};

export const calculateOrderTotalPrice = items => {
  if (!items?.length) return 0;
  let price = 0;
  for (const { price: itemPrice, quantity } of items) price += itemPrice * quantity;
  return price;
};

export const filterInvalidLookbookItemSiblings = item => !item?.isHidden && item?.title && item.isAvailable;

export const getLookbookRequests = (analytics, lookbook) => {
  const { id: Lookbook_id } = lookbook;
  const allRequests = analytics?.brandAnalytics?.requests;
  const requests = allRequests?.filter(request => request.Lookbook_id === Lookbook_id);

  return requests?.length ? requests : [];
};

/**
 *
 * @param {Object} order
 * @param {string} order.status
 * @returns {Object} { display: string, icon: FontAwesomeIcon }
 */
export const getLookbookOrderStatusDisplay = order => {
  return (
    {
      submitted: {
        display: 'Submitted',
        icon: faBox
      },
      pending: {
        display: 'Submitted',
        icon: faTruck
      },
      shipped: {
        display: 'Shipped',
        icon: faTruck
      },
      ready_for_pickup: {
        display: 'Ready For Pickup',
        icon: faStore
      },
      out_for_delivery: {
        display: 'Out for Delivery',
        icon: faTruck
      },
      attempted_delivery: {
        display: 'Attempted Delivery',
        icon: faPersonCarry
      },
      delivered: {
        display: 'Delivered',
        icon: faCheck
      },
      failure: {
        display: 'Failure',
        icon: faTimes
      },
      cancelled: {
        display: 'Cancelled',
        icon: faBan
      }
    }[order?.status] || {
      display: 'Unknown',
      icon: faCheck
    }
  );
};

/**
 * Use this as a universal function to filter requests / mentions based on the date range
 * set by the user
 *
 * @param {Object} item - an item to filter (request or mention)
 * @param {'request'|'mention'} type - used to determine which item field to filter on
 * @param {String} startDate
 * @param {String} endDate
 */
export const lookbookReportItemFilter = (item, type, startDate, endDate) => {
  let filterField;

  if (type === 'request') filterField = 'userAcceptedAt';
  else if (type === 'mention') filterField = 'contentPublishedAt';

  if (!startDate && !endDate) return true;

  let [startDateQualifier, endDateQualifier] = [true, true];
  if (startDate) startDateQualifier = moment(item[filterField]).isSameOrAfter(startDate, 'day');
  if (endDate) endDateQualifier = moment(item[filterField]).isSameOrBefore(endDate, 'day');
  return startDateQualifier && endDateQualifier;
};

export const getFirstLookbookItemSizes = lookbookItem => {
  const { reduced_siblings } = lookbookItem;
  if (!reduced_siblings.length) return null;

  for (const sibling of reduced_siblings) {
    if (sibling.size_variations.length) {
      return sibling.size_variations.map(size => size.tag);
    }
  }

  return null;
};

/**
 * The goal of this function is to take a list of lookbook items and return
 * an array of standard sizes that the brand may want to use based on the
 * previous items / sizes they have used in the past.
 *
 * Item 1:
 *   Color 1: XXS, XS, S, M, L, XL
 *   Color 2: XS, S, M, L, XL
 *   Color 3: XXS, XS, S, M, L, XL
 *   Color 4: XS, S, M, L, XL, XXL
 * Item 2:
 *   Color 1: 0, 2, 4, 6, 8, 10
 *   Color 2: 2, 4, 6, 8, 10
 *   Color 3: 0, 2, 4, 6, 8, 10
 *
 * return [
 *   [XXS, XS, S, M, L, XL, XXL],
 *   [0, 2, 4, 6, 8, 10]
 * ]
 *
 * @param {Object[]} lookbookItems
 */
export const getLookbookStandardSizes = lookbookItems => {
  const allReducedSizes = [];
  for (const lookbookItem of lookbookItems) {
    const { reduced_siblings } = lookbookItem;
    for (const sibling of reduced_siblings) allReducedSizes.push(sibling.size_variations.map(size => size.tag));
  }

  // now we have to create sets,
  // if we find a previous set that has multiple sizes in common, we'll consider them a match and merge them

  const standardSizes = [];

  for (const sizes of allReducedSizes) {
    if (sizes.length <= 2) continue;
    let [matchesFound, matchedIndex] = [0, null];

    for (let i = 0; i < standardSizes.length; i++) {
      const standardSizeSet = standardSizes[i];
      const intersection = _.intersection(standardSizeSet, sizes);
      if (intersection.length > 1) {
        if (matchesFound < intersection.length) {
          matchesFound = intersection.length;
          matchedIndex = i;
        }
      }
    }

    if (matchedIndex !== null) {
      standardSizes[matchedIndex] = _.orderBy(_.union(standardSizes[matchedIndex], sizes), size => {
        // if we can parse a number we'll sort by that, otherwise we'll use the standard ordering object
        const parsedSize = parseInt(size);
        return isNaN(parsedSize) ? standardSizeOrdering[size.toLowerCase()] : parsedSize;
      });
    } else {
      _.orderBy(standardSizes.push(sizes), size => {
        const parsedSize = parseInt(size);
        return isNaN(parsedSize) ? standardSizeOrdering[size.toLowerCase()] : parsedSize;
      });
    }
  }

  return standardSizes.length ? standardSizes : defaultStandardSizes;
};

const defaultStandardSizes = [
  ['Extra Small', 'Small', 'Medium', 'Large', 'Extra Large'],
  ['0', '2', '4', '6', '8', '10'],
  ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL']
];

const standardSizeOrdering = {
  newborn: 0,
  xxs: 1,
  xs: 2,
  s: 3,
  sm: 3,
  m: 4,
  md: 4,
  l: 5,
  lg: 5,
  '1x': 6,
  '2x': 7,
  '2xl': 7,
  '3x': 8,
  '3xl': 9,
  '4xl': 10,
  '5xl': 11,
  '6xl': 12,
  xl: 6,
  xxl: 7,
  xxxl: 8,
  xxxxl: 9,
  xxxxxl: 10,
  xxxxxxl: 11,
  p: 0,
  'extra extra small': 1,
  'extra small': 2,
  small: 3,
  'extra large': 6,
  large: 5,
  xlarge: 6,
  medium: 4,
  'extra extra large': 7
};

/**
 * This function accepts a lookbook object and returns the currency symbol to display for lookbook item prices in the UI.
 * The lookbook object should include the integrationSettings property attached to it, via the /api/Lookbooks/:Lookbook_id endpoint.
 */
export const getLookbookCurrencySymbol = lookbook => {
  const currencyCode = lookbook.orderOverrideCurrencyCode || lookbook.integrationSettings?.defaultCurrency || 'USD';
  return getSymbolFromCurrency(currencyCode);
};
