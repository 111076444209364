import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import './AddPinModalMerchantPayoutTile.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';

import Loader from '../../Loader/Loader';
import Tooltip from '../../General/Tooltip';

import defaultPhoto from '../../../static/images/logos/logo.png';

import { getMerchants as getMerchantsAPI } from '../../../APIClient/pins';
import { getAdjPayoutRate } from '../../../Helpers/user_helpers';
import { getDisclaimerForMerchant } from '../../../Helpers/merchant_helpers';

class AddPinModalMerchantPayoutTile extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    link: PropTypes.string.isRequired,
    initial_link: PropTypes.string.isRequired,
    updateFields: PropTypes.func.isRequired,
    setMerchantData: PropTypes.func.isRequired,
    lockedCatalogBrand: PropTypes.object,
    merchantData: PropTypes.object,
    merchantMatches: PropTypes.array,
    variantMatches: PropTypes.array
  };

  componentDidMount() {
    this.fetchMerchantDataDebounced = AwesomeDebouncePromise(async value => {
      this.fetchMerchantData(this.props.link);
    }, 400);
    this.fetchMerchantData(this.props.initial_link);
  }

  componentDidUpdate(prevProps) {
    if (this.props.link !== prevProps.link) {
      this.fetchMerchantDataDebounced();
    }
  }

  fetchMerchantData = async link => {
    this.setState({ fetchingMerchantData: true, merchantFetchError: false });
    getMerchantsAPI([link]).then(
      ({ data }) => {
        this.setState({
          fetchingMerchantData: false
        });
        this.props.setMerchantData(_.get(data, link));
      },
      err => {
        this.setState({
          fetchingMerchantData: false,
          merchantFetchError: true
        });
        this.props.setMerchantData(null);
      }
    );
  };

  state = {
    fetchingMerchantData: false,
    merchantFetchError: false,
    showingAlternativeMerchants: false
  };

  getStatusMessage = () => {
    const { user, store, merchantData, variantMatches } = this.props;
    const { fetchingMerchantData, merchantFetchError } = this.state;
    const { name, rateType } = merchantData || {};
    const shopUser = _.get(store, ['visibleCollection', 'pins', 0, 'user']);
    const matchingVariant = _.find(variantMatches, v => v.url && v.url.includes(merchantData?.domain));
    const adjPayout = getAdjPayoutRate(shopUser || user, merchantData, matchingVariant);

    let status = null;
    const disclaimer = getDisclaimerForMerchant(merchantData, user, matchingVariant);

    if (fetchingMerchantData) status = 'Finding best payout...';
    else if (merchantFetchError) status = 'Issue finding payout';
    else if (!merchantData || !adjPayout) status = 'No affiliate payout found';
    else if (rateType === 'percentage') {
      status = `${name} pays out ${adjPayout.toFixed(adjPayout < 1 ? 1 : 0)}%.`;
    } else if (adjPayout) {
      status = `${name} pays out $${adjPayout.toFixed(2)} for a purchase`;
    }

    return { statusMessage: status, disclaimer };
  };

  getUpgradeDiv = () => {
    const { user, store, merchantData, merchantMatches, variantMatches } = this.props;
    const { showingAlternativeMerchants } = this.state;
    const shopUser = _.get(store, ['visibleCollection', 'pins', 0, 'user']);
    const matchingVariant = _.find(variantMatches, v => v.url && v.url.includes(merchantData?.domain));
    const adjPayout = getAdjPayoutRate(shopUser || user, merchantData, matchingVariant);

    let [worseMatches, betterMatches] = [[], []];
    _.forEach(merchantMatches, merchant => {
      const variant = _.find(variantMatches, v => v.url && v.url.includes(merchant.domain));
      const otherAdjPayout = getAdjPayoutRate(shopUser || user, merchant, variant);
      if (_.get(merchantData, 'name') === _.get(merchant, 'name')) return;
      if (parseInt(otherAdjPayout.toFixed(0)) > parseInt(adjPayout.toFixed(0))) {
        betterMatches.push({
          merchant,
          payout: parseInt(otherAdjPayout.toFixed(0)),
          variant
        });
        betterMatches = _.orderBy(betterMatches, 'payout', 'desc');
      } else {
        worseMatches.push({
          merchant,
          payout: parseInt(otherAdjPayout.toFixed(0)),
          variant
        });
        worseMatches = _.orderBy(worseMatches, 'payout', 'desc');
      }
    });

    if (!showingAlternativeMerchants) {
      if (this.props.lockedCatalogBrand && this.props.lockedCatalogBrand?.name === merchantData?.name) {
        // Don't show similar brands if locked to a single retailer.
        return null;
      } else if (betterMatches.length) {
        return (
          <div className='alternative-rate-label' onClick={() => this.setState({ showingAlternativeMerchants: true })}>
            Found {betterMatches.length} better rate
            {betterMatches.length === 1 ? '' : 's'}
            <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
          </div>
        );
      } else if (worseMatches.length) {
        return (
          <div className='alternative-rate-label' onClick={() => this.setState({ showingAlternativeMerchants: true })}>
            See other retailers
            <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
          </div>
        );
      } else {
        return null;
      }
    }

    return (
      <div>
        <div className='alternative-rate-label' onClick={() => this.setState({ showingAlternativeMerchants: false })}>
          <FontAwesomeIcon icon={faChevronUp}></FontAwesomeIcon>
        </div>
        <div className='alternative-rates'>
          {[...betterMatches, ...worseMatches].map(match => (
            <div
              key={match.merchant.id}
              className={classnames('alternative-rate', {
                better: parseInt(adjPayout.toFixed(0)) < match.payout,
                worse: parseInt(adjPayout.toFixed(0)) > match.payout
              })}
              onClick={() => {
                let newAlternativeImages = match.variant.otherImageData ? JSON.parse(match.variant.otherImageData) : [];
                this.props.updateFields({
                  link: match.variant.url,
                  image: match.variant.image,
                  all_images_augmented: newAlternativeImages
                });
                this.setState({ showingAlternativeMerchants: false });
              }}
            >
              <div className='payout'>{match.payout}%</div>
              <div className='name'>at {match.merchant.name}</div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  render() {
    const { merchantData } = this.props;
    const { fetchingMerchantData } = this.state;
    const { logo, name } = merchantData || {};
    const { statusMessage, disclaimer } = this.getStatusMessage();
    if (!statusMessage) return null;

    return (
      <div className='add-pin-modal-merchant-payout-tile-outer-container'>
        <div
          className={classnames('merchant-payout-tile', {
            loaded: !fetchingMerchantData
          })}
        >
          <div className='merchant-payout-logo-outer-container left'>
            <div
              className={classnames('merchant-payout-logo-container', {
                empty: !logo
              })}
            >
              {fetchingMerchantData ? (
                <Loader size={60} />
              ) : logo ? (
                <img className='merchant-payout-logo' alt={`${name} Logo`} src={logo} />
              ) : (
                <img className='merchant-payout-logo empty' alt='ShopMy Logo' src={defaultPhoto} />
              )}
            </div>
            <div className='merchant-payout-msg requires-load'>{statusMessage}</div>
          </div>
          {disclaimer && <Tooltip message={disclaimer} getIconDiv={() => <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>} />}
        </div>
        {this.getUpgradeDiv()}
      </div>
    );
  }
}

export default AddPinModalMerchantPayoutTile;
