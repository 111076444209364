import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faPlus, faUpload, faTimes } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import arrayMove from 'array-move';
import cn from 'classnames';
import _ from 'lodash';
import './LookbookProductModalImages.scss';

import SortableList from '../../../General/SortableList';
import ImageUploader from '../../../General/ImageUploader';
import DropUploader from '../../../Uploader/DropUploader';
import Image from '../../../General/Image';

const LookbookProductModalImages = props => {
  const { lookbook, item, selectedSiblingId, updateLookbookItemSibling } = props;
  const { title } = item || {};

  // Image Uploading UI
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [isUploadingNewSiblingImage, setIsUploadingNewSiblingImage] = useState(false);
  const addNewSiblingImage = () => setIsUploadingNewSiblingImage(true);
  const ensureValidFile = (_, rejectedFiles) => rejectedFiles.length && window.ALERT.error('Only PNG, JPG, JPEG and Gif files are supported.');

  // Visible Images
  const [selectedSiblingImage, setSelectedSiblingImage] = useState(null);
  const lookbookItem = lookbook?.items?.find(i => i.id === item.id);

  const selectedSibling = lookbookItem?.reduced_siblings?.find(
    s => s.id === selectedSiblingId || s.size_variations.some(sv => sv.id === selectedSiblingId)
  );
  const getAllImagesFromSibling = sibling => _.uniq(_.filter(_.concat(sibling.image, (sibling.alt_images || '').split(','))));
  const images = getAllImagesFromSibling(selectedSibling) || [item.image];
  const visibleImage = selectedSiblingImage || selectedSibling.image || item.image;
  const getAltImagesFromImages = imgs => imgs.filter(img => img !== selectedSibling.image);
  const addSiblingImage = async newImage => {
    const altImages = _.uniq([...getAltImagesFromImages(images), newImage]);
    setSelectedSiblingImage(newImage);
    await updateLookbookItemSibling(selectedSibling, { alt_images: altImages.join(',') });
  };

  /*
    Update Functions to:

    1) Change the primary image
    2) Reorder sibling images
    3) Add a new sibling image
    4) Delete a sibling image
  */
  const dropOnPrimaryImage = async newImage => {
    if (!selectedSibling) return;

    const isUpdatingPrimaryImage = !selectedSiblingImage || selectedSiblingImage === selectedSibling.image;
    if (isUpdatingPrimaryImage) {
      await updateLookbookItemSibling(selectedSibling, { image: newImage });
    } else {
      const altImages = getAltImagesFromImages(images).map(i => (i === selectedSiblingImage ? newImage : i));
      await updateLookbookItemSibling(selectedSibling, { alt_images: altImages.join(',') });
      setSelectedSiblingImage(newImage);
    }
  };

  // Handle sortable item cards
  const onSortEnd = async ({ oldIndex, newIndex }) => {
    // If we are moving it to the 0 index, we want to make it primary
    if (newIndex === 0) {
      const newAltImages = _.concat(selectedSibling.image, images.filter(i => i !== images[oldIndex])); // prettier-ignore
      const alt_images = newAltImages.join(',');
      await updateLookbookItemSibling(selectedSibling, { image: images[oldIndex], alt_images });
    }

    // Otherwise we are just reordering
    if (newIndex > 0) {
      const newAltImages = getAltImagesFromImages(arrayMove(images, oldIndex, newIndex));
      const alt_images = newAltImages.join(',');
      await updateLookbookItemSibling(selectedSibling, { alt_images });
    }
  };

  const deleteImage = async image => {
    const isPrimaryImage = image === selectedSibling.image;
    if (isPrimaryImage) {
      const newImage = getAltImagesFromImages(images)[0];
      const newAltImages = getAltImagesFromImages(images.filter(i => i !== image));
      await updateLookbookItemSibling(selectedSibling, {
        image: newImage,
        alt_images: newAltImages.join(',')
      });
    } else {
      const newAltImages = getAltImagesFromImages(images.filter(i => i !== image));
      await updateLookbookItemSibling(selectedSibling, { alt_images: newAltImages.join(',') });
    }
    if (selectedSiblingImage === image) setSelectedSiblingImage(null);
  };

  // Handle Resetting Images on sibling changes
  React.useEffect(() => {
    setSelectedSiblingImage(null);
  }, [selectedSibling?.id]);

  return (
    <div className='lookbook-product-modal-images-container'>
      {/* Primary Image */}
      <div>
        <ImageUploader
          isVisible={isUploadingImage}
          setIsVisible={setIsUploadingImage}
          initialImageUrl={visibleImage}
          onSaveCallback={image_url => dropOnPrimaryImage(image_url)}
        />
        <DropUploader onDrop={ensureValidFile} onUpload={dropOnPrimaryImage} onlyAllowImages isDisabled={isUploadingImage}>
          <div
            className={cn('product-image-container', { 'has-image': visibleImage }, lookbook.imageStyle)}
            onClick={() => setIsUploadingImage(!isUploadingImage)}
          >
            {visibleImage ? (
              <>
                <FontAwesomeIcon className='edit-icon' icon={faUpload} />
                <Image src={visibleImage} alt={title} className={cn('product-image', lookbook.imageStyle)} failedText='Error Loading Image' />
              </>
            ) : (
              <div className='no-image'>
                <FontAwesomeIcon icon={faImage} />
                <div>
                  <FontAwesomeIcon icon={faUpload} />
                  Upload File
                </div>
              </div>
            )}
          </div>
        </DropUploader>
      </div>
      {/* Secondary Images */}
      <SortableList
        isEditing
        containerClassName='secondary-images'
        items={images}
        customUpdateItem={onSortEnd}
        props={props}
        additionalCard={
          <>
            <ImageUploader isVisible={isUploadingNewSiblingImage} setIsVisible={setIsUploadingNewSiblingImage} onSaveCallback={addSiblingImage} />
            <DropUploader
              className='lookbook-sibling-image-container add-new'
              dropMessage='Drop'
              onDrop={ensureValidFile}
              onUpload={addSiblingImage}
              onlyAllowImages
            >
              <div className='main' onClick={addNewSiblingImage}>
                <FontAwesomeIcon icon={faPlus} />
                <div className='drag-explainer'>
                  Drag Image
                  <br />
                  Here
                </div>
              </div>
            </DropUploader>
          </>
        }
        getCard={(item, additionalProps = {}) => {
          const { rearrangeHandle } = additionalProps;
          const image = item;
          const isSelected = visibleImage === image;
          const select = () => setSelectedSiblingImage(image);
          const remove = () => deleteImage(image);
          return (
            <div key={image} className={cn('lookbook-sibling-image-container', lookbook.imageStyle, { selected: isSelected })}>
              <Image onClick={select} src={image} alt={`Secondary ${image}`} className={cn('lookbook-sibling-image', lookbook.imageStyle)} />
              <div className='actions'>
                {images.length > 1 && (
                  <div onClick={remove} className='remove'>
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                )}
                {rearrangeHandle}
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

LookbookProductModalImages.propTypes = {
  lookbook: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  selectedSiblingId: PropTypes.number,

  setLookbookItemImage: PropTypes.func.isRequired,
  updateLookbookItemSibling: PropTypes.func.isRequired
};

export default LookbookProductModalImages;
