import APIClient from './index';
import _ from 'lodash';

export const fetchCatalogVariants = (Brand_id, params) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Variants/${Brand_id}?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'response.data.error')));
  });
};
