import helpers from './Helpers/helpers';
import cogoToast from 'cogo-toast';
import moment from 'moment';

// Import Fonts
import './static/fonts/MediaSans/mediasans.css';
import './static/fonts/ParaSupreme/parasupreme.css';

window.__IS_PRO__ = helpers.isPro();
window.__IS_SMS__ = helpers.isSMS() || helpers.isSnap();
window.__IS_SNAP__ = helpers.isSnap();
window.__IS_DEV__ = helpers.isDevelopment();
window.__IS_PROD__ = helpers.isProduction();
window.__ROOT_SCSS__ = helpers.getRootSCSSClass();

// Reduce totals after July 1, 2024
window.__IS_REDUCED_CREATOR_TIER_BONUS__ = moment().isAfter('2024-07-01 05:00:00');

// For locking of the catalog for only product id matches
window.LOCK_CATALOG_MATCHES_ONLY = false;

// To make it easier to alert from everywhere
window.ALERT = cogoToast;

// Feature flags for brands beta testing new features
window.__IS_CUSTOM_AFFILIATE_RATES_LIVE__ = user => {
  const demo_brands = ['Tuckernuck', 'Credo Beauty', 'SALT & STONE', 'Margaux', 'Dolce Vita', 'Rhone'];
  const brandName = user?.profile?.brand?.name;
  return demo_brands.includes(brandName);
};

// For admin control mode without requiring extensive imports
export const setGlobalAdminControlMode = turnOn => (window.__ADMIN_CONTROL_MODE__ = turnOn);
