import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import './DeveloperKeyPanel.scss';

import { fetchBrandDeveloperKey } from '../../../../APIClient/brands';
import { rotateBrandDeveloperKey } from '../../../../Actions/BrandActions';

import { getBrand } from '../../../../Helpers/user_helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';

const DeveloperKeyPanel = props => {
  const { user } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [developerKey, setDeveloperKey] = useState(null);
  const [developerKeyIsVisible, setDeveloperKeyIsVisible] = useState(false);

  const loadDeveloperKey = () => {
    if (isLoading) return window.ALERT.error('Please wait for the current operation to complete.');
    setIsLoading(true);

    const Brand_id = getBrand(user).id;
    fetchBrandDeveloperKey(Brand_id)
      .then(res => setDeveloperKey(res.developerKey))
      .catch(() => window.ALERT.error('Error fetching brand developer key'))
      .finally(() => setIsLoading(false));
  };

  const rotateBrandDeveloperKey = () => {
    if (isLoading) return window.ALERT.error('Please wait for the current operation to complete.');
    setIsLoading(true);

    confirmAlert({
      title: 'Are you sure you want to rotate your developer key?',
      message: (
        <>
          Your current developer key will be replaced and all ShopMy API requests must be made with the new key.
          <br />
          <b>This cannot be undone.</b>
        </>
      ),
      buttons: [
        {
          label: 'Rotate developer key',
          className: 'danger',
          onClick: async () => {
            const res = await props.rotateBrandDeveloperKey(getBrand(props.user)?.id);

            if (res.error) window.ALERT.error('There was an error rotating your developer key, please try again.');
            else window.ALERT.success('Rotated ShopMy developer key!');

            setDeveloperKey(res.developerKey);
            setIsLoading(false);
          }
        },
        { label: 'Cancel', onClick: () => setIsLoading(false), className: 'cancel' }
      ]
    });
  };

  return (
    <div className='developer-key-panel-outer'>
      <div className='developer-key-panel-inner'>
        <div className='settings-section'>
          <div className='settings-section-title'>Developer Key</div>
          <div className='settings-section-subtitle'>
            Key for developer access to the ShopMy API. Keep this secure, do not share in publicly accessible areas.
          </div>
          <div className='settings-section-split-input'>
            <div className='settings-section-split-input-password'>
              <input
                className='settings-input'
                type={developerKeyIsVisible ? 'text' : 'password'}
                placeholder='•••••••••••••••••••••••'
                value={developerKey}
                disabled
              />
              {!!developerKey && (
                <FontAwesomeIcon
                  icon={developerKeyIsVisible ? faEye : faEyeSlash}
                  className='password-view-icon'
                  onClick={() => setDeveloperKeyIsVisible(!developerKeyIsVisible)}
                />
              )}
            </div>
            {developerKey ? (
              <button className='settings-button dark' onClick={rotateBrandDeveloperKey} disabled={isLoading}>
                Rotate
              </button>
            ) : (
              <button className='settings-button' onClick={loadDeveloperKey} disabled={isLoading}>
                Load
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

DeveloperKeyPanel.propTypes = {
  user: PropTypes.object.isRequired,
  rotateBrandDeveloperKey: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  rotateBrandDeveloperKey
})(withRouter(DeveloperKeyPanel));
