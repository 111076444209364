import _ from 'lodash';
import cogoToast from 'cogo-toast';
import { configureAxios, configureAxiosUserHash } from '../Auth';
import { syncAmplitudeUserProfile } from '../Helpers/amplitude_helpers';
import * as ActionTypes from '../Actions/UserActions';
import * as AnalyticsActionTypes from '../Actions/AnalyticsActions';
import * as AnnouncementActionTypes from '../Actions/AnnouncementActions';
import * as PayoutActionTypes from '../Actions/PayoutActions';
import * as BrandActionTypes from '../Actions/BrandActions';
import * as OpportunityActionTypes from '../Actions/OpportunityActions';
import * as SubscriptionActions from '../Actions/SubscriptionActions';
import * as RecommendationActionTypes from '../Actions/RecommendationActions';
import * as ChatActionTypes from '../Actions/ChatActions';
import * as CollaborationsActionTypes from '../Actions/CollaborationsActions';
import * as ManagerActionsTypes from '../Actions/ManagerActions';
import * as SectionActionTypes from '../Actions/SectionActions';
import * as LookbookActionTypes from '../Actions/LookbookActions';
import * as StoreActionTypes from '../Actions/StoreActions';

export const user = (
  state = {
    profile: {},
    payouts: {},
    payments: [],
    invoices: [],
    contractInvoices: [],
    budgetInvoices: [],
    recommendations: [],
    recommendationsMap: [], // For performance
    isLoggedIn: false,
    isSyncing: false,
    jwtToken: null,
    jwtIdToken: null,
    simulatedUsername: null,
    onboarding_data: {},
    payout_summaries: {}
  },
  action
) => {
  const newState = { ...state };
  let newRecommendationsMap;
  switch (action.type) {
    case ActionTypes.REGISTER_USER_SUCCESS:
      return {
        ...newState,
        isLoggedIn: true,
        profile: _.get(action, 'user', {})
      };

    case ActionTypes.STORE_COGNITO_RESPONSE:
      const accessToken =
        action.jwtToken || _.get(action.cognitoRes, 'signInUserSession.accessToken.jwtToken') || _.get(action.cognitoRes, 'accessToken.jwtToken');
      const idToken =
        action.jwtIdToken || _.get(action.cognitoRes, 'signInUserSession.idToken.jwtToken') || _.get(action.cognitoRes, 'idToken.jwtToken');
      configureAxios(accessToken, idToken);

      return {
        ...newState,
        jwtToken: accessToken,
        jwtIdToken: idToken
      };

    case ActionTypes.STORE_USER_HASH:
      configureAxiosUserHash(action.userHash);
      return { ...newState };

    case ActionTypes.REGISTER_USER_ERROR_USERNAME:
      cogoToast.error('A user with that email already exists.');
      return newState;

    case ActionTypes.SYNC_CURRENT_USER:
      newState.isSyncing = true;
      return newState;

    case ActionTypes.SYNC_CURRENT_USER_SUCCESS:
      newState.isSyncing = false;
      newState.profile = _.get(action, 'user', {});
      syncAmplitudeUserProfile(newState);
      return newState;

    case ActionTypes.SYNC_CURRENT_USER_FAILURE:
      newState.isSyncing = false;
      return newState;

    case ActionTypes.SIMULATE_USER:
      newState.simulatedUsername = action.username;
      return newState;

    case ActionTypes.UPDATE_CURRENT_USER_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          ..._.get(action, 'user', {}),
          ...(state.profile?.brand?.id ? { brand: { ...state.profile.brand, ..._.get(action, 'user.brand', {}) } } : {})
        }
      };

    case ActionTypes.LOGIN_USER_SUCCESS:
      cogoToast.success('Successfully logged in!');
      return {
        ...state,
        isLoggedIn: true,
        simulatedUsername: null,
        profile: _.get(action, 'user', {})
      };

    case ActionTypes.REGISTER_USER_FAILURE:
      cogoToast.error(action.error || 'Unknown error, please try again.');
      return {
        ...state,
        isLoggedIn: false,
        profile: {}
      };

    case ActionTypes.LOGIN_USER_FAILURE:
      cogoToast.error(action.message || 'Incorrect username and/or password.', { hideAfter: 7 });
      return {
        ...state,
        isLoggedIn: false,
        profile: {}
      };

    case ActionTypes.LOGOUT_USER_SUCCESS:
      return {
        ...state,
        profile: {},
        payouts: {},
        payments: [],
        invoices: [],
        contractInvoices: [],
        budgetInvoices: [],
        recommendations: [],
        recommendationsMap: {},
        isLoggedIn: false,
        simulatedUsername: null,
        jwtToken: null
      };

    case ActionTypes.SYNC_REQUESTS_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          requests: action.requests,
          brand_requests: action.brand_requests
        }
      };

    case ActionTypes.UPDATE_REQUEST_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          requests: _.map(state.profile.requests, r => (r.id === action.request.id ? action.request : r)),
          brand_requests: _.map(state.profile.brand_requests, r => (r.id === action.request.id ? action.request : r)),
          chat_notifications: _.map(newState.profile?.chat_notifications, c => c),
          brand_chat_notifications: _.map(newState.profile?.chat_notifications, c => c)
        }
      };

    case ActionTypes.UPDATE_USER_OPPORTUNITY_REQUEST_REQUEST:
      return {
        ...state,
        profile: {
          ...state.profile,
          opportunity_requests: _.map(state.profile.opportunity_requests, r =>
            r.id === action.request.id
              ? {
                  ...r,
                  ...action.updates
                }
              : r
          )
        }
      };

    case ActionTypes.UPDATE_USER_OPPORTUNITY_REQUEST_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          opportunity_requests: _.map(state.profile.opportunity_requests, r => (r.id === action.request.id ? action.request : r))
        }
      };

    case ActionTypes.UPDATE_USER_OPPORTUNITY_REQUEST_FAILURE:
      window.ALERT.error(action.error || 'We had an issue updating your opportunity request, please try again.', {
        hideAfter: 10
      });
      return {
        ...state,
        profile: {
          ...state.profile,
          opportunity_requests: _.map(state.profile.opportunity_requests, r => (r.id === action.request.id ? action.request : r))
        }
      };

    case ActionTypes.DELETE_REQUEST_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          requests: _.filter(state.profile.requests, r => r.id !== action.Request_id),
          brand_requests: _.filter(state.profile.brand_requests, r => r.id !== action.Request_id)
        }
      };

    case ActionTypes.UPDATE_REQUEST_FAILURE:
      cogoToast.error('We had an issue updating your gifting request, please try again.');
      return {
        ...state
      };

    case ActionTypes.UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          address: action.address
        }
      };

    case ActionTypes.ADD_TAGS_TO_USER_REQUEST:
      return {
        ...state,
        profile: {
          ...state.profile,
          tags: _.uniqBy(_.concat(state.profile.tags, action.tags), 'id')
        }
      };

    case ActionTypes.DELETE_TAGS_FOR_USER_REQUEST:
      return {
        ...state,
        profile: {
          ...state.profile,
          tags: _.filter(state.profile.tags, tag => !action.tags.map(tag => tag.id).includes(tag.id))
        }
      };

    case ActionTypes.ADD_TAGS_TO_USER_FAILURE:
    case ActionTypes.DELETE_TAGS_FOR_USER_FAILURE:
      return {
        ...state,
        profile: {
          ...state.profile,
          tags: action.tags
        }
      };

    case ActionTypes.UPDATE_USER_SETTINGS_REQUEST:
      return {
        ...state,
        profile: {
          ...state.profile,
          settings: {
            ...state.profile.settings,
            ...action.updates
          }
        }
      };

    case ActionTypes.UPDATE_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          settings: action.settings
        }
      };

    case ActionTypes.UPDATE_USER_SETTINGS_FAILURE:
      cogoToast.error('There was an issue updating your settings, please try again.');
      return {
        ...state,
        profile: {
          ...state.profile,
          settings: action.settings
        }
      };

    case ActionTypes.UPDATE_USER_AUTHENTICATION_TOKEN_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          tokens: action.tokens
        }
      };

    case ActionTypes.UPDATE_USER_AUTHENTICATION_TOKEN_FAILURE:
      cogoToast.error('There was an issue updating your authorized applications, please try again.');
      return {
        ...state
      };

    case ActionTypes.DELETE_USER_AUTHENTICATION_TOKEN_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          tokens: action.tokens
        }
      };

    case ActionTypes.DELETE_USER_AUTHENTICATION_TOKEN_FAILURE:
      cogoToast.error('There was an issue removing your authorized application, please try again.');
      return {
        ...state
      };

    case ActionTypes.CONNECT_YOUTUBE_ACCOUNT_SUCCESS:
    case ActionTypes.CONNECT_INSTAGRAM_ACCOUNT_SUCCESS:
    case ActionTypes.CONNECT_TIKTOK_ACCOUNT_SUCCESS:
      action.warning
        ? cogoToast.warn(action.warning, {
            hideAfter: 10
          })
        : cogoToast.success(`Successfully connected your account.`);
      return {
        ...newState,
        profile: {
          ...(newState.profile || {}),
          social_accounts: [...(newState.profile.social_accounts || []), action.account]
        }
      };

    case ActionTypes.CONNECT_BRAND_YOUTUBE_ACCOUNT_SUCCESS:
    case ActionTypes.CONNECT_BRAND_INSTAGRAM_ACCOUNT_SUCCESS:
    case ActionTypes.CONNECT_BRAND_TIKTOK_ACCOUNT_SUCCESS:
      action.warning
        ? cogoToast.warn(action.warning, {
            hideAfter: 10
          })
        : cogoToast.success(`Successfully connected your account.`);
      return {
        ...newState,
        profile: {
          ...(newState.profile || {}),
          brand: {
            ...newState.profile.brand,
            social_accounts: [...(newState.profile.brand.social_accounts || []), action.account]
          }
        }
      };

    case ActionTypes.CONNECT_YOUTUBE_ACCOUNT_FAILURE:
    case ActionTypes.CONNECT_INSTAGRAM_ACCOUNT_FAILURE:
    case ActionTypes.CONNECT_TIKTOK_ACCOUNT_FAILURE:
      cogoToast.error(action.error, { hideAfter: 15 });
      return {
        ...state
      };

    case ActionTypes.REMOVE_SOCIAL_ACCOUNT_SUCCESS:
      return {
        ...state,
        profile: {
          ...(newState.profile || {}),

          // Remove the account from the user's social accounts
          social_accounts: _.filter(newState.profile.social_accounts, a => a.id !== action.account.id),

          // Remove the account from the brand's social accounts
          brand: newState.profile.brand && {
            ...newState.profile.brand,
            social_accounts: _.filter(newState.profile.brand.social_accounts, a => a.id !== action.account.id)
          }
        }
      };

    case ActionTypes.REMOVE_SOCIAL_ACCOUNT_FAILURE:
      cogoToast.error(action.error);
      return {
        ...state
      };

    case ActionTypes.CONNECT_STRIPE_ACCOUNT_SUCCESS:
      cogoToast.success('Successfully linked your Stripe account.');
      return {
        ...state,
        profile: {
          ...(newState.profile || {}),
          stripeAccountId: action.stripeAccountId
        }
      };

    case ActionTypes.CONNECT_SHOPIFY_ACCOUNT_SUCCESS:
      cogoToast.success('Successfully linked your Shopify account.');
      return {
        ...state,
        profile: {
          ...(newState.profile || {}),
          brand: {
            ...newState.profile.brand,
            ...action.brandUpdates
          }
        }
      };

    case ActionTypes.CONNECT_SHOPIFY_ACCOUNT_FAILURE:
      cogoToast.error(`Could not connect Shopify, please try again or contact your account manager.`);
      return {
        ...state
      };
    case ActionTypes.CONNECT_STRIPE_ACCOUNT_FAILURE:
      cogoToast.error(action.error);
      return {
        ...state
      };

    case ActionTypes.SYNC_USER_TIER_SUCCESS:
      return {
        ...state,
        profile: {
          ...(newState.profile || {}),
          tier: action.tier
        }
      };

    case PayoutActionTypes.GET_USER_PAYOUTS_SUCCESS:
      newState.payouts = {};
      newState.payouts = {
        payout_summaries: action.payout_summaries
      };
      return {
        ...newState
      };

    case PayoutActionTypes.GET_USER_PAYMENTS_SUCCESS:
      return {
        ...newState,
        payments: action.payments
      };

    case PayoutActionTypes.LINK_PAYPAL_ACCOUNT_SUCCESS:
      cogoToast.success('Successfully linked your PayPal account.');
      return {
        ...newState,
        profile: {
          ...(newState.profile || {}),
          payment_accounts: [...newState.profile.payment_accounts, action.account]
        }
      };

    case PayoutActionTypes.LINK_INVOICE_EMAIL_ACCOUNT_SUCCESS:
      cogoToast.success('Successfully linked your email account.');
      return {
        ...newState,
        profile: {
          ...(newState.profile || {}),
          payment_accounts: [...newState.profile.payment_accounts, action.account]
        }
      };

    case PayoutActionTypes.UNLINK_PAYMENT_ACCOUNT_SUCCESS:
      return {
        ...newState,
        profile: {
          ...(newState.profile || {}),
          payment_accounts: _.filter(newState.profile.payment_accounts, acc => acc.id !== action.account.id)
        }
      };

    case PayoutActionTypes.LINK_PAYPAL_ACCOUNT_ERROR:
      cogoToast.error(action.error || 'There was an issue linking your paypal account.');
      return {
        ...newState
      };

    case RecommendationActionTypes.GET_RECOMMENDED_ARTISTS_SUCCESS:
      newRecommendationsMap = {};
      action.recommendations.forEach(r => (newRecommendationsMap[r.id] = r));
      return {
        ...newState,
        recommendations: _.orderBy(action.recommendations, r => r.rankScore || 0, 'desc'),
        recommendationsMap: newRecommendationsMap
      };

    case RecommendationActionTypes.ADD_RECOMMENDED_ARTIST_SUCCESS:
      return {
        ...newState,
        recommendations: _.orderBy(_.concat(newState.recommendations, action.recommendation), r => r.rankScore || 0, 'desc'),
        recommendationsMap: { ...newState.recommendationsMap, [action.recommendation.id]: action.recommendation }
      };

    case RecommendationActionTypes.DELETE_RECOMMENDED_ARTIST_REQUEST:
      newRecommendationsMap = { ...newState.recommendationsMap };
      delete newRecommendationsMap[action.recommendation.id];
      return {
        ...newState,
        recommendations: _.filter(newState.recommendations, r => r.id !== action.recommendation.id),
        recommendationsMap: newRecommendationsMap
      };

    case RecommendationActionTypes.DELETE_RECOMMENDED_ARTIST_SUCCESS:
      cogoToast.success(
        `Recommendation dismissed. We will not recommend them in the future for ${
          action.recommendation.type === 'gifting' ? 'gifting' : action.recommendation.type === 'collaboration' ? 'collaborations' : ''
        }.`
      );
      return newState;

    case RecommendationActionTypes.DELETE_RECOMMENDED_ARTIST_ERROR:
      cogoToast.error(`Failure deleting this recommendation, please try again.`);
      return {
        ...newState,
        recommendations: _.concat(newState.recommendations, action.recommendation),
        recommendationsMap: { ...newState.recommendationsMap, [action.recommendation.id]: action.recommendation }
      };

    case RecommendationActionTypes.UPDATE_RECOMMENDED_ARTIST_SUCCESS:
      return {
        ...newState,
        recommendations: _.orderBy(
          _.map(newState.recommendations, r => (r.id === action.recommendation.id ? { ...r, ...action.updates } : r)),
          r => r.rankScore || 0,
          'desc'
        ),
        recommendationsMap: { ...newState.recommendationsMap, [action.recommendation.id]: action.recommendation }
      };

    case BrandActionTypes.GET_INVOICES_SUMMARY_SUCCESS:
      return {
        ...newState,
        invoices: _.orderBy(action.invoices, 'id', 'desc'),
        contractInvoices: _.orderBy(action.contractInvoices, 'id', 'desc'),
        budgetInvoices: _.orderBy(action.budgetInvoices, 'id', 'desc')
      };

    case BrandActionTypes.GET_INVOICES_SUMMARY_ERROR:
      cogoToast.error(action.error || 'There was an issue loading your invoices.');
      return {
        ...newState
      };

    case BrandActionTypes.GET_ONBOARDING_DATA_SUCCESS:
      return {
        ...newState,
        onboarding_data: action.data
      };

    case BrandActionTypes.UPDATE_BRAND_SUCCESS:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand, // To maintain the relations since this is a top level update
            ...action.brand
          }
        }
      };

    case BrandActionTypes.UPDATE_BRAND_SETTINGS_SUCCESS:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            settings: {
              ...(newState.profile.brand?.settings || {}),
              ...action.settings
            }
          }
        }
      };

    case BrandActionTypes.AUGMENT_BRAND_LISTS_SUCCESS:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            lists: action.lists,
            discoverListPreviews: action.discoverListPreviews // Only used by admins
          }
        }
      };

    case BrandActionTypes.ADD_BRAND_LIST_SUCCESS:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            lists: _.concat(newState.profile.brand.lists || [], action.list)
          }
        }
      };

    case BrandActionTypes.ADD_BRAND_LIST_ERROR:
      cogoToast.error(action.error || 'There was an issue creating this list, please try again.');
      return {
        ...newState
      };

    case BrandActionTypes.DELETE_BRAND_LIST_REQUEST:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            lists: newState.profile.brand.lists.filter(list => list.id !== action.list.id)
          }
        }
      };

    case BrandActionTypes.DELETE_BRAND_LIST_ERROR:
      cogoToast.error(action.error || 'There was an issue deleting this list, please try again.');
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            lists: _.concat(newState.profile.brand.lists || [], action.list)
          }
        }
      };

    case BrandActionTypes.UPDATE_BRAND_LIST_REQUEST:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            lists: newState.profile.brand.lists.map(list => (list.id !== action.list.id ? list : { ...list, ...action.updates }))
          }
        }
      };

    case BrandActionTypes.UPDATE_BRAND_LIST_ERROR:
      cogoToast.error(action.error || 'There was an issue editing this list, please try again.');
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            lists: _.concat(newState.profile.brand.lists || [], action.list)
          }
        }
      };

    case BrandActionTypes.UPDATE_BRAND_LIST_USER_REQUEST:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            lists: newState.profile.brand.lists.map(list =>
              list.id !== action.listUser.BrandList_id
                ? list
                : {
                    ...list,
                    users: list.users.map(user => {
                      user.User_id === action.listUser.User_id &&
                        console.info(user.User_id, action.listUser.User_id, user.User_id !== action.listUser.User_id, { ...user, ...action.updates });
                      return user.User_id !== action.listUser.User_id ? user : { ...user, ...action.updates };
                    })
                  }
            )
          }
        }
      };

    case BrandActionTypes.UPDATE_BRAND_LIST_USER_ERROR:
      window.ALERT.error(action.error || 'There was an issue editing this list, please try again.');
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            lists: newState.profile.brand.lists.map(list =>
              list.id !== action.listUser.BrandList_id
                ? list
                : {
                    ...list,
                    users: list.users.map(user => (user.id !== action.listUser.id ? user : action.listUser))
                  }
            )
          }
        }
      };

    case BrandActionTypes.ADD_BRAND_LIST_USER_SUCCESS:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            lists: newState.profile.brand.lists.map(list =>
              list.id !== action.listUser.BrandList_id
                ? list
                : {
                    ...list,
                    users: [...list.users, action.listUser]
                  }
            )
          }
        }
      };

    case BrandActionTypes.ADD_BRAND_LIST_USER_ERROR:
      cogoToast.error(action.error || 'There was an issue, please try again.');
      return {
        ...newState
      };

    case BrandActionTypes.DELETE_BRAND_LIST_USER_REQUEST:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            lists: newState.profile.brand.lists.map(list =>
              list.id !== action.listUser.BrandList_id
                ? list
                : {
                    ...list,
                    users: list.users.filter(u => u.id !== action.listUser.id)
                  }
            )
          }
        }
      };

    case BrandActionTypes.DELETE_BRAND_LIST_USER_ERROR:
      cogoToast.error(action.error || 'There was an issue removing this user from the list, please try again.');
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            lists: newState.profile.brand.lists.map(list =>
              list.id === action.listUser.BrandList_id
                ? list
                : {
                    ...list,
                    users: [...list.users, action.listUser]
                  }
            )
          }
        }
      };

    case BrandActionTypes.REFRESH_BRAND_TIER_SUCCESS:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            tier: action.tier
          }
        }
      };

    case BrandActionTypes.REFRESH_BRAND_TIER_ERROR:
      cogoToast.error(action.error || 'There was an issue refreshing your tier, please try again.');
      return {
        ...newState
      };

    case BrandActionTypes.ADD_BLACKLISTED_CODE_SUCCESS:
      window.ALERT.success(`Blacklisted code ${action.code.code} added`);
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            blacklisted_codes: [...(newState.profile.brand.blacklisted_codes || []), action.code]
          }
        }
      };

    case BrandActionTypes.ADD_BLACKLISTED_CODE_FAILURE:
      window.ALERT.error(action.error || `Error adding blacklisted code ${action.rawCode}`);
      return { ...newState };

    case BrandActionTypes.DELETE_BLACKLISTED_CODE:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            blacklisted_codes: _.filter(newState.profile.brand.blacklisted_codes, code => code.id !== action.code.id)
          }
        }
      };

    case BrandActionTypes.DELETE_BLACKLISTED_CODE_SUCCESS:
      window.ALERT.success(`Blacklisted code ${action.code.code} removed`);
      return { ...newState };

    case BrandActionTypes.DELETE_BLACKLISTED_CODE_FAILURE:
      window.ALERT.error(action.error || `Error deleting blacklisted code ${action.code.code}`);
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            blacklisted_codes: [...newState.profile.brand.blacklisted_codes, action.code]
          }
        }
      };

    case BrandActionTypes.SAVE_SHOPIFY_INTEGRATION_SUCCESS:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            integrations: [...(newState.profile.brand.integrations || []), action.shopifyIntegration]
          }
        }
      };

    case BrandActionTypes.UPDATE_SHOPIFY_INTEGRATION_SUCCESS:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            integrations: newState.profile.brand.integrations.map(integration => {
              if (integration.id === action.integration.id) {
                return {
                  ...integration, // preserve relations
                  ...action.integration
                };
              }
              return integration;
            })
          }
        }
      };

    case BrandActionTypes.SYNC_SHOPIFY_SCOPES_SUCCESS:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            integrations: action.integrations
          }
        }
      };
    case BrandActionTypes.SYNC_SHOPIFY_SCOPES_ERROR:
      window.ALERT.error(action.error || 'There was an issue syncing your Shopify scopes, please try again.');
      return {
        ...newState
      };

    case BrandActionTypes.UPDATE_SHOPIFY_INVENTORY_LOCATIONS_SUCCESS:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            integrations: newState.profile.brand.integrations.map(integration => {
              if (integration.id !== action.inventoryLocationData.BrandIntegration_id) return integration;
              return {
                ...integration,
                inventory_locations: action.inventoryLocationData.inventory_locations
              };
            })
          }
        }
      };

    case BrandActionTypes.SYNC_BRAND_BUDGET_SUCCESS:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            budget: action.budget,
            budget_invoices: action.invoices
          }
        }
      };

    case BrandActionTypes.UPDATE_COMPETITOR_BRAND_REQUEST:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            competitor_brands: newState.profile.brand.competitor_brands.map(compBrand => {
              if (compBrand.id !== action.competitorBrand.id) return compBrand;
              return {
                ...compBrand,
                ...action.updates
              };
            })
          }
        }
      };

    case BrandActionTypes.UPDATE_COMPETITOR_BRAND_ERROR:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            competitor_brands: newState.profile.brand.competitor_brands.map(compBrand => {
              if (compBrand.id !== action.competitorBrand.id) return compBrand;
              return action.competitorBrand;
            })
          }
        }
      };

    case BrandActionTypes.ADD_COMPETITOR_BRAND_SUCCESS:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            competitor_brands: _.concat(newState.profile.brand.competitor_brands || [], action.competitorBrand)
          }
        }
      };

    case BrandActionTypes.DELETE_COMPETITOR_BRAND_REQUEST:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            competitor_brands: newState.profile.brand.competitor_brands.filter(compBrand => compBrand.id !== action.competitorBrand.id)
          }
        }
      };

    case BrandActionTypes.DELETE_COMPETITOR_BRAND_ERROR:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            competitor_brands: _.concat(newState.profile.brand.competitorBrands || [], action.competitorBrand)
          }
        }
      };

    case OpportunityActionTypes.CREATE_OPPORTUNITY_SUCCESS:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            opportunities: _.concat(newState.profile.brand.opportunities || [], action.opportunity)
          }
        }
      };

    case OpportunityActionTypes.CREATE_OPPORTUNITY_FAILURE:
      window.ALERT.error(action.error || 'There was an issue creating this opportunity, please try again.');
      return {
        ...newState
      };

    case OpportunityActionTypes.DUPLICATE_OPPORTUNITY_SUCCESS:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            opportunities: _.concat(newState.profile.brand.opportunities || [], action.opportunity)
          }
        }
      };

    case OpportunityActionTypes.DUPLICATE_OPPORTUNITY_FAILURE:
      window.ALERT.error(action.error || 'There was an issue duplicating this opportunity, please try again.');
      return {
        ...newState
      };

    case OpportunityActionTypes.UPDATE_OPPORTUNITY_REQUEST:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            opportunities: newState.profile.brand.opportunities.map(o => (o.id === action.opportunity.id ? { ...o, ...action.updates } : o))
          }
        }
      };

    case OpportunityActionTypes.UPDATE_OPPORTUNITY_SUCCESS:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            opportunities: newState.profile.brand.opportunities.map(o => (o.id === action.opportunity.id ? { ...o, ...action.opportunity } : o))
          }
        }
      };

    case OpportunityActionTypes.UPDATE_OPPORTUNITY_FAILURE:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            opportunities: newState.profile.brand.opportunities.map(o => (o.id === action.opportunity.id ? action.opportunity : o))
          }
        }
      };

    case OpportunityActionTypes.DELETE_OPPORTUNITY_REQUEST:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            opportunities: newState.profile.brand.opportunities.filter(o => o.id !== action.opportunity.id)
          }
        }
      };

    case OpportunityActionTypes.DELETE_OPPORTUNITY_FAILURE:
      window.ALERT.error(action.error || 'There was an issue deleting this opportunity, please try again.');
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            opportunities: _.concat(newState.profile.brand.opportunities || [], action.opportunity)
          }
        }
      };

    case OpportunityActionTypes.CREATE_OPPORTUNITY_PLAN_SUCCESS:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            opportunities: newState.profile.brand.opportunities.map(o =>
              o.id === action.plan.Opportunity_id
                ? {
                    ...o,
                    plans: _.concat(o.plans || [], action.plan)
                  }
                : o
            )
          }
        }
      };

    case OpportunityActionTypes.DELETE_OPPORTUNITY_PLAN_SUCCESS:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            opportunities: newState.profile.brand.opportunities.map(o =>
              o.id === action.plan.Opportunity_id
                ? {
                    ...o,
                    plans: o.plans.filter(p => p.id !== action.plan.id)
                  }
                : o
            )
          }
        }
      };

    case OpportunityActionTypes.CREATE_OPPORTUNITY_PAYMENT_TIER_SUCCESS:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            opportunities: newState.profile.brand.opportunities.map(o =>
              o.id === action.payment_tier.Opportunity_id
                ? {
                    ...o,
                    payment_tiers: _.concat(o.payment_tiers || [], action.payment_tier)
                  }
                : o
            )
          }
        }
      };

    case OpportunityActionTypes.UPDATE_OPPORTUNITY_PAYMENT_TIER_SUCCESS:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            opportunities: newState.profile.brand.opportunities.map(o =>
              o.id === action.payment_tier.Opportunity_id
                ? {
                    ...o,
                    payment_tiers: o.payment_tiers.map(pt => (pt.id === action.payment_tier.id ? { ...pt, ...action.payment_tier } : pt))
                  }
                : o
            )
          }
        }
      };

    case OpportunityActionTypes.DELETE_OPPORTUNITY_PAYMENT_TIER_REQUEST:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            opportunities: newState.profile.brand.opportunities.map(o =>
              o.id === action.payment_tier.Opportunity_id
                ? {
                    ...o,
                    payment_tiers: o.payment_tiers.filter(pt => pt.id !== action.payment_tier.id)
                  }
                : o
            )
          }
        }
      };

    case OpportunityActionTypes.DELETE_OPPORTUNITY_PAYMENT_TIER_FAILURE:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            opportunities: newState.profile.brand.opportunities.map(o =>
              o.id === action.payment_tier.Opportunity_id
                ? {
                    ...o,
                    payment_tiers: _.concat(o.payment_tiers || [], action.payment_tier)
                  }
                : o
            )
          }
        }
      };

    case SubscriptionActions.SUBSCRIBE_TO_OFFER_SUCCESS:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            subscription: action.subscription
          }
        }
      };

    case SubscriptionActions.SUBSCRIBE_TO_OFFER_FAILURE:
      return {
        ...newState
      };

    case SubscriptionActions.UPDATE_SUBSCRIPTION_ITEM_SUCCESS:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            subscription: {
              ...newState.profile.brand.subscription,
              items: newState.profile.brand.subscription.items.map(item => (item.id === action.item.id ? { ...item, ...action.item } : item))
            }
          }
        }
      };

    case SubscriptionActions.UPDATE_SUBSCRIPTION_ITEM_FAILURE:
      window.ALERT.error(action.error || 'There was an issue updating this subscription item, please contact support.');
      return {
        ...newState
      };

    case SubscriptionActions.CANCEL_SUBSCRIPTION_ITEM_SUCCESS:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            subscription: action.subscription
          }
        }
      };

    case SubscriptionActions.CANCEL_SUBSCRIPTION_ITEM_FAILURE:
      window.ALERT.error(action.error || 'There was an issue cancelling this subscription item, please contact support.');
      return {
        ...newState
      };

    case LookbookActionTypes.RESYNC_BRAND_LOOKBOOKS:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            lookbooks: action.lookbooks
          }
        }
      };
    case LookbookActionTypes.DELETE_LOOKBOOK:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            lookbooks: newState.profile.brand.lookbooks.filter(lookbook => lookbook.id !== action.lookbook.id)
          }
        }
      };

    case LookbookActionTypes.DELETE_LOOKBOOK_ERROR:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            lookbooks: [...newState.profile.brand.lookbooks, action.lookbook]
          }
        }
      };

    case AnalyticsActionTypes.CREATE_SAMPLES_REQUEST_SUCCESS:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand
          },
          brand_requests: [...newState.profile.brand_requests, action.request]
        }
      };

    case AnalyticsActionTypes.REMOVE_SAMPLES_REQUEST_SUCCESS:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand
          },
          brand_requests: newState.profile.brand_requests.filter(r => r.id !== action.request.id)
        }
      };

    case AnalyticsActionTypes.SET_CUSTOM_CODE_SUCCESS:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          brand: {
            ...newState.profile.brand,
            codes: action.customCodes
          }
        }
      };

    case AnnouncementActionTypes.MARK_ANNOUNCEMENT_AS_READ_REQUEST:
      return {
        ...state,
        profile: {
          ...state.profile,
          announcements: state.profile.announcements?.filter(a => a.id !== action.announcement.id),
          brand_announcements: state.profile.brand_announcements?.filter(a => a.id !== action.announcement.id)
        }
      };

    case AnnouncementActionTypes.MARK_ANNOUNCEMENT_AS_READ_ERROR:
      return {
        ...state,
        profile: {
          ...state.profile,
          requests: _.concat(state.profile.requests, action.announcement.User_id ? action.announcement : []),
          brand_requests: _.concat(state.profile.brand_requests, action.announcement.Brand_id ? action.announcement : [])
        }
      };

    case ChatActionTypes.ADD_MESSAGE:
    case ChatActionTypes.UPDATE_CHAT_SUCCESS:
      const chat = action?.data?.chat || action?.chat;
      return {
        ...newState,
        profile: {
          ...newState.profile,
          chat_notifications: _.map(newState.profile?.chat_notifications, c => (c.id === chat?.id ? chat : c)),
          brand_chat_notifications: _.map(newState.profile?.brand_chat_notifications, c => (c.id === chat?.id ? chat : c))
        }
      };

    case CollaborationsActionTypes.CREATE_USER_CONTRACT_TASK_SUCCESS:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          tasks: action.task?.User_id === newState?.profile?.id ? _.concat(newState.profile.tasks, action.task) : newState.profile.tasks
        }
      };

    case CollaborationsActionTypes.UPDATE_USER_CONTRACT_TASK_SUCCESS:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          tasks: _.map(newState.profile?.tasks, t => (t.id === action.task?.id ? action.task : t))
        }
      };

    case CollaborationsActionTypes.DELETE_USER_CONTRACT_TASK_SUCCESS:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          tasks: _.filter(newState.profile?.tasks, t => t.id !== action.task?.id)
        }
      };

    case CollaborationsActionTypes.CREATE_USER_CONTRACT_TASK_FAILURE:
      cogoToast.error(action.error || `There was an issue creating this deliverable, please try again.`);
      return {
        ...newState
      };

    case CollaborationsActionTypes.UPDATE_USER_CONTRACT_TASK_FAILURE:
      cogoToast.error(typeof action.error === 'string' ? action.error : `There was an issue updating this deliverable, please try again.`);
      return {
        ...newState
      };

    case CollaborationsActionTypes.DELETE_USER_CONTRACT_TASK_FAILURE:
      cogoToast.error(action.error || `There was an issue removing this deliverable, please try again.`);
      return {
        ...newState
      };

    case ManagerActionsTypes.UPDATE_USER_MANAGER_REQUEST:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          managers: newState.profile.managers?.map(manager => {
            if (manager.id !== action.userManager.id) return manager;
            return { ...manager, ...action.updates };
          })
        }
      };

    case ManagerActionsTypes.UPDATE_USER_MANAGER_ERROR:
      cogoToast.error(`There was an error updating these permissions, please try again.`);
      return {
        ...newState,
        profile: {
          ...newState.profile,
          managers: newState.profile.managers?.map(manager => (action.initialManager.id === manager.id ? action.initialManager : manager))
        }
      };

    case ManagerActionsTypes.DELETE_USER_MANAGER_REQUEST:
      return {
        ...state,
        profile: {
          ...state.profile,
          managers: newState.profile.managers?.filter(m => m.id !== action.manager.id),
          manages: newState.profile.manages?.filter(m => m.id !== action.manager.id)
        }
      };

    case ManagerActionsTypes.DELETE_USER_MANAGER_ERROR:
      const isManager = action.manager?.manager?.id === newState.profile?.id;
      return {
        ...state,
        profile: {
          ...state.profile,
          managers: !isManager ? _.concat(newState.profile.managers || [], [action.manager]) : newState.profile.managers,
          manages: isManager ? _.concat(newState.profile.manages || [], [action.manager]) : newState.profile.manages
        }
      };

    // Sections
    case SectionActionTypes.ADD_SECTION_SUCCESS:
      if (action.section.User_id !== state.profile.id) return state; // If admin editing another shop
      return {
        ...state,
        profile: {
          ...state.profile,
          sections: _.concat(state.profile.sections || [], action.section)
        }
      };

    case SectionActionTypes.ADD_SECTION_FAILURE:
      if (action.section.User_id !== state.profile.id) return state; // If admin editing another shop
      cogoToast.error('We had an issue adding a new section, please try again.');
      return {
        ...state
      };

    case SectionActionTypes.UPDATE_SECTION_REQUEST:
      if (action.section.User_id !== state.profile.id) return state; // If admin editing another shop
      return {
        ...state,
        profile: {
          ...state.profile,
          sections: _.map(state.profile.sections, s =>
            s.id === action.section.id
              ? {
                  ...s,
                  ...action.updates
                }
              : s
          )
        }
      };

    case SectionActionTypes.UPDATE_SECTION_ERROR:
      if (action.section.User_id !== state.profile.id) return state; // If admin editing another shop
      cogoToast.error('We had an issue updating your section, please try again.');
      return {
        ...state,
        profile: {
          ...state.profile,
          sections: _.map(state.profile.sections, s => (s.id === action.section.id ? action.section : s))
        }
      };

    case SectionActionTypes.DELETE_SECTION_REQUEST:
      if (action.section.User_id !== state.profile.id) return state; // If admin editing another shop
      return {
        ...state,
        profile: {
          ...state.profile,
          sections: state.profile.sections.filter(s => s.id !== action.section.id)
        }
      };

    case SectionActionTypes.DELETE_SECTION_FAILURE:
      if (action.section.User_id !== state.profile.id) return state; // If admin editing another shop
      cogoToast.error('We had an issue deleting your section, please try again.');
      return {
        ...state,
        profile: {
          ...state.profile,
          sections: _.concat(state.profile.sections || [], action.section)
        }
      };

    // New Collections
    case StoreActionTypes.ADD_COLLECTION_SUCCESS:
      if (action.collection.User_id !== state.profile.id) return state; // If admin editing another shop
      return {
        ...state,
        profile: {
          ...state.profile,
          collections: _.concat(state.profile.collections || [], action.collection)
        }
      };

    case StoreActionTypes.EDIT_COLLECTION_SUCCESS:
      if (action.collection.User_id !== state.profile.id) return state; // If admin editing another shop
      return {
        ...state,
        profile: {
          ...state.profile,
          collections: _.map(state.profile.collections, c =>
            c.id === action.collection.id
              ? {
                  ...c,
                  ...action.collection
                }
              : c
          )
        }
      };

    case BrandActionTypes.ROTATE_BRAND_DEVELOPER_KEY_SUCCESS:
      return {
        ...newState,
        profile: {
          ...newState.profile,
          developerKey: action.developerKey
        }
      };

    default:
      return state;
  }
};
