import _ from 'lodash';
import slugify from 'slugify';
import moment from 'moment';

import { isSMSAffiliatePartnerBrand, getBrandLists, getBrand, getCompetitorBrands, getBrandBudgetRemaining, getOpportunities } from './user_helpers';
import { isOpportunityRequestEventuallyNeedingPayment, isOpportunityRequestOutstanding } from './opportunity_helpers';

export const getMerchant = brand => _.find(brand?.merchants, m => m.isSMSWinner);
export const parseSocialMentionTags = socialTags => socialTags?.split(',').map(t => t.trim()) || [];
export const getBrandSocialMentionTags = brand => parseSocialMentionTags(brand.socialTags);
export const getBrandSocialMentionTagsFromUser = user => getBrandSocialMentionTags(getBrand(user));
export const getCodesForUser = (analytics, artist) => artist && _.filter(analytics?.brandAnalytics?.customCodes, c => c.User_id === artist.id);
export const getAccountManagerForBrandUser = user => _.get(getBrand(user), 'account_manager');
export const getBrandLastLoggedIn = brand => _.get(brand, 'lastLoggedIn');
export const isFetchingBrandAnalytics = analytics => _.get(analytics, 'brandAnalytics.isFetchingAnalytics') || false;

/*
  Budget Related Helpers
*/
export const getAvailableBudgetForBrand = (analytics, user) => {
  // "Available" budget that is not allocated to active opportunities OR outstanding requests
  const totalBudget = getBrandBudgetRemaining(user) || 0;
  const budgetAwaitingOppCompletion = getBrandBudgetPendingOpportunityCompletion(analytics, user);
  const budgetOutstanding = getBrandBudgetPendingOutstandingRequests(analytics, user);
  const budgetAvailable = totalBudget - budgetAwaitingOppCompletion - budgetOutstanding;
  return budgetAvailable;
};

export const getBrandBudgetPendingOpportunityCompletion = (analytics, user) => {
  // "Awaiting Completion" budget that is allocated to active opportunities but not yet paid out
  const requests = getOpportunityRequestsAwaitingCompletion(analytics, user);
  return _.sumBy(requests, r => r.payment_tier?.fixedFee || 0);
};

export const getBrandBudgetPendingOutstandingRequests = (analytics, user) => {
  // "Offers Out" budget that is allocated to outstanding requests but creators have not yet accepted
  const requests = getOpportunityRequestsOutstanding(analytics, user);
  return _.sumBy(requests, r => r.payment_tier?.fixedFee || 0);
};

export const getOpportunityRequestsAwaitingCompletion = (analytics, user) => {
  const augmentedRequests = getOpportunityRequestsAugmentedWithOpportunityAndPaymentTier(analytics, user);
  const requests = augmentedRequests.filter(isOpportunityRequestEventuallyNeedingPayment);
  return requests;
};

export const getOpportunityRequestsAugmentedWithOpportunityAndPaymentTier = (analytics, user) => {
  /*
    The opportunity request objects for user are augmented with the opportunity and payment tier objects.

    This augments the values so you can use the standard helpers in opportunity_helpers.js.
  */
  const opportunities = getOpportunities(user);
  const paymentTiers = _.flatten(opportunities.map(o => o.payment_tiers));
  const allRequests = getOpportunityRequests(analytics);
  return allRequests.map(r => ({
    ...r,
    opportunity: _.find(opportunities, o => o.id === r.Opportunity_id),
    payment_tier: _.find(paymentTiers, t => t.id === r.OpportunityPaymentTier_id)
  }));
};

export const getOpportunityRequestsOutstanding = (analytics, user) => {
  /*
    Returns opportunity requests that can still be accepted by users, meaning:
      1) They haven't expired
      2) The opportunity hasn't ended
  */
  const allRequests = getOpportunityRequestsAugmentedWithOpportunityAndPaymentTier(analytics, user);

  const outstandingRequests = allRequests.filter(isOpportunityRequestOutstanding);

  return outstandingRequests;
};

export const isPinFromBrand = (pin, brandObject) => {
  /*
    Returns true if the pin references the brand either DTC or through a catalog
    brand match via another retailer.
  */
  const { product, domain } = pin;
  const { brand } = product || {};

  // DTC
  if (domain === brandObject.domain) return true;
  if (brandObject.altDomains?.includes(domain)) return true;

  // Indirect
  if (brand === brandObject.name) return true;
  if (brand && brandObject.altBrands?.includes(brand)) return true;

  return false;
};

export const getCompetitorBrandForPin = (pin, user) => {
  /*
    Returns a competitor brand object (name, domain) if the pin references the
    competitor.
  */
  return getCompetitorBrands(user).find(({ name, domain }) => {
    if (domain === pin.domain) return true;
    if (name === pin.product?.brand || name === pin.brand) return true; // Allow brand at either level of the pin
    return false;
  });
};

// Brand Lists
export const isInBrandList = (user, artist) => {
  let found = false; // Looping style for performance purposes
  const brandLists = getBrandLists(user);
  brandLists.forEach(list => {
    if (!found) {
      found = !!getListUserFromList(list, artist.id);
    }
  });
  return found;
};

export const doesTakePercentageFromUser = brand => {
  if (brand.network_rate) return false; // If we charge a rate, no need to take a cut
  if (brand.network_rate_gmv_cut) return false; // If we charge a rate, no need to take a cut
  if (brand.waive_network_rate) return false; // Allow waiving the network fee
  return true;
};

export const getAllBrandListsFoundIn = (user, artist, options = {}) => {
  // Return all the brand lists a user can be found in
  const { hideRecommendationLists } = options;
  const brandLists = getBrandLists(user);
  const listsToSearch = _.filter(brandLists, list => {
    if (hideRecommendationLists && list.isRecommendationList) return false;
    return true;
  });
  return listsToSearch.filter(list => !!getListUserFromList(list, artist?.id));
};
export const getListUserFromList = (list, User_id) => list.users.find(u => u.User_id === User_id);

export const sortBrandListsByRecency = lists => {
  /*
    Smart sorting of brand lists based on likelihood to want to access.
  */
  return _.orderBy(
    lists,
    list => {
      const lastAddedUser = _.orderBy(list.users, 'createdAt', 'desc')[0];
      return lastAddedUser?.createdAt || list.createdAt;
    },
    'desc'
  );
};

export const filterResultsWithLockedBrand = (results, lockedBrand) => {
  /*
    We only want to show the result from the retailer in the case of multiple results
    with the same defining product.
  */
  if (!lockedBrand) return null;
  const finalResults = [];
  let productToResultsMap = _.groupBy(results, 'Product_id');
  results.forEach(result => {
    const hasMultiple = result.Product_id && productToResultsMap[result.Product_id];
    const hasRetailerMatch = hasMultiple && _.find(productToResultsMap[result.Product_id], r => r.retailer === lockedBrand.name);
    const isRetailerMatch = hasRetailerMatch && result._id === hasRetailerMatch._id;
    hasRetailerMatch &&
      !isRetailerMatch &&
      console.info(`Found ${result.title} at ${result.retailer} but overriden by result at ${lockedBrand.name}.`);
    if (hasRetailerMatch && !isRetailerMatch) return;
    finalResults.push(result);
  });

  return finalResults;
};

export const getCustomRateForUser = (analytics, artist) => artist && _.find(analytics?.brandAnalytics?.customRates, r => r.User_id === artist.id);
export const getCustomRateForUserId = (analytics, User_id) => _.find(analytics?.brandAnalytics?.customRates, r => r.User_id === User_id);
export const getCustomCodeForUser = (analytics, artist) => artist && _.find(analytics?.brandAnalytics?.customCodes, c => c.User_id === artist.id);
export const getCustomCodeForUserId = (analytics, User_id) => _.find(analytics?.brandAnalytics?.customCodes, c => c.User_id === User_id);
export const getBrandRequests = analytics => analytics?.brandAnalytics?.requests || [];
export const getBrandRequestById = (analytics, id) => _.find(getBrandRequests(analytics), r => r.id === id);
export const getGiftingRequestsForUser = (analytics, user) =>
  user && _.filter(getBrandRequests(analytics), r => r.User_id === (user.User_id || user.id) && r.type === 'SAMPLES');
export const getGiftingRequestsForLookbook = (analytics, lookbook) =>
  lookbook && _.filter(getBrandRequests(analytics), r => r.Lookbook_id === lookbook.id && r.type === 'SAMPLES');
export const getGiftingRequestsForLookbookId = (analytics, Lookbook_id) => getGiftingRequestsForLookbook(analytics, { id: Lookbook_id });
export const getOpportunityRequests = analytics => analytics?.brandAnalytics?.opportunity_requests || [];
export const getOpportunityRequestsForOpportunity = (analytics, opportunity) =>
  getOpportunityRequests(analytics).filter(r => r.Opportunity_id === opportunity?.id);
export const getOpportunityRequestsForOpportunityAndUserId = (analytics, opportunity, User_id) =>
  getOpportunityRequests(analytics).filter(r => r.Opportunity_id === opportunity?.id && r.User_id === User_id);
export const getBrandOpportunityRequestById = (analytics, id) => _.find(getOpportunityRequests(analytics), r => r.id === id);

export const getStandardRateForArtist = user => {
  const brand = getBrand(user);
  const isSMSPartner = isSMSAffiliatePartnerBrand(user);
  const { commission_rate } = brand;
  const merchant = getMerchant(brand);
  return isSMSPartner ? commission_rate : merchant?.fullPayout || 0;
};

export const getBrandCommissionRateForUser = user => {
  const brand = getBrand(user);
  return getBrandCommissionRate(brand);
};
export const getBrandCommissionRate = brand => {
  const topMerchant = _.find(brand?.merchants, m => m.isSMSWinner);
  const ownCommissionRate = _.get(brand, 'commission_rate');
  const topCommissionRate = _.get(topMerchant, 'fullPayout');
  return ownCommissionRate || topCommissionRate;
};

export const getBrandUserTier = user => _.get(getBrand(user), 'tier');
export const getBrandTier = brand => brand?.tier;

export const getSlugifiedRegistrationCode = brandName => {
  /*
    This is used on both the front and back end so when updating this file ensure
    that the front end AND the admin code are updated to reflect the change and any necessary 
    updates are made to the existing RegistrationCodes for the new formatting.
  */
  return slugify(brandName + '-' + moment().year(), { replacement: '' }).toUpperCase();
};

export const getCalendlyLinkForBrand = (brand, type = 'general') => {
  return (
    {
      lookbooks: {
        'Courtney Woronka': 'https://calendly.com/courtney-shopmy/lookbooks',
        'Suzanne McHeffey': 'https://calendly.com/suzanne-shopmy/shopmy-lookbooks-demo',
        'Hannah Blake': 'https://calendly.com/hannah-shopmy/lookbooks',
        'Nicole Giusti': 'https://calendly.com/nicole-shopmy/lookbooks',
        'Kennedy Levee': 'https://calendly.com/kennedy-shopmy/lookbooks'
      }
    }[type]?.[brand.account_manager?.name] || 'https://calendly.com/courtney-shopmy/lookbooks'
  );
};

export const getBrandDomain = user => {
  const brand = getBrand(user);
  return brand?.domain || null;
};

export const isDomainDTCForBrandUser = (domain, user) => {
  const brand = getBrand(user);
  const domains = _.filter([brand.domain, ...(brand.altDomains || '').split(',').map(d => d.trim())]);
  return domains.includes(domain);
};

export const getBrandCustomAffiliateRates = analytics => analytics?.brandAnalytics?.customAffiliateRates || [];

// { Variant_id: { id: 1, rate: 10 }, ... }
export const getBrandCustomAffiliateRatesMap = analytics => {
  const rates = getBrandCustomAffiliateRates(analytics);

  const variantRatesMap = {};
  const variantSiblingRatesMap = {};
  const catalogGroupRatesMap = {};

  for (const customRate of rates) {
    const trimmedRateFields = {
      id: customRate.id,
      Brand_id: customRate.Brand_id,
      rate: customRate.rate,
      SpecificVariant_id: customRate.SpecificVariant_id,
      SpecificVariantSibling_id: customRate.SpecificVariantSibling_id,
      CatalogGroup_id: customRate.CatalogGroup_id
    };

    if (customRate.SpecificVariant_id) {
      variantRatesMap[customRate.SpecificVariant_id] = trimmedRateFields;
    } else if (customRate.SpecificVariantSibling_id) {
      variantSiblingRatesMap[customRate.SpecificVariantSibling_id] = trimmedRateFields;
    } else if (customRate.CatalogGroup_id) {
      catalogGroupRatesMap[customRate.CatalogGroup_id] = trimmedRateFields;
    }
  }

  return {
    variantRatesMap,
    variantSiblingRatesMap,
    catalogGroupRatesMap
  };
};

export const getDetailedCatalogGroupRatesMap = analytics => {
  const rates = getBrandCustomAffiliateRates(analytics);
  const catalogGroupRatesMap = {};

  for (const customRate of rates) {
    if (customRate.CatalogGroup_id) {
      catalogGroupRatesMap[customRate.CatalogGroup_id] = customRate;
    }
  }

  return catalogGroupRatesMap;
};

export const getBrandCustomAffiliateRateDisplayVariants = analytics => {
  // construct formatted variant rates for UI display
  const allCustomRates = getBrandCustomAffiliateRates(analytics);
  const formattedVariantRates = [];

  allCustomRates.forEach(customRate => {
    if ((customRate.SpecificVariant_id && customRate.basic_variant) || customRate.SpecificVariantSibling_id || customRate.basic_variant_sibling) {
      formattedVariantRates.push({
        id: customRate.id,
        createdAt: customRate.createdAt,
        rate: customRate.rate,
        SpecificVariant_id: customRate.SpecificVariant_id,
        SpecificVariantSibling_id: customRate.SpecificVariantSibling_id,
        variantImage:
          customRate.basic_variant?.image || customRate.basic_variant_sibling?.image || customRate.basic_variant_sibling?.basic_parent_variant?.image,
        variantTitle: customRate.basic_variant?.rawTitle || customRate.basic_variant_sibling?.basic_parent_variant?.rawTitle,
        variantBrand: customRate.basic_variant?.rawBrand || customRate.basic_variant_sibling?.basic_parent_variant?.rawBrand,
        variantNonSizeTag: customRate.basic_variant_sibling?.nonSizeTag,
        variantSizeTag: customRate.basic_variant_sibling?.sizeTag,
        ParentVariant_id: customRate.basic_variant_sibling?.Variant_id || customRate.basic_variant?.id
      });
    }
  });

  return _.orderBy(formattedVariantRates, ['createdAt'], ['desc']);
};
