import React from 'react';
import './CatalogVariant.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Image from '../General/Image';
import cn from 'classnames';
import { openCatalogProductModal } from '../../Actions/UIActions';
import Tooltip from '../General/Tooltip';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDomainFromUrl } from '../../Helpers/formatting';

const CatalogVariant = props => {
  const { variant, isSkeleton, shouldShowVariantDomains } = props;

  if (isSkeleton) {
    return (
      <div className='catalog-variant-container'>
        <div className='variant-image skeleton' />
        <div className='variant-meta'>
          <div className='variant-brand skeleton' />
          <div className='variant-title skeleton' />
        </div>
      </div>
    );
  }

  const handleCatalogProductModalOpen = () => {
    props.openCatalogProductModal(variant);
  };

  const isOutOfStock = variant.siblings.every(sibling => !sibling.isAvailable);

  return (
    <div className={cn('catalog-variant-container')} onClick={handleCatalogProductModalOpen}>
      <div className={cn('catalog-variant', { unavailable: isOutOfStock })}>
        <div className='variant-image'>
          <Image src={variant.image} alt={variant.rawTitle || 'Default'} />
        </div>
        <div className='variant-meta'>
          <div className='variant-brand'>{variant.rawBrand}</div>
          <div className='variant-title'>{variant.rawTitle}</div>
          <div className='variant-info'>
            {variant.siblings.length > 1 && (
              <>
                <div className='sibling-count'>{variant.siblings.length} Variants</div>
              </>
            )}
            {!!variant.url && (
              <div
                className='variant-external-link-container'
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                {!!shouldShowVariantDomains ? (
                  <>
                    {variant.siblings?.length > 1 && <span className='separator'>•</span>}
                    <Tooltip message={variant.hideFromPublicCatalog ? 'This item is not yet publically available' : ''}>
                      <a href={variant.url} target='_blank' rel='noreferrer' className='variant-domain'>
                        {getDomainFromUrl(variant.url)}
                      </a>
                    </Tooltip>
                  </>
                ) : (
                  <Tooltip message={variant.hideFromPublicCatalog ? 'This item is not yet publically available' : ''}>
                    <a href={variant.url} target='_blank' rel='noreferrer' className='variant-external-link'>
                      <FontAwesomeIcon icon={faExternalLink} />
                    </a>
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {isOutOfStock && (
        <div className='out-of-stock-overlay'>
          <div className='out-of-stock-pill'>Out of Stock</div>
        </div>
      )}
    </div>
  );
};

CatalogVariant.propTypes = {
  variant: PropTypes.object.isRequired,
  openCatalogProductModal: PropTypes.func.isRequired,
  isSkeleton: PropTypes.bool,
  shouldShowVariantDomains: PropTypes.bool
};

export default connect(null, {
  openCatalogProductModal
})(CatalogVariant);
