import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './CatalogGroup.scss';
import cn from 'classnames';
import Image from '../General/Image';
import { CATALOG_GROUP_TYPE_LABELS, SHOPIFY_CATALOG_GROUP_TYPES } from './Constants/catalog.js';
import { getDefaultImage } from '../../Helpers/ui_helpers.js';
import InlineRateInput from './InlineRateInput.js';

const CatalogGroup = props => {
  const {
    catalogGroup,
    baseCommissionRate,
    existingRate,
    saveCustomAffiliateRate,
    isSkeleton,
    handleDeleteCatalogGroup,
    isDeletingCatalogGroup,
    navigateToCatalogGroupProducts
  } = props;

  const [catalogGroupRate, setCatalogGroupRate] = useState('');
  const [isSavingRate, setIsSavingRate] = useState(false);

  useEffect(() => {
    setCatalogGroupRate(existingRate?.rate?.toString() || '');
  }, [existingRate]);

  if (isSkeleton) {
    return (
      <div className='catalog-group-container'>
        <div className='catalog-group-images-container skeleton' />
        <div className='catalog-group-content'>
          <div className='catalog-group-meta'>
            <div className='group-type skeleton' />
            <div className='group-name skeleton' />
          </div>
          <div className='catalog-group-actions skeleton'>
            <div className='skeleton' />
          </div>
        </div>
      </div>
    );
  }

  const { preview_images, variants_count } = catalogGroup;

  const handleSaveRate = async newRate => {
    if (isSavingRate) return;
    setIsSavingRate(true);
    await saveCustomAffiliateRate(catalogGroup.id, newRate);
    setIsSavingRate(false);
  };

  const previewImage = preview_images[0];

  return (
    <div key={catalogGroup.id} className='catalog-group-container'>
      <div className='catalog-group-images-container'>{previewImage && <Image src={previewImage} alt={'Catalog Group Image'} />}</div>
      <div className='catalog-group-content'>
        <div className='catalog-group-meta'>
          <div className={cn('group-type', { primary: SHOPIFY_CATALOG_GROUP_TYPES.includes(catalogGroup.type) })}>
            <Image
              src={SHOPIFY_CATALOG_GROUP_TYPES.includes(catalogGroup.type) ? 'https://static.shopmy.us/Icons/shopify.png' : getDefaultImage()}
              alt='Type Logo'
            />
            {CATALOG_GROUP_TYPE_LABELS[catalogGroup.type]}
          </div>
          <div className='group-name'>{catalogGroup.title}</div>
          <div className='group-data'>
            {`${variants_count || 0} product${variants_count === 1 ? '' : 's'}`}
            <span className='bullet'>{'•'}</span>
            <span className='clickable-group-data' onClick={() => navigateToCatalogGroupProducts(catalogGroup)}>
              View
            </span>
            {!!handleDeleteCatalogGroup && (
              <>
                <span className='bullet'>{'•'}</span>
                <span
                  className={cn('clickable-group-data', { disabled: isDeletingCatalogGroup })}
                  onClick={() => handleDeleteCatalogGroup(catalogGroup)}
                >
                  {isDeletingCatalogGroup ? 'Removing...' : 'Remove Group'}
                </span>
              </>
            )}
          </div>
        </div>
        <div className={cn('catalog-group-actions', { empty: !catalogGroupRate })}>
          <div className='action-text'>Group Rate</div>
          <InlineRateInput
            onRateChange={setCatalogGroupRate}
            placeholder={`${baseCommissionRate || ''}`}
            value={catalogGroupRate}
            isActionHidden={true}
            isActionDisabled={!existingRate && !isSavingRate}
            handleSaveRate={() => handleSaveRate(catalogGroupRate)}
            isDisabled={isSavingRate}
            handleRateAction={() => handleSaveRate('')}
            autoFocus={!existingRate}
          />
        </div>
      </div>
    </div>
  );
};

CatalogGroup.propTypes = {
  catalogGroup: PropTypes.object.isRequired,
  baseCommissionRate: PropTypes.number.isRequired,
  saveCustomAffiliateRate: PropTypes.func.isRequired,
  navigateToCatalogGroupProducts: PropTypes.func.isRequired,
  handleDeleteCatalogGroup: PropTypes.func,
  existingRate: PropTypes.object,
  isSkeleton: PropTypes.bool,
  isDeletingCatalogGroup: PropTypes.bool
};

export default CatalogGroup;
