import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './UpdateLookbookOrderModal.scss';
import Modal from '../../General/Modal';
import moment from 'moment';
import _ from 'lodash';
import cogoToast from 'cogo-toast';
import { updateLookbookOrder as updateLookbookOrderAPI } from '../../../APIClient/lookbooks';

import Select from 'react-select';
import SelectOption from '../../General/SelectOption';
import Loader from '../../Loader/Loader';

const UpdateLookbookOrderModal = props => {
  const { order, close, onUpdateCallback } = props;
  const { id: LookbookOrder_id, email, status } = order;

  const statusOptions = [
    { label: 'Pending', value: 'pending', sublabel: 'Order has not been placed on website yet' },
    { label: 'Submitted', value: 'submitted', sublabel: 'Order placed but has no shipping information' },
    { label: 'Shipped', value: 'shipped', sublabel: 'Order has been shipped' },
    { label: 'Delivered', value: 'delivered', sublabel: 'Order has been delivered' }
    // { label: 'Canceled', value: 'canceled', sublabel: 'Order has been canceled' } // not showing this to brands
  ];

  const [isSaving, setIsSaving] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState(order.trackingNumber || '');
  const [trackingUrl, setTrackingUrl] = useState(order.trackingUrl || '');
  const [statusOption, setStatusOption] = useState(statusOptions.find(option => option.value === status));
  const [trackingSource, setTrackingSource] = useState(order.trackingSource || '');
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState(moment(order.expectedDeliveryDate).format('YYYY-MM-DD') || '');

  const updateLookbookOrder = async () => {
    if (isSaving) return;
    else if (statusOption.value === status) return cogoToast.warn('Please select a new status');

    if (!trackingNumber || !trackingUrl || !trackingSource) return window.ALERT.warn('Please enter tracking details before updating order status');

    const updates = _.omitBy(
      {
        status: statusOption.value,
        trackingNumber,
        trackingUrl,
        trackingSource,
        ...(moment(expectedDeliveryDate).isValid() && { expectedDeliveryDate }) // only include expectedDeliveryDate if it's a valid date
      },
      _.isNil
    );

    try {
      await updateLookbookOrderAPI(LookbookOrder_id, updates);
      if (onUpdateCallback) onUpdateCallback(updates);
      cogoToast.success('Order updated');
      close();
    } catch (e) {
      console.error(e);
      cogoToast.error('Error saving order');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal
      visible={true}
      close={close}
      className='update-lookbook-order-modal-outer'
      innerClassName='update-lookbook-order-modal-inner'
      showClose={true}
      smallForm
    >
      <div className='update-lookbook-order-modal-content'>
        <div className='update-lookbook-order-modal-title'>Update Lookbook Order</div>
        <div className='update-lookbook-order-modal-subtitle'>all changes will be sent to {email}</div>

        <div className='update-lookbook-order-modal-form'>
          <div className='modal-form-input-container'>
            <div className='input-container-label'>Status</div>
            <Select
              className='select'
              value={statusOption}
              onChange={option => setStatusOption(option)}
              options={statusOptions}
              components={{ Option: SelectOption }}
            />
          </div>

          <div className='modal-form-input-container'>
            <div className='input-container-label'>Tracking Number</div>
            <input value={trackingNumber} onChange={e => setTrackingNumber(e.target.value)} />
          </div>

          <div className='modal-form-input-container'>
            <div className='input-container-label'>Tracking URL</div>
            <input value={trackingUrl} onChange={e => setTrackingUrl(e.target.value)} />
          </div>

          <div className='modal-form-input-container'>
            <div className='input-container-label'>Tracking Source</div>
            <input value={trackingSource} onChange={e => setTrackingSource(e.target.value)} />
          </div>

          <div className='modal-form-input-container'>
            <div className='input-container-label'>Expected Delivery Date</div>
            <input
              type='date'
              value={expectedDeliveryDate}
              onChange={e => setExpectedDeliveryDate(e.target.value)}
              onClick={e => e.stopPropagation()}
            />
          </div>
        </div>

        <div className='update-lookbook-order-modal-actions'>
          <div className='update-lookbook-order-modal-action' onClick={close}>
            Cancel
          </div>
          <div className='update-lookbook-order-modal-action save' onClick={updateLookbookOrder}>
            {isSaving ? <Loader size={20} /> : 'Save'}
          </div>
        </div>
      </div>
    </Modal>
  );
};

UpdateLookbookOrderModal.propTypes = {
  order: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  onUpdateCallback: PropTypes.func
};

export default UpdateLookbookOrderModal;
