import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import commaNumber from 'comma-number';
import './EarningsPortalPayments.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import Tooltip from '../General/Tooltip';

import { isAgent, getUserId, getStripeAccountId } from '../../Helpers/user_helpers';
import { getPrettyDate } from '../../Helpers/formatting';
import { PAYOUTS_DISCLAIMER_COPY } from '../../Helpers/constants';

const EarningsPortalPayments = props => {
  const { user, paymentsAreLoading } = props;
  const payments = _.get(user, 'payments', []);

  const paymentAccounts = _.get(user, 'payment_accounts', []);
  const stripeAccountId = getStripeAccountId(user);
  const hasPaymentAccount = !!paymentAccounts.length || !!stripeAccountId;

  const gridTemplateColumns = isAgent(user) ? '1fr 1fr 1fr 1fr' : '1fr 1fr 1fr';
  const [paymentsVisible, setPaymentsVisible] = useState(5);
  const hasMorePayments = payments.length > paymentsVisible;

  const payoutSummaries = _.get(user, 'payouts.payout_summaries.months', {});
  const monthlyTotals = useMemo(() => {
    const transformedTotals = Object.entries(payoutSummaries).map(([date, payoutSummary]) => {
      const { user_payout_total, user_payout_total_locked, user_payout_total_paid } = payoutSummary;
      const lockedPayoutsAreIncomplete = user_payout_total !== user_payout_total_locked && user_payout_total_locked > 0;

      return {
        date,
        amount: user_payout_total,
        lockedAmount: user_payout_total_locked,
        lockedAndNotPaidAmount: user_payout_total_locked - user_payout_total_paid,
        paidAmount: user_payout_total_paid,
        lockedTooltip: lockedPayoutsAreIncomplete ? PAYOUTS_DISCLAIMER_COPY : null
      };
    });

    const sortedTotals = _.orderBy(transformedTotals, ({ date }) => new Date(date), 'desc');
    return sortedTotals;
  }, [payoutSummaries]);
  const earnings = _.sum(_.map(monthlyTotals, 'amount'));

  return (
    <div className='earnings-portal-payments-outer'>
      <div className='earnings-portal-payments-inner'>
        <div className='earnings-portal-section-header'>
          <div>
            <div className='earnings-portal-section-title'>Payments</div>
          </div>
        </div>

        <div className='earnings-portal-table'>
          <div className='earnings-portal-table-header' style={{ gridTemplateColumns }}>
            <div className='earnings-portal-table-cell'>Sent On</div>
            {isAgent(user) ? (
              <>
                <div className='earnings-portal-table-cell'>Name</div>
                <div className='earnings-portal-table-cell'>Artist Amount</div>
                <div className='earnings-portal-table-cell'>Agent Amount</div>
              </>
            ) : (
              <div className='earnings-portal-table-cell'>Amount</div>
            )}
            <div className='earnings-portal-table-cell'>Source</div>
          </div>

          <div className='earnings-portal-table-body'>
            {paymentsAreLoading ? (
              new Array(5).fill(0).map((_, index) => {
                return (
                  <div key={index} className='earnings-portal-table-row' style={{ gridTemplateColumns }}>
                    <div className='earnings-portal-table-cell skeleton-cell'></div>
                    <div className='earnings-portal-table-cell skeleton-cell'></div>
                    <div className='earnings-portal-table-cell skeleton-cell'></div>
                    <div className='earnings-portal-table-cell skeleton-cell'></div>
                  </div>
                );
              })
            ) : payments.length ? (
              payments
                .slice(0, paymentsVisible)
                .map(({ amount, user_amount, agent_amount, transaction_fee, source, sent_date, name, User_id }, idx) => {
                  const agentHandlesTake = !user_amount && agent_amount;
                  const userEarningsAmount = agentHandlesTake ? agent_amount : user_amount;
                  const transactionFeeScaled =
                    (transaction_fee * (agentHandlesTake || isAgent(user) ? user_amount + agent_amount : user_amount)) / (amount - transaction_fee);
                  const infoTooltip = transactionFeeScaled && `$${transactionFeeScaled.toFixed(2)} Transaction Fee`;
                  return (
                    <div key={idx} className='earnings-portal-table-row' style={{ gridTemplateColumns }}>
                      <div className='earnings-portal-table-cell'>{getPrettyDate(sent_date)}</div>
                      {isAgent(user) ? (
                        User_id === getUserId(user) ? (
                          <>
                            <div className='earnings-portal-table-cell'>{name}</div>
                            <div className='earnings-portal-table-cell'>-</div>
                            <div className='earnings-portal-table-cell'>${user_amount && commaNumber(user_amount.toFixed(2))}</div>
                          </>
                        ) : (
                          <>
                            <div className='earnings-portal-table-cell'>{name}</div>
                            <div className='earnings-portal-table-cell'>${user_amount && commaNumber(user_amount.toFixed(2))}</div>
                            <div className='earnings-portal-table-cell'>{agent_amount ? `$${commaNumber(agent_amount.toFixed(2))}` : '-'}</div>
                          </>
                        )
                      ) : (
                        <div className='earnings-portal-table-cell'>
                          <div>${userEarningsAmount && commaNumber(userEarningsAmount.toFixed(2))}</div>
                        </div>
                      )}
                      <div className='earnings-portal-table-cell'>
                        <div>{source}</div>
                        {!!infoTooltip && (
                          <Tooltip message={infoTooltip} getIconDiv={() => <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>} />
                        )}
                      </div>
                    </div>
                  );
                })
            ) : !hasPaymentAccount ? (
              <div className='earnings-portal-empty-message'>PLEASE LINK AN ACCOUNT BELOW TO ENABLE PAYMENTS</div>
            ) : earnings > 10 ? (
              <div className='earnings-portal-empty-message'>PAYMENTS ARE SUBMITTED ON FRIDAY AFTERNOONS</div>
            ) : (
              <div className='earnings-portal-empty-message'>PAYMENTS START ONCE YOUR SHOP EARNS AT LEAST $10</div>
            )}

            {payments.length > 5 && !paymentsAreLoading && (
              <div className='earnings-portal-table-row' style={{ gridTemplateColumns: '1fr' }}>
                <div className='show-more' onClick={() => (hasMorePayments ? setPaymentsVisible(paymentsVisible + 5) : setPaymentsVisible(5))}>
                  {hasMorePayments ? 'Show More' : 'Show Less'}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

EarningsPortalPayments.propTypes = {
  user: PropTypes.object.isRequired,
  paymentsAreLoading: PropTypes.bool.isRequired
};

export default EarningsPortalPayments;
