import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isValidPhoneNumber } from 'libphonenumber-js';
import './LookbookOrderModal.scss';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { useLocation } from 'react-router-dom';

import Modal from '../../../General/Modal';
import Loader from '../../../Loader/Loader';
import LookbookOrderModalCart from './LookbookOrderModalCart';
import LookbookOrderModalShipping from './LookbookOrderModalShipping';
import LookbookOrderModalRegister from './LookbookOrderModalRegister';

import * as lookbookHelpers from '../../../../Helpers/lookbook_helpers';
import { hasRequestExpired } from '../../../../Helpers/gifting_helpers';
import { getRequestLookbookOrders, getAddress, getEmail, getRequestForLookbook, isLoggedIn } from '../../../../Helpers/user_helpers';

import { openAddressModal, openAuthModal } from '../../../../Actions/UIActions';
import { updateLookbookCart, createLookbookOrder } from '../../../../Actions/LookbookActions';
import { deleteRequest } from '../../../../Actions/UserActions';
import { confirmAlert } from 'react-confirm-alert';
import { getCountryCodeFromDisplay } from '../../../../Helpers/geo_helpers';
import Image from '../../../General/Image';

const LookbookOrderModal = props => {
  const { lookbook, user, lookbooks, openAddressModal, updateLookbookCart, createLookbookOrder, deleteRequest } = props;
  const history = useHistory();

  const location = useLocation();
  const cartItems = lookbookHelpers.getCartItemsForLookbook(lookbooks, lookbook);
  const request = getRequestForLookbook(user, lookbook);
  const order = request?.lookbook_order?.id ? request.lookbook_order : null;
  const isExpired = request && hasRequestExpired(request);
  const isShopMyUser = isLoggedIn(user);

  const userAddress = getAddress(user);
  const isAddressConfirmed = !!userAddress?.isConfirmed;
  const parsedAddress = JSON.parse(userAddress?.raw || null);
  const hasFirstAndLastName = !!parsedAddress?.firstName && !!parsedAddress?.lastName;
  const userAddressCountryCode = getCountryCodeFromDisplay(JSON.parse(userAddress?.raw || null)?.country);
  const isAddressInvalidForLookbook =
    isAddressConfirmed && !!lookbook.allowedCountryCodes && !lookbook.allowedCountryCodes.includes(userAddressCountryCode);
  const isPhoneInvalidForLookbook =
    !!lookbook.integrationSettings?.requireOrderPhoneNumber && (!userAddress?.phone || !isValidPhoneNumber(userAddress.phone, 'US'));

  const [email, setEmail] = useState('');
  const [additionalNote, setAdditionalNote] = useState('');
  const [curStep, setCurStep] = useState('order'); // cart, shipping, success
  const [stepsShouldBePaged, setStepsShouldBePaged] = useState(false);

  React.useEffect(() => {
    const breakpoint = 768;
    const showLargeScreenModal = window.innerWidth >= breakpoint;
    setStepsShouldBePaged(!showLargeScreenModal);
  }, [window.innerWidth]);

  // Quick View
  const [showingModal, setShowingModal] = useState(false);
  const toggleFullModal = () => setShowingModal(!showingModal);
  const hasItems = cartItems.length > 0;
  const { item_limit, price_limit } = lookbook;
  const overItemLimit = !!item_limit && lookbookHelpers.calculateLookbookCartItems(cartItems).cartItemCount > item_limit;
  const overPriceLimit = !!price_limit && lookbookHelpers.calculateLookbookCartItems(cartItems).cartPrice > price_limit;

  React.useEffect(() => {
    if (!hasItems) {
      setShowingModal(false);
    }
  }, [hasItems]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const submitOrder = async () => {
    if (overItemLimit) return window.ALERT.warn(`You may only select ${item_limit} items for this lookbook.`);
    else if (overPriceLimit) return window.ALERT.warn(`You may only select up to $${price_limit} worth of items for this lookbook.`);

    if (!request?.id) return window.ALERT.warn('We could not verify your invite to this lookbook.');
    if (isExpired) return window.ALERT.warn('This request has expired, please contact the brand via Chat for more information.');
    if (!isAddressConfirmed) return window.ALERT.warn('Please add an address before submitting your order.');
    if (!hasFirstAndLastName) return window.ALERT.warn('Please add your first and last name before submitting your order.');
    if (isAddressInvalidForLookbook) return window.ALERT.warn('This lookbook is only available to United States addresses.');
    if (isPhoneInvalidForLookbook) return window.ALERT.warn('Please add a valid phone number before submitting your order.');

    setIsSubmitting(true);

    const queryParams = new URLSearchParams(location.search);
    const LookbookInvite_hash = queryParams.get('invite');
    const siblingIds = [];
    for (const { sibling, quantity } of cartItems) {
      for (let i = 0; i < quantity; i++) siblingIds.push(sibling.id);
    }

    const order = {
      Request_id: request.id,
      Lookbook_id: lookbook.id,
      email,
      LookbookInvite_hash,
      additionalNote,
      siblingIds
    };
    const resp = await createLookbookOrder(order);
    if (resp.order) window.location.href = `/lookbooks/order/${resp.order.id}`;
    setIsSubmitting(false);
  };

  const getDeleteConfirmMessage = () => {
    if (isAddressInvalidForLookbook) return 'This lookbook is only available to United States addresses.';

    return '';
  };

  const handleRequestDelete = async () => {
    try {
      await deleteRequest(request.id);
      window.ALERT.success('Request deleted.');
      history.push('/partners/gifting');
    } catch (err) {
      console.error(err);
      window.ALERT.error('There was an issue deleting this request. Please try again.');
    }
  };

  const deleteGiftingRequest = () => {
    let deleteConfirmMessage = getDeleteConfirmMessage();

    if (deleteConfirmMessage) {
      deleteConfirmMessage = `${deleteConfirmMessage} `;
    }

    return confirmAlert({
      title: 'Delete Gifting Request',
      message: `${deleteConfirmMessage}Are you sure you want to delete this gifting request?`,
      buttons: [
        {
          label: 'Yes',
          onClick: handleRequestDelete
        },
        {
          label: 'No'
        }
      ]
    });
  };

  const close = () => {
    alert('not yet');
  };

  const canSubmit =
    isAddressConfirmed &&
    isShopMyUser &&
    !overItemLimit &&
    !overPriceLimit &&
    !isExpired &&
    !isAddressInvalidForLookbook &&
    hasFirstAndLastName &&
    !isPhoneInvalidForLookbook;
  const canDelete = isAddressInvalidForLookbook && isShopMyUser;

  React.useEffect(() => {
    if (isShopMyUser) {
      const mostRecentOrder = getRequestLookbookOrders(user)[0];
      setEmail(mostRecentOrder?.email || getEmail(user));
    }
  }, [isShopMyUser]);

  const showItemsPanel = !stepsShouldBePaged || curStep === 'order';
  const showShippingPanel = !stepsShouldBePaged || curStep === 'shipping';
  const { cartItemCount, cartPrice } = lookbookHelpers.calculateLookbookCartItems(cartItems);
  const currencyDisplay = lookbookHelpers.getLookbookCurrencySymbol(lookbook);

  if (!showingModal) {
    return (
      <div className={cn('lookbook-order-modal-quick-view-outer', { 'has-items': hasItems })}>
        <div className='background' />
        <div className='lookbook-order-modal-quick-view'>
          <div className='lookbook-order-modal-quick-view-product-previews'>
            {cartItems.slice(0, 4).map((cartItem, i) => {
              const { item, sibling } = cartItem;
              const image = sibling?.image || lookbookHelpers.getLookbookItemStartingImage(item);

              if (!image) return null;
              return (
                <Image
                  src={image}
                  alt='product'
                  key={sibling.id}
                  className='lookbook-order-modal-quick-view-product-preview'
                  useGenericFailedStyles
                />
              );
            })}
          </div>
          {overItemLimit && (
            <div className='limit-container'>
              <div className='limit-container-value'>
                {cartItemCount} / {item_limit}
              </div>
              <div className='limit-container-label'>item limit</div>
            </div>
          )}
          {overPriceLimit && (
            <div className='limit-container'>
              <div className='limit-container-value'>
                {`${currencyDisplay}${cartPrice}`} / {`${currencyDisplay}${price_limit}`}
              </div>
              <div className='limit-container-label'>price limit</div>
            </div>
          )}
          <div className={cn('open-modal-btn', { error: overItemLimit || overPriceLimit })} onClick={toggleFullModal}>
            Done Selecting{cartItemCount > 1 ? ` (${cartItemCount})` : ''}
          </div>
        </div>
      </div>
    );
  }

  if (!hasItems) return null;
  return (
    <Modal visible={showingModal} innerClassName='lookbook-order-modal-outer' showClose={true} noPadding={true} close={toggleFullModal}>
      <div className={cn('order-success', { show: !!order })}>
        <div className='order-success-inner'>
          <div className='animated-check-mark'>
            <FontAwesomeIcon className='checkmark' icon={faCheck} />
            <div className='spiky-circle'></div>
          </div>
          <div className='order-success-title'>Order Submitted</div>
          <div className='order-success-subtitle'>
            Thank you for your order! An email confirmation has been sent to <span className='email'>{email}</span> and you can check here for order
            updates / tracking information at any time.
          </div>
          <div className='continue-button' onClick={close}>
            Continue &gt;
          </div>
        </div>
      </div>
      <div className='lookbook-order-modal-inner'>
        <div className='body'>
          {stepsShouldBePaged && (
            <div className='body-header'>
              <div className='body-title'>{curStep === 'order' ? 'Confirm Your Selected Items' : 'Shipping Details'}</div>
              <div className='steps'>
                <div className={cn('step', { active: curStep === 'order' })} onClick={() => setCurStep('order')}>
                  Order
                </div>
                <div className='spacer'>&gt;</div>
                <div className={cn('step', { active: curStep === 'shipping' })} onClick={() => setCurStep('shipping')}>
                  Shipping
                </div>
              </div>
            </div>
          )}
          {showItemsPanel && <LookbookOrderModalCart lookbook={lookbook} lookbooks={lookbooks} updateLookbookCart={updateLookbookCart} />}
          {showShippingPanel &&
            (!isShopMyUser ? (
              <LookbookOrderModalRegister lookbook={lookbook} openAuthModal={props.openAuthModal} />
            ) : (
              <LookbookOrderModalShipping
                user={user}
                lookbook={lookbook}
                email={email}
                setEmail={setEmail}
                additionalNotes={additionalNote}
                setAdditionalNotes={setAdditionalNote}
                openAddressModal={openAddressModal}
              />
            ))}
          <div className='body-footer'>
            {overPriceLimit && (
              <div className='limit-container'>
                <div className='limit-container-value'>
                  {`${currencyDisplay}${lookbookHelpers.calculateLookbookCartItems(cartItems).cartPrice}`} / {`${currencyDisplay}${price_limit}`}
                </div>
                <div className='limit-container-label'>price limit</div>
              </div>
            )}
            {overItemLimit && (
              <div className='limit-container'>
                <div className='limit-container-value'>
                  {lookbookHelpers.calculateLookbookCartItems(cartItems).cartItemCount} / {item_limit}
                </div>
                <div className='limit-container-label'>item limit</div>
              </div>
            )}
            {stepsShouldBePaged && curStep === 'order' && (
              <div className='submit-order-btn button' onClick={() => setCurStep('shipping')}>
                Continue
              </div>
            )}
            {(!stepsShouldBePaged || curStep === 'shipping') && (
              <div className='footer-buttons'>
                {canDelete && (
                  <div className='delete-request-btn' onClick={deleteGiftingRequest}>
                    Delete
                  </div>
                )}
                <div className={cn('submit-order-btn button', { submitting: isSubmitting, disabled: !canSubmit })} onClick={submitOrder}>
                  {!isShopMyUser
                    ? 'Create Account or Log In'
                    : !isAddressConfirmed
                    ? 'Please Add Address'
                    : !hasFirstAndLastName
                    ? 'Please Add Name'
                    : isAddressInvalidForLookbook
                    ? 'Invalid Address for Lookbook'
                    : isPhoneInvalidForLookbook
                    ? 'Invalid Phone Number'
                    : overItemLimit
                    ? 'Over Item Limit'
                    : overPriceLimit
                    ? 'Over Price Limit'
                    : isExpired
                    ? 'Request Expired'
                    : 'Submit Order'}
                  {isSubmitting && <Loader size={64} />}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

LookbookOrderModal.propTypes = {
  // From Outside
  lookbook: PropTypes.object.isRequired,

  // From Redux
  user: PropTypes.object.isRequired,
  lookbooks: PropTypes.object.isRequired,
  openAddressModal: PropTypes.func.isRequired,
  openAuthModal: PropTypes.func.isRequired,
  updateLookbookCart: PropTypes.func.isRequired,
  createLookbookOrder: PropTypes.func.isRequired,
  deleteRequest: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { ui, user, lookbooks } = state;
  return { ui, user, lookbooks };
};

export default connect(mapStateToProps, {
  openAddressModal,
  openAuthModal,
  updateLookbookCart,
  createLookbookOrder,
  deleteRequest
})(LookbookOrderModal);
