import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { confirmAlert } from 'react-confirm-alert';
import './EarningsPortalPaymentAccounts.scss';

import { updateCurrentUser } from '../../Actions/UserActions';
import { linkPaypalAccount, unlinkPaymentAccount, linkInvoiceEmailAccount } from '../../Actions/PayoutActions';

import { getUserId, getUserPaymentAccounts, isBrand, getStripeAccountId } from '../../Helpers/user_helpers';
import { paypalAuthUrl } from '../../Helpers/payment_helpers';

const EarningsPortalPaymentAccounts = props => {
  const { user, updateCurrentUser } = props;

  const paymentAccounts = getUserPaymentAccounts(user);
  const stripeAccountId = getStripeAccountId(user);
  const gridTemplateColumns = '1fr 1fr 1fr';

  const getDisplaySource = source =>
    ({
      INVOICE_EMAIL: 'Email To Send Invoices For Commissions',
      PAYPAL_SUBSCRIPTION: 'Basic Subscription PayPal',
      PAYPAL_SUBSCRIPTION_PREMIUM: 'Premium Subscription PayPal'
    }[source] || source);

  const unlinkStripe = () => {
    confirmAlert({
      title: 'Just confirming',
      message: 'Are you sure you want to unlink your Stripe Account?',
      buttons: [
        { label: 'No', className: 'cancel', onClick: () => {} },
        {
          label: 'Yes',
          onClick: () => {
            updateCurrentUser({ stripeAccountId: null, stripeOnboardingComplete: false }, getUserId(user));
          }
        }
      ]
    });
  };

  return (
    <div className='earnings-portal-payment-accounts-outer'>
      <div className='earnings-portal-payment-accounts-inner'>
        <div className='earnings-portal-section-header'>
          <div>
            <div className='earnings-portal-section-title'>Linked Accounts</div>
            <div className='earnings-portal-section-subtitle'>
              Please link your PayPal account below. If you cannot successfully link your PayPal account, please go to{' '}
              <Link to='settings'>account settings</Link> to link a bank account using Stripe.
            </div>
          </div>
        </div>

        <div className='earnings-portal-table'>
          <div className='earnings-portal-table-header' style={{ gridTemplateColumns }}>
            <div className='earnings-portal-table-cell'>Type</div>
            <div className='earnings-portal-table-cell'>Account</div>
            <div className='earnings-portal-table-cell'>Action</div>
          </div>

          <div className='earnings-portal-table-body'>
            {stripeAccountId && (
              <div className='earnings-portal-table-row' style={{ gridTemplateColumns }}>
                <div className='earnings-portal-table-cell'>STRIPE</div>
                <div className='earnings-portal-table-cell'>{stripeAccountId}</div>
                <div className='earnings-portal-table-cell btn' onClick={unlinkStripe}>
                  REMOVE
                </div>
              </div>
            )}

            {_.map(paymentAccounts, account => {
              const { paypalEmail, email, address, paypalSubscriptionId, source } = account;
              return (
                <div key={account.id} className='earnings-portal-table-row' style={{ gridTemplateColumns }}>
                  <div className='earnings-portal-table-cell'>{getDisplaySource(source)}</div>
                  <div className='earnings-portal-table-cell'>{paypalEmail || email || address || paypalSubscriptionId}</div>
                  <Link to='/settings?tab=Connected+Accounts' className='earnings-portal-table-cell btn'>
                    REMOVE
                  </Link>
                </div>
              );
            })}

            {!_.get(paymentAccounts, 'length') && (
              <div className='earnings-portal-table-row' style={{ gridTemplateColumns }}>
                <div className='earnings-portal-table-cell'>{isBrand(user) ? 'Commission Invoices - PayPal' : 'PAYPAL'}</div>
                <div className='earnings-portal-table-cell'>-</div>
                <a href={paypalAuthUrl} target='_blank' rel='noreferrer' className='earnings-portal-table-cell btn loaded'>
                  LINK ACCOUNT
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

EarningsPortalPaymentAccounts.propTypes = {
  user: PropTypes.object.isRequired,
  linkPaypalAccount: PropTypes.func.isRequired,
  unlinkPaymentAccount: PropTypes.func.isRequired,
  updateCurrentUser: PropTypes.func.isRequired,
  linkInvoiceEmailAccount: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  linkPaypalAccount,
  unlinkPaymentAccount,
  updateCurrentUser,
  linkInvoiceEmailAccount
})(EarningsPortalPaymentAccounts);
