import React from 'react';
import _ from 'lodash';
import './CatalogCreatorRateRow.scss';
import PropTypes from 'prop-types';
import ProfilePhoto from '../Profile/ProfilePhoto';
import { setCustomRate } from '../../Helpers/rate_helpers';

const CatalogCreatorRateRow = props => {
  const { talent, analytics, ui, user, openArtistModal, isSkeleton } = props;

  if (isSkeleton) {
    return (
      <div className='catalog-creator-rate-row'>
        <div className='profile-img-wrapper skeleton' />
        <div className='rate-content'>
          <div className='rate-info skeleton'>
            <div className='rate-label skeleton' />
            <div className='rate-meta skeleton' />
          </div>
        </div>
      </div>
    );
  }

  const existingRate = _.find(analytics.brandAnalytics?.customRates, r => r.User_id === talent.id);

  const handleEditCreatorRate = () => {
    setCustomRate({
      user,
      artist: talent,
      analytics: analytics,
      setCustomCommissionRate: props.setCustomCommissionRate
    });
  };

  const viewTalent = () => {
    openArtistModal(talent);
  };

  return (
    <div className='catalog-creator-rate-row'>
      <div className='profile-img-wrapper'>
        <ProfilePhoto ui={ui} profile={talent} basic={true} />
      </div>
      <div className='rate-content'>
        <div className='rate-info'>
          <div className='rate-label'>{talent.name}</div>
          <div className='rate-meta'>
            <div className='meta-shop'>@{talent.username}</div>
            <span className='bullet'>{'•'}</span>
            <div className='view-talent' onClick={viewTalent}>
              View Talent
            </div>
          </div>
        </div>
        <div className='rate-action-container'>
          <div className='rate-container'>
            <div className='rate'>
              {existingRate?.rate || '—'}
              <span>%</span>
            </div>
            <span className='rate-label'>Commission</span>
          </div>
          <div className='rate-action' onClick={handleEditCreatorRate}>
            Edit
          </div>
        </div>
      </div>
    </div>
  );
};

CatalogCreatorRateRow.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  talent: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  isSkeleton: PropTypes.bool
};

export default CatalogCreatorRateRow;
