import { getAdjPayoutRate, getAmazonCode } from './user_helpers';
import { getRateDisplay } from './formatting';
import _ from 'lodash';

export const getDisclaimerForMerchant = (merchant, user, matchingVariant = {}) => {
  if (!merchant) return null;

  let disclaimerStr = merchant.disclaimer || '';
  if (disclaimerStr && disclaimerStr[disclaimerStr.length - 1] !== '.') {
    disclaimerStr += '. ';
  }

  if (matchingVariant?.id) {
    // disclaimer to flag sibling-level custom affiliate rates for a variant
    const merchantCustomAffiliateRates = _.get(merchant, 'brand.custom_affiliate_rates', []);
    const variantSiblingCustomAffiliateRates = merchantCustomAffiliateRates
      .filter(customRate => customRate.basic_variant_sibling?.Variant_id === matchingVariant.id)
      .map(customRate => customRate.rate);
    const sortedRateValues = _.sortBy(_.uniq(variantSiblingCustomAffiliateRates));

    if (sortedRateValues.length) {
      const rateDisplayStr =
        sortedRateValues.length === 1 ? `${sortedRateValues[0]}%` : `${sortedRateValues[0]}% - ${sortedRateValues[sortedRateValues.length - 1]}%`;
      disclaimerStr += `Certain variations pay out ${rateDisplayStr}. `;
    }
  }

  if (merchant.source === 'shopmyshelf') {
    const brand = JSON.parse(merchant.raw);
    if (brand.commission_rate_returning) {
      const adjRate = getAdjPayoutRate(user, merchant, matchingVariant);
      const adjRateDisplay = getRateDisplay(adjRate, merchant);
      const returningRateDisplay = getRateDisplay(brand.commission_rate_returning, merchant);
      const returningRateDisclaimer = `The rate of ${adjRateDisplay} applies only to new customers. For returning customers you will receive a rate of ${returningRateDisplay}.`;
      disclaimerStr += `${returningRateDisclaimer}`;
    }
  }

  return disclaimerStr.trim() || null;
};

/*
  Highlight disclaimer when there is a merchant.brand.custom_affiliate_rate on sibling-level for a variant
*/
export const getDisclaimerForMerchantForVariant = (merchant, user, variant) => {};

export const getMerchantHomeUrl = merchant => {
  const wwwDomainOverrides = ['aliceandolivia.com', 'abercrombie.com'];
  if (wwwDomainOverrides.includes(merchant.domain)) {
    return `https://www.${merchant.domain}`;
  }

  return `https://${merchant.domain}`;
};

export const isAmazonProgramLink = (link, user) => {
  /*
    Amazon Associates links can be identified by the following:
      - amzn.to (Short Links)
      - amazon.com with a tag query parameter (Long Links)
  */
  if (!link) return false;
  if (link.includes('amzn.to')) return true;
  if (link.includes('amazon.') && link.includes('tag=')) return true;
  if (link.includes('amazon.') && getAmazonCode(user)) return true;
  return false;
};
