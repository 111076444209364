import React from 'react';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import cn from 'classnames';
import './SnapshopExplainerPanel.scss';
import { isSafari } from 'react-device-detect';
import { Link } from 'react-router-dom';

import snapshopExplainerElement from '../../static/images/add_pin_modal/snapshop_screenshot.png';
import { isLoggedIn, getUserHash, isManager } from '../../Helpers/user_helpers';

import { getSnapshopBookmarkContent } from '../../Helpers/snapshop_helpers';

const SnapshopExplainerPanel = props => {
  const { fullPanel, user, openAuthModal } = props;
  const userHash = getUserHash(user);

  const clickBookmark = () => {
    cogoToast.warn(isMobile ? `Snapshop only works on desktop.` : `Please drag this to your bookmarks bar!`);
  };

  return (
    <div className={cn('snapshop-explainer-panel-outer-container', { 'full-panel': fullPanel })}>
      <img className='preview' src={snapshopExplainerElement} alt='Snapshop Preview' />
      <div className='description-container'>
        <div className='header-wrapper'>
          <div className='header'>Easy linking with Snapshop</div>
          <div className='new-badge'>New!</div>
        </div>
        <div className='subheader'>Add links directly from any website in seconds:</div>
        <div className='bullets'>
          <div className='bullet'>
            <div className='number'>1</div>
            <div className='task'>Drag the Snapshop button below to your bookmarks bar.</div>
          </div>

          {isSafari && (
            <>
              <div className='bullet'>
                <div className='number'>2</div>
                <div className='task'>Click "Safari" in Safari menu &#8594; Preferences &#8594; Advanced &#8594; Show Development Bar</div>
              </div>
              <div className='bullet'>
                <div className='number'>3</div>
                <div className='task'>Click "Develop" in Safari menu &#8594; Disable Cross-Origin Restrictions</div>
              </div>
              <div className='bullet'>
                <div className='number'>4</div>
                <div className='task'>Go a product page on any retailer website.</div>
              </div>
              <div className='bullet'>
                <div className='number'>5</div>
                <div className='task'>Click on Snapshop from your bookmarks to create a link.</div>
              </div>
            </>
          )}

          {!isSafari && (
            <>
              <div className='bullet'>
                <div className='number'>2</div>
                <div className='task'>Go a product page on any retailer website.</div>
              </div>
              <div className='bullet'>
                <div className='number'>3</div>
                <div className='task'>Click on Snapshop from your bookmarks to create a link.</div>
              </div>
            </>
          )}
        </div>
        {!isLoggedIn(user) ? (
          <div className='login-btn' onClick={() => openAuthModal('login')}>
            Login To Your Account First
          </div>
        ) : isManager(user) ? (
          <Link className='login-btn' to='settings?tab=My+Talent'>
            Go To My Talent
          </Link>
        ) : (
          <a onClick={clickBookmark} className='drag-container' href={getSnapshopBookmarkContent(userHash)}>
            <div className='drag-label'>Snapshop</div>
          </a>
        )}
        <div className='need-help'>
          {isManager(user) ? (
            <>Snapshop is only available for talent accounts.</>
          ) : (
            <>
              Need help? Click{' '}
              <a target='_blank' rel='noopener noreferrer' href='https://guide.shopmy.us/snapshop'>
                here
              </a>{' '}
              to learn more.
            </>
          )}
        </div>
      </div>
    </div>
  );
};

SnapshopExplainerPanel.propTypes = {
  user: PropTypes.object.isRequired,
  openAuthModal: PropTypes.func.isRequired,
  fullPanel: PropTypes.bool
};

export default SnapshopExplainerPanel;
