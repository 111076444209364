import APIClient from './index';
import _ from 'lodash';

// CustomAffiliateRates
export const addCustomAffiliateRate = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/CustomAffiliateRates`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const updateCustomAffiliateRate = updates => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/CustomAffiliateRates/${updates.id}`, updates)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const deleteCustomAffiliateRateById = id => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/CustomAffiliateRates/${id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};
