import APIClient from './index';
import _ from 'lodash';

// CatalogGroups
export const addCatalogGroup = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/CatalogGroups`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const deleteCatalogGroupById = id => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/CatalogGroups/${id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const getCatalogGroups = Brand_id => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/CatalogGroups/${Brand_id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};
