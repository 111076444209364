import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import cogoToast from 'cogo-toast';
import './SimpleAuthPanel.scss';

import { applyToWaitlist, registerUser } from '../../Actions/UserActions';
import { openAuthModal } from '../../Actions/UIActions';

import Loader from '../Loader/Loader';

const TESTING = false;

const SimpleAuthPanel = props => {
  const { inverted, ReferringOAuthApplication, isWaitlist, authSubmitCallback } = props;
  const randomNum = (Math.random() * 1000000).toFixed(0);

  const [name, setName] = React.useState(TESTING ? `First ${randomNum}` : '');
  const [username, setUsername] = React.useState(TESTING ? randomNum : '');
  const [email, setEmail] = React.useState(TESTING ? `${randomNum}@shopmy.us` : '');
  const [password, setPassword] = React.useState(TESTING ? `${randomNum}abc` : '');
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const additionalClasses = { inverted };

  const getInputField = ({ display, value, onChange, type, placeholder }) => {
    return (
      <div className={cn('input-section', additionalClasses)}>
        <div className='label'>{display}</div>
        <input placeholder={placeholder} type={type} value={value} onChange={e => onChange(e.target.value)} />
      </div>
    );
  };

  const register = async () => {
    // all fields required
    if (!email) return window.ALERT.error('Please enter an email');
    if (!password) return window.ALERT.error('Please enter a password');
    if (!name) return window.ALERT.error('Please enter a name');
    if (!username) return window.ALERT.error('Please enter a username');

    const cleanEmail = email.trim();
    const cleanUsername = username.trim();
    const cleanName = name.trim();

    const result = await props.registerUser({
      password,
      email: cleanEmail,
      username: cleanUsername,
      name: cleanName,
      ...(props.ReferringBrand_id ? { ReferringBrand_id: props.ReferringBrand_id } : {}),
      ...(props.ReferringLookbook_id ? { ReferringLookbook_id: props.ReferringLookbook_id } : {}),
      ...(props.LookbookInvite_hash ? { LookbookInvite_hash: props.LookbookInvite_hash } : {}),
      ...(ReferringOAuthApplication
        ? { ReferringOAuthApplication_id: ReferringOAuthApplication.id, code: ReferringOAuthApplication.registrationCode }
        : {})
    });

    return { success: result?.success ?? false };
  };

  const apply = async () => {
    // only email and name required for applying to waitlist
    if (!email) return window.ALERT.error('Please enter an email');
    if (!name) return window.ALERT.error('Please enter a name');

    try {
      const result = await props.applyToWaitlist({
        name: name.trim(),
        email: email.trim(),
        ...(ReferringOAuthApplication ? { pitch: `User applying from app ${ReferringOAuthApplication.appName}` } : {}),
        ...(ReferringOAuthApplication ? { OAuthApplication_id: ReferringOAuthApplication.id } : {})
      });

      return { success: result?.data?.success ?? false };
    } catch (e) {
      console.error('Error applying to waitlist: ', e);
      cogoToast.error('Error applying to waitlist', { hideAfter: 5 });
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (isSubmitting) return true;

    let result;

    setIsSubmitting(true);
    if (isWaitlist) {
      result = await apply();
    } else {
      result = await register();
    }
    setIsSubmitting(false);

    if (authSubmitCallback) {
      authSubmitCallback({ ...result, isWaitlist: !!isWaitlist });
    }
  };

  return (
    <div className={cn('simple-auth-panel-outer-container', additionalClasses)}>
      <form onSubmit={handleSubmit}>
        <div className='input-sections'>
          {getInputField({
            display: 'Name',
            placeholder: 'Enter Name',
            value: name,
            onChange: setName,
            type: 'text'
          })}
          {!isWaitlist &&
            getInputField({
              display: 'Username',
              placeholder: 'Username',
              value: username,
              onChange: newUsername => setUsername(newUsername.toLowerCase().replace(/[^A-Z a-z0-9]/, '')),
              type: 'text'
            })}
          {getInputField({
            display: 'Email',
            placeholder: 'Enter Email',
            value: email,
            onChange: setEmail,
            type: 'email'
          })}
          {!isWaitlist &&
            getInputField({
              display: 'Password',
              placeholder: 'Enter Password',
              value: password,
              onChange: setPassword,
              type: 'password'
            })}
        </div>
        <div className='auth-buttons'>
          <button onClick={handleSubmit} type='submit' className={cn('auth-button', additionalClasses, { registering: isSubmitting })}>
            {isWaitlist ? 'Apply' : 'Create Account'}
            {isSubmitting && <Loader size={60} />}
          </button>
          {!ReferringOAuthApplication && (
            <div onClick={() => props.openAuthModal('login')} className={cn('auth-button text', additionalClasses)}>
              {isWaitlist ? 'Already have an account?' : 'I Have an Account'}
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

SimpleAuthPanel.propTypes = {
  user: PropTypes.object.isRequired,
  registerUser: PropTypes.func.isRequired,
  applyToWaitlist: PropTypes.func.isRequired,
  openAuthModal: PropTypes.func.isRequired,

  // Optional from outside
  inverted: PropTypes.bool,
  ReferringBrand_id: PropTypes.number,
  ReferringLookbook_id: PropTypes.number,
  ReferringOAuthApplication: PropTypes.object,
  authSubmitCallback: PropTypes.func
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  openAuthModal,
  registerUser,
  applyToWaitlist
})(SimpleAuthPanel);
