import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import './GeneralProfileSettingsPanel.scss';

import {
  getProfileFromObject,
  isBrand,
  getBrand,
  isSimulatingUser,
  getAddress,
  isSMSAffiliatePartnerBrand,
  isSubscribedBrand,
  getBrandWinningMerchant,
  isManager
} from '../../../../Helpers/user_helpers';
import { getAllIntegrationsHaveCustomerScopesEnabled } from '../../../../Helpers/shopify_helpers.js';
import { doesTakePercentageFromUser } from '../../../../Helpers/brand_helpers';
import { isValidEmail, getDisplaySourceFromMerchant } from '../../../../Helpers/formatting';
import { updateCurrentUser } from '../../../../Actions/UserActions';
import { openAddressModal } from '../../../../Actions/UIActions';
import { updateBrand } from '../../../../Actions/BrandActions';
import { doesUserExist as doesUserExistAPI } from '../../../../APIClient/users';

import Image from '../../../General/Image';
import ImageUploader from '../../../General/ImageUploader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';

const GeneralProfileSettingsPanel = props => {
  const { user } = props;
  const profile = getProfileFromObject(user);
  const brand = getBrand(user);

  const [isSaving, setIsSaving] = useState(false);

  // all settings
  const [image, setImage] = useState(profile?.image || '');
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [email, setEmail] = useState(profile?.email || '');
  const [username, setUsername] = useState(profile?.username || '');
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordIsViewable, setPasswordIsViewable] = useState(false);
  const [amazonCode, setAmazonCode] = useState(profile?.amazonCode || '');
  const fullAddress = getAddress(user);
  const address = fullAddress?.address;
  const phone = fullAddress?.phone;

  // brand settings
  const [requestResponseEmail, setRequestResponseEmail] = useState(profile?.requestsEmail || '');
  const [commissionRate, setCommissionRate] = useState(getBrandWinningMerchant(user)?.fullPayout);
  const [commissionRateReturningCustomer, setCommissionRateReturningCustomer] = useState(
    getBrand(user)?.commission_rate_returning ? `${getBrand(user)?.commission_rate_returning}%` : ''
  );
  const [showingReturningCommissionRateInput, setShowingReturningCommissionRateInput] = useState(false);
  const isUnsubscribedBrand = !isSubscribedBrand(user) && !window.__ADMIN_CONTROL_MODE__;
  const visibleSMSRate = ((parseFloat(commissionRate) || 0) * 0.82).toFixed(1);

  const canUpdateEmail = email !== profile?.email && isValidEmail(email);
  const canUpdateUsername = username !== profile?.username && username.length;
  const newPasswordIsValid = newPassword.length >= 3 && !!oldPassword.length;
  const canUpdateRequestResponseEmail =
    requestResponseEmail !== profile?.requestsEmail && (!requestResponseEmail || requestResponseEmail.split(',').every(email => isValidEmail(email)));
  const canUpdateAmazonCode = !!amazonCode.length && amazonCode !== profile?.amazonCode;
  const canUpdateCommissionRate = parseInt(commissionRate) !== getBrandWinningMerchant(user)?.fullPayout && parseInt(commissionRate) > 0;
  const canUpdateCommissionRateReturningCustomer = parseInt(commissionRateReturningCustomer) !== getBrand(user)?.commission_rate_returning;

  const updateEmail = async () => {
    if (isSaving) return window.ALERT.warn('Already saving, please wait.');
    else if (!canUpdateEmail) return window.ALERT.warn('You must enter a valid email address to update.');

    const existingUserResponse = await doesUserExistAPI({ email });
    if (existingUserResponse.exists) return window.ALERT.error('Email already exists, please use another.');

    setIsSaving(true);
    const response = await props.updateCurrentUser({ email }, profile.id);
    setIsSaving(false);

    if (response?.error) window.ALERT.error(response.error);
    else window.ALERT.success(`Email updated to ${email}`);
  };

  const updateUsername = async () => {
    if (isSaving) return window.ALERT.warn('Already saving, please wait.');
    else if (!canUpdateUsername) return window.ALERT.warn('You must enter a valid username to update.');

    const existingUserResponse = await doesUserExistAPI({ username });
    if (existingUserResponse.exists) return window.ALERT.error('Username already exists, please choose another.');

    setIsSaving(true);
    const response = await props.updateCurrentUser({ username }, profile.id);
    setIsSaving(false);

    if (response?.error) window.ALERT.error(response.error);
    else window.ALERT.success(`Username updated to "${username}"`);
  };

  const updatePassword = async () => {
    if (isSimulatingUser(user)) return window.ALERT.warn('Cannot update password while simulating user.');
    else if (isSaving) return window.ALERT.warn('Already saving, please wait.');
    else if (!oldPassword.length) return window.ALERT.warn('You must enter your old password to update.');
    else if (!newPasswordIsValid) return window.ALERT.warn('You must enter a valid new password to update.');

    try {
      setIsSaving(true);
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(currentUser, oldPassword, newPassword);

      setIsEditingPassword(false);
      setNewPassword('');
      window.ALERT.success('Password updated successfully.');
    } catch (err) {
      window.ALERT.error(err?.message || 'Incorrect password.');
    } finally {
      setIsSaving(false);
      setOldPassword(newPassword); // for quick re-entry
    }
  };

  const updateRequestResponseEmail = async () => {
    if (isSaving) return window.ALERT.warn('Already saving, please wait.');
    else if (!canUpdateRequestResponseEmail) return window.ALERT.warn('You must enter a valid email address to update.');

    setIsSaving(true);
    const response = await props.updateCurrentUser({ requestsEmail: requestResponseEmail }, profile.id);
    setIsSaving(false);

    if (response?.error) window.ALERT.error(response.error);
    else window.ALERT.success(`Request response email updated to ${requestResponseEmail}`);
  };

  const updateCommissionRate = async () => {
    if (isSaving) return window.ALERT.warn('Already saving, please wait.');
    else if (!canUpdateCommissionRate && !canUpdateCommissionRateReturningCustomer)
      return window.ALERT.warn('You must enter a valid commission rate to update.');
    else if (isUnsubscribedBrand) return window.ALERT.error('Your account is currently inactive, you must upgrade your account to update your rate.');

    const allShopifyIntegrationsHaveCustomerScopesEnabled = getAllIntegrationsHaveCustomerScopesEnabled(user);
    if (canUpdateCommissionRateReturningCustomer && !allShopifyIntegrationsHaveCustomerScopesEnabled)
      return window.ALERT.error('You must enable the "read_customers" scope in your Shopify integration to set a returning customer rate.');

    setIsSaving(true);
    const data = {
      ...(canUpdateCommissionRate ? { commission_rate: parseInt(commissionRate) } : {}),
      ...(canUpdateCommissionRateReturningCustomer ? { commission_rate_returning: parseInt(commissionRateReturningCustomer) } : {})
    };
    const response = await props.updateBrand(brand, data);
    setIsSaving(false);

    if (response?.error) window.ALERT.error(response.error);
    else window.ALERT.success('Commission rate updated');
  };

  const updateAmazonCode = async () => {
    if (isSaving) return window.ALERT.warn('Already saving, please wait.');
    if (!amazonCode) return window.ALERT.error('Please enter a valid Amazon code');
    if (amazonCode.includes('https://')) return window.ALERT.error('Only make sure to paste in the code in the format my-code-20, not the whole URL');

    setIsSaving(true);
    const response = await props.updateCurrentUser({ amazonCode }, profile.id);
    setIsSaving(false);

    if (response?.error) window.ALERT.error(response.error);
    else window.ALERT.success(`Amazon code updated to ${amazonCode}`);
  };

  return (
    <div className='general-profile-settings-panel-outer'>
      <ImageUploader
        isVisible={isUploadingImage}
        setIsVisible={setIsUploadingImage}
        initialImageUrl={profile?.image}
        onSaveCallback={image_url => {
          setImage(image_url);
          props.updateCurrentUser({ image: image_url }, profile.id);
        }}
      />
      <div className='general-profile-settings-panel-inner'>
        {!isBrand(user) && (
          <div className='settings-section'>
            <div className='general-profile-settings-panel-image' onClick={() => setIsUploadingImage(true)}>
              <Image src={image} className='general-profile-settings-panel-profile-image' useGenericFailedStyles />

              <div className='general-profile-settings-panel-image-details'>
                <div className='general-profile-settings-panel-image-details-name'>{profile?.name}</div>
                <button className='general-profile-settings-panel-image-details-button'>Change Profile Photo</button>
              </div>
            </div>
          </div>
        )}

        <div className='settings-section'>
          <div className='settings-section-title'>Primary Email</div>
          <div className='settings-section-subtitle'>This is the email you use to log in and receive notifications.</div>
          <div className='general-profile-settings-panel-split-input'>
            <input className='settings-input' type='text' placeholder='Email' value={email} onChange={e => setEmail(e.target.value)} />
            {canUpdateEmail && (
              <button className='settings-button dark' onClick={updateEmail} disabled={!canUpdateEmail}>
                Update
              </button>
            )}
          </div>
        </div>

        {!isBrand(user) && (
          <div className='settings-section'>
            <div className='settings-section-title'>Username</div>
            <div className='settings-section-subtitle'>This is the username that will be used to identify your public shop url.</div>
            <div className='general-profile-settings-panel-split-input'>
              <input className='settings-input' type='text' placeholder='Username' value={username} onChange={e => setUsername(e.target.value)} />
              {canUpdateUsername && (
                <button className='settings-button dark' onClick={updateUsername} disabled={!canUpdateUsername}>
                  Update
                </button>
              )}
            </div>
          </div>
        )}

        <div className='settings-section'>
          <div className='settings-section-title'>Password</div>
          <div className='general-profile-settings-panel-split-input'>
            <div className='general-profile-settings-panel-split-input-password'>
              <input
                className='settings-input'
                type={passwordIsViewable ? 'text' : 'password'}
                placeholder={isEditingPassword ? 'Old Password' : '•••••••••••••••••••••••'}
                value={oldPassword}
                onChange={e => setOldPassword(e.target.value)}
                disabled={!isEditingPassword}
              />
              {isEditingPassword && (
                <FontAwesomeIcon
                  icon={passwordIsViewable ? faEye : faEyeSlash}
                  className='password-view-icon'
                  onClick={() => setPasswordIsViewable(!passwordIsViewable)}
                />
              )}
            </div>
            <button className='settings-button' onClick={() => setIsEditingPassword(!isEditingPassword)}>
              {isEditingPassword ? 'Cancel' : 'Edit'}
            </button>
          </div>
          {isEditingPassword && (
            <div className='general-profile-settings-panel-split-input'>
              <div className='general-profile-settings-panel-split-input-password'>
                <input
                  className='settings-input'
                  type={passwordIsViewable ? 'text' : 'password'}
                  placeholder='New Password'
                  value={newPassword}
                  onChange={e => setNewPassword(e.target.value)}
                />
                <FontAwesomeIcon
                  icon={passwordIsViewable ? faEye : faEyeSlash}
                  className='password-view-icon'
                  onClick={() => setPasswordIsViewable(!passwordIsViewable)}
                />
              </div>
              <button className='settings-button dark' onClick={updatePassword} disabled={!newPasswordIsValid}>
                Save
              </button>
            </div>
          )}
        </div>

        {!isBrand(user) && !isManager(user) && (
          <div className='settings-section'>
            <div className='settings-section-title'>Address For Gifting</div>
            <div className='settings-section-subtitle'>This is the address we will share with brands when you agree to receiving gifting.</div>
            <div className='general-profile-settings-panel-split-input' onClick={props.openAddressModal}>
              <input className='settings-input' type='text' placeholder='Gifting Address' value={address} />
            </div>
          </div>
        )}

        {!isBrand(user) && !isManager(user) && (
          <div className='settings-section'>
            <div className='settings-section-title'>Phone Number</div>
            <div className='general-profile-settings-panel-split-input' onClick={props.openAddressModal}>
              <input className='settings-input' type='text' placeholder='Add Your Phone Number' value={phone} />
            </div>
          </div>
        )}

        {!isBrand(user) && !isManager(user) && (
          <div className='settings-section'>
            <div className='settings-section-title'>Amazon Affiliate Code</div>
            <div className='settings-section-subtitle'>
              If you would like to use a custom Amazon affiliate code, please enter it below and we will automatically convert standard Amazon links
              into affiliate links. Please make sure to add shopmy.us as a valid domain in your Amazon affiliate settings.
            </div>
            <div className='general-profile-settings-panel-split-input'>
              <input
                className='settings-input'
                type='text'
                placeholder='Example: my-code-20'
                value={amazonCode}
                onChange={e => setAmazonCode(e.target.value)}
              />
              {canUpdateAmazonCode && (
                <button className='settings-button dark' onClick={updateAmazonCode}>
                  Update
                </button>
              )}
            </div>
          </div>
        )}

        {isBrand(user) && (
          <div className='settings-section'>
            <div className='settings-section-title'>Email for Request Responses</div>
            <div className='settings-section-subtitle'>
              If you would like to add a different email address (instead of your login email) to receive a confirmation email when talent accept your
              gifting requests, please input it here. If you would like the confirmation sent to multiple emails, please separate them by commas.
            </div>
            <div className='general-profile-settings-panel-split-input'>
              <input
                className='settings-input'
                type='text'
                placeholder='Email'
                value={requestResponseEmail}
                onChange={e => setRequestResponseEmail(e.target.value)}
              />
              {canUpdateRequestResponseEmail && (
                <button className='settings-button dark' onClick={updateRequestResponseEmail}>
                  Update
                </button>
              )}
            </div>
          </div>
        )}

        {isBrand(user) && (
          <div className='settings-section'>
            <div className='settings-section-title'>Update Commission Rate</div>
            <div className='settings-section-subtitle'>
              {!doesTakePercentageFromUser(brand) ? (
                isSMSAffiliatePartnerBrand(user) ? (
                  isUnsubscribedBrand ? (
                    'Your account is currently inactive, to update your rate please get in touch with our team.'
                  ) : (
                    <>
                      This is the commission rate that the creator will see when they add your products.
                      {!showingReturningCommissionRateInput ? (
                        <>
                          {' '}
                          To set a separate rate for returning customers, click{' '}
                          <span onClick={() => setShowingReturningCommissionRateInput(!showingReturningCommissionRateInput)} className='link'>
                            here.
                          </span>
                        </>
                      ) : (
                        ' The rate for returning customers will be explicitly shown to creators as a disclaimer.'
                      )}
                    </>
                  )
                ) : (
                  <>
                    This commission rate represents the percentage of the final purchase that we will charge in the invoice. We operate on a revenue
                    share model with our talent, with an 82/18 split. This means at the current rate of <b>{commissionRate}%</b> a ShopMy creator will
                    see <b>{visibleSMSRate}%</b>.
                  </>
                )
              ) : !isSMSAffiliatePartnerBrand(user) ? (
                getBrandWinningMerchant(user) ? (
                  <>
                    This is the current commission rate we are offering users via {getDisplaySourceFromMerchant(getBrandWinningMerchant(user))}. We
                    operate on a revenue share model with our creators with an 82/18 split, so at the current rate of <b>{commissionRate}%</b> a
                    ShopMy creator will see <b>{visibleSMSRate}%</b>.
                  </>
                ) : (
                  <>
                    We cannot currently offer a commission rate to our artists until you complete integration with our direct affiliate network using
                    the instructions <Link to='brand-setup'>here</Link>.
                  </>
                )
              ) : (
                <>
                  This commission rate represents the percentage of the final purchase that we will charge in the invoice. We operate on a revenue
                  share model with our artists, with an 82/18 split for ShopMy creators. This means at the current rate of <b>{commissionRate}%</b> a
                  ShopMy creator will see <b>{visibleSMSRate}%</b>.
                </>
              )}
            </div>
            <div className='general-profile-settings-panel-split-input'>
              <input
                className='settings-input'
                type='text'
                placeholder='15% (default rate)'
                value={commissionRate}
                onChange={e => {
                  // Only allow numbers
                  let value = e.target.value;
                  value = value.replace(/[^0-9]/g, '');

                  // if it was a backspace, we need to delete another character because we always append %
                  if (e.nativeEvent.inputType === 'deleteContentBackward') value = value.slice(0, -1);

                  if (value.length) setCommissionRate(`${value}%`);
                  else setCommissionRate('');
                }}
              />
              {canUpdateCommissionRate && (
                <button className='settings-button dark' onClick={updateCommissionRate}>
                  Update
                </button>
              )}
            </div>

            {showingReturningCommissionRateInput && (
              <div className='general-profile-settings-panel-split-input'>
                <input
                  className='settings-input'
                  type='text'
                  placeholder='15% (returning customer rate)'
                  value={commissionRateReturningCustomer}
                  onChange={e => {
                    // Only allow numbers
                    let value = e.target.value;
                    value = value.replace(/[^0-9]/g, '');

                    // if it was a backspace, we need to delete another character because we always append %
                    if (e.nativeEvent.inputType === 'deleteContentBackward') value = value.slice(0, -1);

                    if (value.length) setCommissionRateReturningCustomer(`${value}%`);
                    else setCommissionRateReturningCustomer('');
                  }}
                />

                {canUpdateCommissionRateReturningCustomer && (
                  <button className='settings-button dark' onClick={updateCommissionRate}>
                    Update
                  </button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

GeneralProfileSettingsPanel.propTypes = {
  user: PropTypes.object.isRequired,
  updateCurrentUser: PropTypes.func.isRequired,
  openAddressModal: PropTypes.func.isRequired,
  updateBrand: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  updateCurrentUser,
  openAddressModal,
  updateBrand
})(withRouter(GeneralProfileSettingsPanel));
