import React, { useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import './CompetitorBrandSettingsPanel.scss';

import { updateCompetitorBrand, addCompetitorBrand, deleteCompetitorBrand } from '../../../../Actions/BrandActions';

import { getBrand, getCompetitorBrands } from '../../../../Helpers/user_helpers';
import { fetchAlgoliaIndex } from '../../../../Helpers/search_helpers';
import { getDomainFromUrl, getGMTTime, getPrettyNumber } from '../../../../Helpers/formatting';
import { isSubscribedToFeature } from '../../../../Helpers/subscription_helpers';

import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConfirmPrompt from '../../../General/ConfirmPrompt';
import InfiniteScrollSelect from '../../../General/InfiniteScrollSelect';
import SortableList from '../../../General/SortableList';

const LIMITED_SIMILAR_BRANDS_COUNT = 3;
const FULL_ACCESS_SIMILAR_BRANDS_COUNT = 20;

const CompetitorBrandSettingsPanel = props => {
  const { user } = props;

  const [isLoadingBrands, setIsLoadingBrands] = useState(false);
  const [algoliaOffset, setAlgoliaOffset] = useState(0);
  const [searchHasNextPage, setSearchHasNextPage] = useState(true);

  const allCompetitors = getCompetitorBrands(user);
  const brand = getBrand(user);
  const hasFullCompetitorBrandAccess = isSubscribedToFeature(user, 'ADVANCED_TALENT_ANALYTICS') || window.__ADMIN_CONTROL_MODE__;
  const viewableCompetitorLimit = hasFullCompetitorBrandAccess ? FULL_ACCESS_SIMILAR_BRANDS_COUNT : LIMITED_SIMILAR_BRANDS_COUNT;
  const competitorBrands = _.orderBy(allCompetitors, 'sortOrderRank', 'asc').slice(0, viewableCompetitorLimit);
  const canAddMore = competitorBrands.length < viewableCompetitorLimit;

  const loadMoreAlgoliaBrands = async (searchTerm, isNewSearch) => {
    if (!searchHasNextPage && !isNewSearch) return [];
    setIsLoadingBrands(true);

    let results = [];
    const offset = isNewSearch ? 0 : algoliaOffset;
    const PAGE_LENTGH = 20;

    try {
      const merchantsAlgoliaIndex = fetchAlgoliaIndex(!window.__IS_PROD__ ? 'merchants_staging' : 'merchants_production');
      const algoliaResp = await merchantsAlgoliaIndex.search(searchTerm || '', {
        ...(offset ? { offset } : {}),
        length: PAGE_LENTGH
      });

      results = algoliaResp.hits;
      const formattedResults = results.map(r => {
        const sublabels = [];

        if (r.promoter_count) sublabels.push(`${getPrettyNumber(r.promoter_count)} promoters`);
        if (r.domain) sublabels.push(r.domain);

        return {
          value: r.name,
          label: r.name,
          domain: r.domain,
          subLabel: sublabels.join(' • '),
          isDisabled: !r.domain || allCompetitors.find(competitorBrand => competitorBrand.domain === r.domain) || brand?.domain === r.domain
        };
      });

      setAlgoliaOffset(offset + PAGE_LENTGH);
      if (results.length < PAGE_LENTGH) setSearchHasNextPage(false);
      else setSearchHasNextPage(true);
      return formattedResults;
    } catch (error) {
      console.error('Error on Algolia Lookup: ', error);
      window.ALERT.error('There was an issue searching for brands. Please try again.');
    } finally {
      setIsLoadingBrands(false);
    }

    return [];
  };

  const getCard = (competitorBrand, { rearrangeHandle }) => {
    return (
      <div className='competitor-brand-settings-list-item'>
        {rearrangeHandle}
        <div className='name'>{competitorBrand.name}</div>
        <a href={`https://${competitorBrand.domain}`} target='_blank' rel='noreferrer' className='domain'>
          {competitorBrand.domain}
        </a>
        <FontAwesomeIcon onClick={() => handleCompetitorBrandDelete(competitorBrand)} icon={faTimes}></FontAwesomeIcon>
      </div>
    );
  };

  const handleCompetitorBrandUpdate = async (competitorBrand, updates) => {
    const res = await props.updateCompetitorBrand(competitorBrand, updates);
    if (res.error) window.ALERT.error('There was an issue updating your similar brand configuration. Please try again.');
  };

  const handleCompetitorBrandAdd = async option => {
    const formattedCompetitorBrand = {
      Brand_id: brand.id,
      name: option.label,
      domain: option.domain,
      sortOrderRank: getGMTTime()
    };

    const res = await props.addCompetitorBrand(formattedCompetitorBrand);
    if (res.error) window.ALERT.error('There was an issue adding your similar brand. Please try again.');
    else window.ALERT.success('Similar brand added successfully! It may take up to 24 hours to see the changes throughout the UI.');
  };

  const handleCompetitorBrandDelete = async competitorBrand => {
    const res = await props.deleteCompetitorBrand(competitorBrand);
    if (res.error) window.ALERT.error('There was an issue deleting your similar brand. Please try again.');
  };

  const handleManualCompetitorBrandAdd = async inputVals => {
    const { name, domain } = inputVals;

    const cleanName = name.trim();
    const cleanDomain = (getDomainFromUrl(domain) || '').trim();

    if (!cleanName || !cleanDomain) {
      window.ALERT.error('Please provide a name and domain.');
      return false;
    }

    const formattedCompetitorBrand = {
      Brand_id: brand.id,
      name: cleanName,
      domain: cleanDomain,
      sortOrderRank: getGMTTime()
    };

    const res = await props.addCompetitorBrand(formattedCompetitorBrand);
    if (res.error) window.ALERT.error('There was an issue adding your similar brand. Please try again.');
    return !res.error;
  };

  const confirmManualCompetitorBrandAdd = async newOptionName => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          header='Configure Similar Brand'
          subheader='Please provide the following information to configure your custom similar brand.'
          onCancel={onClose}
          customInputFields={[
            { display: 'Name', placeholder: 'Brand', value: 'name', isSingleLine: true, preloaded: newOptionName },
            { display: 'Domain', placeholder: 'brand.com', value: 'domain', isSingleLine: true }
          ]}
          onSubmitAwait={handleManualCompetitorBrandAdd}
          submitMustReturnTrueToClose={true}
        />
      )
    });
  };

  return (
    <div className='competitor-brand-settings-panel-outer'>
      <div className='competitor-brand-settings-panel-inner'>
        <div className='settings-section'>
          <div className='settings-section-title'>Configure Similar Brands</div>
          <div className='settings-section-subtitle'>
            Similar brands are used for advanced talent analytics and recommendations. Search to add or click{' '}
            <span
              className='manual-create'
              onClick={() =>
                canAddMore ? confirmManualCompetitorBrandAdd() : window.ALERT.info('You have reached the limit of similar brands you can add.')
              }
            >
              here
            </span>{' '}
            to create manually. Drag and drop to reorder.
          </div>

          {canAddMore && (
            <InfiniteScrollSelect
              className='brand-search-select'
              autoFocus={false}
              openMenuOnFocus={true}
              closeMenuOnSelect={true}
              onSelectOptions={handleCompetitorBrandAdd}
              loadMoreOptions={loadMoreAlgoliaBrands}
              menuHeight={'170px'}
              isLoading={isLoadingBrands}
              placeholder={'Search for a brand'}
              isOptionDisabled={option => !!option.isDisabled}
              onNewOptionClick={confirmManualCompetitorBrandAdd}
            />
          )}

          {!!competitorBrands.length && (
            <SortableList
              isEditing
              containerClassName='settings-section-list'
              items={competitorBrands}
              updateItem={handleCompetitorBrandUpdate}
              getCard={getCard}
            />
          )}
        </div>
      </div>
    </div>
  );
};

CompetitorBrandSettingsPanel.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user, ui } = state;
  return { user, ui };
};

export default connect(mapStateToProps, {
  updateCompetitorBrand,
  addCompetitorBrand,
  deleteCompetitorBrand
})(CompetitorBrandSettingsPanel);
