import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cogoToast from 'cogo-toast';
import cn from 'classnames';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { faTimes, faPlus } from '@fortawesome/pro-light-svg-icons';
import { faExternalLink, faCopy } from '@fortawesome/pro-regular-svg-icons';

import { addEvent } from '../../../APIClient/events';
import { isUrlString, getDomainFromUrl, formatCountryFromCode } from '../../../Helpers/formatting';
import { getAdjPayoutRate, getUsername, getName } from '../../../Helpers/user_helpers';
import { copyToClipboard } from '../../../Helpers/helpers';
import { isValidGeoLinkRecommendation } from '../../../Helpers/geo_helpers';

import './AddPinModalGeoLink.scss';

class AddPinModalGeoLink extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    updateGeolinks: PropTypes.func.isRequired,
    updateMerchantData: PropTypes.func.isRequired,
    productMatch: PropTypes.object,
    variantMatch: PropTypes.object,
    geolinkMatches: PropTypes.array,
    variantMatches: PropTypes.array
  };

  state = {
    isAdding: false,
    newLink: '',
    newCountryDomain: '',
    fetchingNewLinkPayout: false,

    // For speed of access and performance we store them on the state
    countrySelectOption: null,
    countryOptions: countryList().getData()
  };

  componentDidMount() {
    this.fetchMerchantDataDebounced = AwesomeDebouncePromise(async value => {
      if (isUrlString(value)) {
        const curDomain = getDomainFromUrl(value);
        await this.props.updateMerchantData([curDomain]);
      }
      this.setState({ fetchingNewLinkPayout: false });
    }, 1000);

    this.props.updateMerchantData([
      _.get(this.props.product, 'link'),
      ..._.get(this.props.product, 'geolinks', [])
        .map(g => g.link)
        .filter(a => a)
        .map(a => getDomainFromUrl(a)),
      ..._.map(_.get(this.props, 'variantMatches', []), m => getDomainFromUrl(m.url)),
      ..._.map(_.get(this.props, 'geolinkMatches', []), m => getDomainFromUrl(m.link))
    ]);
  }

  canSubmit = () => isUrlString(this.state.newLink) && this.state.newCountryDomain.length;

  submitNew = async event => {
    event.preventDefault();
    const { product, user } = this.props;
    const { newLink, newCountryDomain } = this.state;
    const { geolinks } = product;
    if (!newLink.length || !isUrlString(newLink)) {
      cogoToast.error('Please add a valid link.');
      return;
    }
    if (!newCountryDomain.length) {
      cogoToast.error('Please add at least one country.');
      return;
    }
    this.props.updateGeolinks([
      ...geolinks,
      {
        link: newLink,
        countryDomain: newCountryDomain
      }
    ]);
    this.setState({
      isAdding: false,
      newLink: '',
      newCountryDomain: ''
    });

    addEvent(`Collections - Add Geo Link`, {
      username: getUsername(user),
      name: getName(user),
      link: newLink,
      countryCode: newCountryDomain
    });
  };

  getCountriesFromDomain = domainString => {
    return domainString.split(',').map(countryCode => formatCountryFromCode(countryCode));
  };

  getVisibleShopUser = () => _.get(this.props.store, ['visibleCollection', 'user']);

  getPayoutStringFromUrl = (url, verbose) => {
    const { user, store } = this.props;
    const { merchantData } = store;

    // in case of paste by url, the entire url is added to the merchantData object
    const merchant = merchantData[getDomainFromUrl(url)] || merchantData[url];
    const { name, rateType } = merchant || {};
    const shopUser = this.getVisibleShopUser();
    const adjPayout = getAdjPayoutRate(shopUser || user, merchant);
    const payoutString =
      rateType === 'percentage'
        ? verbose
          ? `${name} pays a ${adjPayout.toFixed(adjPayout < 1 ? 1 : 0)}% commission.`
          : `${adjPayout.toFixed(adjPayout < 1 ? 1 : 0)}% commission.`
        : adjPayout
        ? verbose
          ? `${name} pays a $${adjPayout.toFixed(2)} commission.`
          : `$${adjPayout.toFixed(2)} commission.`
        : 'No commission';
    return payoutString;
  };

  getRecommendedUrls = () => {
    const { variantMatches, product } = this.props;
    return _.filter(
      _.map(
        _.uniqBy(variantMatches, variant => getDomainFromUrl(variant.url)),
        'url'
      ),
      url =>
        getDomainFromUrl(url) !== getDomainFromUrl(product.link) &&
        !_.find(product.geolinks, ({ link }) => getDomainFromUrl(link) === getDomainFromUrl(url))
    );
  };

  getCurrentCountryCodesFromDomain = () => {
    const { newCountryDomain } = this.state;
    return newCountryDomain.length ? _.orderBy(newCountryDomain.split(',')) : [];
  };

  getRecommendedGeoLinks = () => {
    const { product, geolinkMatches } = this.props;
    const { geolinks } = product;
    const validGeoLinks = _.filter(geolinkMatches, geolink => {
      // Don't show if they already added one with that country domain
      const hasExistingCountry = !!_.find(geolinks, link => link.countryDomain === geolink.countryDomain);
      if (hasExistingCountry) return false;

      const isValidRec = isValidGeoLinkRecommendation(geolink);
      if (!isValidRec) return false;

      return true;
    });

    const sortedLinks = _.orderBy(validGeoLinks, ['createdAt'], ['desc']);
    return sortedLinks.slice(0, 5);
  };

  toggleCountryCode = newCode => {
    const currentCountryCodes = this.getCurrentCountryCodesFromDomain();
    this.setState({
      newCountryDomain: currentCountryCodes.includes(newCode)
        ? _.filter(currentCountryCodes, code => code !== newCode).join(',')
        : _.orderBy([...currentCountryCodes, newCode]).join(',')
    });
  };

  render() {
    const { product } = this.props;
    const { isAdding, newLink, fetchingNewLinkPayout, countryOptions } = this.state;
    const { geolinks, link } = product;
    const recommendedUrls = this.getRecommendedUrls();
    const recommendedGeoLinks = this.getRecommendedGeoLinks();
    const currentCountries = _.map(this.getCurrentCountryCodesFromDomain(), code => ({ code, label: formatCountryFromCode(code) }));
    return (
      <>
        <div className='add-pin-modal-geo-link-outer-container'>
          <div className='geo-link-header'>SET COUNTRY SPECIFIC LINKS</div>
          <div className='section current-geo-links default'>
            <div className='section-header'>Current Default Link</div>
            <div className='current-geo-link'>
              <div className='data'>
                <div className='link'>{link}</div>
                <div className='payout'>{this.getPayoutStringFromUrl(link)}</div>
              </div>
            </div>
          </div>
          {!!geolinks.length && (
            <div className='section current-geo-links'>
              {geolinks.map(({ link, countryDomain }, idx) => {
                return (
                  <div key={link + idx} className='current-geo-link'>
                    <div className='data'>
                      <div className='countries'>{countryDomain ? this.getCountriesFromDomain(countryDomain).join(', ') : '-'}</div>
                      <div className='link'>{link}</div>
                      <div className='payout'>{this.getPayoutStringFromUrl(link, false)}</div>
                    </div>
                    <div className='actions'>
                      <div onClick={() => copyToClipboard(link, true)} className='action-icn copy'>
                        <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
                      </div>
                      <div
                        onClick={() => {
                          this.props.updateGeolinks(geolinks.filter(geolink => geolink.link !== link && geolink.countryDomain !== countryDomain));
                        }}
                        className='action-icn delete'
                      >
                        <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {!isAdding ? (
            <div onClick={() => this.setState({ isAdding: true })} className='add-new-link-btn'>
              <div>Add New Link</div>
            </div>
          ) : (
            <div className='add-new-link-form'>
              <div onClick={() => this.setState({ isAdding: false, newLink: '', newCountryDomain: '' })} className='close-new-link-form'>
                <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
              </div>
              <form onSubmit={this.submitNew}>
                <input
                  value={newLink}
                  type='text'
                  className='url-input'
                  placeholder='Product URL'
                  onChange={({ target }) => {
                    this.setState({
                      fetchingNewLinkPayout: true,
                      newLink: target.value
                    });
                    this.fetchMerchantDataDebounced(target.value);
                  }}
                />
                <div className='country-select'>
                  <Select
                    placeholder='Search for a country'
                    options={countryOptions}
                    onChange={({ value, label }) => this.toggleCountryCode(value)}
                  />
                </div>
                <div className='new-card-preview'>
                  {!!currentCountries.length ? (
                    <div className='current-countries'>
                      {currentCountries.map(({ code, label }) => (
                        <div key={code} onClick={() => this.toggleCountryCode(code)} className='current-country'>
                          <div>{label}</div>
                          <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className='empty-countries'>Select At Least One Country</div>
                  )}
                  {newLink.length ? (
                    <div className='payout'>{fetchingNewLinkPayout ? 'Calculating Commission...' : this.getPayoutStringFromUrl(newLink, true)}</div>
                  ) : (
                    <div className='empty-link'>Please add a Product URL</div>
                  )}
                </div>
                <button type='submit' className={cn('add-new-btn', { active: this.canSubmit() })} onClick={this.submitNew}>
                  Add Link
                </button>
              </form>
            </div>
          )}
          <div className='alternative-options section'>
            {!!recommendedGeoLinks.length && (
              <>
                <div className='section-header'>Popular Geo Links Used By Others</div>
                <div className='recommended-geo-links'>
                  {recommendedGeoLinks.map(({ link, countryDomain }, idx) => {
                    return (
                      <div key={link + idx} className='recommended-geo-link'>
                        <div className='data'>
                          <div className='countries'>{countryDomain ? this.getCountriesFromDomain(countryDomain).join(', ') : '-'}</div>
                          <div className='link'>{link}</div>
                          <div className='payout'>{this.getPayoutStringFromUrl(link, false)}</div>
                        </div>
                        <div className='actions'>
                          <div onClick={() => copyToClipboard(link, true)} className='copy action-icn'>
                            <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
                          </div>
                          <div
                            onClick={() => {
                              this.props.updateGeolinks([...geolinks, { link, countryDomain }]);
                            }}
                            className='add action-icn'
                          >
                            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
            {!!recommendedUrls.length && (
              <>
                <div className='section-header'>Other Link Options We Found</div>
                <div className='recommended-urls'>
                  {recommendedUrls.map((url, idx) => (
                    <div key={idx} className='recommended-url'>
                      <div className='data'>
                        <div className='url'>{url}</div>
                        <div className='payout'>{this.getPayoutStringFromUrl(url)}</div>
                      </div>
                      <div className='actions'>
                        <div onClick={() => copyToClipboard(url, true)} className='action-icn copy'>
                          <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
                        </div>
                        <a href={url} target='_blank' rel='noopener noreferrer' className='action-icn link'>
                          <FontAwesomeIcon icon={faExternalLink}></FontAwesomeIcon>
                        </a>
                        <div onClick={() => this.setState({ isAdding: true, newLink: url })} className='action-icn add'>
                          <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default AddPinModalGeoLink;
