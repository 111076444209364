import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';
import './PaymentAccountsPanel.scss';

import { connectStripeAccount } from '../../../../Actions/UserActions';
import { linkPaypalAccount, unlinkPaymentAccount } from '../../../../Actions/PayoutActions';
import { getStripeOnboardingStatus } from '../../../../APIClient/stripe';

import { isManager, getStripeAccountId, needsToCompleteStripeOnboarding, getUserPaymentAccounts } from '../../../../Helpers/user_helpers';
import { goToStripeOnboarding, formatStripeRequirement } from '../../../../Helpers/stripe_helpers';
import { paypalAuthUrl } from '../../../../Helpers/payment_helpers';
import stripeIcon from '../../../../static/images/misc/stripe.svg';
import paypalIcon from '../../../../static/images/misc/paypal.svg';

import { confirmAlert } from 'react-confirm-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import Tooltip from '../../../General/Tooltip';

const PaymentAccountsPanel = props => {
  const { user, updateCurrentUser } = props;
  const paymentAccounts = getUserPaymentAccounts(user);
  const hasPaypalLinked = paymentAccounts?.length;

  // Stripe
  const [stripeAccountLinkWarnings, setStripeAccountLinkWarnings] = useState([]);
  const [isConnectingStripe, setIsConnectingStripe] = useState(false);

  useEffect(() => {
    checkStripeStatus();
  }, []);

  const unlinkPaypal = async () => {
    return confirmAlert({
      title: 'Unlink PayPal Account',
      message: 'Are you sure you want to unlink your PayPal account?',
      buttons: [
        { label: 'Cancel', className: 'cancel', onClick: () => {} },
        { label: 'Confirm', onClick: async () => await props.unlinkPaymentAccount(paymentAccounts[0]) }
      ]
    });
  };

  const checkStripeStatus = () => {
    needsToCompleteStripeOnboarding(user) &&
      getStripeOnboardingStatus(user).then(resp => {
        if (resp.account?.payouts_enabled) {
          // This shouldn't happen, but if we get a response and are in fact updated, fire off the update
          updateCurrentUser({ stripeOnboardingComplete: true }, user.id);
        }

        setStripeAccountLinkWarnings([
          ..._.get(resp, 'account.requirements.currently_due', []).map(formatStripeRequirement),
          ..._.get(resp, 'account.requirements.pending_verification', []).map(formatStripeRequirement)
        ]);
      });
  };

  const connectStripe = async () => {
    if (isConnectingStripe) return window.ALERT.warn(`Already in the process of connecting.`);
    setIsConnectingStripe(true);

    try {
      await props.connectStripeAccount();
      goToStripeOnboarding(props.user);
    } finally {
      setIsConnectingStripe(false);
    }
  };

  return (
    <div className='payment-accounts-panel-outer'>
      <div className='payment-accounts-panel-inner'>
        <div className='settings-section'>
          <div className='settings-section-title'>Connect Payment Account</div>
          <div className='settings-section-subtitle'>
            {isManager(user)
              ? 'Connect your Stripe account to receive payments for your cut of creator opportunities. If you are outside the US, please connect your PayPal account instead.'
              : `Stripe is currently only available for US-based creators. If you're a creator outside of the US, please connect your PayPal.`}
          </div>

          <div className='connect-payments-btns'>
            {/***********************************************************************/}
            {/* STRIPE */}
            {/***********************************************************************/}
            {getStripeAccountId(user) ? (
              <>
                <button
                  onClick={() => goToStripeOnboarding(user)}
                  className={cn('settings-button stripe link', {
                    warning: needsToCompleteStripeOnboarding(user),
                    connected: !needsToCompleteStripeOnboarding(user)
                  })}
                >
                  <img className='icn' src={stripeIcon} alt='Stripe Logo' />
                  {needsToCompleteStripeOnboarding(user) ? (
                    <>
                      <div className='data'>
                        {!!stripeAccountLinkWarnings.length ? (
                          <Tooltip
                            message={`We have connected to stripe, however, to finish the integration please fill out the following fields in your Stripe account. ${stripeAccountLinkWarnings
                              .map((warning, index) => `${index + 1}: ${warning}`)
                              .join(', ')}`}
                          >
                            Stripe Needs More Information
                          </Tooltip>
                        ) : (
                          <Tooltip message={`You should receive an email when everything has been verified`}>Stripe Is Reviewing</Tooltip>
                        )}
                      </div>
                      <FontAwesomeIcon icon={faExternalLink} />
                    </>
                  ) : (
                    <>
                      Stripe Connected
                      <FontAwesomeIcon icon={faExternalLink} />
                    </>
                  )}
                </button>
              </>
            ) : (
              <button onClick={connectStripe} className='settings-button stripe'>
                <img className='social-icn' src={stripeIcon} alt='Stripe Logo' />
                {isConnectingStripe ? 'Connecting...' : 'Connect'}
              </button>
            )}

            {/***********************************************************************/}
            {/* Paypal */}
            {/***********************************************************************/}
            {hasPaypalLinked ? (
              <button onClick={unlinkPaypal} className='settings-button paypal connected'>
                <img src={paypalIcon} alt='Paypal Icon' />
                <div>Paypal Connected</div>
              </button>
            ) : (
              <a href={paypalAuthUrl} target='_blank' rel='noreferrer' className='settings-button paypal'>
                <img src={paypalIcon} alt='Paypal Icon' />
                <div>Connect</div>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

PaymentAccountsPanel.propTypes = {
  user: PropTypes.object.isRequired,
  connectStripeAccount: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  connectStripeAccount,
  linkPaypalAccount,
  unlinkPaymentAccount
})(withRouter(PaymentAccountsPanel));
