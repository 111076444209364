import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import './BrandLookbookHeader.scss';

import { matchScrollHeight } from '../../../Helpers/helpers';
import { getOrderHandledByData, getLookbookRequests, isLookbookConnectedToShopify } from '../../../Helpers/lookbook_helpers';
import { isAdminControlMode } from '../../../Helpers/ui_helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faArrowLeft, faBell } from '@fortawesome/pro-regular-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';

import Tooltip from '../../General/Tooltip';
import ImageUploader from '../../General/ImageUploader';
import Switch from 'react-switch';
import { getShopifyIntegration } from '../../../Helpers/user_helpers';
import { getShopifyLocations } from '../../../APIClient/shopify';

let debouncer;

const BrandLookbookHeader = props => {
  const { user, lookbook, analytics, updateLookbook } = props;
  const { inPublicViewMode, setInPublicViewMode, inAnalyticsMode, setInAnalyticsMode } = props;
  const { openSettingsModal, openAddProductsModal } = props;
  const { title, coverImage } = lookbook;
  const orderHandledData = getOrderHandledByData(user, lookbook);
  const lastLocation = useLastLocation();

  const requests = getLookbookRequests(analytics, lookbook);
  const lookbookHasSentRequests = requests?.length > 0;

  const clickToSendGifting = () => props.openRequestModal({ params: { Lookbook_id: lookbook.id } });

  const imageUploaderRef = useRef(null);
  const lookbookTitleRef = useRef(null);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [syncedInventoryLocations, setSyncedInventoryLocations] = useState(null);
  const [isUpdatingLookbook, setIsUpdatingLookbook] = useState(false);
  const history = useHistory();

  const initSyncedShopifyLocations = async () => {
    const { isConnectedToShopify, forDomain } = isLookbookConnectedToShopify(user, lookbook);

    const shopifyIntegrationForLookbook = isConnectedToShopify && forDomain ? getShopifyIntegration(user, forDomain) : null;

    if (shopifyIntegrationForLookbook?.inventory_locations.length) {
      // fetch inventory locations
      const inventoryLocationIds = shopifyIntegrationForLookbook.inventory_locations.map(location => location.shopifyLocationId);
      const data = {
        BrandIntegration_id: shopifyIntegrationForLookbook.id,
        locationIds: inventoryLocationIds
      };

      const response = await getShopifyLocations(data);
      setSyncedInventoryLocations(response.locations.map(location => location.name));
    } else if (shopifyIntegrationForLookbook) {
      // no inventory locations specified, uses all available inventory
      setSyncedInventoryLocations([]);
    }
  };

  useEffect(() => {
    initSyncedShopifyLocations();
  }, []);

  const updateLookbookCoverImage = async imageUrl => await updateLookbook({ coverImage: imageUrl });

  const updateLookbookName = e => {
    const newTitle = e.target.value;
    clearTimeout(debouncer);
    debouncer = setTimeout(async () => {
      if (newTitle === title || newTitle === '') return;
      await updateLookbook({ title: newTitle });
    }, 1000);
  };

  const updateLookbookOrderHandling = async () => {
    if (isUpdatingLookbook) return;
    setIsUpdatingLookbook(true);

    await updateLookbook({ orderHandledBy: 'brand' });
    setIsUpdatingLookbook(false);
  };

  const headBack = () => {
    const lastPath = lastLocation?.pathname;
    if (inAnalyticsMode) return history.push(`/lookbooks/${lookbook.id}`);
    else if (lastPath?.includes('/lookbooks') && !lastPath.includes('analytics')) history.goBack();
    else return history.push('/lookbooks');
  };

  const isLookbookPartiallyConnectedToIntegration = orderHandledData.isPartiallyConnected;
  const isLookbookManual = orderHandledData.handledBy !== 'shopify';

  const getIntegrationStatusTooltip = () => {
    if (isLookbookPartiallyConnectedToIntegration) {
      return 'Some items in this Lookbook are disconnected from Shopify. Disconnected items cannot be selected by creators.';
    } else if (!isLookbookManual) {
      return 'This Lookbook is fully connected to Shopify';
    } else {
      return null;
    }
  };

  const integrationTooltipMessage = getIntegrationStatusTooltip();

  return (
    <div className={cn('brand-lookbook-header-outer', { 'has-cover-image': !!coverImage })}>
      {coverImage && <img className='cover-image' alt='cover' src={coverImage} />}
      {coverImage && <div className='cover-image-background'></div>}
      <div className={cn('brand-lookbook-header-inner')}>
        <ImageUploader
          ref={imageUploaderRef}
          isVisible={isUploadingImage}
          setIsVisible={setIsUploadingImage}
          onSaveCallback={updateLookbookCoverImage}
          initialImageUrl={coverImage}
          allowCrop
        />
        <div className='add-image-button' onClick={() => setIsUploadingImage(true)}>
          <img alt='boxes' src='https://static.shopmy.us/snapshop/boxes.svg' />
          <span>{coverImage ? 'change image' : 'add image'}</span>
        </div>

        <div className='text-container'>
          <div
            id='backlink-and-title'
            className={cn({
              'has-cover-image': !!coverImage
            })}
          >
            <div className='backlink' onClick={headBack}>
              <FontAwesomeIcon icon={faArrowLeft} />
              <div>{inAnalyticsMode ? 'back to lookbook' : 'back to my gifting portal'}</div>
            </div>
            <textarea
              rows={1}
              autoFocus={!title?.length}
              placeholder='Enter Lookbook Title'
              className='title'
              onChange={updateLookbookName}
              defaultValue={title}
              spellCheck={false}
              ref={ref => {
                matchScrollHeight(ref);
                lookbookTitleRef.current = ref;
              }}
            />
            {!inPublicViewMode && lookbook.items.length > 0 && (
              <>
                <div className='lookbook-fulfillment-status-container'>
                  <div className='display-container'>
                    <div
                      className={cn('display-icon', {
                        'partially-connected': isLookbookPartiallyConnectedToIntegration,
                        manual: isLookbookManual
                      })}
                    >
                      {!!integrationTooltipMessage ? (
                        <Tooltip message={integrationTooltipMessage}>
                          <FontAwesomeIcon icon={faBell} />
                        </Tooltip>
                      ) : (
                        <FontAwesomeIcon icon={faBell} />
                      )}
                    </div>
                    {orderHandledData.display}
                    <div className='reasoning'>
                      {(orderHandledData.showReasoning || isAdminControlMode(props.ui)) && (
                        <Tooltip message={orderHandledData.reasoning} getIconDiv={() => <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>} />
                      )}
                    </div>
                  </div>
                </div>
                {!!orderHandledData?.showSwitchToManual && (
                  <div className='switch-to-manual-container'>
                    Click <span onClick={updateLookbookOrderHandling}>here</span> to confirm you intend to fulfill these orders manually
                  </div>
                )}
                <div className='integration-inventory-container'>
                  {orderHandledData?.handledBy === 'shopify' && !!syncedInventoryLocations && (
                    <div className='inventory-status'>
                      Inventory is synced with Shopify
                      {syncedInventoryLocations.length > 1 && ' at multiple locations'}
                      {syncedInventoryLocations.length === 0 && ' using all available inventory'}
                      {syncedInventoryLocations.length === 1 && (
                        <div className='single-location'>
                          at
                          <span>{syncedInventoryLocations[0]}</span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <div id='options-and-configuration' className={cn({ 'has-cover-image': !!coverImage })}>
            <div className='toggles'>
              {lookbookHasSentRequests ? (
                <div className='toggle' onClick={() => setInAnalyticsMode(!inAnalyticsMode)}>
                  View Analytics
                  <Switch
                    onChange={() => {}}
                    checked={!!inAnalyticsMode}
                    uncheckedIcon={true}
                    checkedIcon={true}
                    onColor='#11835a'
                    height={20}
                    width={40}
                    handleDiameter={17}
                  />
                </div>
              ) : (
                <Tooltip message='Send this Lookbook to creators to view analytics.'>
                  <div className='toggle toggle-disabled'>
                    View Analytics
                    <Switch
                      onChange={() => {}}
                      checked={!!inAnalyticsMode}
                      uncheckedIcon={true}
                      checkedIcon={true}
                      onColor='#11835a'
                      height={20}
                      width={40}
                      handleDiameter={17}
                      disabled
                    />
                  </div>
                </Tooltip>
              )}
              <div className='toggle'>
                View as Creator
                <Switch
                  onChange={() => setInPublicViewMode(!inPublicViewMode)}
                  checked={!!inPublicViewMode}
                  uncheckedIcon={true}
                  checkedIcon={true}
                  onColor='#11835a'
                  height={20}
                  width={40}
                  handleDiameter={17}
                />
              </div>
            </div>
            <div className='configuration'>
              <div className='configuration-button' id='open-lookbook-settings' onClick={openSettingsModal}>
                <FontAwesomeIcon icon={faCog} />
              </div>
              <div className='configuration-button' id='add-product' onClick={openAddProductsModal}>
                Add Product +
              </div>
              <div id='send-gifting-request' className='configuration-button' onClick={clickToSendGifting}>
                Send Gifting Request
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BrandLookbookHeader.propTypes = {
  user: PropTypes.object.isRequired,
  lookbook: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  updateLookbook: PropTypes.func.isRequired,
  deleteLookbook: PropTypes.func.isRequired,

  inPublicViewMode: PropTypes.bool.isRequired,
  setInPublicViewMode: PropTypes.func.isRequired,
  inAnalyticsMode: PropTypes.bool.isRequired,
  setInAnalyticsMode: PropTypes.func.isRequired,

  openSettingsModal: PropTypes.func.isRequired,
  openAddProductsModal: PropTypes.func.isRequired,
  openRequestModal: PropTypes.func.isRequired
};

export default BrandLookbookHeader;
