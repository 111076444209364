import React from 'react';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import _ from 'lodash';
import './CodePreferencesPanel.scss';

import { getProfileFromObject, getPrefferredCodeFormat } from '../../../../Helpers/user_helpers';
import { getCodeFormattingOptions, getCodeDisplayForUserWithFormat, createFormatFromInputText } from '../../../../Helpers/code_helpers';

import ConfirmPrompt from '../../../General/ConfirmPrompt';
import CheckboxButton from '../../../General/Buttons/CheckboxButton';

const CodePreferencesPanel = props => {
  const { user } = props;
  const profile = getProfileFromObject(user);
  const codeFormattingOptions = getCodeFormattingOptions();
  const uniqueFormattingOptions = _.uniqBy(codeFormattingOptions, o => o.getDisplay(profile));
  const preferredCodeFormat = getPrefferredCodeFormat(user);
  const defaultFormat = codeFormattingOptions.find(option => option.isDefault);
  const isCustomCodeFormat = !codeFormattingOptions.find(option => option.value === preferredCodeFormat);

  const setCustomCodeFormat = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          header='Specify Format for 20% Code'
          subheader='Please outline the format you would like to use for a 20% Discount Code'
          allowOverflow
          onCancel={onClose}
          customInputFields={[
            {
              isSingleLine: true,
              placeholder: getCodeDisplayForUserWithFormat(profile, preferredCodeFormat || defaultFormat.value, 20),
              value: 'preferredCodeFormat'
            }
          ]}
          onSubmitAwait={async data => {
            if (!data.preferredCodeFormat) return;
            const newFormat = createFormatFromInputText(data.preferredCodeFormat, profile);
            await props.updateUserSettings({ preferredCodeFormat: newFormat });
          }}
        />
      )
    });
  };

  return (
    <div className='code-preferences-panel-outer'>
      <div className='code-preferences-panel-inner'>
        <div className='settings-section'>
          <div className='settings-section-title'>How do you prefer your discount codes to look?</div>
          <div className='settings-section-subtitle'>
            We will recommend to brands that they use this style when generating custom discount codes for you.
          </div>

          <div className='settings-section-list'>
            {uniqueFormattingOptions.map(option => {
              const { getDisplay, subdisplay, value } = option;
              const display = getDisplay(profile);
              const selected = preferredCodeFormat === value;
              const selectOption = () => !selected && props.updateUserSettings({ preferredCodeFormat: value });

              return <CheckboxButton key={value} isChecked={selected} onChange={selectOption} text={display} description={subdisplay} />;
            })}

            <CheckboxButton
              isChecked={isCustomCodeFormat}
              onChange={setCustomCodeFormat}
              text={
                isCustomCodeFormat ? (preferredCodeFormat ? getCodeDisplayForUserWithFormat(profile, preferredCodeFormat, 20) : 'Custom') : 'Custom'
              }
              description={isCustomCodeFormat ? 'Custom Code Formatting' : 'Create your own format'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

CodePreferencesPanel.propTypes = {
  user: PropTypes.object.isRequired,
  updateUserSettings: PropTypes.func.isRequired
};

export default CodePreferencesPanel;
