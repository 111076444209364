import React from 'react';
import { connect } from 'react-redux';
import commaNumber from 'comma-number';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import _ from 'lodash';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import './OpportunitySetupGuide.scss';

import { getOpportunities, getBrand } from '../../Helpers/user_helpers';
import {
  opportunityTypeData,
  getTypeDisplayForOpportunity,
  opportunityFieldMaxLengths,
  getOpportunityWarningForField
} from '../../Helpers/opportunity_helpers';

import {
  updateOpportunity,
  createSmartOpportunityPlan,
  createOpportunityPaymentTier,
  deleteOpportunityPaymentTier,
  syncActiveOpportunityFullData
} from '../../Actions/OpportunityActions';

import Modal from '../General/Modal';
import ConfirmPrompt from '../General/ConfirmPrompt';
import OpportunitySetupGuideSidebar from './Elements/OpportunitySetupGuideSidebar';
import OpportunitySetupGuideStep from './Elements/OpportunitySetupGuideStep';
import OpportunitySetupGuideFooter from './Elements/OpportunitySetupGuideFooter';
import OpportunitySetupGuideIntro from './Elements/OpportunitySetupGuideIntro';
import OpportunitySetupGuideFinalizing from './Elements/OpportunitySetupGuideFinalizing';

const OpportunitySetupGuide = props => {
  const { opportunity, user, showPlanningSteps } = props;
  const { trackingStartsAt, trackingEndsAt, eventStartsAt, eventEndsAt } = opportunity;
  const isExperiential = opportunity.type === 'experience';
  const isPromotional = opportunity.type === 'promotion';
  const mostRecentlyAddedPlan = _.maxBy(opportunity.plans, 'createdAt');

  // UI References
  const stepRef = React.useRef();
  const scrollStepToTop = () => stepRef.current && stepRef.current.scroll(0, 0);

  // Intro Page
  const shouldStartWithIntroPage = getOpportunities(user).length === 1 && !showPlanningSteps;
  const [isOnIntroPage, setIsOnIntroPage] = React.useState(shouldStartWithIntroPage);
  const beginSetup = () => setIsOnIntroPage(false);

  // Finalization Pages
  const [isFinalizing, setIsFinalizing] = React.useState(false);
  const closeToOverview = () => closeToTab('overview');
  const closeToPlans = () => closeToTab('plans');
  const closeToTab = tab => {
    props.selectTabByValue(tab);
    props.closeModal();
  };

  // Date options
  const nextMonthStart = moment().add(1, 'month').startOf('month'); // prettier-ignore
  const nextMonthEnd = moment().add(1, 'month').endOf('month'); // prettier-ignore
  const isNextMonthSelected = moment(trackingStartsAt).isSame(nextMonthStart, 'day') && moment(trackingEndsAt).isSame(nextMonthEnd, 'day');
  const followingMonthStart = moment().add(2, 'months').startOf('month'); // prettier-ignore
  const followingMonthEnd = moment().add(2, 'months').endOf('month'); // prettier-ignore
  const isFollowingMonthSelected =
    moment(trackingStartsAt).isSame(followingMonthStart, 'day') && moment(trackingEndsAt).isSame(followingMonthEnd, 'day');

  const isCustomDateRangeSelected = !isNextMonthSelected && !isFollowingMonthSelected && !!trackingStartsAt && !!trackingEndsAt;
  const eventDateRangeDisplay = !eventStartsAt
    ? null
    : eventStartsAt && eventEndsAt && moment(eventStartsAt).format('MMMM D') === moment(eventEndsAt).format('MMMM D')
    ? moment(eventStartsAt).format('MMMM D')
    : `${moment(eventStartsAt).format('MMMM D')} - ${moment(eventEndsAt).format('MMMM D')}`;
  const trackingDateRangeDisplay = !trackingStartsAt
    ? null
    : trackingStartsAt && trackingEndsAt && moment(trackingStartsAt).format('MMMM D') === moment(trackingEndsAt).format('MMMM D')
    ? moment(trackingStartsAt).format('MMMM D')
    : `${moment(trackingStartsAt).format('MMMM D')} - ${moment(trackingEndsAt).format('MMMM D')}`;

  // Target Goals
  const hadExpectationsOnLoadRef = React.useRef(
    opportunity.linksExpected || opportunity.mentionsExpected || opportunity.mentionDaysExpected || opportunity.linkingDaysExpected
  );
  const hadExpectationsOnLoad = hadExpectationsOnLoadRef.current;
  const isExpectingLinksOnLoad = hadExpectationsOnLoad && (opportunity.linksExpected > 0 || opportunity.linkingDaysExpected > 0);
  const [isExpectingLinks, setIsExpectingLinks] = React.useState(hadExpectationsOnLoad ? isExpectingLinksOnLoad : true);
  const [isExpectingMentions, setIsExpectingMentions] = React.useState(hadExpectationsOnLoad ? !isExpectingLinksOnLoad : false);
  const isExpectingBoth = isExpectingLinks && isExpectingMentions;

  // Budget
  const [maxBudget, setMaxBudget] = React.useState(opportunity.maxBudget || 0);
  const budgetOptions = isExperiential ? [1000, 5000, 10000, 25000, 50000] : [5000, 10000, 25000, 50000];
  const isCustomBudget = !!maxBudget && !budgetOptions.includes(maxBudget);

  // Payment Tiers
  const generateDefaultPaymentTiers = async ranges => {
    if (opportunity.payment_tiers.length > 0) return;
    for (const range of ranges) {
      props.createOpportunityPaymentTier({ Opportunity_id: opportunity.id, fixedFee: range });
      await new Promise(resolve => setTimeout(resolve, 150)); // Give time so we make sure to do them in order
    }
  };

  // Creator Targets
  // const [creatorFilters, setCreatorFilters] = React.useState([]); // [{ Tag_id: 1, value: 'Fashion' }]

  // Brand Targets
  const brand = getBrand(user);
  const initialTargets = _.concat(
    [{ name: brand.name, domain: brand.domain }],
    brand.competitor_brands ? brand.competitor_brands.map(competitor => ({ name: competitor.name, domain: competitor.domain })) : []
  );
  const [brandTargets, setBrandTargets] = React.useState(initialTargets); // [{ name: 'Nike', domain: 'nike.com' }

  // Mention Targets
  const cleanTag = tag =>
    tag
      .toLowerCase()
      .split(' ')
      .join('');
  const initialMentionTargets = _.concat(
    [{ tag: cleanTag(brand.name) }],
    brand.competitor_brands?.map(competitor => ({ tag: cleanTag(competitor.name) })) || []
  );
  const [mentionTargets, setMentionTargets] = React.useState(initialMentionTargets); // [{ tag: 'Nike' }]

  // Advanced Settings
  const [onlyMyPromoters, setOnlyMyPromoters] = React.useState(true);
  const [lockedNumExpectations, setLockedNumExpectations] = React.useState(null);
  const [costMultiplier, setCostMultiplier] = React.useState(1);

  // Explainers
  const typeDisplay = getTypeDisplayForOpportunity(opportunity);
  const [title, setTitle] = React.useState(opportunity.title || '');
  const [description, setDescription] = React.useState(opportunity.description || '');
  const [guidelines, setGuidelines] = React.useState(opportunity.guidelines || '');
  const [location, setLocation] = React.useState(opportunity.location || '');
  const debouncers = React.useRef({ title: null, description: null, guidelines: null, location: null });
  const updateFieldImmediately = (field, value) => updateField(field, value, 0);
  const updateField = (field, value, delay = 500) => {
    if (field === 'title') setTitle(value);
    if (field === 'description') setDescription(value);
    if (field === 'guidelines') setGuidelines(value);
    if (field === 'location') setLocation(value);
    if (debouncers.current[field]) clearTimeout(debouncers.current[field]);
    debouncers.current[field] = setTimeout(() => props.updateOpportunity(opportunity, { [field]: value }), delay);
  };
  const smartUpdateTitle = () => {
    /* Automatically update the title based on the type of opportunity and the date range. */
    let smartTitle = title;
    if (title && !title.includes('Opportunity')) return;
    else if (isNextMonthSelected) smartTitle = `${typeDisplay} Opportunity for ${nextMonthStart.format('MMMM')}`;
    else if (isFollowingMonthSelected) smartTitle = `${typeDisplay} Opportunity for ${followingMonthStart.format('MMMM')}`;
    else if (isCustomDateRangeSelected) smartTitle = `${typeDisplay} Opportunity`;
    if (smartTitle) updateFieldImmediately('title', smartTitle);
  };

  React.useEffect(() => {
    if (!showPlanningSteps) {
      smartUpdateTitle();
    }
  }, [isNextMonthSelected, isFollowingMonthSelected, isCustomDateRangeSelected, typeDisplay]);

  // Active Steps
  const [activeStepIdx, setActiveStepIdx] = React.useState(0);
  let steps = [
    {
      title: 'Opportunity Type',
      isComplete: !!opportunity.type,
      completeDisplayTitle: `${getTypeDisplayForOpportunity(opportunity)} Opportunity`,
      questions: [
        {
          display: 'What kind of Opportunity is this?',
          type: 'CHECKBOXES',
          options: opportunityTypeData.map(data => {
            return {
              type: 'CHECKBOX',
              display: data.display,
              isSelected: opportunity.type === data.value,
              isRecommended: data.isRecommended,
              description: data.description,
              onChangeValue: () => {
                props.updateOpportunity(opportunity, { type: data.value });
              }
            };
          })
        }
      ]
    },
    {
      title: 'Event Date',
      isComplete: !!eventStartsAt && eventStartsAt,
      completeDisplayTitle: !eventStartsAt ? 'No Date Set' : eventDateRangeDisplay,
      isHidden: !isExperiential,
      questions: [
        {
          display: `When is this event?`,
          subdisplay: ``,
          type: 'DATES',
          hidden: isExperiential,
          details: {
            type: 'DATE_RANGE',
            currentDateDisplay: eventDateRangeDisplay,
            onClick: () => {
              confirmAlert({
                customUI: ({ onClose }) => (
                  <ConfirmPrompt
                    header='Set Event Dates'
                    subheader='Please specify when the event is going to be held, if it is only one day, please select the same date for both fields.'
                    allowOverflow
                    onCancel={onClose}
                    customInputFields={[
                      {
                        isDateRange: true,
                        value_date_range: ['eventStartsAt', 'eventEndsAt'],
                        preloaded_date_range: [eventStartsAt && moment(eventStartsAt), eventEndsAt && moment(eventEndsAt)]
                      }
                    ]}
                    onSubmit={async data => {
                      const { eventStartsAt, eventEndsAt } = data;
                      props.updateOpportunity(opportunity, {
                        eventStartsAt: eventStartsAt.format('YYYY-MM-DD 12:00:00'),
                        eventEndsAt: eventEndsAt.format('YYYY-MM-DD 12:00:00'),
                        trackingStartsAt: eventStartsAt.format('YYYY-MM-DD 12:00:00'),
                        trackingEndsAt: eventEndsAt.add(7, 'days').format('YYYY-MM-DD 12:00:00')
                      });
                    }}
                  />
                )
              });
            }
          }
        },
        ...(eventStartsAt
          ? [
              {
                display: `When should the tracking window be?`,
                subdisplay: `Typically we recommend at least 7 days after the completion of the event to allow for content to be created and shared.`,
                type: 'DATES',
                hidden: isExperiential,
                details: {
                  type: 'DATE_RANGE',
                  currentDateDisplay: trackingDateRangeDisplay,
                  onClick: () => {
                    confirmAlert({
                      customUI: ({ onClose }) => (
                        <ConfirmPrompt
                          header='Set Tracking'
                          subheader='Typically we recommend at least 7 days after the completion of the event to allow for content to be created and shared.'
                          allowOverflow
                          onCancel={onClose}
                          customInputFields={[
                            {
                              isDateRange: true,
                              value_date_range: ['trackingStartsAt', 'trackingEndsAt'],
                              preloaded_date_range: [trackingStartsAt && moment(trackingStartsAt), trackingEndsAt && moment(trackingEndsAt)]
                            }
                          ]}
                          onSubmit={async data => {
                            const { trackingStartsAt, trackingEndsAt } = data;
                            props.updateOpportunity(opportunity, {
                              trackingStartsAt: trackingStartsAt.format('YYYY-MM-DD 12:00:00'),
                              trackingEndsAt: trackingEndsAt.format('YYYY-MM-DD 12:00:00')
                            });
                          }}
                        />
                      )
                    });
                  }
                }
              }
            ]
          : [])
      ]
    },
    {
      title: 'Duration / Timing',
      isComplete: !!opportunity.trackingStartsAt && !!opportunity.trackingEndsAt,
      completeDisplayTitle:
        trackingStartsAt && trackingEndsAt && `${moment(trackingStartsAt).format('MMMM D')} - ${moment(trackingEndsAt).format('MMMM D')}`,
      isHidden: isExperiential,
      onFirstLoad: () => {
        // Default to next month if no dates are set
        !trackingStartsAt &&
          !trackingEndsAt &&
          props.updateOpportunity(opportunity, {
            trackingStartsAt: nextMonthStart.format('YYYY-MM-DD 12:00:00'),
            trackingEndsAt: nextMonthEnd.format('YYYY-MM-DD 12:00:00')
          });
      },
      questions: [
        {
          display: `When do you want this opportunity to run?`,
          subdisplay: `This timeframe is the tracking period for your opportunity. You can adjust this later.`,
          type: 'CHECKBOXES',
          options: [
            {
              type: 'CHECKBOX',
              display: `Month of ${nextMonthStart.format('MMMM')}`,
              description:
                'We recommend that you run opportunities for one month and start a new one the following month. This is easy for creators to work with and helps you continue to optimize your candidate pool each month.',
              isSelected: isNextMonthSelected,
              onChangeValue: () => {
                props.updateOpportunity(opportunity, {
                  trackingStartsAt: nextMonthStart.format('YYYY-MM-DD 12:00:00'),
                  trackingEndsAt: nextMonthEnd.format('YYYY-MM-DD 12:00:00')
                });
              }
            },
            {
              type: 'CHECKBOX',
              display: `Month of ${followingMonthStart.format('MMMM')}`,
              isSelected: isFollowingMonthSelected,
              onChangeValue: () => {
                props.updateOpportunity(opportunity, {
                  trackingStartsAt: followingMonthStart.format('YYYY-MM-DD 12:00:00'),
                  trackingEndsAt: followingMonthEnd.format('YYYY-MM-DD 12:00:00')
                });
              }
            },
            {
              type: 'CHECKBOX',
              display: isCustomDateRangeSelected
                ? `${moment(trackingStartsAt).format('MMMM D')} - ${moment(trackingEndsAt).format('MMMM D')}`
                : 'Custom Date Range',
              isSelected: isCustomDateRangeSelected,
              onChangeValue: () => {
                confirmAlert({
                  customUI: ({ onClose }) => (
                    <ConfirmPrompt
                      header='Set Tracking Window'
                      subheader='This window specifies in what time period the talent is able to complete the expecations of this opportunity. We will only count content created or links shared within this window.'
                      allowOverflow
                      onCancel={onClose}
                      customInputFields={[
                        {
                          isDateRange: true,
                          value_date_range: ['trackingStartsAt', 'trackingEndsAt'],
                          preloaded_date_range: [trackingStartsAt && moment(trackingStartsAt), trackingEndsAt && moment(trackingEndsAt)]
                        }
                      ]}
                      onSubmit={async data => {
                        const { trackingStartsAt, trackingEndsAt } = data;
                        props.updateOpportunity(opportunity, {
                          trackingStartsAt: trackingStartsAt.format('YYYY-MM-DD 12:00:00'),
                          trackingEndsAt: trackingEndsAt.format('YYYY-MM-DD 12:00:00')
                        });
                      }}
                    />
                  )
                });
              }
            }
          ]
        }
      ]
    },
    {
      title: 'Target Results',
      isComplete: isExpectingLinks || isExpectingMentions,
      completeDisplayTitle: isExpectingBoth ? 'Links and Content' : isExpectingLinks ? 'Driving Traffic' : 'Driving Social Content',
      isPlanningPanel: true,
      isSetupPanel: true,
      isHidden: hadExpectationsOnLoad,
      onFirstLoad: () => {
        if (isExperiential) {
          // Default to no expectations to use this purely for invitations
          !hadExpectationsOnLoad && props.updateOpportunity(opportunity, { linksExpected: 0, mentionsExpected: 0 });
          setIsExpectingLinks(false);
          setIsExpectingMentions(true);
        } else {
          // Default to expecting links if no results are set
          !hadExpectationsOnLoad && props.updateOpportunity(opportunity, { linksExpected: 3, mentionsExpected: 0 });
          setIsExpectingLinks(true);
          setIsExpectingMentions(false);
        }
      },
      questions: [
        {
          display: 'What are the key results you want to see?',
          subdisplay: isExperiential
            ? 'This will help us customize the event dashboard to show you the most relevant information to judge the success of your event in terms of content creation or sales.'
            : 'This will determine the expectations we outline for the creators.',
          type: 'CHECKBOXES',
          options: [
            {
              type: 'CHECKBOX',
              display: 'Website Traffic & Sales Volume',
              isRecommended: !isExperiential,
              description: isExperiential
                ? `You want to track the sales creators are driving during or after the experience.`
                : `This is the most common goal for opportunities. You want to pay creators for a guaranteed number links shared.`,
              isSelected: isExpectingLinks && !isExpectingMentions,
              onChangeValue: () => {
                setIsExpectingLinks(true);
                setIsExpectingMentions(false);
                !hadExpectationsOnLoad && props.updateOpportunity(opportunity, { linksExpected: 3, mentionsExpected: 0 });
              }
            },
            {
              type: 'CHECKBOX',
              display: 'Mentions on Social Media',
              isRecommended: isExperiential,
              description: isExperiential
                ? `You want to track the content creators are sharing on their social platforms during or after the experience.`
                : `You want to pay creators for a guaranteed level of coverage on their social platforms. You can get more specific about what you want to see later.`,
              isSelected: isExpectingMentions && !isExpectingLinks,
              onChangeValue: () => {
                setIsExpectingLinks(false);
                setIsExpectingMentions(true);
                !hadExpectationsOnLoad && props.updateOpportunity(opportunity, { mentionsExpected: 3, linksExpected: 0 });
              }
            }
          ]
        }
      ]
    },
    {
      title: 'Opportunity Details',
      isComplete: opportunity.description,
      completeDisplayTitle: 'Opportunity Details',
      onFirstLoad: () => {
        if (isExperiential) {
          generateDefaultPaymentTiers([0]);
        }
      },
      questions: [
        {
          display: 'Add any additional details.',
          subdisplay: 'Be sure to include all the details creators will need to know.',
          type: 'TEXTAREAS',
          fields: [
            {
              label: 'Title',
              value: title,
              rows: 1,
              placeholder: 'Enter Opportunity Title',
              onChangeValue: title => updateField('title', title)
            },
            ...(isExperiential
              ? [
                  {
                    label: 'Location',
                    value: location,
                    rows: 1,
                    placeholder: 'Address or location',
                    onChangeValue: location => updateField('location', location)
                  }
                ]
              : []),
            {
              label: isExperiential ? 'About this Event' : 'About This Opportunity',
              value: description,
              warningMessage: getOpportunityWarningForField(opportunity, 'description'),
              maxCharCount: opportunityFieldMaxLengths.description,
              rows: 5,
              placeholder: isExperiential
                ? 'Add additional information on this event.'
                : isPromotional
                ? 'Add additional information on this promotional opportunity. What do you want them to promote?'
                : 'Add additional information on this bonus opportunity.',
              onChangeValue: description => updateField('description', description)
            },
            {
              label: 'Guidelines',
              sublabel: isExperiential
                ? 'These are not requirements, but helpful tips for creators when generating content about this experience.'
                : 'These are not requirements, but helpful tips for creators when generating content.',
              value: guidelines,
              rows: 5,
              placeholder: isExperiential
                ? 'What should creators know about what you are looking for in sharing this experience with their audiences?'
                : 'Any specific things creators should know about what you want out of their content?',
              onChangeValue: guidelines => updateField('guidelines', guidelines)
            }
          ]
        }
      ]
    },
    {
      title: 'Budget',
      isComplete: maxBudget > 0,
      completeDisplayTitle: maxBudget && `$${commaNumber(maxBudget)}`,
      onFirstLoad: () => {
        if (mostRecentlyAddedPlan) {
          const mostRecentlyAddedPlanBudget = _.sumBy(mostRecentlyAddedPlan?.users, 'recommendedFixedFee');
          const budgetOptionClosesToMostRecentPlan = _.minBy(budgetOptions, budget => Math.abs(budget - mostRecentlyAddedPlanBudget));
          setMaxBudget(isExperiential ? 0 : budgetOptionClosesToMostRecentPlan);
        } else {
          setMaxBudget(isExperiential ? 0 : 10_000);
        }
      },
      isHidden: isExperiential && !showPlanningSteps,
      isPlanningPanel: true,
      showOptionalAIMessage: !showPlanningSteps,
      questions: [
        {
          display: 'What is your budget?',
          subdisplay: isExperiential
            ? 'To further incentivize sharing, experiential opportunities must offer a bonus to creators in addition for a set of deliverables.'
            : 'This is the total amount you are willing to spend on this opportunity. You can adjust this later.',
          type: 'CHECKBOXES',
          options: [
            ...budgetOptions.map(budget => {
              return {
                type: 'CHECKBOX',
                display: budget ? `$${commaNumber(budget)}` : 'No Budget',
                isSelected: maxBudget === budget,
                onChangeValue: () => setMaxBudget(budget)
              };
            }),
            {
              type: 'CHECKBOX',
              display: isCustomBudget ? `$${commaNumber(maxBudget)}` : 'Custom Budget',
              isSelected: isCustomBudget,
              onChangeValue: () => {
                confirmAlert({
                  customUI: ({ onClose }) => (
                    <ConfirmPrompt
                      header='Set Budget'
                      subheader='This is the total amount you are willing to spend on this opportunity. You can adjust this later.'
                      allowOverflow
                      customInputFields={[
                        {
                          value: 'maxBudget',
                          preloaded_currency: maxBudget,
                          placeholder: 'Enter Budget',
                          isSingleLine: true
                        }
                      ]}
                      onCancel={onClose}
                      onSubmit={data => setMaxBudget(data.maxBudget)}
                    />
                  )
                });
              }
            }
          ]
        }
      ]
    },
    // {
    //   title: 'Creator Selection',
    //   isComplete: creatorFilters.length,
    //   completeDisplayTitle: creatorFilters.length ? `${creatorFilters.length} Filter${creatorFilters.length === 1 ? '' : 's'}` : 'No Creator Filters',
    //   isPlanningPanel: true,
    //   questions: [
    //     {
    //       display: 'Are there any restrictions on talent you want to invite?',
    //       subdisplay: 'Apply talent filters to help our talent recommendation system provide the best results for you.',
    //       type: 'MULTI_SEARCH',
    //       search_options: {
    //         results: creatorFilters,
    //         setResults: setCreatorFilters,
    //         resultsLabel: 'Current Filters',
    //         searchMoreResultsLabel: 'Add New Filters',
    //         searchPlaceholder: 'Search by Tag or Keyword',
    //         performSearch: async query => {
    //           const tags = await getTagsForOpportunities({ query });
    //           return tags;
    //         },
    //         getDefaultResults: async () => []
    //       }
    //     }
    //   ]
    // },
    // {
    //   title: 'Creator Selection',
    //   isComplete: true,
    //   completeDisplayTitle: 'All Creators',
    //   isPlanningPanel: true,
    //   questions: [
    //     {
    //       display: 'What type of creator mix do you want?',
    //       subdisplay: 'This will help our talent recommendation system provide the best results for you.',
    //       type: 'CHECKBOXES',
    //       options: [
    //         {
    //           type: 'CHECKBOX',
    //           display: 'All Creators',
    //           isRecommended: true,
    //           description: 'You are open to the full creator pool, this will ensure your talent recommendations are optimized fully for ROI.',
    //           isSelected: creatorTargetEnum === 'ALL',
    //           onChangeValue: () => setCreatorTargetEnum('ALL')
    //         },
    //         {
    //           type: 'CHECKBOX',
    //           display: 'Smaller Creators Only',
    //           description:
    //             'This will pull from a talent pool of creators who are typically open to lower price points for opportunties. This is great for smaller campaigns or large campaigns wanting many creators at once.',
    //           isSelected: creatorTargetEnum === 'SMALL',
    //           onChangeValue: () => setCreatorTargetEnum('SMALL')
    //         },
    //         {
    //           type: 'CHECKBOX',
    //           display: 'Elite Creators Only',
    //           isDisabled: maxBudget < 20_000,
    //           isDisabledMessage: 'This option is only available for opportunities with a budget of $20,000 or more.',
    //           description: 'This will pull from a smaller talent pool of Icons and Trendsetters only. This is great for high-end campaigns.',
    //           isSelected: creatorTargetEnum === 'ELITE',
    //           onChangeValue: () => setCreatorTargetEnum('ELITE')
    //         }
    //       ]
    //     }
    //   ]
    // },
    {
      title: 'Targets: Traffic/Volume',
      isComplete: brandTargets.length,
      completeDisplayTitle: brandTargets.length ? `${brandTargets.length} Target${brandTargets.length === 1 ? '' : 's'}` : 'No Targets',
      isHidden: (isExpectingMentions && !isExpectingLinks) || isExperiential,
      isPlanningPanel: true,
      questions: [
        {
          display: `Which traffic and sales do you want us to analyze?`,
          subdisplay: `We will analyze performance to these websites to help our talent recommendation system provide the optimal strategy for you.`,
          type: 'MULTI_SEARCH',
          search_options: {
            results: brandTargets,
            setResults: setBrandTargets,
            emptySearchResultSecondaryMessage: `Type the full domain (website.com) to add a custom one.`
          }
        }
      ]
    },
    {
      title: 'Targets: Social Tags',
      isComplete: mentionTargets.length,
      completeDisplayTitle: mentionTargets.length ? `${mentionTargets.length} Tag${mentionTargets.length === 1 ? '' : 's'}` : 'No Tags',
      isHidden: (isExpectingLinks && !isExpectingMentions) || isExperiential,
      isPlanningPanel: true,
      questions: [
        {
          display: `Which social tags should we look for?`,
          subdisplay: `We will analyze performance on social content using these tags to enable our talent recommendation system provide the optimal strategy for you.`,
          type: 'MULTI_TAGS',
          tag_options: {
            results: mentionTargets,
            setResults: setMentionTargets
          }
        }
      ]
    },
    {
      title: 'Advanced Settings',
      subtitle: 'These settings are optional, we recommend leaving the defaults below unless you have a specific reason.',
      isComplete: false,
      isHidden: isExperiential,
      isPlanningPanel: true,
      questions: [
        {
          display: 'Creator Pool',
          subdisplay: 'What creators do you want to consider for this opportunity?',
          type: 'CHECKBOXES_SMALL',
          options: [
            {
              type: 'CHECKBOX',
              display: 'My Promoters',
              isRecommended: true,
              isSelected: onlyMyPromoters === true,
              onChangeValue: () => setOnlyMyPromoters(true)
            },
            {
              type: 'CHECKBOX',
              display: 'All Creators',
              isSelected: onlyMyPromoters === false,
              onChangeValue: () => setOnlyMyPromoters(false)
            }
          ]
        },
        {
          display: 'Fee Strategy',
          subdisplay:
            'How aggressive do you want to be with the fees you offer to talent. This will impact the likelihood of acceptance as well as the potential ROI.',
          type: 'CHECKBOXES_SMALL',
          options: [
            {
              type: 'CHECKBOX',
              display: 'Aggressive',
              description: 'This may reduce the acceptance rate of your opportunity.',
              isSelected: costMultiplier === 0.7,
              onChangeValue: () => setCostMultiplier(0.7)
            },
            {
              type: 'CHECKBOX',
              display: 'Normal',
              isRecommended: true,
              description: 'This is the recommended setting for most opportunities.',
              isSelected: costMultiplier === 1,
              onChangeValue: () => setCostMultiplier(1)
            },
            {
              type: 'CHECKBOX',
              display: 'Generous',
              description: 'This will increase the acceptance rate of your opportunity.',
              isSelected: costMultiplier === 1.3,
              onChangeValue: () => setCostMultiplier(1.3)
            },
            {
              type: 'CHECKBOX',
              display: 'Very Generous',
              description: 'This will ensure creators are very excited to work with you, but may reduce your return on investment.',
              isSelected: costMultiplier === 1.7,
              onChangeValue: () => setCostMultiplier(1.7)
            }
          ]
        },
        {
          display: 'Requested Content',
          subdisplay: `Do you want to lock in a specific number of ${isExpectingLinks ? 'links' : 'mentions'}?`,
          type: 'CHECKBOXES_SMALL',
          options: [
            {
              type: 'CHECKBOX',
              display: 'No Preference',
              isRecommended: true,
              description: 'This will allow for the maximal optimization, we recommend this setting if you want to maximize your return.',
              isSelected: !lockedNumExpectations,
              onChangeValue: val => setLockedNumExpectations(null)
            },
            {
              type: 'CHECKBOX',
              display: lockedNumExpectations
                ? `${lockedNumExpectations} ${
                    isExpectingLinks ? `Link${lockedNumExpectations === 1 ? '' : 's'}` : `Mention${lockedNumExpectations === 1 ? '' : 's'}`
                  }`
                : `Custom Number of ${isExpectingLinks ? 'Links' : 'Mentions'}`,
              isSelected: !!lockedNumExpectations,
              onChangeValue: () => {
                confirmAlert({
                  customUI: ({ onClose }) => (
                    <ConfirmPrompt
                      header={`Set Number of ${isExpectingLinks ? 'Links' : 'Mentions'}`}
                      subheader='This will lock in the number of links or mentions you want to see. This will reduce the optimization of the system.'
                      allowOverflow
                      customInputFields={[
                        {
                          value: 'lockedNumExpectations',
                          placeholder: `Enter Number of ${isExpectingLinks ? 'Links' : 'Mentions'}`,
                          isSingleLine: true
                        }
                      ]}
                      onCancel={onClose}
                      onSubmit={data => setLockedNumExpectations(data.lockedNumExpectations ? +data.lockedNumExpectations : 0)}
                    />
                  )
                });
              }
            }
          ]
        }
      ]
    }
  ];

  // Filter out hidden steps
  steps = steps.filter(step => !step.isHidden);

  // Show Smart Planning or Standard Setup Guide
  steps = steps.filter(step => (showPlanningSteps ? step.isPlanningPanel : !step.isPlanningPanel || step.isSetupPanel));

  // Augment Steps
  steps = steps.map((step, index) => ({
    ...step,
    index,
    isActive: index === activeStepIdx,
    isLast: index === steps.length - 1,
    isUpcoming: index > activeStepIdx
  }));

  const selectStep = step => {
    setActiveStepIdx(step.index);
    step.onFirstLoad && step.onFirstLoad();
    scrollStepToTop();
  };
  const goToNextStep = () => selectStep(steps.find(step => step.isUpcoming));
  const activeStep = steps.find(step => step.isActive);
  React.useEffect(() => {
    steps[0]?.onFirstLoad && steps[0].onFirstLoad();
  }, []);

  const completeSetupWithoutPlanning = () => props.closeModal();
  const completeSetupAndStartStandardPlanning = () => {
    props.selectTabByValue('plans');
    props.closeModal();
  };
  const completeSetupAndStartSmartPlanning = (showAnimation = true) => {
    /*
      Kick off the finalization process. This will either show the animation or immediately generate the plan.
      If we show the animation, the plan is sent from the finalizing panel which first connects to the websockets.
    */
    if (showAnimation) {
      setIsFinalizing(true);
    } else {
      window.ALERT.success('Generating Plan Quickly');
      generatePlan();
    }
  };

  const jobIdForPlanning = React.useRef(`${opportunity.id}-${Date.now()}`);
  const generatePlan = () => {
    props.createSmartOpportunityPlan({
      Job_id: jobIdForPlanning.current,
      Opportunity_id: opportunity.id,
      brandTargetsToConsider: isExpectingLinks ? brandTargets : [],
      tagsToConsider: isExpectingMentions ? mentionTargets.map(mention => mention.tag) : [],
      lockedUserIds: props.lockedUserIds,
      blockedUserIds: props.blockedUserIds,
      budget: maxBudget,
      onlyMyPromoters,
      lockedNumExpectations,
      costMultiplier
    });
  };

  // Show disclaimer if needed
  let disclaimer;
  if (props.lockedUserIds?.length || props.blockedUserIds?.length) {
    disclaimer = [
      `You have`,
      props.lockedUserIds.length ? ` locked in ${props.lockedUserIds.length} specific creator${props.lockedUserIds.length === 1 ? '' : 's'}` : '',
      props.blockedUserIds.length
        ? `${props.lockedUserIds.length ? ' and' : ''} blocked ${props.blockedUserIds.length} specific creator${
            props.blockedUserIds.length === 1 ? '' : 's'
          }`
        : '',
      ' for this opportunity. This will override the normal talent recommendation system.'
    ].join('');
  }

  return (
    <Modal
      visible
      noPadding
      fitContent
      close={props.closeModal}
      className='opportunity-setup-guide-modal'
      innerClassName='opportunity-setup-guide-inner'
      contentClassName='opportunity-setup-guide-content'
    >
      {isOnIntroPage && <OpportunitySetupGuideIntro beginSetup={beginSetup} />}
      {isFinalizing && (
        <OpportunitySetupGuideFinalizing
          user={user}
          jobIdForPlanning={jobIdForPlanning.current}
          opportunity={opportunity}
          generatePlan={generatePlan}
          closeToOverview={closeToOverview}
          closeToPlans={closeToPlans}
          backToSteps={() => setIsFinalizing(false)}
          syncActiveOpportunityFullData={props.syncActiveOpportunityFullData}
        />
      )}
      <div className='sidebar-container'>
        <OpportunitySetupGuideSidebar steps={steps} selectStep={selectStep} />
      </div>
      <div className='main-container'>
        <div ref={stepRef} className='body-container'>
          <OpportunitySetupGuideStep step={activeStep} opportunity={opportunity} user={user} closeToOverview={closeToOverview} />
        </div>
        <div className='footer-container'>
          <OpportunitySetupGuideFooter
            step={activeStep}
            disclaimer={disclaimer}
            opportunity={opportunity}
            goToNextStep={goToNextStep}
            completeSetupAndStartSmartPlanning={completeSetupAndStartSmartPlanning}
            completeSetupAndStartStandardPlanning={completeSetupAndStartStandardPlanning}
            completeSetupWithoutPlanning={completeSetupWithoutPlanning}
          />
        </div>
      </div>
      <div className='close-icn'>
        <FontAwesomeIcon icon={faTimes} onClick={props.closeModal} />
      </div>
    </Modal>
  );
};

OpportunitySetupGuide.propTypes = {
  // From Inside
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  updateOpportunity: PropTypes.func.isRequired,
  createSmartOpportunityPlan: PropTypes.func.isRequired,
  createOpportunityPaymentTier: PropTypes.func.isRequired,
  deleteOpportunityPaymentTier: PropTypes.func.isRequired,
  syncActiveOpportunityFullData: PropTypes.func.isRequired,

  // From Outside
  opportunity: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectTabByValue: PropTypes.func.isRequired,
  showPlanningSteps: PropTypes.bool, // Used when creating a new plan
  lockedUserIds: PropTypes.array, // Used when creating a new plan if you want to require certain users
  blockedUserIds: PropTypes.array // Used when creating a new plan if you want to block certain users
};

const mapStateToProps = state => {
  const { user, analytics } = state;
  return { user, analytics };
};

export default connect(mapStateToProps, {
  updateOpportunity,
  createSmartOpportunityPlan,
  createOpportunityPaymentTier,
  deleteOpportunityPaymentTier,
  syncActiveOpportunityFullData
})(OpportunitySetupGuide);
