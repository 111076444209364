import React from 'react';
import _ from 'lodash';
import { confirmAlert } from 'react-confirm-alert';

import { logToSlack } from '../APIClient/alerts';
import ConfirmPrompt from '../Components/General/ConfirmPrompt';
import { getUserTierDisplay } from './tier_helpers';
import { isBrand, isBanned, getBrandRequests, isSubscribedBrand } from '../Helpers/user_helpers';
import { isSubscribedToFeature, isAffiliateOnlyPartnerBrand } from '../Helpers/subscription_helpers';
import { isUserIdPromoterForOutreachPurposes } from '../Helpers/talent_helpers';

export const getAllChats = chats => _.get(chats, 'chats', []);

// Intercom Specific Handling
const INTERCOM_APP_ID = 'qblcp41j';
export const intitializeIntercom = async user => {
  if (isBanned(user) || !isSubscribedBrand(user) || isAffiliateOnlyPartnerBrand(user)) {
    return;
  }

  await new Promise(resolve => {
    (function() {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        var d = document;
        var i = function() {
          i.c(arguments);
        };
        i.q = [];
        i.c = function(args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var l = function() {
          var s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/' + INTERCOM_APP_ID;
          var x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
          resolve();
        };
        if (document.readyState === 'complete') {
          l();
        } else if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();
  });

  // Establish Intercom User Profile
  const userProfileData = {
    userId: _.get(user, 'profile.id'),
    name: _.get(user, 'profile.name'),
    email: _.get(user, 'profile.email'),
    username: _.get(user, 'profile.username'),
    'Account Manager': _.get(user, 'profile.account_manager.name') || 'None',
    Tier: getUserTierDisplay(_.get(user, 'profile.tier.tier')),
    isBanned: isBanned(user),
    trueScore: _.get(user, 'profile.tier.trueScore'),
    is_brand: !!_.get(user, 'profile.Brand_id')
  };

  window.Intercom('boot', {
    app_id: INTERCOM_APP_ID,
    ...userProfileData
  });
};

export const openIntercomChat = () => {
  window.Intercom('show');
};

export const prepopulateIntercomMessage = msg => {
  window.Intercom && window.Intercom('showNewMessage', msg);
};

export const requestFeedback = async ({ header, subheader, delay, messagePrefix }) => {
  if (delay) await new Promise(resolve => setTimeout(resolve, delay));
  confirmAlert({
    customUI: ({ onClose }) => (
      <ConfirmPrompt
        header={header || 'Request Feedback'}
        subheader={subheader || null}
        onCancel={onClose}
        customInputFields={[{ placeholder: 'Please share feedback here', value: 'message' }]}
        onSubmit={({ message }) => {
          if (!message) return;
          window.ALERT.success('Feedback submitted, thank you!');
          logToSlack({ message: messagePrefix ? `${messagePrefix}: ${message}` : message, channel: 'opportunities' });
        }}
      />
    )
  });
};

/**
 * Determines whether the current user (brand or non-brand)
 * can send chat messages, and if not, why.
 *
 * @param {Object} data - The current user object
 * @param {Object} data.user - The current user object
 * @param {Object} data.analytics - Analytics data (may contain brand requests, etc.)
 * @param {Object} data.activeChat - The active chat object (includes .brand, .messages, etc.)
 * @param {Object} data.talent - Talent object (used for promoter logic)
 * @returns {{ canMessage: boolean, reason: string }}
 */
export function getBrandHasAccessToChat(data) {
  const { user, analytics, activeChat, talent } = data;

  // Make sure there is a brand object before we reference it
  const brand = activeChat?.brand || {};
  const { subscription_basic } = brand;

  /*************************************************** */
  // Creator sending message to brand
  /*************************************************** */
  if (!isBrand(user)) {
    const brandOffersChat = brand?.settings?.offersChat ?? true;
    const invalidSubscriptionStatuses = ['cancelled', 'unsubscribed'];

    if (!brandOffersChat) return { canMessage: false, reason: 'This brand has disabled messaging.' };
    if (subscription_basic && invalidSubscriptionStatuses.includes(subscription_basic.status))
      return { canMessage: false, reason: `${brand.name} is no longer available for chat on ShopMy.` };

    // Otherwise, user can chat
    return { canMessage: true, reason: '' };
  }

  /*************************************************** */
  // Brand sending message to creator
  /*************************************************** */
  // Case 1: Brand subscription inactive => cannot chat at all
  if (!isSubscribedBrand(user)) {
    return {
      canMessage: false,
      reason: 'Your subscription is currently inactive, please reactivate it on the Invoices & Subscriptions tab.'
    };
  }

  // Case 2: Brand has unlimited chat
  if (isSubscribedToFeature(user, 'UNLIMITED_CHAT')) {
    return { canMessage: true, reason: '' };
  }

  // Case 3: Talent has accepted request or has responded to previous messages
  const relevantRequests = getBrandRequests(analytics).filter(r => r.User_id === activeChat?.User_id);
  const hasAcceptedRequest = relevantRequests.some(r => r.userAccepted);
  const hasResponderMessage = (activeChat?.messages || []).some(m => m.isUserMessage);
  if (hasAcceptedRequest || hasResponderMessage) return { canMessage: true, reason: '' };

  // Case 4: Talent is promoting the brand and brand has the 'CHAT_WITH_PROMOTERS' feature
  const isPromoter = isUserIdPromoterForOutreachPurposes(talent, activeChat?.user?.id);
  if (isPromoter && isSubscribedToFeature(user, 'CHAT_WITH_PROMOTERS')) {
    return { canMessage: true, reason: '' };
  }

  return {
    canMessage: false,
    reason: 'You cannot send general chat messages with your current subscription, please upgrade your package to leverage this feature.'
  };
}

export const getTalentHasAccessToChat = data => {
  const { user, activeChat } = data;

  if (isBrand(user)) {
    if (activeChat?.user?.isArchived) return { canMessage: false, reason: 'This creator has been archived and can no longer be contacted.' };
    if (activeChat?.user?.isBanned) return { canMessage: false, reason: 'This creator has been banned and can no longer be contacted.' };
    if (activeChat?.user?.isBannedFromChat)
      return { canMessage: false, reason: 'This creator has been banned from chat and can no longer be contacted.' };

    return { canMessage: true, reason: '' };
  }

  if (isBanned(user)) {
    return {
      canMessage: false,
      reason: 'Your profile has been flagged and you will not be able to access chat features until this is resolved.'
    };
  }

  return { canMessage: true, reason: '' };
};
