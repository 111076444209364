import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import _ from 'lodash';
import ReactCrop from 'react-image-crop';
import cogoToast from 'cogo-toast';
import OutsideClickHandler from 'react-outside-click-handler';
import cn from 'classnames';
import 'react-image-crop/lib/ReactCrop.scss';
import 'react-image-crop/dist/ReactCrop.css';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink, faMapMarker } from '@fortawesome/pro-regular-svg-icons';
import { faMagic, faRepeat, faUpload, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import './AddPinModalFinalTile.scss';

import { getSmartImage } from '../../../Helpers/helpers';
import { addEvent } from '../../../APIClient/events';
import { prettifyImage as prettifyImageAPI, flipImage as flipImageAPI } from '../../../APIClient/images';
import { getCodeForPinFromCodes } from '../../../Helpers/store_helpers';
import { getUsername, getName, isAdmin, getCodes, isSimulatingUser, getUserTier, isBanned } from '../../../Helpers/user_helpers';
import { isAdminControlMode } from '../../../Helpers/ui_helpers';

import Tooltip from '../../General/Tooltip';
import Loader from '../../Loader/Loader';
import UploadImage from '../../General/UploadImage';
import DropUploader from '../../Uploader/DropUploader';
import AddPinModalAdminControls from './AddPinModalAdminControls';

const IMAGE_SIZE = 200;

class AddPinModalFinalTile extends Component {
  static propTypes = {
    ui: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    finalProduct: PropTypes.object.isRequired,
    updateField: PropTypes.func.isRequired,
    toggleAdvancedPanel: PropTypes.func.isRequired,
    geolinkMatches: PropTypes.array,
    pinBeingEdited: PropTypes.object,
    merchantData: PropTypes.object,
    advancedSelectionOverlay: PropTypes.string,
    isPinLinkWebRisk: PropTypes.bool
  };

  handleChange = async ({ target }) => {
    const value = target.value;
    const id = target.id;
    this.props.updateField(id, value);
  };

  getStarterCrop = () => ({
    unit: 'px',
    x: 0,
    y: 0,
    aspect: 1,
    height: 0,
    width: 0
  });

  state = {
    crop: { aspect: 1 },
    applyingCrop: false,
    appliedCrop: this.getStarterCrop(),

    modifyingImage: false,
    modifyingImageMsg: null
  };

  setCrop = crop => {
    this.setState({
      crop: {
        ...crop,
        aspect: 1
      },
      applyingCrop: !_.isEqual(crop, this.state.appliedCrop) && !_.isEqual(crop, this.getStarterCrop())
    });
  };

  applyCrop = crop => {
    this.appliedZoomOffset = this.zoomOffset;
    this.appliedXTransformOffset = this.xTransformOffset;
    this.appliedYTransformOffset = this.yTransformOffset;
    this.setState(
      {
        crop: this.getStarterCrop(),
        appliedCrop: this.state.crop,
        trueCrop: this.state.appliedCrop.width
          ? {
              width: this.state.crop.width / this.appliedZoomOffset,
              height: this.state.crop.width / this.appliedZoomOffset,
              x: (this.state.crop.x - this.xTransformOffset) / this.appliedZoomOffset,
              y: (this.state.crop.y - this.yTransformOffset) / this.appliedZoomOffset
            }
          : this.state.crop,
        applyingCrop: false
      },
      () => {
        this.props.updateField('crop', {
          x: this.state.trueCrop.x,
          y: this.state.trueCrop.y,
          width: this.state.trueCrop.width,
          height: this.state.trueCrop.height,
          frame: IMAGE_SIZE
        });
      }
    );
  };

  removeCrop = () => {
    this.zoomOffset = null;
    this.xTransformOffset = null;
    this.yTransformOffset = null;
    this.appliedZoomOffset = null;
    this.appliedXTransformOffset = null;
    this.appliedYTransformOffset = null;
    this.setState({
      crop: {},
      appliedCrop: this.getStarterCrop(),
      applyingCrop: false
    });
    this.props.updateField('crop', null);
  };

  isCropApplied = () => !_.isEqual(this.getStarterCrop(), this.state.appliedCrop);

  getCroppedImageStyle = () => {
    const { width, height, x, y } = this.state.appliedCrop;

    if (height < 10 || width < 10) {
      return {};
    }

    const adjustedWidth = width / (this.appliedZoomOffset || 1);
    const adjustedHeight = height / (this.appliedZoomOffset || 1);

    const fullImageWidth = _.get(this.curImageDimensions, 'width', adjustedWidth);
    const fullImageHeight = _.get(this.curImageDimensions, 'height', adjustedHeight);

    const trueWidthRatio = fullImageWidth / adjustedWidth;
    const trueHeightRatio = fullImageHeight / adjustedHeight;

    const newImageWidth = trueWidthRatio * IMAGE_SIZE;
    const newImageHeight = trueHeightRatio * IMAGE_SIZE;

    const newImageOffsetX = (-1 * IMAGE_SIZE * (x - (this.appliedXTransformOffset || 0))) / adjustedHeight / (this.appliedZoomOffset || 1);
    const newImageOffsetY = (-1 * IMAGE_SIZE * (y - (this.appliedYTransformOffset || 0))) / adjustedHeight / (this.appliedZoomOffset || 1);

    this.zoomOffset = _.max([trueWidthRatio, trueHeightRatio]);
    this.xTransformOffset = newImageOffsetX;
    this.yTransformOffset = newImageOffsetY;
    return {
      width: `${newImageWidth.toFixed(2)}px`,
      maxWidth: `${newImageWidth.toFixed(2)}px`,
      height: `${newImageHeight.toFixed(2)}px`,
      maxHeight: `${newImageHeight.toFixed(2)}px`,
      transform: `translate(${newImageOffsetX.toFixed(1)}px, ${newImageOffsetY.toFixed(1)}px)`,
      objectFit: 'contain'
    };
  };

  onImageLoaded = image => {
    this.curImageDimensions = {
      width: image.width / (this.zoomOffset || 1),
      height: image.height / (this.zoomOffset || 1)
    };
  };

  toggleMonetization = () => {
    const { finalProduct, user } = this.props;
    if (isBanned(user) && finalProduct.disable_monetization) return cogoToast.error('Your account is currently blocked from monetizing products.');
    this.props.updateField('disable_monetization', !finalProduct.disable_monetization);
  };

  toggleOptimization = () => {
    const { finalProduct } = this.props;
    this.props.updateField('allow_optimization', !finalProduct.allow_optimization);
  };

  toggleDisplayCodes = () => {
    const { finalProduct } = this.props;
    this.props.updateField('allow_displaying_codes', !finalProduct.allow_displaying_codes);
  };

  toggleAllowDeepLinkToApp = () => {
    const { finalProduct } = this.props;
    this.props.updateField('allow_deep_link_to_app', !finalProduct.allow_deep_link_to_app);
  };

  linkToItem = () => {
    const { finalProduct } = this.props;
    const { link } = finalProduct;
    window.open(link, '_blank');
  };

  flipImage = () => {
    const { finalProduct, updateField } = this.props;
    const { image } = finalProduct;
    this.setState({ modifyingImage: true, modifyingImageMsg: 'Flipping Image' });
    flipImageAPI(image)
      .then(resp => {
        updateField('image', resp.url);
        setTimeout(() => this.setState({ modifyingImage: false }), 500);
      })
      .catch(error => {
        cogoToast.error(
          typeof error === 'string' ? error : 'There was an error processing this image, please try with a new image or contact a developer.'
        );
        this.setState({ modifyingImage: false });
      });
  };
  prettifyImageWithForceBackground = () => this.prettifyImage(true);
  prettifyImage = (forceBackground = false) => {
    const { finalProduct, updateField } = this.props;
    const { image } = finalProduct;
    this.setState({ modifyingImage: true, modifyingImageMsg: 'Prettifying Image' });

    let options = {};
    if (forceBackground) options.forceBackground = true;
    prettifyImageAPI(image, options)
      .then(resp => updateField('image', resp.url))
      .catch(error =>
        cogoToast.error(
          typeof error === 'string' ? error : 'There was an error processing this image, please try with a new image or contact a developer.'
        )
      )
      .finally(() => {
        setTimeout(() => this.setState({ modifyingImage: false }), 500); // To give time for the new image to load without choppiness
      });
  };

  uploadImageComplete = newImage => {
    this.props.updateField('image', newImage);
    setTimeout(() => this.setState({ modifyingImage: false }), 500); // To give time for the new image to load without choppiness
  };

  render() {
    const { finalProduct, user, geolinkMatches, merchantData, advancedSelectionOverlay, pinBeingEdited, isPinLinkWebRisk } = this.props;
    const { crop, applyingCrop, modifyingImage, modifyingImageMsg } = this.state;
    const {
      title,
      image,
      description,
      link,
      disable_monetization,
      allow_optimization,
      allow_displaying_codes,
      allow_deep_link_to_app
    } = finalProduct;
    const { appDeepLinkingEnabled } = merchantData || {};
    const codes = getCodes(this.props.user);
    const code = getCodeForPinFromCodes(finalProduct, codes);
    const tier = getUserTier(user);
    const canPrettifyImage = isAdmin(user) || isSimulatingUser(user) || tier?.tier <= 2; // Admins, Icons and Trendsetters

    return (
      <div className='add-pin-modal-final-tile-outer-container'>
        <DropUploader
          onlyAllowImages
          onUpload={url => {
            this.uploadImageComplete(url);
            window.ALERT.success('Image uploaded successfully.');
          }}
          onDrop={(acceptedFiles, rejectedFiles) => {
            if (rejectedFiles.length) return window.ALERT.error('Only image files are supported.');
          }}
        >
          <div className='collection-final-tile'>
            <OutsideClickHandler onOutsideClick={() => this.setState({ applyingCrop: false })}>
              <div className={cn('collection-product-img-wrapper', { modifying: modifyingImage })}>
                <ReactCrop
                  imageStyle={this.getCroppedImageStyle()}
                  src={getSmartImage(image)}
                  crop={crop}
                  ruleOfThirds
                  onChange={this.setCrop}
                  onImageLoaded={this.onImageLoaded}
                />
                {modifyingImage ? (
                  <div className='loading-container'>
                    <Loader size={72} />
                    <div className='message'>{modifyingImageMsg}</div>
                  </div>
                ) : (
                  <div className='transform-image-btns'>
                    <UploadImage
                      basicUploader
                      basicContent={
                        <div className='upload-image-btn'>
                          <FontAwesomeIcon icon={faUpload} />
                        </div>
                      }
                      onUploadProgress={(progress, status) => {
                        this.setState({
                          modifyingImage: true,
                          modifyingImageMsg: progress > 0 && progress < 100 ? `${progress}% complete...` : `${status}...`
                        });
                      }}
                      completeUpload={this.uploadImageComplete}
                    />
                    <div onClick={this.flipImage} className='rotate-image-btn'>
                      <Tooltip message={`Flip Image Horizontally`} getIconDiv={() => <FontAwesomeIcon icon={faRepeat} />} />
                    </div>
                    {canPrettifyImage && (
                      <div onClick={this.prettifyImage} className='prettify-image-btn'>
                        <Tooltip message={`Make the image pretty`} getIconDiv={() => <FontAwesomeIcon icon={faMagic} />} />
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className='product-image-controls'>
                {this.isCropApplied() && (
                  <div onClick={this.removeCrop} className='remove-crop-btn btn'>
                    RESET
                  </div>
                )}
                {applyingCrop && (
                  <div onClick={this.applyCrop} className='apply-crop-btn btn'>
                    APPLY
                  </div>
                )}
                {!applyingCrop && !this.isCropApplied() && <div className='apply-crop-msg'>Drag on the image to apply a crop</div>}
              </div>
              {(isMobile || advancedSelectionOverlay === 'links') && (
                <div className='change-image' onClick={() => this.props.toggleAdvancedPanel('images')}>
                  Change Image
                </div>
              )}
            </OutsideClickHandler>
            <div className='meta-container'>
              <div className='input-header'>Title</div>
              <textarea id='title' name='title' rows='3' value={title} type='text' placeholder='Product Name' onChange={this.handleChange} />
              <div className='input-header'>Description</div>
              <textarea
                id='description'
                name='description'
                rows='4'
                value={description}
                type='text'
                placeholder='Add more details on why you love it.'
                className='input'
                onChange={this.handleChange}
              />
              <div className='input-header'>Destination Link</div>
              <div className='input-action-btn-group'>
                <input
                  id='link'
                  name='link'
                  value={link}
                  type='text'
                  placeholder='Add a custom link here'
                  className='input'
                  onChange={this.handleChange}
                />
              </div>
              <div className='actions'>
                {!isPinLinkWebRisk && (
                  <div className='preview-link-btn active' onClick={this.linkToItem}>
                    Preview Link
                    <FontAwesomeIcon icon={faExternalLink}></FontAwesomeIcon>
                  </div>
                )}
                {advancedSelectionOverlay !== 'links' && (
                  <div
                    className={cn('link-advanced-settings', { active: geolinkMatches && geolinkMatches.length })}
                    onClick={() => {
                      this.props.toggleAdvancedPanel('links');
                      addEvent(`Collections - Switch To Geo Links Panel`, {
                        username: getUsername(this.props.user),
                        name: getName(this.props.user)
                      });
                    }}
                  >
                    Set Country Specific Links
                    <FontAwesomeIcon icon={faMapMarker}></FontAwesomeIcon>
                  </div>
                )}
              </div>
              <div className='input-header'>Image URL</div>
              <input
                name='image'
                id='image'
                value={image}
                type='text'
                placeholder='Add an image link'
                className='input'
                onChange={this.handleChange}
              />
              <div onClick={this.toggleMonetization} className='toggle-link'>
                <span>Link Monetization {disable_monetization ? 'Off' : 'On'}</span>
                <Tooltip
                  message={`This means you're earning commission if someone buys through this link. If you are inputting your own affiliate links, turn this off.`}
                  getIconDiv={() => <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>}
                />
                <Switch
                  onChange={() => {}}
                  checked={!disable_monetization}
                  height={14}
                  width={24}
                  onColor='#28b928'
                  className='switch'
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
              </div>
              <div onClick={this.toggleOptimization} className='toggle-link'>
                <span>Link Optimization {allow_optimization ? 'On' : 'Off'}</span>
                <Tooltip
                  message={`By turning this on, we'll automatically optimize to find the retailer with the best commission rate and replace out of stock links.`}
                  getIconDiv={() => <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>}
                />
                <Switch
                  onChange={() => {}}
                  checked={allow_optimization}
                  height={14}
                  width={24}
                  onColor='#28b928'
                  className='switch'
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
              </div>
              <div onClick={this.toggleAllowDeepLinkToApp} className={cn('toggle-link', { disabled: !appDeepLinkingEnabled })}>
                <span>Smart App Linking {allow_deep_link_to_app ? 'On' : 'Off'}</span>
                <Tooltip
                  message={
                    appDeepLinkingEnabled
                      ? `By turning this on, we will attempt to link to the ${merchantData?.name ||
                          'merchant'} app if the user has it installed. If not, we will link to the web.`
                      : `This feature is not available for links to ${merchantData?.domain || 'this website'}.`
                  }
                  getIconDiv={() => <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>}
                />
                <Switch
                  onChange={() => {}}
                  checked={allow_deep_link_to_app}
                  height={14}
                  width={24}
                  onColor='#28b928'
                  className='switch'
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
              </div>
              {(code || isAdmin(this.props.user)) && (
                <div onClick={this.toggleDisplayCodes} className='toggle-link'>
                  {code ? <span>Display Your Code: {code.displayText}</span> : <span>Display Discount Code</span>}
                  <Tooltip
                    message={`Determine whether you want to display any codes associated with this product for easy viewing by your audience.`}
                    getIconDiv={() => <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>}
                  />
                  <Switch
                    onChange={() => {}}
                    checked={allow_displaying_codes}
                    height={14}
                    width={24}
                    onColor='#28b928'
                    className='switch'
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
              )}
              {window.__ADMIN_CONTROL_MODE__ && !!pinBeingEdited && <AddPinModalAdminControls user={user} pin={pinBeingEdited} />}
            </div>
          </div>
        </DropUploader>
      </div>
    );
  }
}

export default AddPinModalFinalTile;
