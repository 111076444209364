import React from 'react';
import _ from 'lodash';

import cogoToast from 'cogo-toast';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmPrompt from '../Components/General/ConfirmPrompt';

import { blockOnRequiringSubscription } from './subscription_helpers';
import { getBrand } from './user_helpers';
import { getAllIntegrationsHaveCustomerScopesEnabled } from './shopify_helpers';

export const setCustomRate = ({ user, artist, analytics, setCustomCommissionRate, closeCallback }) => {
  if (!user || !artist || !analytics || !setCustomCommissionRate) return cogoToast.error(`Missing Parameters`);
  if (blockOnRequiringSubscription(user, 'CUSTOM_RATES', { directIntegrationRequired: true })) return;
  const existingRate = _.find(analytics.brandAnalytics?.customRates, r => r.User_id === artist.id);
  const brand = getBrand(user);
  const showRateReturningField = brand.commission_rate_returning || existingRate?.rate_returning;

  let customFields = [];
  customFields.push({
    display: showRateReturningField ? 'Custom Rate for New Customers' : 'Custom Rate',
    placeholder: `${brand.commission_rate || 20}%`,
    preloaded: existingRate?.rate || '',
    value: 'rate',
    isSingleLine: true
  });

  if (showRateReturningField) {
    customFields.push({
      display: 'Custom Rate for Returning Customers',
      placeholder: `${brand.commission_rate_returning || existingRate?.rate || 20}%`,
      preloaded: existingRate?.rate_returning || '',
      value: 'rate_returning',
      isSingleLine: true
    });
  }

  confirmAlert({
    customUI: ({ onClose }) => {
      const closeModal = () => {
        onClose();
        closeCallback && closeCallback();
      };
      return (
        <ConfirmPrompt
          header=''
          subheader=''
          onCancel={closeModal}
          customInputFields={customFields}
          secondaryBtn={
            existingRate
              ? {
                  display: 'Delete Rate',
                  onClick: async () => {
                    await setCustomCommissionRate({ User_id: artist.id, rate: null, rate_returning: null });
                    window.ALERT.success(`Successfully removed custom rate.`);
                    window.__ADD_EVENT__('Talent Card - Remove Custom Rate');
                    closeModal();
                  }
                }
              : null
          }
          submitMustReturnTrueToClose
          onSubmitAwait={async responseValues => {
            const { rate, rate_returning } = responseValues;
            const allShopifyIntegrationsHaveCustomerScopesEnabled = getAllIntegrationsHaveCustomerScopesEnabled(user);

            if (!rate && rate_returning) {
              window.ALERT.error('You cannot set a returning customer rate without setting a base custom rate.');
              return false;
            }
            if (rate_returning && !allShopifyIntegrationsHaveCustomerScopesEnabled) {
              window.ALERT.error('You must enable the "read_customers" scope in your Shopify integration to set a returning customer rate.');
              return false;
            }
            if (rate && _.isNaN(parseFloat(rate))) {
              window.ALERT.error('Rate must be a valid number');
              return false;
            }
            if (rate_returning && _.isNaN(parseFloat(rate_returning))) {
              window.ALERT.error('Returning Rate must be a valid number');
              return false;
            }
            try {
              const response = await setCustomCommissionRate({
                User_id: artist.id,
                rate: parseFloat(rate),
                rate_returning: rate_returning ? parseFloat(rate_returning) : null
              });

              if (response?.error) return false;
              window.ALERT.success(`Successfully updated rate.`);
              window.__ADD_EVENT__('Talent Card - Offer Custom Rate', { rate, rate_returning, creator: artist.name });
              return true;
            } catch (error) {
              window.ALERT.error(`There was an error setting this custom rate, please try again.`);
              return false;
            }
          }}
        />
      );
    }
  });
};
