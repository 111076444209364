import React from 'react';
import './CatalogVariantRateCard.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Image from '../General/Image';
import { openCatalogProductModal } from '../../Actions/UIActions';

const CatalogVariantRateCard = props => {
  const { variantRate, isSkeleton } = props;

  if (isSkeleton) {
    return (
      <div className='catalog-variant-rate-card'>
        <div className='variant-rate skeleton' />
        <div className='variant-image skeleton' />
        <div className='variant-meta'>
          <div className='variant-brand skeleton' />
          <div className='variant-title skeleton' />
        </div>
      </div>
    );
  }

  const handleOpenProductModal = () => {
    const variantToLoad = {
      id: variantRate.ParentVariant_id,
      requiresLoad: true
    };
    props.openCatalogProductModal(variantToLoad);
  };

  const siblingTags = [variantRate.variantNonSizeTag, variantRate.variantSizeTag].filter(Boolean);

  return (
    <div className='catalog-variant-rate-card' onClick={handleOpenProductModal}>
      <div className='variant-rate'>{variantRate.rate}% Commission</div>
      <div className='variant-image'>
        <Image src={variantRate.variantImage} alt={variantRate.variantTitle || 'Default'} />
      </div>
      <div className='variant-meta'>
        <div className='variant-brand'>{variantRate.variantBrand}</div>
        <div className='variant-title'>{variantRate.variantTitle}</div>
        <div className='variant-sibling-info'>
          {siblingTags.map((tag, index) => (
            <div key={index} className='sibling-tag'>
              {index > 0 && <span className='bullet'>{'•'}</span>}
              {tag}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

CatalogVariantRateCard.propTypes = {
  variantRate: PropTypes.object.isRequired,
  openCatalogProductModal: PropTypes.func.isRequired,
  isSkeleton: PropTypes.bool
};

export default connect(null, {
  openCatalogProductModal
})(CatalogVariantRateCard);
