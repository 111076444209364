import APIClient from './index';
import _ from 'lodash';

export const addCatalogSyncTask = taskData => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/CatalogTasks`, taskData)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const fetchCatalogSyncTasksStatus = Brand_id => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/CatalogTasks/status/${Brand_id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};
