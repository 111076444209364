import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './LookbookReport.scss';

import { connect } from 'react-redux';
import { getLookbookRequests, lookbookReportItemFilter } from '../../../../Helpers/lookbook_helpers';

import LookbookReportStats from './LookbookReportStats';
import LookbookReportTalent from './LookbookReportTalent';
import LookbookReportProducts from './LookbookReportProducts';
import LookbookReportMentions from './LookbookReportMentions';
import LookbookReportData from './LookbookReportData';
import LookbookReportControls from './LookbookReportControls';
import Loader from '../../../Loader/Loader';

const LookbookReport = props => {
  const { lookbook, user, analytics } = props;

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const updateDates = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const [isFetchingMentions, setIsFetchingMentions] = useState(false);
  const [isFetchingPins, setIsFetchingPins] = useState(false);

  const requests = getLookbookRequests(analytics, lookbook);
  const [pins, setPins] = useState([]);
  const [mentions, setMentions] = useState([]);
  const [mentionUserStats, setMentionUserStats] = useState([]);
  const filteredRequests = requests.filter(r => lookbookReportItemFilter(r, 'request', startDate, endDate));
  const filteredMentions = mentions.filter(m => lookbookReportItemFilter(m, 'mention', startDate, endDate));
  const hasSelectedProducts = filteredRequests?.some(request => request.lookbook_order?.items?.length);

  const showTalentSection = !!filteredRequests.length;
  const showProductSection = !!hasSelectedProducts;
  const showMentionsSection = !!filteredMentions.length;

  return (
    <div className='lookbook-report-outer'>
      <LookbookReportData
        lookbook={lookbook}
        user={user}
        requests={requests}
        startDate={startDate}
        endDate={endDate}
        setMentions={setMentions}
        setMentionUserStats={setMentionUserStats}
        setPins={setPins}
        setIsFetchingMentions={setIsFetchingMentions}
        setIsFetchingPins={setIsFetchingPins}
      />
      <div className='lookbook-report-inner'>
        <LookbookReportControls startDate={startDate} endDate={endDate} updateDates={updateDates} />

        <div className='lookbook-report-section'>
          <div className='lookbook-report-section-title'>Overall Statistics</div>
          <LookbookReportStats
            mentionUserStats={mentionUserStats}
            pins={pins}
            analytics={analytics}
            lookbook={lookbook}
            isFetchingPins={isFetchingPins}
          />
        </div>

        {showTalentSection ? (
          <div className='lookbook-report-section'>
            <div className='lookbook-report-section-title'>Creator Performance</div>
            <LookbookReportTalent
              lookbook={lookbook}
              mentionUserStats={mentionUserStats}
              pins={pins}
              startDate={startDate}
              endDate={endDate}
              isFetchingMentions={isFetchingMentions}
              isFetchingPins={isFetchingPins}
            />
          </div>
        ) : null}

        {showProductSection ? (
          <div className='lookbook-report-section'>
            <div className='lookbook-report-section-title'>Top Products Selected</div>
            <LookbookReportProducts lookbook={lookbook} analytics={analytics} startDate={startDate} endDate={endDate} />
          </div>
        ) : null}

        {isFetchingMentions ? (
          <Loader />
        ) : showMentionsSection ? (
          <div className='lookbook-report-section'>
            <div className='lookbook-report-section-title'>Creator Content</div>
            <LookbookReportMentions
              requests={requests}
              lookbook={lookbook}
              mentions={mentions}
              startDate={startDate}
              endDate={endDate}
              isFetchingMentions={isFetchingMentions}
            />
          </div>
        ) : null}

        {!showProductSection && !showTalentSection && !showMentionsSection && !isFetchingMentions && !isFetchingPins && (
          <div className='lookbook-report-no-data'>
            No data available for the selected date range.
            <button
              className='clear-dates'
              onClick={() => {
                setStartDate(null);
                setEndDate(null);
              }}
            >
              Clear Dates
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

LookbookReport.propTypes = {
  lookbook: PropTypes.object.isRequired,

  // redux
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user, analytics, talent } = state;
  return { user, analytics, talent };
};

export default connect(mapStateToProps)(LookbookReport);
