import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './AddCatalogGroupModal.scss';
import InfiniteScrollSelect from '../General/InfiniteScrollSelect';
import { getAllShopifyIntegrations, getBrandId } from '../../Helpers/user_helpers';
import { getShopifyCollections } from '../../APIClient/shopify';
import SelectOption from '../General/SelectOption';

const AddCatalogGroupShopifyCollections = props => {
  const { user, isConnecting } = props;

  const [shopifyIntegrationOptions, setShopifyIntegrationOptions] = useState([]);
  const [selectedIntegrationId, setSelectedIntegrationId] = useState(null);
  const [selectedShopifyCollection, setSelectedShopifyCollection] = useState(null);
  const [collectionsHasNextPage, setCollectionsHasNextPage] = useState(true);
  const [lastCursor, setLastCursor] = useState(null);
  const [isLoadingCollections, setIsLoadingCollections] = useState(false);

  useEffect(() => {
    const allShopifyIntegrations = getAllShopifyIntegrations(user);
    const validShopifyIntegrations = allShopifyIntegrations.filter(integration => integration.allowProductSyncing);

    setShopifyIntegrationOptions(
      validShopifyIntegrations.map(integration => ({
        label: integration.shopName,
        sublabel: integration.domain,
        value: integration.id
      }))
    );

    setSelectedIntegrationId(validShopifyIntegrations[0]?.id || null);
  }, []);

  const loadShopifyCollectionsPage = async (searchTerm, isNewSearch = false) => {
    if (!collectionsHasNextPage && !isNewSearch) return [];

    const data = {
      BrandIntegration_id: selectedIntegrationId
    };

    if (searchTerm) {
      data.searchTerm = searchTerm;
    }

    if (!isNewSearch && lastCursor) {
      data.lastCursor = lastCursor;
    }

    let response;
    try {
      setIsLoadingCollections(true);
      response = await getShopifyCollections(data);
    } catch (e) {
      console.error(e);
      window.ALERT.error('There was an error loading your shopify collections, please try again.');
      setIsLoadingCollections(false);
      return [];
    }

    const collections = response.collections?.edges || [];
    const hasNextPage = response.collections?.pageInfo?.hasNextPage || false;
    const formattedCollections = collections.map(cEdge => ({
      label: cEdge.node.title,
      value: +cEdge.node.id.split('/').pop(),
      subLabel: `${cEdge.node.productsCount.count} ${cEdge.node.productsCount.count === 1 ? 'product' : 'products'}`
    }));
    if (hasNextPage) {
      const lastCollection = _.last(collections);
      setLastCursor(lastCollection?.cursor || null);
    }

    setCollectionsHasNextPage(hasNextPage);
    setIsLoadingCollections(false);
    return formattedCollections;
  };

  const hasMultipleShopifyIntegrations = shopifyIntegrationOptions.length > 1;
  const isSubmitDisabled = !selectedShopifyCollection || isConnecting;

  const handleConnect = () => {
    if (isSubmitDisabled || isConnecting) return;

    const data = {
      type: 'shopify_collection',
      title: selectedShopifyCollection.label,
      externalId: `${selectedShopifyCollection.value}`,
      BrandIntegration_id: selectedIntegrationId,
      Brand_id: getBrandId(user)
    };

    props.onConnect(data);
  };

  return (
    <div className='shopify-collection-catalog-group-container'>
      <div className='shopify-add-group-content'>
        {hasMultipleShopifyIntegrations ? (
          <div className='integration-select-container'>
            <div className='description'>
              You have multiple Shopify integrations. Select one to connect a Shopify collection from that integrated store.
            </div>
            <div className='shopify-integration-select-field'>
              <Select
                options={shopifyIntegrationOptions}
                value={shopifyIntegrationOptions.find(option => option.value === selectedIntegrationId)}
                onChange={selected => {
                  setSelectedIntegrationId(selected.value);
                  setSelectedShopifyCollection(null);
                }}
                components={{ Option: SelectOption }}
                styles={{
                  singleValue: provided => ({
                    ...provided,
                    fontWeight: 'bold',
                    fontSize: '14px'
                  })
                }}
              />
            </div>
          </div>
        ) : null}
        <div className='shopify-add-group-search-container'>
          <InfiniteScrollSelect
            disabled={true}
            isMultiple={false}
            closeMenuOnSelect={true}
            onSelectOptions={selected => {
              setSelectedShopifyCollection(selected);
            }}
            value={selectedShopifyCollection}
            loadMoreOptions={loadShopifyCollectionsPage}
            menuHeight={'170px'}
            isLoading={isLoadingCollections}
            placeholder={'Select Collection'}
          />
        </div>
      </div>
      <div className='shopify-add-group-footer'>
        <div className={cn('action-button', { disabled: isSubmitDisabled })} onClick={handleConnect}>
          {isConnecting ? 'Connecting...' : 'Connect'}
        </div>
      </div>
    </div>
  );
};

AddCatalogGroupShopifyCollections.propTypes = {
  user: PropTypes.object.isRequired,
  onConnect: PropTypes.func.isRequired,
  isConnecting: PropTypes.bool.isRequired
};

export default AddCatalogGroupShopifyCollections;
