import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import cogoToast from 'cogo-toast';
import cn from 'classnames';
import _ from 'lodash';

import { copyToClipboard, getRootUrl } from '../../Helpers/helpers';
import { getShortCollectionLink } from '../../Helpers/attribution_helpers';

import './ProfileManagementHeader_SMS.scss';
import './ProfileManagementHeader_SL.scss';
import './ProfileManagementHeader_PRO.scss';

class ProfileManagementHeader extends Component {
  static propTypes = {
    ui: PropTypes.object.isRequired,
    shop: PropTypes.object, // when on profile page
    collection: PropTypes.object, // when on collection page
    setAnalyticsMode: PropTypes.func.isRequired,
    openSettingsPanel: PropTypes.func
  };

  render() {
    const { ui, shop, setAnalyticsMode, openSettingsPanel } = this.props;
    const analyticsOn = _.get(ui, 'inAnalyticsMode') === true;
    return (
      <div className='action-row'>
        <div className='action-row-inner-container'>
          {openSettingsPanel && (
            <div onClick={openSettingsPanel} className={cn('open-more-btn', 'btn')}>
              {isMobile ? 'SETTINGS & SHARING' : 'SETTINGS & SHARING'}
            </div>
          )}
          {!!shop && (
            <div onClick={this.copyLink} className={cn('share-link-btn', 'btn')}>
              <div>Copy Link</div>
            </div>
          )}
          <div
            onClick={() => setAnalyticsMode(!analyticsOn)}
            className={cn('analytics-link-label', 'btn', {
              active: analyticsOn
            })}
          >
            <div className='label'>{analyticsOn ? 'Hide' : 'Show'} Analytics</div>
          </div>
        </div>
      </div>
    );
  }

  getPublicLink = () => {
    // SMS only for now
    const { collection, shop } = this.props;
    const { id } = collection || {};
    const { username } = shop || {};
    return collection ? `${getRootUrl()}/collections/public/${id}` : `${getRootUrl()}/public/${username}`;
  };

  getUserLink = () => {
    const { shop } = this.props;
    const { username } = shop || {};
    return `${getRootUrl()}/${username}`;
  };

  copyLink = () => {
    const { collection } = this.props;
    copyToClipboard(window.__IS_SMS__ ? (collection ? getShortCollectionLink(collection) : this.getUserLink()) : window.location.href);
    cogoToast.success(`Copied link to clipboard`, { hideAfter: 1 });
  };
}

export default ProfileManagementHeader;
