import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrochipAi } from '@fortawesome/pro-regular-svg-icons';
import './ShopMyIntelligenceToggle.scss';

import Tooltip from '../Tooltip';

const ShopMyIntelligenceToggle = props => {
  const { isBadge, isActive, setIsActive } = props;
  const toggle = () => setIsActive(!isActive);

  const additionalClasses = { active: isActive, badge: isBadge };

  return (
    <div onClick={toggle} className={cn('shop-my-intelligence-toggle', additionalClasses)}>
      <div className={cn('icon', additionalClasses)}>
        <Tooltip message={isActive ? 'ShopMy Intelligence is enabled. Click to disable.' : 'ShopMy Intelligence is disabled. Click to enable.'}>
          <FontAwesomeIcon icon={faMicrochipAi} />
        </Tooltip>
      </div>
    </div>
  );
};

ShopMyIntelligenceToggle.propTypes = {
  // Required
  isActive: PropTypes.bool.isRequired,
  setIsActive: PropTypes.func.isRequired,

  // Optional
  isBadge: PropTypes.bool
};

export default ShopMyIntelligenceToggle;
