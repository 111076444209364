import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './YourSubscriptionOffers.scss';

import { getBrandSubscription } from '../../Helpers/user_helpers';

import SubscriptionOfferTile from './SubscriptionOfferTile';

const YourSubscriptionOffers = props => {
  const { user } = props;
  const subscription = getBrandSubscription(user);

  const activeOffers = subscription.items.filter(i => !i.isCancelled).map(i => i.offer);
  const numMonthsSubscribed = subscription?.startedAt ? moment().diff(moment(subscription.startedAt), 'months') : null;
  const isAffiliatesOnlyBrand = activeOffers.length === 1 && activeOffers[0].title === 'Affiliates Only';

  return (
    <div className='your-subscription-offers-container'>
      <div className='container-title'>
        {!!numMonthsSubscribed ? (
          <div className='badge main'>
            Member for {numMonthsSubscribed} month{numMonthsSubscribed > 1 ? 's' : ''}
          </div>
        ) : (
          <div className='badge main'>Recently Joined</div>
        )}
        {isAffiliatesOnlyBrand ? (
          <div className='header main'>{activeOffers[0].title}</div>
        ) : subscription.bundle ? (
          <div className='header main'>Subscribed to the {subscription.bundle.title}</div>
        ) : (
          <div className='header main'>Your Subscription</div>
        )}
      </div>
      <div className='your-subscription-offers'>
        {!isAffiliatesOnlyBrand &&
          activeOffers.map(offer => {
            return <SubscriptionOfferTile offer={offer} />;
          })}
      </div>
    </div>
  );
};

YourSubscriptionOffers.propTypes = {
  user: PropTypes.object.isRequired
};

export default YourSubscriptionOffers;
