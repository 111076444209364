import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { faTimes, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { Link } from 'react-router-dom';
import commaNumber from 'comma-number';
import _ from 'lodash';
import cn from 'classnames';
import './HeaderBudgetPanel.scss';

import { openBrandBudgetModal } from '../../../Actions/UIActions';
import useShowPanelOnTriggerHover from '../../../CustomHooks/useShowPanelOnTriggerHover';

import { dontBubble } from '../../../Helpers/helpers';
import { getBrandBudget } from '../../../Helpers/user_helpers';
import { getOpportunityRequestsAwaitingCompletion, getOpportunityRequestsOutstanding } from '../../../Helpers/brand_helpers';

const TESTING = null; // [Total, Awaiting Completion, Offers Out]

const HeaderBudgetPanel = props => {
  const { analytics, user, closePanel, triggerRef, visible } = props;

  // Get the total budget
  const budget = getBrandBudget(user) || {};

  // Get the various budget elements
  const totalBudget = TESTING ? TESTING[0] : budget.amountRemaining || 0;

  // Opps in progress, awaiting completion
  const requestsAwaitingCompletion = getOpportunityRequestsAwaitingCompletion(analytics, user);
  const numCreatorsAwaitingOppCompletion = _.uniq(requestsAwaitingCompletion.map(r => r.User_id)).length;
  const budgetAwaitingOppCompletion = TESTING ? TESTING[1] : _.sumBy(requestsAwaitingCompletion, r => r.payment_tier?.fixedFee || 0);

  // Outstanding Requests
  const requestsOutstanding = getOpportunityRequestsOutstanding(analytics, user);
  const budgetOutstanding = TESTING ? TESTING[2] : _.sumBy(requestsOutstanding, r => r.payment_tier?.fixedFee || 0);

  // Available
  const budgetAvailable = Math.round((totalBudget - budgetAwaitingOppCompletion - budgetOutstanding) * 100) / 100;

  // Checking for Overdrafting
  const hasNegativeAvailableBudget = budgetAvailable < 0;
  const notEnoughBudgetForIncompleteRequests = totalBudget < budgetAwaitingOppCompletion;

  // For the meter
  const totalBudgetIncludingOverdraft = budgetAwaitingOppCompletion + budgetOutstanding + Math.abs(budgetAvailable);

  const budgetSections = [
    {
      display: 'Awaiting Completion',
      subdisplay: `Waiting for ${numCreatorsAwaitingOppCompletion} creator${
        numCreatorsAwaitingOppCompletion === 1 ? '' : 's'
      } to complete their paid ${requestsAwaitingCompletion.length === 1 ? 'opportunity' : 'opportunities'}.`,
      amount: budgetAwaitingOppCompletion,
      meterLevel: Math.abs(budgetAwaitingOppCompletion / totalBudgetIncludingOverdraft),
      className: 'waiting'
    },
    {
      display: 'Offers Out',
      subdisplay: `Waiting for ${requestsOutstanding.length} creator${requestsOutstanding.length === 1 ? '' : 's'} to respond to their requests.`,
      amount: budgetOutstanding,
      meterLevel: Math.abs(budgetOutstanding / totalBudgetIncludingOverdraft),
      className: 'outstanding'
    },
    {
      display: hasNegativeAvailableBudget ? 'Overdrafted' : 'Available',
      subdisplay: hasNegativeAvailableBudget
        ? notEnoughBudgetForIncompleteRequests
          ? 'You currently have in-progress opportunities. Creators cannot be paid if they complete the opportunity and you do not have enough budget.'
          : 'You currently have more opportunity requests out than you have the balance for. If you run out of available funds, remaining creators will not be able to accept their opportunities.'
        : 'Remaining budget available for sending new opportunities.',
      amount: budgetAvailable,
      meterLevel: Math.abs(budgetAvailable / totalBudgetIncludingOverdraft),
      className: hasNegativeAvailableBudget ? 'overdrafted' : 'available'
    }
  ].filter(section => section.amount);

  // Actions
  const clickToAddBudget = () => props.openBrandBudgetModal();

  // Allow for showing and hiding
  const panelRef = React.createRef();
  useShowPanelOnTriggerHover({ triggerRef, panelRef, closePanel, visible });

  const additionalClasses = { visible: props.visible };
  return (
    <div onClick={dontBubble} className={cn('header-budget-panel-outer-container', additionalClasses)}>
      <div onClick={props.closePanel} className={cn('fade-background', additionalClasses)} />
      <div ref={panelRef} className={cn('header-budget-panel-inner-container', additionalClasses)}>
        <div onClick={props.closePanel} className='close-icon'>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        {!!totalBudgetIncludingOverdraft && (
          <Link className='view-details-link' to='/subscriptions/budget'>
            <div>View Transactions</div>
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        )}
        <div className={cn('section header-section', additionalClasses)}>
          <div className='budget-overall'>
            <div className='label'>Available Performance Balance</div>
            <div className='amount'>
              {hasNegativeAvailableBudget ? '-' : ''}${commaNumber(Math.abs(budgetAvailable))}
              {totalBudget !== budgetAvailable && <span className='of-total'>of ${commaNumber(totalBudget)}</span>}
            </div>
          </div>
          <div className='actions'>
            <div
              onClick={clickToAddBudget}
              className={cn('action', {
                primary: !hasNegativeAvailableBudget,
                negative: hasNegativeAvailableBudget
              })}
            >
              Add Funds
            </div>
          </div>
        </div>
        {budgetSections.length > 1 && (
          <div className={cn('section meter-section', additionalClasses)}>
            <div className='meter main' />
            {budgetSections.map((section, index) => {
              const { meterLevel, className } = section;
              const previousMeterLevel = _.sumBy(budgetSections.slice(0, index), 'meterLevel');
              return (
                <div key={index} className={cn('meter', className)} style={{ left: `${previousMeterLevel * 100}%`, width: `${meterLevel * 100}%` }} />
              );
            })}
          </div>
        )}
        <div className={cn('section budget-sections', additionalClasses)}>
          {budgetSections.map((section, index) => {
            const { display, subdisplay, className } = section;
            const isNegative = section.amount < 0;
            const additionalClasses = { negative: isNegative, [className]: true };
            return (
              <div key={index} className={cn('budget-section', additionalClasses)}>
                <div className={cn('color-key', additionalClasses)}>{isNegative && <FontAwesomeIcon icon={faExclamationTriangle} />}</div>
                <div className={cn('data', additionalClasses)}>
                  <div className={cn('display', additionalClasses)}>{display}</div>
                  {subdisplay && <div className={cn('subdisplay', additionalClasses)}>{subdisplay}</div>}
                </div>
                <div className={cn('amount', additionalClasses)}>
                  <div>
                    {isNegative ? '-' : ''}${commaNumber(Math.abs(section.amount))}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

HeaderBudgetPanel.propTypes = {
  // Inside
  analytics: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,

  // Outside
  triggerRef: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  closePanel: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { analytics, user } = state;
  return { analytics, user };
};

export default connect(mapStateToProps, {
  openBrandBudgetModal
})(HeaderBudgetPanel);
