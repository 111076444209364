import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Image from '../General/Image';
import InlineRateInput from './InlineRateInput';

const CatalogProductModalSibling = props => {
  const { sibling, existingSiblingRate, shouldAllowSettingVariantRates, commissionRatePlaceholder, handleSaveRate } = props;

  const [siblingRate, setSiblingRate] = useState('');
  const [isSavingSiblingRate, setIsSavingSiblingRate] = useState(false);

  useEffect(() => {
    setSiblingRate(existingSiblingRate?.rate?.toString() || '');
  }, [existingSiblingRate]);

  const saveSiblingRate = async inputRate => {
    setIsSavingSiblingRate(true);
    await handleSaveRate({ inputRate, SpecificVariantSibling_id: sibling.id });
    setIsSavingSiblingRate(false);
  };

  return (
    <div key={sibling.id} className='sibling-row'>
      <div className='table-cell lg'>
        <Image src={sibling.image} alt={sibling.nonSizeTag || 'Default'} />
        <div className='product-title'>{sibling.nonSizeTag || 'Default'}</div>
      </div>
      <div className='table-cell sm center'>{sibling.isAvailable ? 'In Stock' : 'Out of Stock'}</div>
      <div className='table-cell sm center'>{sibling.sizeTag || '—'}</div>
      {shouldAllowSettingVariantRates && (
        <div className='table-cell sm center'>
          <div className='sibling-rate-container'>
            <InlineRateInput
              value={siblingRate}
              placeholder={commissionRatePlaceholder || ''}
              onRateChange={setSiblingRate}
              handleSaveRate={() => saveSiblingRate(siblingRate)}
              isDisabled={isSavingSiblingRate}
              isActionHidden={!existingSiblingRate}
              handleRateAction={() => !!existingSiblingRate && saveSiblingRate('')}
            />
          </div>
        </div>
      )}
    </div>
  );
};

CatalogProductModalSibling.propTypes = {
  sibling: PropTypes.object.isRequired,
  existingSiblingRate: PropTypes.object,
  shouldAllowSettingVariantRates: PropTypes.bool,
  commissionRatePlaceholder: PropTypes.string,
  handleSaveRate: PropTypes.func.isRequired
};

export default CatalogProductModalSibling;
