import React from 'react';
import './InlineRateInput.scss';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';

const InlineRateInput = props => {
  const {
    onFocus,
    value,
    placeholder,
    onRateChange,
    handleSaveRate,
    isDisabled,
    isActionDisabled,
    handleRateAction,
    isActionHidden = false,
    actionButtonText = 'Clear',
    autoFocus = false
  } = props;

  const handleRateChange = evt => {
    const inputRate = evt.target.value;

    if (!inputRate) {
      return onRateChange ? onRateChange('') : null;
    }

    const numericalRate = parseFloat(inputRate);
    if (_.isNaN(numericalRate) || numericalRate < 0 || numericalRate > 100) {
      return;
    }

    // enforce 2 decimal places max
    const formattedRate = inputRate.match(/^\d*\.?\d{0,2}$/) ? inputRate : numericalRate.toFixed(2);
    onRateChange && onRateChange(formattedRate);
  };

  return (
    <div className='inline-rate-container'>
      <div className='input-rate-container'>
        <input
          onFocus={onFocus}
          autoFocus={autoFocus}
          value={value}
          placeholder={placeholder}
          onChange={handleRateChange}
          onBlur={handleSaveRate}
          disabled={isDisabled}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              event.target.blur();
            }
          }}
        />
        <span>%</span>
      </div>
      {!isActionHidden && (
        <div
          className={cn('input-rate-action', { disabled: isActionDisabled, delete: actionButtonText === 'Delete' })}
          onClick={() => {
            if (isActionDisabled || !handleRateAction) return;
            handleRateAction();
          }}
        >
          {actionButtonText || 'Clear'}
        </div>
      )}
    </div>
  );
};

InlineRateInput.propTypes = {
  onFocus: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onRateChange: PropTypes.func,
  handleSaveRate: PropTypes.func,
  isDisabled: PropTypes.bool,
  isActionDisabled: PropTypes.bool,
  handleRateAction: PropTypes.func,
  isActionHidden: PropTypes.bool,
  autoFocus: PropTypes.bool
};

export default InlineRateInput;
