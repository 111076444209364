import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './EarningsPortal.scss';

import EarningsPortalData from './EarningsPortalData';
import EarningsPortalHeader from './EarningsPortalHeader';
import EarningsPortalMonthlyBreakdown from './EarningsPortalMonthlyBreakdown';
import EarningsPortalReferrals from './EarningsPortalReferrals';
import EarningsPortalCommissions from './EarningsPortalCommissions';
import EarningsPortalConsults from './EarningsPortalConsults';
import EarningsPortalCollaborations from './EarningsPortalCollaborations';
import EarningsPortalPayments from './EarningsPortalPayments';
import EarningsPortalPaymentAccounts from './EarningsPortalPaymentAccounts';
import RequiresPermissions from '../../Components/Managers/RequiresPermissions';
import ScrollToTop from '../../Components/General/ScrollToTop';

const EarningsPortal = props => {
  const { user } = props;
  const [payoutsAreLoading, setPayoutsAreLoading] = useState(true);
  const [paymentsAreLoading, setPaymentsAreLoading] = useState(true);

  return (
    <RequiresPermissions permission='canViewEarnings'>
      <ScrollToTop />

      <div className='earnings-portal-outer'>
        <EarningsPortalData setPayoutsAreLoading={setPayoutsAreLoading} setPaymentsAreLoading={setPaymentsAreLoading} />

        <div className='earnings-portal-inner'>
          <EarningsPortalHeader payoutsAreLoading={payoutsAreLoading} user={user} />

          <EarningsPortalMonthlyBreakdown payoutsAreLoading={payoutsAreLoading} user={user} />
          <EarningsPortalReferrals payoutsAreLoading={payoutsAreLoading} user={user} />
          <EarningsPortalCommissions payoutsAreLoading={payoutsAreLoading} user={user} />
          <EarningsPortalConsults payoutsAreLoading={payoutsAreLoading} user={user} />
          <EarningsPortalCollaborations payoutsAreLoading={payoutsAreLoading} user={user} />
          <EarningsPortalPayments paymentsAreLoading={paymentsAreLoading} user={user} />
          <EarningsPortalPaymentAccounts user={user} />
        </div>
      </div>
    </RequiresPermissions>
  );
};

EarningsPortal.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps)(EarningsPortal);
