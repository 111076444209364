import React from 'react';
import PropTypes from 'prop-types';
import './CustomTooltip.scss';

import useShowPanelOnTriggerHover from '../../CustomHooks/useShowPanelOnTriggerHover';

const CustomTooltip = props => {
  /*
    Use this Tooltip if you want to show a custom panel when hovering over a trigger element.

    Example:
      <CustomTooltip
        tooltipEl={<div>Some Custom Panel</div>}
        forceVisible={false}
        allowTooltipInteraction={false}
        delayBeforeOpening={50}
        delayBeforeClosing={50}
      >
        <div>Trigger Element</div>
      </CustomTooltip>
  */

  const { children } = props;
  const panelDebouce = React.useRef(null);
  const triggerRef = React.useRef(null);
  const [visible, setVisible] = React.useState(false);

  return (
    <div className='custom-tooltip-outer-container'>
      <div
        ref={triggerRef}
        onClick={() => !visible && setVisible(true)}
        onMouseMove={() => (panelDebouce.current = panelDebouce.current || setTimeout(() => setVisible(true), props.delayBeforeOpening || 0))}
        onMouseOut={() => {
          clearTimeout(panelDebouce.current);
          panelDebouce.current = null;
        }}
      >
        {children}
      </div>
      {(props.forceVisible || visible) && (
        <CustomTooltipWrapper visible closePanel={() => setVisible(false)} triggerRef={triggerRef} {...props}>
          {props.tooltipEl}
        </CustomTooltipWrapper>
      )}
    </div>
  );
};

const CustomTooltipWrapper = props => {
  const { triggerRef, visible, closePanel } = props;
  const panelRef = React.createRef();
  useShowPanelOnTriggerHover({ panelRef, triggerRef, closePanel, visible, debounceDuration: props.delayBeforeClosing || 0 });
  return (
    <div className='custom-tooltip-wrapper' ref={props.allowTooltipInteraction ? panelRef : null}>
      {props.children}
    </div>
  );
};

CustomTooltip.propTypes = {
  children: PropTypes.object.isRequired,
  tooltipEl: PropTypes.object,

  // Optional
  forceVisible: PropTypes.bool, // If you want to force it visible for some reason
  allowTooltipInteraction: PropTypes.bool, // Don't close tooltip when hovering over it
  delayBeforeOpening: PropTypes.number, // Delay Xms while hovering on tooltip trigger before opening
  delayBeforeClosing: PropTypes.number // Delay Xms while hovering off tooltip trigger (or panel if allowTooltipInteraction) before closing
};

export default CustomTooltip;
