import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import sanitizeHtml from 'sanitize-html';
import cn from 'classnames';
import './TagTypeInput.scss';
import TagPill from './TagPill';
import _ from 'lodash';

const TagTypeInput = props => {
  const { maxTagLength = 30, showDescription = true, handleSaveOnBlur, handleSingleSave, tags, restrictedTags = [], dark } = props;
  const [inputValue, setInputValue] = useState('');
  const [curTags, setCurTags] = useState(tags || []);
  const [isContentEditable, setIsContentEditable] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isShowingPlaceholder, setIsShowingPlaceholder] = useState(true);
  const inputRef = useRef(null);
  const placeholder = props.placeholder || 'Type new tag';

  useEffect(() => {
    if (!inputRef.current) return;

    if (isContentEditable) {
      inputRef.current.focus();
      inputRef.current.innerText = inputValue || '';
      setIsShowingPlaceholder(false);
    } else {
      inputRef.current.innerText = inputValue || placeholder;

      if (!inputValue) {
        setIsShowingPlaceholder(true);
      }
    }
  }, [isContentEditable, inputRef]);

  useEffect(() => {
    // handle updates
    setCurTags(tags);
  }, [tags]);

  const positionCursorAtEnd = () => {
    const range = document.createRange();
    const selection = window.getSelection();

    range.selectNodeContents(inputRef.current);
    range.collapse(false);

    selection.removeAllRanges();
    selection.addRange(range);
  };

  const cleanTagString = tagStr => {
    let cleanTag = tagStr.trim();

    // protect against cross-site scripting
    cleanTag = sanitizeHtml(cleanTag, { allowedTags: [], allowedAttributes: {} });
    return cleanTag;
  };

  const saveTags = async tagsToSave => {
    if (handleSaveOnBlur) await handleSaveOnBlur(tagsToSave);
    else if (handleSingleSave) await handleSingleSave(tagsToSave);
  };

  const handleKeyDown = evt => {
    if ([',', 'Enter'].includes(evt.key)) {
      evt.preventDefault();
      const cleanTag = cleanTagString(inputValue);

      if (cleanTag && !curTags.includes(cleanTag)) {
        setCurTags([...curTags, cleanTag]);
        setInputValue('');
        if (inputRef.current) {
          inputRef.current.innerText = '';
          inputRef.current.focus();
        }

        if (handleSingleSave) saveTags([...curTags, cleanTag]);
      } else {
        !cleanTag && window.ALERT.error('Invalid tag format');
        // clear input
        setInputValue('');
        if (inputRef.current) {
          inputRef.current.innerText = '';
          inputRef.current.focus();
        }
      }
    } else if (evt.key === 'Backspace' && !inputValue) {
      if (handleSingleSave) return; // don't allow spam deletion if we're saving on every keypress
      setCurTags(curTags.slice(0, -1));
    }
  };

  const handleChange = event => {
    const inputVal = event.target.innerText;

    if (inputVal.length > maxTagLength) {
      window.ALERT.warn(`Tag length cannot exceed ${maxTagLength} characters`);

      const trimmedVal = inputVal.substring(0, maxTagLength);

      inputRef.current && (inputRef.current.innerText = trimmedVal);
      positionCursorAtEnd();
    } else {
      setInputValue(inputVal);
    }
  };

  const handleFocus = () => {
    if (!isFocused) {
      setIsContentEditable(true);
      setIsFocused(true);
    }
  };

  const handleBlur = () => {
    let newTags = curTags;

    if (inputValue) {
      // create/save staged tag if exists
      const cleanTag = cleanTagString(inputValue);

      if (cleanTag && !curTags.includes(cleanTag)) {
        newTags = [...curTags, cleanTag];
        setCurTags(newTags);
      }

      setInputValue('');

      if (inputRef.current) {
        inputRef.current.innerText = '';
      }
    }

    setIsContentEditable(false);
    setIsFocused(false);

    const needsUpdate = !_.isEqual(newTags, tags);
    if (needsUpdate) saveTags(newTags);
  };

  const clickRemoveTag = indexToRemove => {
    const newTags = curTags.filter((_, index) => index !== indexToRemove);
    saveTags(newTags);
    setInputValue('');

    if (inputRef.current) {
      inputRef.current.innerText = placeholder;
    }

    setIsContentEditable(false);
    setIsFocused(false);
  };

  const handlePaste = evt => {
    evt.preventDefault();

    // Get plain text from clipboard
    const text = evt.clipboardData?.getData('text/plain') || '';
    let concatText = `${inputValue}${text}`;

    if (concatText.length > maxTagLength) {
      window.ALERT.warn(`Tag length cannot exceed ${maxTagLength} characters`);
      concatText = concatText.substring(0, maxTagLength);
    }

    setInputValue(concatText);

    if (inputRef.current) {
      inputRef.current.innerText = concatText;
    }

    positionCursorAtEnd();
  };

  return (
    <div className='tag-type-input-outer'>
      <div className='tag-type-input-inner'>
        <div className={cn('tag-type-container', { dark })}>
          {showDescription && (
            <div className='tag-type-description'>
              Type tags below, use comma or enter keys to add a tag. Maximum tag length is {maxTagLength} characters.
            </div>
          )}
          <div className='tag-input-inline' onMouseDown={handleFocus}>
            {restrictedTags.map((tag, index) => (
              <div key={index} onMouseDown={evt => evt.stopPropagation()}>
                <TagPill tag={tag} hideRemove={true} dark={dark} />
              </div>
            ))}
            {curTags.map((tag, index) => (
              <div key={index} onMouseDown={evt => evt.stopPropagation()}>
                <TagPill tag={tag} onRemove={() => clickRemoveTag(index)} dark={dark} />
              </div>
            ))}
            <div
              contentEditable={isContentEditable}
              ref={inputRef}
              onInput={handleChange}
              onKeyDown={handleKeyDown}
              role='textbox'
              className={cn('type-input', { placeholder: isShowingPlaceholder, dark })}
              aria-multiline='false'
              onBlur={handleBlur}
              onPaste={handlePaste}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

TagTypeInput.propTypes = {
  tags: PropTypes.array.isRequired,
  maxTagLength: PropTypes.number,
  showDescription: PropTypes.bool,
  handleSaveOnBlur: PropTypes.func,
  handleSingleSave: PropTypes.func,
  restrictedTags: PropTypes.array,
  placeholder: PropTypes.string,

  dark: PropTypes.bool
};

export default TagTypeInput;
