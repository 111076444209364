import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchange } from '@fortawesome/pro-solid-svg-icons';
import './OAuthLanding.scss';
import { isLoggedIn } from '../../Helpers/user_helpers';
import Loader from '../Loader/Loader';
import LoginForm from './Elements/LoginForm';
import PermissionScopes from './Elements/PermissionScopes';
import { validateOAuthParams as validateOAuthParamsAPI } from '../../APIClient/oauth_applications';
import ErrorBanner from '../General/ErrorBanner';
import shopMyLogo from '../../static/images/logos/logo.png';
import shopMyWhiteLogo from '../../static/images/logos/shop_my_white.png';
import { getInitialsForUser } from '../../Helpers/formatting';
import SimpleAuthPanel from '../Auth/SimpleAuthPanel';
import { getAndRemoveUrlParam } from '../../Helpers/helpers';

/*
 * example format:
 * https://shopmy.us/oauth?Developer_id=28140&scopes=read_links,read_collections,write_links&redirect_url=https://test-app.com/oauth-callback
 **/

const OAuthLanding = props => {
  const { user } = props;
  const [isValidating, setIsValidating] = useState(true);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isApplying, setIsApplying] = useState(false);

  // validated params
  const [application, setApplication] = useState(null);
  const [scopes, setScopes] = useState([]);
  const [redirectUrl, setRedirectUrl] = useState(null);

  useEffect(() => {
    validateOAuthParams();
  }, []);

  const validateOAuthParams = async () => {
    const paramDeveloperId = getAndRemoveUrlParam('Developer_id');
    const paramScopes = getAndRemoveUrlParam('scopes');
    const paramRedirect = getAndRemoveUrlParam('redirect_url');

    try {
      const response = await validateOAuthParamsAPI({
        Developer_id: paramDeveloperId,
        scopes: paramScopes,
        redirectUrl: paramRedirect
      });

      // track validated params in state
      setApplication(response.application);
      setScopes(response.scopes);
      setRedirectUrl(response.redirectUrl);
    } catch (e) {
      setIsInvalid(true);
    } finally {
      setIsValidating(false);
    }
  };

  const toggleIsApplying = () => {
    setIsApplying(!isApplying);
  };

  const handleApplyRedirect = applyResult => {
    if (applyResult.success) {
      if (applyResult.isWaitlist) {
        // applied to waitlist successfully, redirect with shopmy_status applied
        window.location.href = `${redirectUrl}?shopmy_status=applied`;
        return;
      }

      // registered as new user successfully and is logged in
      toggleIsApplying();
    }
  };

  const getContent = () => {
    if (isValidating) {
      return <Loader />;
    } else if (isInvalid) {
      return <ErrorBanner>Invalid OAuth URL</ErrorBanner>;
    } else if (isApplying) {
      return (
        <div className='apply-auth-wrapper'>
          <div className='apply-auth-header'>Join ShopMy</div>
          <div className='apply-auth-subheader'>
            {application.allowAutomaticRegistration ? 'Create an account' : 'Apply for an account'} to unlock commission at over 50k sites.
          </div>
          <SimpleAuthPanel
            isWaitlist={!application.allowAutomaticRegistration}
            ReferringOAuthApplication={{ id: application.id, appName: application.appName, registrationCode: application.registrationCode }}
            authSubmitCallback={handleApplyRedirect}
          />
        </div>
      );
    } else if (!isLoggedIn(user)) {
      return <LoginForm formHeader={'Log in to continue.'} />;
    } else {
      // valid OAuth URL and user is logged in, move to permission scopes
      return (
        <div className='connect-page-wrapper'>
          <div className='connect-logos'>
            <img className='logo-img' alt='ShopMy Logo' src={shopMyLogo} />
            <FontAwesomeIcon icon={faExchange} />
            {application.image ? (
              <img className='logo-img app' alt={`${application.appName} Logo`} src={application.image} />
            ) : (
              <div className='initials'>{getInitialsForUser({ name: application.appName })}</div>
            )}
          </div>
          <div className='connect-page-header'>Connect your ShopMy to {application.appName}!</div>
          <PermissionScopes scopes={scopes} application={application} redirectUrl={redirectUrl} />
        </div>
      );
    }
  };

  return (
    <div className='oauth-landing-wrapper'>
      {!isLoggedIn(user) && <img className='header-logo' alt='ShopMy Logo' src={shopMyWhiteLogo} />}
      <div className='content-wrapper'>{getContent()}</div>
      {!isLoggedIn(user) && !isInvalid && !isValidating && (
        <div className='sign-up-footer'>
          {!isApplying ? (
            <>
              Don't have an account? <button onClick={toggleIsApplying}>Sign up here.</button>
            </>
          ) : (
            <>
              I have an account. <button onClick={toggleIsApplying}>Sign in here.</button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

OAuthLanding.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps)(withRouter(OAuthLanding));
