import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import Switch from 'react-switch';
import Select from 'react-select';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import './ListSettings.scss';

import { getTags } from '../../APIClient/tags';
import { updateDiscoverList } from '../../APIClient/discover';
import { getMentionBrandsAndTargets } from '../../APIClient/mentions';
import { isDiscoverListCurated, recommendationListTypes } from '../../Helpers/discover_helpers';

import SelectOption from '../General/SelectOption';

let debounce;
const ListSettings = props => {
  const { list } = props;

  const [settingsExpanded, setSettingsExpanded] = useState(false);

  const [showMentions, setShowMentions] = useState(!!list.showMentions);
  const [lookalikeUserId, setLookalikeUserid] = useState(list.LookalikeUser_id || '');
  const [limit, setLimit] = useState(list.limit || '');
  const [isShopMyRecommendationList, setIsShopMyRecommendationList] = useState(!!list.isShopMyRecommendationList);
  const isCuratedList = isDiscoverListCurated(list);

  // Tags
  const [allTags, setAllTags] = useState([]);
  const [tagValues, setTagValues] = useState(list.tagValues?.split(',').map(i => +i) || []);
  const tagOptions = allTags.map(tag => ({ value: tag.value, label: tag.value, sublabels: [tag.description] }));
  React.useEffect(() => {
    getTags({ hideUnused: true }).then(resp => setAllTags(resp.tags));
  }, []);

  // AI Lists
  const [recommendationListType, setRecommendationListType] = useState(list.recommendationListType);
  const recommendationListTypeOptions = [
    { value: null, label: '-' },
    ...recommendationListTypes.map(type => ({ value: type.value, label: type.display }))
  ];

  // Sorting
  const [sortBy, setSortBy] = useState(list.sortBy || 'score');
  const sortOptions = [
    { value: 'score', label: 'Score' },
    { value: 'followers', label: 'Followers' },
    { value: 'orders', label: 'Orders' },
    { value: 'aov', label: 'AOV' },
    { value: 'gmv', label: 'GMV' },
    { value: 'gmv-monthly', label: 'Monthly GMV' },
    { value: 'gmv-weekly', label: 'Weekly GMV' },
  ];

  // Brands and Targets
  const [allBrands, setAllBrands] = useState([]);
  const [allTargets, setAllTargets] = useState([]);
  const [mentionNames, setMentionNames] = useState(list.mentionNames?.split(',') || []);
  const brandNameOptions = allBrands.map(brand => ({ value: brand.name, label: brand.name }));
  const targetOptions = allTargets.map(target => ({ value: target.name, label: target.name }));
  const brandAndTargetOptions = _.concat(brandNameOptions, targetOptions);
  React.useEffect(() => {
    getMentionBrandsAndTargets().then(resp => {
      setAllBrands(resp.brands);
      setAllTargets(resp.targets);
    });
  }, []);

  const options = [
    {
      type: 'input',
      label: 'Lookalike User ID',
      isDisabled: isCuratedList || recommendationListType,
      value: lookalikeUserId,
      placeholder: '146',
      onChange: async newValue => {
        setLookalikeUserid(newValue);
        clearTimeout(debounce);
        debounce = setTimeout(async () => {
          await updateDiscoverList(list, { LookalikeUser_id: newValue ? parseInt(newValue) : null })
            .then(props.syncList)
            .catch(error => cogoToast.error(error?.message || 'Likely not a valid User ID, please try again'));
        }, 750);
      }
    },
    {
      type: 'input',
      label: 'Creator Limit',
      isDisabled: isCuratedList,
      value: limit,
      placeholder: '-',
      onChange: async newValue => {
        setLimit(newValue);
        clearTimeout(debounce);
        debounce = setTimeout(async () => {
          await updateDiscoverList(list, { limit: parseFloat(newValue) })
            .then(props.syncList)
            .catch(error => cogoToast.error(error?.message || 'Likely not a valid number, please try again'))
            .finally(() => {
              if (isCuratedList) cogoToast.warn('This limit does not apply to curated lists, only automatically generated lists.');
            });
        }, 750);
      }
    },
    {
      type: 'single-select',
      label: 'AI List Type',
      isDisabled: lookalikeUserId || tagValues.length || isCuratedList,
      value: recommendationListTypeOptions.filter(type => recommendationListType === type.value),
      onChange: async data => {
        setRecommendationListType(data.value);
        if (tagValues.length && data.value)
          cogoToast.warn(
            `AI lists will not adhere to the filters applied, those only work on filter based lists. Please remove them so we don't get confused.`,
            { hideAfter: 8 }
          );

        if (isCuratedList && data.value)
          cogoToast.warn(`Please remove the Lookalike User ID as that will no longer apply since we are using an AI recommendation list.`, {
            hideAfter: 8
          });
        await updateDiscoverList(list, { recommendationListType: data.value }).then(props.syncList);
      },
      options: recommendationListTypeOptions,
      placeholder: 'Select List Type'
    },
    {
      type: 'select',
      label: 'Talent Tags',
      isDisabled: isCuratedList || recommendationListType,
      value: tagOptions.filter(tag => tagValues.includes(tag.value)),
      onChange: async data => {
        const newTagValues = data?.map(tag => tag.value) || [];
        setTagValues(newTagValues);
        if (isCuratedList && newTagValues.length)
          cogoToast.warn(
            'These tags will not filter curated lists, only automatically generated lists. Please set a lookalike user ID or delete all users from this list in order to use tags (or make a new list).',
            { hideAfter: 8 }
          );
        await updateDiscoverList(list, { tagValues: newTagValues.join(',') }).then(props.syncList);
      },
      options: tagOptions,
      placeholder: 'Add Tags'
    },
    {
      type: 'radio',
      label: 'Show Mentions',
      isActive: showMentions,
      onChange: async () => {
        setShowMentions(!showMentions);
        await updateDiscoverList(list, { showMentions: !list.showMentions }).then(props.syncList);
      }
    },
    {
      type: 'select',
      label: 'Brands for Mentions',
      value: brandAndTargetOptions.filter(tag => mentionNames.includes(tag.value)),
      onChange: async data => {
        const newNames = data?.map(tag => tag.value) || [];
        setMentionNames(newNames);
        await updateDiscoverList(list, { mentionNames: newNames.join(',') }).then(props.syncList);
      },
      options: brandAndTargetOptions,
      placeholder: 'Add Brands/Targets'
    },
    {
      type: 'single-select',
      isDisabled: isCuratedList || !!recommendationListType || !!lookalikeUserId,
      label: 'Sort By',
      value: sortOptions.filter(option => sortBy === option.value),
      onChange: async data => {
        setSortBy(data.value);
        await updateDiscoverList(list, { sortBy: data.value }).then(props.syncList);
      },
      options: sortOptions,
      placeholder: 'Sort By'
    },
    {
      type: 'radio',
      label: 'Is ShopMy Agency Recommendation List',
      isDisabled: !isCuratedList,
      isActive: isShopMyRecommendationList,
      onChange: async () => {
        setIsShopMyRecommendationList(!isShopMyRecommendationList);
        await updateDiscoverList(list, { isShopMyRecommendationList: !list.isShopMyRecommendationList }).then(props.syncList);
      }
    }
  ];

  return (
    <div className='list-settings-container'>
      <div className='settings-header-container'>
        <div className='header'>{settingsExpanded ? 'List Settings:' : 'Edit List Settings'}</div>
        <div className='expand-button' onClick={() => setSettingsExpanded(!settingsExpanded)}>
          <FontAwesomeIcon icon={settingsExpanded ? faChevronUp : faChevronDown} />
        </div>
      </div>
      {settingsExpanded && (
        <div className='all-settings'>
          {options.map((option, i) => (
            <div className={cn('setting', { disabled: option.isDisabled })} key={i}>
              <div className='label'>{option.label}</div>
              {option.type === 'radio' ? (
                <Switch
                  onChange={option.onChange}
                  checked={option.isActive}
                  height={14}
                  width={24}
                  onColor='#11835a'
                  offColor='#999'
                  className='switch'
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
              ) : option.type === 'select' || option.type === 'single-select' ? (
                <Select
                  value={option.value}
                  onChange={option.onChange}
                  isMulti={option.type === 'select'}
                  className='select'
                  classNamePrefix='select'
                  options={option.options}
                  placeholder={option.placeholder}
                  components={{ Option: SelectOption }}
                />
              ) : option.type === 'input' ? (
                <input value={option.value} onChange={e => option.onChange(e.target.value)} placeholder={option.placeholder} className='input' />
              ) : (
                <div>UNKNOWN TYPE</div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

ListSettings.propTypes = {
  user: PropTypes.object.isRequired,
  list: PropTypes.object.isRequired,
  syncList: PropTypes.func.isRequired
};

export default ListSettings;
