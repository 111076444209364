import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './AdvancedSettingsPanel.scss';

import Loader from '../../../Loader/Loader';
import { faCircleCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AdvancedSettingsPanel = props => {
  const { user } = props;
  const scriptTag = `<script defer src="https://static.shopmy.us/Affiliates/auto_pin_min.js" data-smsu="${user?.profile.username}"></script>`;

  const [isSaving, setIsSaving] = useState(false);
  const [isCopying, setIsCopying] = useState(false);
  const [autoPinDomains, setAutoPinDomains] = useState(user?.profile.settings.autoPinDomains || '');
  const canUpdateAutoPinDomains = autoPinDomains !== user?.profile.settings.autoPinDomains;

  const updateAutoPinDomains = async () => {
    if (isSaving) return window.ALERT.warn('Already saving, please wait.');
    if (autoPinDomains.includes('https://')) return window.ALERT.error('Only include the base domain of your site without https:// and www.');

    setIsSaving(true);
    const response = await props.updateUserSettings({ autoPinDomains }, user.id);
    setIsSaving(false);

    if (response?.error) window.ALERT.error(response.error);
    else window.ALERT.success(`Auto link domains updated`);
  };

  const copyScriptTag = async () => {
    navigator.clipboard.writeText(scriptTag);
    setIsCopying(true);
    window.setTimeout(() => setIsCopying(false), 2000);
  };

  return (
    <div className='advanced-panel-outer'>
      <div className='advanced-panel-inner'>
        <div className='settings-section'>
          <div className='settings-section-title'>Auto Linking</div>
          <div className='settings-section-subtitle'>
            If you would like to automatically convert external links on your site to ShopMy affiliate links, include this snippet tag on your site
            and enter your site's domains below. The tag must be included on each page you'd link to auto link.
          </div>
          <div className='advanced-split-input'>
            <input className='settings-input' type='text' placeholder='mysite.com' value={scriptTag} disabled />
            <button className='settings-button dark' onClick={copyScriptTag}>
              {isCopying ? <FontAwesomeIcon icon={faCircleCheck} size='xl' /> : 'Copy'}
            </button>
          </div>
        </div>
        <div className='settings-section'>
          <div className='settings-section-title'>Allowed Domains</div>
          <div className='settings-section-subtitle'>
            Enter your site's domains separated by commas. Auto links will only be allowed from sites that match one of your domains.
          </div>
          <div className='advanced-split-input'>
            <input
              className='settings-input'
              type='text'
              placeholder='mysite.com'
              value={autoPinDomains}
              onChange={e => setAutoPinDomains(e.target.value)}
            />
            {canUpdateAutoPinDomains && (
              <button className='settings-button dark' disabled={isSaving} onClick={updateAutoPinDomains}>
                {isSaving ? <Loader size={30} /> : 'Update'}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

AdvancedSettingsPanel.propTypes = {
  user: PropTypes.object.isRequired,
  updateUserSettings: PropTypes.func.isRequired
};

export default AdvancedSettingsPanel;
