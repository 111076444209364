import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faPlus, faEdit, faTimes, faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { confirmAlert } from 'react-confirm-alert';
import './OpportunityPlanUser.scss';

import { enhanceHTML } from '../../../Helpers/formatting';
import { getUserTierDisplay } from '../../../Helpers/tier_helpers';
import { getOpportunityRequests } from '../../../Helpers/brand_helpers';
import { checkValidityOfOpportunity } from '../../../Helpers/opportunity_helpers';
import { isPlanUserManuallyAdded } from '../../../Helpers/planning_helpers';

import Tooltip from '../../General/Tooltip';
import ConfirmPrompt from '../../General/ConfirmPrompt';
import CostReasoningOverlay from './CostReasoningOverlay';
import CustomTooltip from '../../General/CustomTooltip';

const OpportunityPlanUser = props => {
  const { opportunity, planUser, dataFields } = props;
  const { user, reasoning, isBackup, disclaimer } = planUser;
  const viewTalent = () => props.openArtistModal(user);
  const isManual = isPlanUserManuallyAdded(planUser);

  const { canSendOpportunity, invalidMessage } = checkValidityOfOpportunity(opportunity, props.user, { ignorePaymentTiers: true });
  const existingRequest = getOpportunityRequests(props.analytics).find(r => r.Opportunity_id === opportunity.id && r.User_id === user.id);

  const inviteTalent = async () => {
    if (canSendOpportunity) {
      let tier = opportunity.payment_tiers.find(t => t.fixedFee === planUser.recommendedFixedFee);
      let hadToCreateNewTier = false;
      if (!tier) {
        hadToCreateNewTier = true;
        const resp = await props.createOpportunityPaymentTier({
          Opportunity_id: opportunity.id,
          fixedFee: planUser.recommendedFixedFee
        });
        tier = resp.payment_tier;
      }

      props.openRequestModal({
        params: {
          Opportunity_id: opportunity.id,
          type: 'opportunities',
          OpportunityPaymentTier_id: tier.id,
          preselectedUsers: [
            {
              id: user.id,
              name: user.name,
              image: user.image
            }
          ]
        },
        closeCallback: () => {
          // If we had to create a new tier, delete it if the request was not sent so we don't have a bunch of empty tiers
          hadToCreateNewTier && props.deleteOpportunityPaymentTier(tier);
        }
      });
    }
  };

  const makePrimary = () => {
    props.updateOpportunityPlanUser(planUser, { isBackup: false });
  };

  const makeBackup = () => {
    props.updateOpportunityPlanUser(planUser, { isBackup: true });
  };

  const remove = () => {
    props.deleteOpportunityPlanUser(planUser);
  };

  let [cannotSend, cannotSendMessage] = [false, null];
  if (existingRequest) {
    cannotSend = true;
    cannotSendMessage = 'Already Sent Request';
  } else if (!canSendOpportunity) {
    cannotSend = true;
    cannotSendMessage = invalidMessage;
  }

  const metadata = [getUserTierDisplay(user.tier?.tier)];

  const editReasoning = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          header='Explain this selection'
          onCancel={onClose}
          customInputFields={[
            {
              preloaded: reasoning,
              value: 'reasoning',
              isSingleLine: false
            }
          ]}
          onSubmit={responseValues => {
            props.updateOpportunityPlanUser(planUser, { reasoning: responseValues.reasoning });
          }}
        />
      )
    });
  };

  const additionalClasses = {
    'already-sent': existingRequest
  };
  return (
    <div key={planUser.id} className={cn('opportunity-plan-user-container', additionalClasses)}>
      {existingRequest && (
        <div className='already-sent-overlay'>
          <div className='already-sent-text'>Request Sent</div>
        </div>
      )}
      <div className='about-section'>
        <div className='image-container'>
          <img src={user.image} alt={user.name} />
        </div>
        <div className='metadata-container'>
          <div onClick={viewTalent} className='name'>
            {user.name}
          </div>
          {reasoning ? (
            <div onClick={editReasoning} className='reasoning'>
              {enhanceHTML(reasoning)}
            </div>
          ) : (
            <div onClick={editReasoning} className='metadata'>
              {metadata.join(' • ')}
            </div>
          )}
          {!!disclaimer && window.__ADMIN_CONTROL_MODE__ && (
            <div className='disclaimer'>
              <FontAwesomeIcon icon={faExclamationTriangle} />
              {disclaimer}
            </div>
          )}
        </div>
      </div>
      <div className='stats-and-actions'>
        <div className='section-stats'>
          {dataFields.map(field => {
            const { label, isFeatured, canEditConfig } = field;
            const value = field.getValue(planUser);
            const edit = () => {
              if (canEditConfig) {
                confirmAlert({
                  customUI: ({ onClose }) => (
                    <ConfirmPrompt
                      onCancel={onClose}
                      customInputFields={[
                        {
                          preloaded: value,
                          value: canEditConfig.variable,
                          isSingleLine: true
                        }
                      ]}
                      onSubmit={responseValues => {
                        let newValue = responseValues[canEditConfig.variable];
                        if (!isNaN(+newValue)) newValue = +newValue;
                        props.updateOpportunityPlanUser(planUser, { [canEditConfig.variable]: newValue });
                      }}
                    />
                  )
                });
              }
            };
            const resultEl = (
              <div onClick={edit} key={label} className={cn('section-stat', { featured: isFeatured, editable: canEditConfig })}>
                {isFeatured && <div className='background-highlight' />}
                <div
                  className={cn('value', {
                    small: value.length > 8
                  })}
                >
                  {field.getDisplayValue(planUser)}
                </div>
                <div className='label'>
                  {label}
                  {canEditConfig ? <FontAwesomeIcon className='edit-icon' icon={faEdit} /> : null}
                </div>
              </div>
            );

            return planUser.recommended_cost_reasoning ? (
              <CustomTooltip tooltipEl={<CostReasoningOverlay reasoning={planUser.recommended_cost_reasoning.reasoning} />}>{resultEl}</CustomTooltip>
            ) : (
              resultEl
            );
          })}
        </div>
        <div className='plan-user-actions'>
          {isBackup ? (
            <>
              <Tooltip message={'Move into plan'}>
                <div className='action' onClick={makePrimary}>
                  <FontAwesomeIcon icon={faPlus} />
                </div>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip message={cannotSendMessage || 'Send Opportunity'}>
                <div className={cn('action', { disabled: cannotSend })} onClick={cannotSend ? null : inviteTalent}>
                  <FontAwesomeIcon icon={faPaperPlane} />
                </div>
              </Tooltip>
              <Tooltip message={'Remove From Plan'}>
                <div className='action' onClick={isManual ? remove : makeBackup}>
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              </Tooltip>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

OpportunityPlanUser.propTypes = {
  user: PropTypes.object.isRequired,
  opportunity: PropTypes.object.isRequired,
  planUser: PropTypes.object.isRequired,
  dataFields: PropTypes.array.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  updateOpportunityPlanUser: PropTypes.func.isRequired,
  deleteOpportunityPlanUser: PropTypes.func.isRequired,
  createOpportunityPaymentTier: PropTypes.func.isRequired,
  deleteOpportunityPaymentTier: PropTypes.func.isRequired
};

export default OpportunityPlanUser;
