import React from 'react';
import PropTypes from 'prop-types';
import './ShopMyPagination.scss';

const ShopMyPagination = props => {
  const { goToPage, curPage, totalPages } = props;

  return (
    <div className='links-pagination-container'>
      {curPage > 2 && (
        <div onClick={() => goToPage(1)} className='page-num'>
          1
        </div>
      )}
      {curPage > 3 && <div className='ellipsis'>...</div>}
      {curPage > 1 && (
        <div onClick={() => goToPage(curPage - 1)} className='page-num'>
          {curPage - 1}
        </div>
      )}
      <div className='page-num selected'>{curPage}</div>
      {curPage < totalPages && (
        <div onClick={() => goToPage(curPage + 1)} className='page-num'>
          {curPage + 1}
        </div>
      )}
      {curPage + 2 < totalPages && <div className='ellipsis'>...</div>}
      {curPage + 1 < totalPages && (
        <div onClick={() => goToPage(totalPages)} className='page-num'>
          {totalPages}
        </div>
      )}
    </div>
  );
};

ShopMyPagination.propTypes = {
  curPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  goToPage: PropTypes.func.isRequired
};

export default ShopMyPagination;
