import React from 'react';
import PropTypes from 'prop-types';
import './CatalogProductModalSiblings.scss';
import get from 'lodash/get';
import CatalogProductModalSibling from './CatalogProductModalSibling';

const CatalogProductModalSiblings = props => {
  const { variant, shouldAllowSettingVariantRates, variantSiblingRatesMap, commissionRatePlaceholder, handleSaveRate } = props;

  return (
    <div className='variant-siblings-container'>
      <div className='header-row'>
        <div className='table-cell header lg'>Product</div>
        <div className='table-cell header sm center'>Stock</div>
        <div className='table-cell header sm center'>Size</div>
        {shouldAllowSettingVariantRates && <div className='table-cell header sm center'>Product Rate</div>}
      </div>
      <div className='table-content'>
        {variant.siblings.map(sibling => {
          const existingSiblingRate = get(variantSiblingRatesMap, sibling.id);

          return (
            <CatalogProductModalSibling
              key={sibling.id}
              sibling={sibling}
              existingSiblingRate={existingSiblingRate}
              shouldAllowSettingVariantRates={shouldAllowSettingVariantRates}
              commissionRatePlaceholder={commissionRatePlaceholder}
              handleSaveRate={handleSaveRate}
            />
          );
        })}
      </div>
    </div>
  );
};

CatalogProductModalSiblings.propTypes = {
  variant: PropTypes.object.isRequired,
  shouldAllowSettingVariantRates: PropTypes.bool,
  variantSiblingRatesMap: PropTypes.object.isRequired,
  commissionRatePlaceholder: PropTypes.string,
  handleSaveRate: PropTypes.func.isRequired
};

export default CatalogProductModalSiblings;
