import React from 'react';
import PropTypes from 'prop-types';
import commaNumber from 'comma-number';
import cn from 'classnames';
import Switch from 'react-switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import './BrandLookbookBody.scss';

import { matchScrollHeight } from '../../../Helpers/helpers';

import BrandLookbookProducts from './BrandLookbookProducts';
import LookbookReport from './LookbookReport/LookbookReport';
import Tooltip from '../../General/Tooltip';
import LookbookEmphasizedNote from '../Elements/LookbookEmphasizedNote';
import { getLookbookCurrencySymbol } from '../../../Helpers/lookbook_helpers';

const BrandLookbookBody = props => {
  const {
    lookbook,
    openSettingsModal,
    deleteItemFromLookbook,
    updateLookbook,
    openAddProductsModal,
    openReplaceProductsModal,
    updateLookbookItem,
    saving
  } = props;
  const { inPublicViewMode, inAnalyticsMode } = props;

  const { price_limit, item_limit, brand } = lookbook;

  // Note Handling
  const [brandNote, setBrandNote] = React.useState(lookbook.brandNote);
  const brandNoteDebouncer = React.useRef(null);
  const updateLookbookBrandNote = e => {
    const newBrandNote = e.target.value;
    setBrandNote(newBrandNote);
    clearTimeout(brandNoteDebouncer.current);
    brandNoteDebouncer.current = setTimeout(async () => {
      if (newBrandNote === brandNote) return;
      await updateLookbook({ brandNote: newBrandNote || null });
    }, 750);
    if (!newBrandNote && isBrandNoteEmphasized) toggleEmphasizedNote();
  };

  // Show the brand a preview of the emphasized note and reset it when they transition to public view mode
  const [isBrandNoteEmphasized, setIsBrandNoteEmphasized] = React.useState(lookbook.isBrandNoteEmphasized);
  const [hasDismissedEmphasizedNote, setHasDismissedEmphasizedNote] = React.useState(false);
  React.useEffect(() => {
    if (hasDismissedEmphasizedNote) setHasDismissedEmphasizedNote(false);
  }, [inPublicViewMode]);

  const emphasisDebouncer = React.useRef(null);
  const toggleEmphasizedNote = () => {
    clearTimeout(emphasisDebouncer.current);
    setIsBrandNoteEmphasized(!isBrandNoteEmphasized);
    emphasisDebouncer.current = setTimeout(async () => {
      updateLookbook({ isBrandNoteEmphasized: !isBrandNoteEmphasized });
    }, 500);
  };

  const showEmphasizedNote = !!inPublicViewMode && !!isBrandNoteEmphasized && !hasDismissedEmphasizedNote && !!brandNote;
  const showPriceLimitBlock = !inPublicViewMode || !!price_limit;
  const showItemLimitBlock = !inPublicViewMode || !!item_limit;
  const currencyDisplay = getLookbookCurrencySymbol(lookbook);

  return (
    <div className='brand-lookbook-body-outer'>
      {showEmphasizedNote && <LookbookEmphasizedNote lookbook={lookbook} onDismiss={() => setHasDismissedEmphasizedNote(true)} />}
      <div className={cn('brand-lookbook-body-inner', { 'showing-emphasized-note-overlay': showEmphasizedNote })}>
        {inAnalyticsMode ? (
          <LookbookReport lookbook={lookbook} />
        ) : (
          <>
            <div className='header'>
              <div id='note-from-brand'>
                <div className='image-container'>
                  <img src={brand.logo} alt='brand-logo' className='icon' />
                </div>
                <div className='text-and-controls'>
                  <div className='text'>
                    <div className='intro'>A note from {inPublicViewMode ? brand.name : 'you'}</div>
                    <textarea
                      placeholder='Write a description here for your talent to see'
                      rows={1}
                      className='brand-note'
                      onChange={updateLookbookBrandNote}
                      defaultValue={brandNote}
                      spellCheck={false}
                      ref={ref => matchScrollHeight(ref)}
                    />
                  </div>
                  {!inPublicViewMode && !!brandNote && (
                    <div
                      className={cn('toggle-emphasis-switch-container', {
                        active: isBrandNoteEmphasized
                      })}
                    >
                      <Switch
                        onChange={toggleEmphasizedNote}
                        checked={!!isBrandNoteEmphasized}
                        height={14}
                        width={24}
                        className='toggle-emphasis-switch'
                        onColor='#11835a'
                        offColor='#ccc'
                        checkedIcon={false}
                        uncheckedIcon={false}
                      />
                      <div onClick={toggleEmphasizedNote} className='toggle-emphasis-switch-label'>
                        Emphasize Note
                      </div>
                      <Tooltip message='Emphasizing a note requires the creator to read and dismiss the note before making selections. Use this if you have critical instructions in the note.'>
                        <FontAwesomeIcon className='toggle-emphasis-tooltip-icon' icon={faInfoCircle} />
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>
              <div id='price-and-item-limit'>
                {showPriceLimitBlock && (
                  <div className='price-limit' onClick={openSettingsModal}>
                    <div className={cn('limit-content', { inactive: !price_limit })}>
                      {!inPublicViewMode && !!price_limit && <FontAwesomeIcon icon={faEdit} />}
                      <span>{price_limit ? `${currencyDisplay}${commaNumber(price_limit)}` : '-'}</span>
                    </div>
                    <div className='limit-label'>{price_limit ? 'Price Limit' : 'Set Price Limit'}</div>
                  </div>
                )}
                {showItemLimitBlock && (
                  <div className='item-limit' onClick={openSettingsModal}>
                    <div className={cn('limit-content', { inactive: !item_limit })}>
                      {!inPublicViewMode && !!item_limit && <FontAwesomeIcon icon={faEdit} />}
                      {item_limit ? (
                        <>
                          {item_limit} {item_limit === 1 ? 'Item' : 'Items'}
                        </>
                      ) : (
                        '-'
                      )}
                    </div>
                    <div className='limit-label'>{item_limit ? 'Item Limit' : 'Set Item Limit'}</div>
                  </div>
                )}
              </div>
            </div>
            <BrandLookbookProducts
              ui={props.ui}
              user={props.user}
              analytics={props.analytics}
              lookbook={lookbook}
              lookbooks={props.lookbooks}
              updateLookbookCart={props.updateLookbookCart}
              deleteItemFromLookbook={deleteItemFromLookbook}
              openEditModal={props.openEditModal}
              inPublicViewMode={inPublicViewMode}
              openAddProductsModal={openAddProductsModal}
              openReplaceProductsModal={openReplaceProductsModal}
              updateLookbookItem={updateLookbookItem}
              saving={saving}
            />
          </>
        )}
      </div>
    </div>
  );
};

BrandLookbookBody.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  lookbook: PropTypes.object.isRequired,
  lookbooks: PropTypes.object.isRequired,
  updateLookbook: PropTypes.func.isRequired,
  deleteItemFromLookbook: PropTypes.func.isRequired,
  openEditModal: PropTypes.func.isRequired,
  openSettingsModal: PropTypes.func.isRequired,
  openAddProductsModal: PropTypes.func.isRequired,
  openReplaceProductsModal: PropTypes.func.isRequired,
  updateLookbookItem: PropTypes.func.isRequired,
  updateLookbookCart: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,

  inPublicViewMode: PropTypes.bool.isRequired,
  inAnalyticsMode: PropTypes.bool.isRequired
};

export default BrandLookbookBody;
