import React, { useState } from 'react';
import './CatalogControlBarFiltersMenu.scss';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import InputActions from '../General/InputActions';

const CatalogControlBarFiltersMenu = props => {
  const { active, close, catalogGroups, controlBarActiveFilters, setControlBarFilterValue } = props;
  const [searchTerm, setSearchTerm] = useState('');

  const shopifyCollectionCatalogGroups = catalogGroups.filter(cg => cg.type === 'shopify_collection');
  const filteredShopifyCollections = searchTerm.length
    ? shopifyCollectionCatalogGroups.filter(cg => cg.title.toLowerCase().includes(searchTerm.toLowerCase()))
    : shopifyCollectionCatalogGroups;

  const hasNoFilterOptions = !shopifyCollectionCatalogGroups.length;

  const toggleFilter = async (key, value) => {
    await setControlBarFilterValue(key, value);
  };

  return (
    <>
      <div onClick={close} className={cn('catalog-filters-fade-container', { active })} />
      <div className={cn('catalog-filters-container', { active })}>
        <div className='header'>
          <span>Filter</span>
          <FontAwesomeIcon icon={faTimes} onClick={close} />
        </div>
        {!hasNoFilterOptions && (
          <div className='catalog-filters-search has-actions'>
            <FontAwesomeIcon icon={faSearch} />
            <input type='text' placeholder='Search...' value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
            <InputActions searchVal={searchTerm} onCancel={() => setSearchTerm('')} />
          </div>
        )}
        {shopifyCollectionCatalogGroups.length > 0 && (
          <div className='filter-section'>
            <div className='label'>Groups</div>
            <div className='filter-options'>
              {filteredShopifyCollections.map(cg => {
                const isFilterActive = controlBarActiveFilters.CatalogGroup?.id === cg.id;
                return (
                  <div
                    key={cg.id}
                    className={cn('filter-option', { active: isFilterActive })}
                    onClick={() => toggleFilter('CatalogGroup', isFilterActive ? null : cg)}
                  >
                    {cg.title}
                    <span>{cg.variants_count || 0}</span>
                  </div>
                );
              })}
              {!filteredShopifyCollections.length && <div className='no-results'>No collections found</div>}
            </div>
          </div>
        )}
        {hasNoFilterOptions && <div className='no-results'>No available filters yet!</div>}
      </div>
    </>
  );
};

CatalogControlBarFiltersMenu.propTypes = {
  close: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  catalogGroups: PropTypes.array.isRequired,
  controlBarActiveFilters: PropTypes.object.isRequired,
  setControlBarFilterValue: PropTypes.func.isRequired
};

export default CatalogControlBarFiltersMenu;
