import React, { useState } from 'react';
import './CatalogControlBar.scss';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBarsFilter, faTimes } from '@fortawesome/pro-regular-svg-icons';
import CatalogControlBarFiltersMenu from './CatalogControlBarFiltersMenu';
import InputActions from '../General/InputActions';

const CatalogControlBar = props => {
  const {
    curTab,
    setCurTab,
    canSupportCustomRates,
    controlBarSearchTerm,
    setControlBarSearchTerm,
    catalogGroups,
    controlBarActiveFilters,
    setControlBarFilterValue
  } = props;
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);

  const tabOptions = [
    { name: 'Products', value: 'products' },
    { name: 'Groups', value: 'groups', hidden: !canSupportCustomRates },
    { name: 'Commission Rates', value: 'rates' }
  ];

  const visibleTabOptions = tabOptions.filter(option => option.hidden !== true);

  const handleTabChange = newTab => {
    if (newTab === curTab) return;
    setCurTab(newTab);
  };

  const isFilterUnavailable = ['rates', 'groups'].includes(curTab);
  const handleOpenFiltersMenu = () => {
    if (isFilterUnavailable) return;
    setIsFilterMenuOpen(true);
  };

  const getFilterValueLabel = (filterKey, filterValue) => {
    if (filterKey === 'CatalogGroup') {
      return filterValue.title;
    }
  };

  const getFilterValueCount = (filterKey, filterValue) => {
    if (filterKey === 'CatalogGroup') {
      return filterValue.variants_count || 0;
    }
  };

  return (
    <div className='catalog-control-bar'>
      <div className='control-bar-search has-actions'>
        <FontAwesomeIcon icon={faSearch} />
        <input type='text' placeholder='Search...' value={controlBarSearchTerm} onChange={e => setControlBarSearchTerm(e.target.value)} />
        <InputActions searchVal={controlBarSearchTerm} onCancel={() => setControlBarSearchTerm('')} />
      </div>
      <div className='control-bar-filters-container'>
        <div className={cn('filters-button', { disabled: isFilterUnavailable })} onClick={handleOpenFiltersMenu}>
          <FontAwesomeIcon icon={faBarsFilter} />
        </div>
        {isFilterMenuOpen && (
          <CatalogControlBarFiltersMenu
            close={() => setIsFilterMenuOpen(false)}
            active={isFilterMenuOpen}
            catalogGroups={catalogGroups}
            setControlBarFilterValue={setControlBarFilterValue}
            controlBarActiveFilters={controlBarActiveFilters}
          />
        )}
      </div>
      <div className='control-bar-center-container'>
        <div className='control-bar-tabs'>
          {visibleTabOptions.map(option => {
            return (
              <div
                key={option.value}
                className={cn('tab-container', { active: curTab === option.value })}
                onClick={() => handleTabChange(option.value)}
              >
                <span className='control-bar-tab'>{option.name}</span>
              </div>
            );
          })}
        </div>
        <div className='control-bar-active-filters'>
          {Object.keys(controlBarActiveFilters).map(filterKey => {
            const filterValue = controlBarActiveFilters[filterKey];
            return (
              <div key={filterKey} className='active-filter'>
                {getFilterValueLabel(filterKey, filterValue)}
                <span>{getFilterValueCount(filterKey, filterValue)}</span>
                <div className='clear-btn'>
                  <FontAwesomeIcon icon={faTimes} onClick={() => setControlBarFilterValue(filterKey, null)} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

CatalogControlBar.propTypes = {
  curTab: PropTypes.string.isRequired,
  setCurTab: PropTypes.func.isRequired,
  canSupportCustomRates: PropTypes.bool.isRequired,
  controlBarSearchTerm: PropTypes.string.isRequired,
  setControlBarSearchTerm: PropTypes.func.isRequired,
  catalogGroups: PropTypes.array.isRequired,
  controlBarActiveFilters: PropTypes.object.isRequired,
  setControlBarFilterValue: PropTypes.func.isRequired
};

export default CatalogControlBar;
