import React, { useState, useEffect } from 'react';
import './CatalogSitewideRateRow.scss';
import PropTypes from 'prop-types';
import ProfilePhoto from '../Profile/ProfilePhoto';
import InlineRateInput from './InlineRateInput';

const CatalogSitewideRateRow = props => {
  const {
    ui,
    photoUserProfile,
    label,
    meta,
    rateValue,
    ratePlaceholder = null,
    isInputActionHidden = false,
    handleSaveRate,
    isRateInputDisabled = false,
    autoFocusRateInput = false
  } = props;
  const [inputValue, setInputValue] = useState(rateValue || '');
  const [isSavingRate, setIsSavingRate] = useState(false);

  const onRateSave = async newRate => {
    if (isSavingRate || !handleSaveRate) return;
    setIsSavingRate(true);
    const numericalRate = parseFloat(newRate);
    const success = await handleSaveRate(numericalRate);
    if (!success) setInputValue(rateValue || '');
    setIsSavingRate(false);
  };

  useEffect(() => {
    setInputValue(rateValue || '');
  }, [rateValue]);

  return (
    <div className='catalog-sitewide-rate-row'>
      <div className='profile-img-wrapper'>
        <ProfilePhoto ui={ui} profile={photoUserProfile} basic={true} />
      </div>
      <div className='rate-content'>
        <div className='rate-info'>
          <div className='rate-label'>{label}</div>
          <div className='rate-meta'>{meta}</div>
        </div>
        <div className='rate-action-container'>
          <InlineRateInput
            placeholder={ratePlaceholder}
            value={inputValue}
            isActionHidden={isInputActionHidden}
            isDisabled={isRateInputDisabled || isSavingRate}
            onRateChange={setInputValue}
            handleSaveRate={() => onRateSave(inputValue)}
            handleRateAction={() => onRateSave('')}
            actionButtonText={'Delete'}
            autoFocus={autoFocusRateInput}
          />
        </div>
      </div>
    </div>
  );
};

CatalogSitewideRateRow.propTypes = {
  ui: PropTypes.object.isRequired,
  photoUserProfile: PropTypes.object,
  label: PropTypes.string.isRequired,
  meta: PropTypes.string,
  rateValue: PropTypes.string,
  isInputActionHidden: PropTypes.bool,
  handleSaveRate: PropTypes.func,
  isRateInputDisabled: PropTypes.bool,
  autoFocusRateInput: PropTypes.bool
};

export default CatalogSitewideRateRow;
