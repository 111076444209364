import React from 'react';
import PropTypes from 'prop-types';
import './LocationDataPanel.scss';

import { getSettings } from '../../../../Helpers/user_helpers';
import CheckboxButton from '../../../General/Buttons/CheckboxButton';

const LocationDataPanel = props => {
  const { user } = props;

  const toggleUserSetting = async (setting, value) => {
    await props.updateUserSettings({ [setting]: value });
  };

  const sections = [
    {
      title: 'Location Data',
      subtitle: (
        <>
          We may use your location data to help brands connect with you for collaborations. Details on how we use your location data can be found{' '}
          <a target='_blank' rel='noopener noreferrer' href={`https://static.shopmy.us/Privacy_Policy.pdf`}>
            here
          </a>{' '}
          in our privacy policy. You have the option to opt out of location data collection anytime via the checkbox below.
        </>
      ),
      options: [
        {
          title: 'Allow Location Data',
          userSettingsVariable: 'allowLocationServices',
          value: getSettings(user).allowLocationServices
        }
      ]
    }
  ];

  return (
    <div className='location-data-panel-outer'>
      <div className='location-data-panel-inner'>
        {sections.map(section => {
          const { title, subtitle, options } = section;

          return (
            <div className='settings-section' key={`location-panel-${title}`}>
              {title && <div className='settings-section-title'>{title}</div>}
              {subtitle && <div className='settings-section-subtitle'>{subtitle}</div>}

              <div className='settings-section-list'>
                {options.map(option => {
                  const { title, userSettingsVariable, value } = option;
                  return (
                    <CheckboxButton
                      key={`location-panel-${userSettingsVariable}`}
                      isChecked={!!value}
                      onChange={() => toggleUserSetting(userSettingsVariable, !value)}
                      text={title}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

LocationDataPanel.propTypes = {
  user: PropTypes.object.isRequired,
  updateUserSettings: PropTypes.func.isRequired
};

export default LocationDataPanel;
