import cogoToast from 'cogo-toast';
import _ from 'lodash';

import * as ActionTypes from '../Actions/AnalyticsActions';

export const analytics = (
  state = {
    brandAnalytics: {
      requests: [],
      customRates: [],
      customCodes: [],
      customAffiliateRates: [],
      isFetchingAnalytics: true
    }
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.GET_BRAND_ANALYTICS_REQUEST:
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          isFetchingAnalytics: true
        }
      };
    case ActionTypes.GET_BRAND_ANALYTICS_SUCCESS:
      return {
        ...state,
        brandAnalytics: {
          ...action.analytics,
          isFetchingAnalytics: false
        }
      };

    case ActionTypes.GET_BRAND_ANALYTICS_FAILURE:
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          isFetchingAnalytics: false
        }
      };

    case ActionTypes.SET_CUSTOM_COMMISSION_RATE_SUCCESS:
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          customRates: action.customRates
        }
      };

    case ActionTypes.SET_CUSTOM_COMMISSION_RATE_FAILURE:
      cogoToast.error(action.error);
      return {
        ...state
      };

    case ActionTypes.SET_CUSTOM_CODE_SUCCESS:
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          customCodes: action.customCodes
        }
      };
    case ActionTypes.SET_CUSTOM_CODE_FAILURE:
      return {
        ...state
      };

    case ActionTypes.CREATE_SAMPLES_REQUEST_SUCCESS:
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          requests: [...(state.brandAnalytics.requests || []), action.request]
        }
      };
    case ActionTypes.CREATE_SAMPLES_REQUEST_FAILURE:
      console.error(action); // Leave for debugging
      window.ALERT.error(action.error || 'There was an issue submitting your gifting request.');
      return {
        ...state
      };
    case ActionTypes.REMOVE_SAMPLES_REQUEST_SUCCESS:
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          requests: _.filter(state.brandAnalytics.requests, r => r.id !== action.request.id)
        }
      };
    case ActionTypes.REMOVE_SAMPLES_REQUEST_FAILURE:
      window.ALERT.error('There was an issue removing your gifting request.');
      return {
        ...state
      };

    case ActionTypes.CREATE_OPPORTUNITY_REQUEST_SUCCESS:
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          opportunity_requests: [...(state.brandAnalytics.opportunity_requests || []), action.request]
        }
      };

    case ActionTypes.CREATE_OPPORTUNITY_REQUEST_FAILURE:
      return {
        ...state
      };

    case ActionTypes.UPDATE_OPPORTUNITY_REQUEST_REQUEST:
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          opportunity_requests: state.brandAnalytics.opportunity_requests.map(r => (r.id === action.request.id ? { ...r, ...action.updates } : r))
        }
      };

    case ActionTypes.UPDATE_OPPORTUNITY_REQUEST_SUCCESS:
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          opportunity_requests: state.brandAnalytics.opportunity_requests.map(r => (r.id === action.request.id ? { ...r, ...action.request } : r))
        }
      };

    case ActionTypes.UPDATE_OPPORTUNITY_REQUEST_FAILURE:
      window.ALERT.error('There was an issue updating this opportunity request.');
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          opportunity_requests: state.brandAnalytics.opportunity_requests.map(r => (r.id === action.request.id ? action.request : r))
        }
      };

    case ActionTypes.REMOVE_OPPORTUNITY_REQUEST_REQUEST:
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          opportunity_requests: _.filter(state.brandAnalytics.opportunity_requests, r => r.id !== action.request.id)
        }
      };

    case ActionTypes.REMOVE_OPPORTUNITY_REQUEST_FAILURE:
      window.ALERT.error('There was an issue removing this opportunity request.');
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          opportunity_requests: [...(state.brandAnalytics.opportunity_requests || []), action.request]
        }
      };

    case ActionTypes.ADD_CUSTOM_AFFILIATE_RATE_REQUEST:
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          customAffiliateRates: _.concat(state.brandAnalytics.customAffiliateRates || [], action.customAffiliateRate)
        }
      };

    case ActionTypes.ADD_CUSTOM_AFFILIATE_RATE_SUCCESS:
      window.ALERT.success('Rate successfully created');
      const { SpecificVariant_id, SpecificVariantSibling_id, CatalogGroup_id } = action.customAffiliateRate;

      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          customAffiliateRates: (state.brandAnalytics.customAffiliateRates || []).map(rate => {
            if (
              rate.SpecificVariant_id === SpecificVariant_id &&
              rate.SpecificVariantSibling_id === SpecificVariantSibling_id &&
              rate.CatalogGroup_id === CatalogGroup_id
            ) {
              return action.customAffiliateRate;
            }

            return rate;
          })
        }
      };

    case ActionTypes.ADD_CUSTOM_AFFILIATE_RATE_FAILURE:
      window.ALERT.error('We had an issue creating this affiliate rate, please try again.', {
        hideAfter: 5
      });
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          customAffiliateRates: action.existingCustomAffiliateRates
        }
      };

    case ActionTypes.UPDATE_CUSTOM_AFFILIATE_RATE_REQUEST:
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          customAffiliateRates: _.map(state.brandAnalytics.customAffiliateRates || [], rate =>
            rate.id === action.customAffiliateRate.id ? action.customAffiliateRate : rate
          )
        }
      };

    case ActionTypes.UPDATE_CUSTOM_AFFILIATE_RATE_SUCCESS:
      window.ALERT.success('Rate successfully updated');
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          customAffiliateRates: _.map(state.brandAnalytics.customAffiliateRates || [], rate =>
            rate.id === action.customAffiliateRate.id ? action.customAffiliateRate : rate
          )
        }
      };

    case ActionTypes.UPDATE_CUSTOM_AFFILIATE_RATE_FAILURE:
      window.ALERT.error('We had an issue updating this affiliate rate, please try again.', {
        hideAfter: 5
      });
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          customAffiliateRates: _.map(state.brandAnalytics.customAffiliateRates || [], rate =>
            rate.id === action.existingCustomAffiliateRate.id ? action.existingCustomAffiliateRate : rate
          )
        }
      };

    case ActionTypes.DELETE_CUSTOM_AFFILIATE_RATE_REQUEST:
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          customAffiliateRates: _.filter(state.brandAnalytics.customAffiliateRates || [], rate => rate.id !== action.customAffiliateRate.id)
        }
      };

    case ActionTypes.DELETE_CUSTOM_AFFILIATE_RATE_SUCCESS:
      window.ALERT.success('Rate successfully removed');
      return {
        ...state
      };

    case ActionTypes.DELETE_CUSTOM_AFFILIATE_RATE_FAILURE:
      window.ALERT.error('We had an issue deleting this affiliate rate, please try again.', {
        hideAfter: 5
      });
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          customAffiliateRates: _.concat(state.brandAnalytics.customAffiliateRates || [], action.customAffiliateRate)
        }
      };

    default:
      return state;
  }
};
