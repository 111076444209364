import React, { useState } from 'react';
import './UserTokensPanel.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { getTokens } from '../../../../Helpers/user_helpers';
import { getInitialsForUser } from '../../../../Helpers/formatting';
import { OAUTH_SCOPE_DISPLAY_NAMES, OAUTH_SCOPE_DETAILED_DISPLAY_NAMES } from '../../../OAuth/Constants/OAuthScopes';
import { updateUserAuthenticationToken, deleteUserAuthenticationToken } from '../../../../Actions/UserActions';
import cogoToast from 'cogo-toast';

const UserTokensPanel = props => {
  const { user, updateUserAuthenticationToken, deleteUserAuthenticationToken } = props;
  const tokens = getTokens(user);

  return (
    <div className='user-tokens-panel-outer'>
      <div className='user-tokens-panel-inner'>
        <div className='settings-section'>
          <div className='settings-section-title'>Your Authorized Applications</div>
          <div className='settings-section-subtitle'>
            You have authorized the following applications to access your ShopMy account. You can edit their access or remove them here.
          </div>

          <div className='token-rows-wrapper'>
            {tokens.map(token => (
              <TokenRow key={token.id} token={token} onUpdate={updateUserAuthenticationToken} onDelete={deleteUserAuthenticationToken} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const TokenRow = props => {
  const { token, onUpdate, onDelete } = props;
  const { scopes, application, developer } = token;
  const scopesArr = scopes.length ? scopes.split(',') : [];
  const displayScopes = scopesArr.map(s => OAUTH_SCOPE_DISPLAY_NAMES[s]).join(', ');

  const [isEditing, setIsEditing] = useState(false);
  const toggleEdit = () => setIsEditing(!isEditing);

  const toggleScope = async (isChecked, scope) => {
    const newScopes = isChecked ? [...scopesArr, scope] : scopesArr.filter(s => s !== scope);
    const newScopesStr = newScopes.join(',');

    await onUpdate(token.id, { scopes: newScopesStr });
  };

  const deleteToken = async () => {
    const confirm = window.confirm(`Are you sure you want to remove ${application.appName} from your authorized applications?`);
    if (!confirm) return;

    await onDelete(token.id);
    cogoToast.success('Application removed successfully', { hideAfter: 5 });
  };

  return (
    <div className={cn('token-row', { editing: isEditing })}>
      <div className='token-info'>
        <div className='info-left'>
          {developer?.image ? (
            <img src={developer.image} alt={developer.name} />
          ) : (
            <div className='initials'>{getInitialsForUser({ name: application.appName })}</div>
          )}
          <div className='meta'>
            <div className='name'>{application.appName}</div>
            <div className='scopes'>{displayScopes}</div>
          </div>
        </div>
        {!isEditing ? (
          <div className='info-right'>
            <div className='action remove' onClick={deleteToken}>
              Remove
            </div>
            <div className='action' onClick={toggleEdit}>
              Edit
            </div>
          </div>
        ) : (
          <div className='info-right'>
            <div className='action' onClick={toggleEdit}>
              Done
            </div>
          </div>
        )}
      </div>
      {isEditing && (
        <div className='edit-scopes'>
          {Object.keys(OAUTH_SCOPE_DETAILED_DISPLAY_NAMES).map(scopeKey => {
            const isChecked = scopesArr.includes(scopeKey);
            return (
              <div key={scopeKey} className={cn('scope-row', { selected: isChecked })} onClick={() => toggleScope(!isChecked, scopeKey)}>
                <input
                  type='checkbox'
                  name={scopeKey}
                  checked={isChecked}
                  onChange={() => {
                    toggleScope(!isChecked, scopeKey);
                  }}
                />
                {OAUTH_SCOPE_DETAILED_DISPLAY_NAMES[scopeKey]}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

UserTokensPanel.propTypes = {
  user: PropTypes.object.isRequired,
  updateUserAuthenticationToken: PropTypes.func.isRequired,
  deleteUserAuthenticationToken: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  updateUserAuthenticationToken,
  deleteUserAuthenticationToken
})(UserTokensPanel);
