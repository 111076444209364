import React from 'react';
import { connect } from 'react-redux';
import commaNumber from 'comma-number';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './ManageBudgetPanel.scss';

import { openBrandBudgetModal } from '../../Actions/UIActions';
import { getBrandAllowedBudgetOverage, getBrandBudgetPending } from '../../Helpers/user_helpers';
import {
  getAvailableBudgetForBrand,
  getBrandBudgetPendingOpportunityCompletion,
  getBrandBudgetPendingOutstandingRequests
} from '../../Helpers/brand_helpers';

import Tooltip from '../General/Tooltip';

const ManageBudgetPanel = props => {
  const { user, analytics } = props;
  const budgetPending = getBrandBudgetPending(user);
  const availableBudget = getAvailableBudgetForBrand(analytics, user);
  const budgetPendingOpportunityCompletion = getBrandBudgetPendingOpportunityCompletion(analytics, user);
  const budgetPendinOutstandingRequests = getBrandBudgetPendingOutstandingRequests(analytics, user);
  const allowedOverage = getBrandAllowedBudgetOverage(user);

  const openManagementPanel = () => {
    props.openBrandBudgetModal();
  };
  return (
    <div className='manage-budget-panel-container'>
      <div className='main'>
        <div className='data-point'>
          <div className='badge'>Available Balance</div>
          <div className={cn('value', { negative: availableBudget < 0 })}>
            {availableBudget < 0 ? '-' : ''}${commaNumber((Math.abs(availableBudget) || 0)?.toFixed(0))}
          </div>
        </div>
        {!!budgetPending && (
          <Tooltip message='You have a pending budget increase that will be added to your account once it is paid. Please switch to the Invoices tab to get links to the invoices.'>
            <div className='data-point'>
              <div className='badge'>Pending Invoice Completion</div>
              <div className='value'>${commaNumber(budgetPending?.toFixed(0))}</div>
            </div>
          </Tooltip>
        )}
        {!!budgetPendingOpportunityCompletion && (
          <Tooltip message='This budget that is owed to creators for active opportunities that have not yet been completed. This will be returned to the Available budget if they do not complete their expectations in time.'>
            <div className='data-point'>
              <div className='badge'>Awaiting Completion</div>
              <div className='value'>-${commaNumber(budgetPendingOpportunityCompletion?.toFixed(0))}</div>
            </div>
          </Tooltip>
        )}
        {!!budgetPendinOutstandingRequests && (
          <Tooltip message='The budget that you have in outstanding opportunity requests. This will be returned to the Available budget when their offers expire.'>
            <div className='data-point'>
              <div className='badge'>Offers Out</div>
              <div className='value'>-${commaNumber(budgetPendinOutstandingRequests?.toFixed(0))}</div>
            </div>
          </Tooltip>
        )}
        {!!allowedOverage && (
          <Tooltip message='The amount your spending may exceed to allow creators to accept & complete opportunities.'>
            <div className='data-point'>
              <div className='badge'>Allowed Overage</div>
              <div className='value'>${commaNumber(allowedOverage?.toFixed(0))}</div>
            </div>
          </Tooltip>
        )}
      </div>
      <div className='add-btn' onClick={openManagementPanel}>
        Add to Balance
      </div>
    </div>
  );
};

ManageBudgetPanel.propTypes = {
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  openBrandBudgetModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, analytics } = state;
  return { user, analytics };
};

export default connect(mapStateToProps, {
  openBrandBudgetModal
})(ManageBudgetPanel);
